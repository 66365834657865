// components
import Page from "../../../components/Page";
import { useState, useCallback, useContext, useEffect, useRef } from "react";
import Map, { Marker, MapProvider } from "react-map-gl";
// import otherMap from "mapbox-gl";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import FeatureEdit from "./featureEdit";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { ProjectContext } from "src/utils/contexts/project";
import DrawControl from "./draw-control";
import { debounce } from "@mui/material";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import MultiSelect from "../../../components/mui-rhf/Multiselect";
import * as turf from "@turf/turf";
import { arSA } from "date-fns/locale";
import { Link, useParams } from "react-router-dom";
// ----------------------------------------------------------------------

const TOKEN = process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN; // Set your mapbox token here

const GET_PROJECT_LOCATIONS = gql`
  query ProjectLocations($projectId: ID!) {
    projectLocations(projectId: $projectId) {
      id
      description
      city
      buildingType
      identifier
      lat
      lis
      lng
      mapboxId
      name
      neighborhood
      owner
      parentId
      projectId
      state
      street1
      street2
      street3
      tenant
      unit
      zip
    }
  }
`;

const GET_LINE_BETWEEN_FEATURES = gql`
  query LineBetweenFeatures($features: InputLineBetweenFeaturesParams!) {
    lineBetweenFeatures(features: $features) {
      line {
        line {
          latitude
          longitude
        }
      }
      distance
    }
  }
`;

const GET_GEO_FEATURES = gql`
  query GeoFeatures($geoFeature: InputGeoFeatureParams!) {
    geoFeatures(geoFeature: $geoFeature) {
      id
      shape
      name
      type
      projectId
      point {
        latitude
        longitude
      }
      line {
        latitude
        longitude
      }
      polygon {
        latitude
        longitude
      }
      multiPolygon {
        latitude
        longitude
      }
      multiLine {
        latitude
        longitude
      }
    }
  }
`;

const GET_GEO_FEATURE_TYPES = gql`
  query GeoFeatureTypes {
    geoFeatureTypes {
      id
      name
      isDeleted
    }
  }
`;

const UPLOAD_GEO_FEATURE = gql`
  mutation UploadGeoFeature($geoFeature: InputGeoFeatureParams) {
    uploadGeoFeature(geoFeature: $geoFeature) {
      id
    }
  }
`;

const DELETE_GEO_FEATURE = gql`
  mutation DeleteGeoFeature($geoFeature: InputGeoFeatureParams) {
    deleteGeoFeature(geoFeature: $geoFeature) {
      id
    }
  }
`;

export default function EnvironmentMap(props) {
  const mapRef = useRef(null);
  const [addresses, setAddresses] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const [options, setOptions] = useState([]);
  const project = props.project;
  const [currentFeature, setCurrentFeature] = useState(null);
  const [featuresToUpdate, setFeaturesToUpdate] = useState(null);
  const [newFeature, setNewFeature] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasMap, setHasMap] = useState(false);
  const [hasAddresses, setHasAddresses] = useState(false);
  const [shouldUpdateTextLayer, setShouldUpdateTextLayer] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [initialMapZoom, setInitialMapZoom] = useState(false);
  const [capturingManualMap, setCapturingManualMap] = useState(false);
  const [measureLineMode, setMeasureLineMode] = useState(false);
  const [featureTypesLoading, setFeatureTypesLoading] = useState(true);
  const [features, setFeatures] = useState([]);
  const [drawControl, setDrawControl] = useState(null);
  const [geoFeatures, setGeoFeatures] = useState([]);
  const [geoFeatureTypes, setGeoFeatureTypes] = useState([]);
  const [currentGeoFeatureTypes, setCurrentGeoFeatureTypes] = useState(
    props.mapArgs ? (props.mapArgs.types ? props.mapArgs.types : []) : []
  );
  const [draw, setDraw] = useState(null);
  const [map, setMap] = useState(null);
  const [currMapArgs, setCurrMapArgs] = useState(
    props.mapArgs ? props.mapArgs : null
  );
  const [viewport, setViewport] = useState({
    latitude: 39.8283,
    longitude: -98.5795,
    zoom: 2.9,
  });
  const [boundingBox, setBoundingBox] = useState(null);
  const [loadedPreviousFeatures, setLoadedPreviousFeatures] = useState(false);
  const intervalRef = useRef(null);
  const [uploadGeoFeature] = useMutation(UPLOAD_GEO_FEATURE, {
    fetchPolicy: "network-only",
    onCompleted: (response) => { },
  });

  const [deleteGeoFeature] = useMutation(DELETE_GEO_FEATURE, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (response) {
        setFeatures(draw.getAll().features);
        setCurrentFeature(null);
        snackbar("Feature Deleted Successfully", "success");
        updateTextLayer();
      }
    },
  });

  const [getProjectLocations, { called: projLocsCalled }] = useLazyQuery(
    GET_PROJECT_LOCATIONS,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setAddresses(response.projectLocations);
      },
      onError: (response) => { },
    }
  );

  const [getLineBetweenFeatures, { called: lineBetweenFeaturesCalled }] =
    useLazyQuery(GET_LINE_BETWEEN_FEATURES, {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        var result = response.lineBetweenFeatures;
        var line = result.line;
        let lineCoordinates = line.line.map((e) => {
          return [e.longitude, e.latitude];
        });
        var newId = uuidv4();
        var feet =
          (Math.round(result.distance * 3.2808 * 100) / 100).toFixed(2) +
          " ft.";
        var f = {
          type: "Feature",
          properties: {
            text: feet,
            id: newId,
            name: newId,
            type: "Annotation",
            fillColorInactive: "#000000",
            isMeasureLine: true,
          },
          geometry: {
            type: "LineString",
            coordinates: lineCoordinates,
          },
          id: newId,
        };
        draw.add(f);
        var newFeatures = [...features];
        newFeatures.push(f);
        setFeatures(newFeatures);
        updateTextLayer();
      },
      onError: (response) => { },
    });

  const [getGeoFeatures, { refetch, called }] = useLazyQuery(GET_GEO_FEATURES, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (draw) {
        draw.deleteAll();
        updateTextLayer();
        var newFeatures = [];

        response.geoFeatures.forEach((e, index) => {
          switch (e.shape) {
            case "ST_Point":
              let newPoint = {
                type: "Feature",
                properties: {
                  text: e.name,
                  id: e.id,
                  name: e.name,
                  type: e.type,
                  fillColorInactive: "#000000",
                },
                geometry: {
                  type: "Point",
                  coordinates: [e.point.longitude, e.point.latitude],
                },
                id: e.id,
              };
              draw.add(newPoint);
              newFeatures.push(newPoint);
              break;
            case "ST_Polygon":
              let polygonCoordinates = e.polygon.map((e) => {
                return e.map((f) => {
                  return [f.longitude, f.latitude];
                });
              });
              let newPoly = {
                type: "Feature",
                properties: {
                  text: e.name,
                  id: e.id,
                  name: e.name,
                  type: e.type,
                  fillColorInactive: "#000000",
                },
                geometry: {
                  type: "Polygon",
                  coordinates: polygonCoordinates,
                },
                id: e.id,
              };
              draw.add(newPoly);
              newFeatures.push(newPoly);
              break;
            case "ST_MultiPolygon":
              let multiPolygonCoordinates = e.multiPolygon.map((e) => {
                return e.map((f) => {
                  return f.map((g) => {
                    return [g.longitude, g.latitude];
                  });
                });
              });
              let newMultiPoly = {
                type: "Feature",
                properties: {
                  text: e.name,
                  id: e.id,
                  name: e.name,
                  type: e.type,
                  fillColorInactive: "#000000",
                },
                geometry: {
                  type: "MultiPolygon",
                  coordinates: multiPolygonCoordinates,
                },
                id: e.id,
              };
              draw.add(newMultiPoly);
              newFeatures.push(newMultiPoly);
              break;
            case "ST_LineString":
              let lineCoordinates = e.line.map((e) => {
                return [e.longitude, e.latitude];
              });
              let newLine = {
                type: "Feature",
                properties: {
                  text: e.name,
                  id: e.id,
                  name: e.name,
                  type: e.type,
                  fillColorInactive: "#000000",
                },
                geometry: {
                  type: "LineString",
                  coordinates: lineCoordinates,
                },
                id: e.id,
              };
              draw.add(newLine);
              newFeatures.push(newLine);
              break;
            case "ST_MultiLineString":
              let multiLineCoordinates = e.multiLine.map((e) => {
                return e.map((f) => {
                  return [f.longitude, f.latitude];
                });
              });
              let newMultiLine = {
                type: "Feature",
                properties: {
                  text: e.name,
                  id: e.id,
                  name: e.name,
                  type: e.type,
                  fillColorInactive: "#000000",
                },
                geometry: {
                  type: "MultiLineString",
                  coordinates: multiLineCoordinates,
                },
                id: e.id,
              };
              draw.add(newMultiLine);
              newFeatures.add(newMultiLine);
              break;
          }
        });

        setFeatures(newFeatures);
        updateTextLayer();
      }
      setGeoFeatures(response.geoFeatures);
      updateDrawControl(response.geoFeatures);

      setDataLoading(false);
    },
    onError: (response) => { },
  });

  useEffect(() => {
    if (hasMap && hasAddresses) {
      console.log("=========HAS MAP REFERENCE AND ADDRESS=========");
      onStyleLoad();
    }
  }, [hasMap, hasAddresses]);
  const [getGeoFeatureTypes, { called: featureTypesCalled }] = useLazyQuery(
    GET_GEO_FEATURE_TYPES,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setGeoFeatureTypes(response.geoFeatureTypes);
        var opts = [
          // {
          //   label: "[Filter Geo Feature Type]",
          //   value: "[Filter Geo Feature Type]",
          // },
          { label: "Annotation", value: "Annotation" },
        ];
        for (var i = 0; i < response.geoFeatureTypes.length; i++) {
          let doesExist = opts.some(function (ele) {
            return ele.label === response.geoFeatureTypes[i].name;
          });
          if (!doesExist) {
            opts.push({
              label: response.geoFeatureTypes[i].name,
              value: response.geoFeatureTypes[i].name,
            });
          }
        }
        setOptions(opts);

        setFeatureTypesLoading(false);
      },
      onError: (response) => { },
    }
  );

  const onCurrentSubmit = async (values, snackbar) => {
    var newFeatures = [];
    var f = currentFeature;
    var shape = f.geometry.type;
    var geoms = [];
    var multiPolygonGeoms = [];
    var multiLineGeoms = [];
    switch (f.geometry.type) {
      case "Point":
        shape = "ST_Point";
        geoms.push({
          latitude: f.geometry.coordinates[1],
          longitude: f.geometry.coordinates[0],
        });
        break;
      case "Polygon":
        shape = "ST_Polygon";
        var polyCoords = f.geometry.coordinates[0];
        for (var i = 0; i < polyCoords.length; i++) {
          let coord = polyCoords[i];
          geoms.push({ latitude: coord[1], longitude: coord[0] });
        }
        break;
      case "MultiPolygon":
        shape = "ST_MultiPolygon";
        var polyCoords = f.geometry.coordinates;

        for (var i = 0; i < polyCoords.length; i++) {
          let polys = polyCoords[i];
          var polyGeoms = [];
          for (var j = 0; j < polys.length; j++) {
            var coords = polys[j];
            var polyCs = [];
            for (var k = 0; k < coords.length; k++) {
              var pc = coords[k];
              polyCs.push({ latitude: pc[1], longitude: pc[0] });
            }
            polyGeoms.push(polyCs);
          }
          multiPolygonGeoms.push(polyGeoms);
        }

        break;
      case "LineString":
        shape = "ST_LineString";
        var lineCoords = f.geometry.coordinates;
        for (var i = 0; i < lineCoords.length; i++) {
          let coord = lineCoords[i];
          geoms.push({ latitude: coord[1], longitude: coord[0] });
        }
        break;
      case "MultiLineString":
        shape = "ST_MultiLineString";

        var lineCoords = f.geometry.coordinates;
        for (var i = 0; i < lineCoords.length; i++) {
          let coord = lineCoords[i];
          let lineResults = [];
          for (var j = 0; j < coord.length; j++) {
            let c = coord[j];
            lineResults.push({ latitude: c[1], longitude: c[0] });
          }
          multiLineGeoms.push(lineResults);
        }
        break;
    }
    var geoFeature = {
      id: f.properties.id,
      shape: shape,
      name: values.name,
      type: values.type,
      projectId: project.project.id,
      isCreate: false,
    };
    if (geoms.length > 0) {
      geoFeature.geometry = geoms;
    }
    if (multiPolygonGeoms.length > 0) {
      geoFeature.multiPolygonGeometry = multiPolygonGeoms;
    }
    if (multiLineGeoms.length > 0) {
      geoFeature.multiLineGeometry = multiLineGeoms;
    }
    await uploadGeoFeature({
      variables: {
        geoFeature: geoFeature,
      },
    }).then((result) => {
      if (result) {
        var oldId = f.id;
        f.properties.id = result.data.uploadGeoFeature.id;
        f.properties.name = values.name;
        f.properties.type = values.type;
        f.properties.text = values.name;
        if (draw) {
          draw.setFeatureProperty(oldId, "id", f.properties.id);
          draw.setFeatureProperty(oldId, "name", f.properties.name);
          draw.setFeatureProperty(oldId, "type", f.properties.type);
          draw.setFeatureProperty(oldId, "text", f.properties.text);
        }
        newFeatures.push(f);
        setCurrentFeature(f);

        setFeatures(draw.getAll().features);
        snackbar("Updated Feature", "success");
        updateTextLayer();
      } else {
        snackbar("Update Failed", "error");
      }
    });
  };

  const onNewSubmit = async (values, snackbar) => {
    var newFeatures = [];
    var f = newFeature;
    var shape = f.geometry.type;
    var geoms = [];
    switch (f.geometry.type) {
      case "Point":
        shape = "ST_Point";
        geoms.push({
          latitude: f.geometry.coordinates[1],
          longitude: f.geometry.coordinates[0],
        });
        break;
      case "Polygon":
        shape = "ST_Polygon";
        var polyCoords = f.geometry.coordinates[0];
        for (var i = 0; i < polyCoords.length; i++) {
          let coord = polyCoords[i];
          geoms.push({ latitude: coord[1], longitude: coord[0] });
        }
        break;
      case "LineString":
        shape = "ST_LineString";
        var lineCoords = f.geometry.coordinates;
        for (var i = 0; i < lineCoords.length; i++) {
          let coord = lineCoords[i];
          geoms.push({ latitude: coord[1], longitude: coord[0] });
        }
        break;
    }
    await uploadGeoFeature({
      variables: {
        geoFeature: {
          geometry: geoms,
          shape: shape,
          name: values.name,
          type: values.type,
          projectId: project.project.id,
          isCreate: true,
        },
      },
    }).then((result) => {
      if (result) {
        var oldId = f.id;
        f.properties.id = result.data.uploadGeoFeature.id;
        f.properties.name = values.name;
        f.properties.type = values.type;
        f.properties.text = values.name;
        f.properties.fillColorInactive = "#000000";
        if (draw) {
          draw.setFeatureProperty(oldId, "id", f.properties.id);
          draw.setFeatureProperty(oldId, "name", f.properties.name);
          draw.setFeatureProperty(oldId, "type", f.properties.type);
          draw.setFeatureProperty(oldId, "text", f.properties.text);
          draw.setFeatureProperty(oldId, "fillColorInactive", "#000000");
          setFeatures(draw.getAll().features);
        }
        newFeatures.push(f);
        setCurrentFeature(f);
        setNewFeature(null);
        setDialogOpen(false);
        let all = draw.getAll();
        setFeatures(all.features);

        snackbar("Created Feature", "success");
        updateTextLayer();
      } else {
        snackbar("Create Failed", "error");
      }
    });
  };

  const handleClose = () => {
    if (!currentFeature.properties) {
      draw.delete(currentFeature.id);
      setCurrentFeature(null);
      setNewFeature(null);
      setDialogOpen(false);
      return;
    }
    if (!currentFeature.properties.name || !currentFeature.properties.type) {
      draw.delete(currentFeature.id);
      setCurrentFeature(null);
      setNewFeature(null);
      setDialogOpen(false);
      return;
    }
  };

  const getZoomLevel = (latitude, radiusInFeet) => {
    const equatorLength = 40075016.686; // in meters
    const latitudeRadians = latitude * (Math.PI / 180);
    const earthCircumferenceAtLatitude =
      equatorLength * Math.cos(latitudeRadians);
    const earthCircumferenceInFeet = earthCircumferenceAtLatitude * 3.28084;
    const zoomLevel =
      Math.log2(earthCircumferenceInFeet / (radiusInFeet * 2)) - 1;
    return zoomLevel * 20;
  };

  useEffect(() => {
    if (draw) {
      let usePreviousViewstate = false;
      if (currMapArgs) {
        if (currMapArgs.features) {
          usePreviousViewstate = true;
        }
      }
      if (usePreviousViewstate && !loadedPreviousFeatures) {
        draw.deleteAll();
        currMapArgs.features.features.forEach((e, index) => {
          draw.add(e);
        });
        setLoadedPreviousFeatures(true);
        updateTextLayer();
      }
    }
  }, [draw]);

  useEffect(() => {
    if (featureTypesLoading) {
      async function getFeatureTypeData() {
        await getGeoFeatureTypes();
      }
      let timer = setTimeout(() => {
        getFeatureTypeData();
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  });

  async function getData() {
    let myProject = project.project;
    if (myProject && boundingBox) {
      var geoFeature = {
        projectId: myProject.id,
        bounds: {
          minX: boundingBox.minLat,
          minY: boundingBox.minLong,
          maxX: boundingBox.maxLat,
          maxY: boundingBox.maxLong,
        },
        typesList: currentGeoFeatureTypes,
      };

      await getGeoFeatures({
        variables: {
          geoFeature: geoFeature,
        },
      });
    }
  }

  // useEffect(() => {
  //   if (dataLoading && !called && !featureTypesLoading) {
  //     let timer = setTimeout(() => {
  //       getData();
  //     }, 500);
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }
  // });

  useEffect(() => {
    if (!featureTypesLoading) {
      getData();
    }
  }, [currentGeoFeatureTypes, featureTypesLoading]);

  function radians(degrees) {
    return degrees * (Math.PI / 180);
  }

  function degrees(radians) {
    return radians * (180 / Math.PI);
  }

  function calculateSquareRadius(lat, lng, radius) {
    var R = 6371; // earth radius in km
    var latMin = lat - degrees(radius / R);
    var latMax = lat + degrees(radius / R);
    var lngMin = lng - degrees(radius / R / Math.cos(radians(lat)));
    var lngMax = lng + degrees(radius / R / Math.cos(radians(lat)));

    return { minLat: latMin, maxLat: latMax, minLong: lngMin, maxLong: lngMax };
  }

  useEffect(() => {
    if (!props.addresses) {
      if (!projLocsCalled) {
        let args = {
          projectId: project.project.id,
        };
        let variables = {
          variables: args,
        };

        getProjectLocations(variables);
      }
    } else {
      setAddresses(props.addresses);
    }
  });

  useEffect(() => {
    if(addresses){
      if(addresses.length > 0){
        setHasAddresses(true);
      }
    }
    let usePreviousViewstate = false;
    if (currMapArgs) {
      if (currMapArgs.viewport) {
        usePreviousViewstate = true;
      }
    }
    if (usePreviousViewstate) {
      let ne = currMapArgs.viewport.bounds._ne;
      let sw = currMapArgs.viewport.bounds._sw;
      setInitialMapZoom(false);
      setViewport(currMapArgs.viewport);
      let minLat = sw.lat,
        maxLat = ne.lat;
      let minLng = sw.lng,
        maxLng = ne.lng;

      var bounds = {
        minLat: minLat,
        maxLat: maxLat,
        minLong: minLng,
        maxLong: maxLng,
      };
      setBoundingBox(bounds);
    } else {
      if (!addresses) {
        return;
      }
      let centerLat;
      let centerLng;
      if (addresses.length === 0) {
        return;
      }
      if (addresses.length === 1) {
        centerLat = addresses[0].lat;
        centerLng = addresses[0].lng;

        var bounds = calculateSquareRadius(centerLat, centerLng, 25);
        setBoundingBox(bounds);
      } else {
        let minLat = addresses[0].lat,
          maxLat = addresses[0].lat;
        let minLng = addresses[0].lng,
          maxLng = addresses[0].lng;

        addresses.forEach(({ lat, lng }) => {
          minLat = Math.min(minLat, lat);
          maxLat = Math.max(maxLat, lat);
          minLng = Math.min(minLng, lng);
          maxLng = Math.max(maxLng, lng);
        });

        var bounds = {
          minLat: minLat,
          maxLat: maxLat,
          minLong: minLng,
          maxLong: maxLng,
        };
        setBoundingBox(bounds);

        const latExpansion = 17000 / 364320; // Convert feet to degrees
        const lngExpansion = latExpansion / Math.cos((minLat * Math.PI) / 180); // Adjust for longitude

        minLat -= latExpansion;
        maxLat += latExpansion;
        minLng -= lngExpansion;
        maxLng += lngExpansion;

        centerLat = (minLat + maxLat) / 2;
        centerLng = (minLng + maxLng) / 2;
      }
      const zoomLevel = getZoomLevel(centerLat, 17000); // 17,000 feet

      setViewport({
        ...viewport,
        latitude: centerLat,
        longitude: centerLng,
        zoom: zoomLevel,
      });
    }
  }, [addresses]);

  useEffect(() => {
    if (map && boundingBox && !initialMapZoom) {
      setInitialMapZoom(true);
      map.fitBounds(
        [
          [boundingBox.minLong, boundingBox.minLat],
          [boundingBox.maxLong, boundingBox.maxLat],
        ],
        { padding: 40, duration: 1000 }
      );
    }
  }, [map, boundingBox]);

  const onCreate = useCallback(
    async (e) => {
      var newFeatures = features;
      for (var f of e.features) {
        newFeatures.push(f);
      }

      setFeatures(newFeatures);

      var index = newFeatures.indexOf(
        newFeatures.filter((d) => d.id === e.features[0].id)[0]
      );
      setNewFeature(newFeatures[index]);
      setDialogOpen(true);
      return newFeatures;
    },
    [features, setFeatures]
  );

  useEffect(() => {
    if (featuresToUpdate) {
      var newFeatures = features;
      if (!newFeatures) {
        newFeatures = [];
      }
      for (var f of featuresToUpdate.features) {
        var filtered = newFeatures.filter((d) => d.id === f.id);
        if (filtered.length > 0) {
          var index = newFeatures.indexOf(filtered[0]);
          newFeatures[index].geometry = f.geometry;

          if (f.properties.isMeasureLine) {
            var from = turf.point(f.geometry.coordinates[0]);
            var to = turf.point(f.geometry.coordinates[1]);
            var options = { units: "feet" };
            var distance = turf.distance(from, to, options);
            var feet = distance.toFixed(2) + " ft.";
            newFeatures[index].properties.text = feet;
          }
        } else {
          newFeatures.push(f);
        }
      }

      setFeatures(newFeatures);
      setShouldUpdateTextLayer(true);

      var index = newFeatures.indexOf(
        newFeatures.filter((d) => d.id === featuresToUpdate.features[0].id)[0]
      );
      setCurrentFeature(newFeatures[index]);
    }
  }, [featuresToUpdate]);

  const handleUpdate = async (e) => {
    setFeaturesToUpdate(e);
  };

  const onUpdate = useCallback(debounce(handleUpdate, 1000), [
    features,
    setFeatures,
  ]);

  function selectionChange(e) {
    if (e.features.length > 0) {
      var selected = features[e.features[0].id];
      if (selected) {
        setCurrentFeature(selected);
      } else {
        setCurrentFeature(e.features[0]);
      }
    } else {
      setCurrentFeature(null);
    }
  }

  useEffect(() => {
    if (measureLineMode && addresses.length === 1 && currentFeature) {
      let variables = {
        variables: {
          features: {
            point: {
              latitude: addresses[0].lat,
              longitude: addresses[0].lng,
            },
            featureId: currentFeature.id,
          },
        },
      };

      draw.changeMode("simple_select", { featureIds: [] });
      getLineBetweenFeatures(variables);
      setCurrentFeature(null);
      setMeasureLineMode(false);
    }
  }, [currentFeature]);

  useEffect(() => {
    if (shouldUpdateTextLayer) {
      var measureLines = features.filter((f) => f.properties.isMeasureLine);
      for (var i = 0; i < measureLines.length; i++) {
        var idToUpdate = measureLines[i].id;
        var newText = measureLines[i].properties.text;
        draw.setFeatureProperty(idToUpdate, "text", newText);
      }
      updateTextLayer();
      setShouldUpdateTextLayer(false);
    }
  }, [shouldUpdateTextLayer]);

  useEffect(()=>{

  }, [capturingManualMap]);

  const onDelete = useCallback(
    (e) => {
      if (!e.features[0].properties.isMeasureLine) {
        deleteGeoFeature({
          variables: {
            geoFeature: {
              id: e.features[0].properties.id,
            },
          },
        });
      }
      setCurrentFeature(null);
    },
    [features]
  );

  function updateDrawControl(f) {
    let dc = (
      <DrawControl
        position="top-left"
        displayControlsDefault={false}
        controls={{
          point: true,
          line_string: true,
          polygon: true,
          trash: true,
        }}
        geoFeatures={f}
        selectionChange={(e) => {
          selectionChange(e);
        }}
        onCreate={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
        setDraw={setDraw}
        setMap={(map)=>{setMap(map); setHasMap(true);}}
        updateTextLayer={updateTextLayer}
      />
    );
    setDrawControl(dc);
  }

  function onStyleLoad(){
    const markersGeoJson = {
      type: 'FeatureCollection',
      features: addresses.map(address => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [address.lng, address.lat]
        },
        properties: {
          id: address.id // Assuming each address has a unique id
        }
      }))
    };
    if (map.getSource('markers')) {
      // Update data if the source already exists
      map.getSource('markers').setData(markersGeoJson);
    } else {
      // Add source and layer if they don't exist
      map.addSource('markers', {
        type: 'geojson',
        data: markersGeoJson
      });

      map.addLayer({
        id: 'markers',
        type: 'circle',
        source: 'markers',
        paint: {
          'circle-radius': 6,
          'circle-color': '#ff0000'
        }
      });
    }
  };

  function updateTextLayer() {
    map.removeLayer("textLayer");
    map.removeSource("textLayer");
    map.removeLayer("lineTextLayer");
    map.removeSource("lineTextLayer");
    map.removeLayer("polyPatternFill");
    map.removeSource("polyPatternFill");
    var color = "";
    var opositeColor = "";
    var switchy = document.getElementById("remover");
    if (switchy.className.includes("satellite-turned-on")) {
      color = "#ffffff";
      opositeColor = "#000000";
    } else {
      color = "#000000";
      opositeColor = "#ffffff";
    }
    var all = draw.getAll();

    let allNotLines = {
      type: "FeatureCollection",
      features: all.features.filter(
        (el) =>
          el.geometry.type != "LineString" &&
          el.geometry.type != "MultiLineString"
      ),
    };

    var textPoints = [];
    for (var i = 0; i < allNotLines.features.length; i++) {
      var f = allNotLines.features[i];
      var point = turf.center(f);
      point.properties = f.properties;
      
      textPoints.push(point);
    }

    let textPointFeatureCollection = {
      type: "FeatureCollection",
      features: textPoints,
    };

    let allLines = {
      type: "FeatureCollection",
      features: all.features.filter(
        (el) =>
          el.geometry.type == "LineString" ||
          el.geometry.type == "MultiLineString"
      ),
    };

    var lineTextPoints = [];

    for (var i = 0; i < allLines.features.length; i++) {
      var f = allLines.features[i];
      var point = turf.center(f);
      point.properties = f.properties;
      
      lineTextPoints.push(point);
    }

    let lineTextPointFeatureCollection = {
      type: "FeatureCollection",
      features: lineTextPoints,
    };

    map.addLayer(
      {
        id: "polyPatternFill",
        type: "fill",
        source: {
          type: "geojson",
          data: all, //Initially this is empty
        },
        filter: ["all", ["==", "$type", "Polygon"]],
        paint: {
          "fill-pattern": [
            "match",
            ["get", "user_type"], // get the property
            "Runway",
            "polyStripesRed",
            "polyStripesBlue",
          ],
        },
      },
      "admin-2-boundaries-dispute"
    );

    map.addLayer({
      id: "textLayer",
      type: "symbol",
      source: {
        type: "geojson",
        //data: allNotLines, //Initially this is empty
        data: textPointFeatureCollection,
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#ffffff",
        "text-halo-width": 3,
      },
      layout: {
        "text-field": ["get", "text"],
        "text-rotation-alignment": "auto",
        "text-allow-overlap": true,
        "text-size": 12,
        "text-anchor": "center",
      },
    });

    map.addLayer({
      id: "lineTextLayer",
      type: "symbol",
      source: {
        type: "geojson",
        data: allLines, //Initially this is empty
      },
      paint: {
        "text-color": "#000000",
        "text-halo-color": "#ffffff",
        "text-halo-width": 3,
      },
      layout: {
        "text-field": ["get", "text"],
        "text-rotation-alignment": "auto",
        "symbol-placement": "line-center",
        "text-allow-overlap": true,
        "text-size": 12,
        "text-anchor": "top",
        //"text-offset": ["literal", [-0.84, 0.23]]
      },
    });
    updateMapArgsFeatures();
  }

  function updateMapArgsTypes(types) {
    let newMapArgs = { ...currMapArgs };
    newMapArgs.types = types;
    if(setCurrMapArgs){
    setCurrMapArgs(newMapArgs);
    }
    if(props.setMapArgs){
    props.setMapArgs(newMapArgs);
    }
  }

  function updateMapArgsViewport(viewport) {
    let newMapArgs = { ...currMapArgs };
    newMapArgs.viewport = viewport;
    if(setCurrMapArgs){
      let view = map.getBounds();
      let sw = view._sw;
      let ne = view._ne;
      let minLat = sw.lat,
        maxLat = ne.lat;
      let minLng = sw.lng,
        maxLng = ne.lng;

      var bounds = {
        minLat: minLat,
        maxLat: maxLat,
        minLong: minLng,
        maxLong: maxLng,
      };
      setBoundingBox(bounds);
      getData();
      setCurrMapArgs(newMapArgs);
    }
    if(props.setMapArgs){
    props.setMapArgs(newMapArgs);
    }
  }

  function updateMapArgsFeatures() {
    let newMapArgs = { ...currMapArgs };
    newMapArgs.features = draw.getAll();
    if(setCurrMapArgs){
    setCurrMapArgs(newMapArgs);
    }
    if(props.setMapArgs){
    props.setMapArgs(newMapArgs);
    }
  }

  const handleCreateMapImage = () => {
    const map = mapRef.current.getMap();
    const canvas = map.getCanvas();
    const image = canvas.toDataURL('image/png');
    return image
  };

  function getIsEditing(){
    if(draw){
      let currMode = draw.getMode();
      debugger;
      if(currMode !== "simple_select"){
        return true;
      }
    }
    return false;
  }

  let showFilter = !currentFeature && !newFeature && !featureTypesLoading;
  let showFeatureEdit = currentFeature && !newFeature;

  return (
    <Page title="Environment Map | FINBACK670">
        {!capturingManualMap && (
      <Grid container spacing={2} columns={12}>
        <Grid item xs={4}>
          <Grid container spacing={2} columns={12}>
            <Grid item xs={12}>
              <Button
                id="remover"
                variant="contained"
                data="satellite-turned-off"
              >
                Add Satelite View
              </Button>
            </Grid>
            {!showFeatureEdit && props.showMeasurementLine && (
              <Grid item xs={12}>
                <Button
                  id="measureLine"
                  variant="contained"
                  onClick={() => {
                    setMeasureLineMode(!measureLineMode);
                  }}
                >
                  Add Measurement Line
                </Button>
              </Grid>
            )}
            {showFeatureEdit && (
              <Grid item xs={12}>
                <Button
                  id="stopEdit"
                  variant="contained"
                  onClick={() => {

                    draw.changeMode("simple_select", { featureIds: [] });
                    setCurrentFeature(null);
                    setMeasureLineMode(false);
                  }}
                >
                  Stop Editing
                </Button>
              </Grid>
            )}
            {props.isCapture && (
              <Grid item xs={12}>
                <Button
                  id="confirmMapCapture"
                  variant="contained"
                  onClick={() => {
                    setCapturingManualMap(true);
                    const mapImage = handleCreateMapImage();
                    // var mapBounds = map.getBounds();
                    // var mapBoundsBBox = turf.polygon
                    // debugger;
                    // var bboxes = currMapArgs.features.features.map((f)=>{
                    //   return turf.bboxPolygon(f);
                    // });
                    // var resultFeatures = [];
                    // for(var i = 0; i<bboxes.length; i++){
                    //   if(turf.booleanOverlap(bboxes[i], mapBounds)){
                    //     resultFeatures.push(currMapArgs.features[i]);
                    //   }
                    // }
                    // debugger;
                    props.handleUploadMapCapture({captureArgs: props.mapCaptureArgs, mapArgs: currMapArgs, mapImage});
                  }}
                >
                  Confirm Map Capture
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {measureLineMode && (
          <Grid item xs={8}>
            Click on a feature to place a measurement line.
          </Grid>
        )}
        {showFilter && options.length > 0 && !measureLineMode && (
          <Grid item xs={8}>
            <MultiSelect
              options={options}
              label="Feature Types"
              placeholder="Feature Types"
              defaultValue={currentGeoFeatureTypes.map((e) => {
                return { label: e, value: e };
              })}
              onChange={(e) => {
                var types = e.map((e) => e.value);
                setCurrentGeoFeatureTypes(types);
                updateMapArgsTypes(types);
              }}
            ></MultiSelect>
          </Grid>
        )}
        {showFeatureEdit && (
          <Grid item xs={8}>
            <FeatureEdit
              currentFeature={currentFeature}
              onSubmit={onCurrentSubmit}
              options={options}
            ></FeatureEdit>
          </Grid>
        )}
        <Grid item xs={12}>
          <div>
            {dataLoading && <CircularProgress></CircularProgress>}
            {/* <Button onClick={handlePrint} variant="contained" type="submit">Capture Map</Button> */}
            <div ref={props.componentRef}>
              {addresses && (
                <MapProvider>
                  <Map
                    onDragEnd={(e) => {
                      updateMapArgsViewport({
                        bounds: map.getBounds(),
                        viewState: e.viewState,
                      });
                    }}
                    onZoomEnd={(e) => {
                      updateMapArgsViewport({
                        bounds: map.getBounds(),
                        viewState: e.viewState,
                      });
                    }}
                    // {...viewport}
                    // onViewportChange={(nextViewport) => setViewport(nextViewport)}
                    //initialViewState={{ ...viewport }}
                    style={{ width: 500, height: 500 }}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    mapboxAccessToken={TOKEN}
                    preserveDrawingBuffer={true}
                    ref={mapRef}
                  >
                    {drawControl}
                    {/* {addresses.map((address, index) => (
                      <Marker
                        key={index}
                        latitude={address.lat}
                        longitude={address.lng}
                      >
                        <div
                          style={{
                            backgroundColor: "red",
                            width: "12px",
                            height: "12px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </Marker>
                    ))} */}
                    {/* {geoFeatures.map((e: any) =>{
          switch(e.shape){
            case "Point":
              return <Marker longitude={e.point.longitude} latitude={e.point.latitude} key={e.id}></Marker>
            case "Polygon":
              let polygonCoordinates = e.polygon.map((e: any) => {
               return e.map((f: any) =>{
                  return [f.longitude, f.latitude];
                })
              });
              return <Source
              key={e.id}
                  type="geojson"
                  data={{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                      type: 'Polygon',
                      coordinates: polygonCoordinates,
                    },
                  }}
                >
                  <Layer
                    id="zoneLayer"
                    type="fill"
                    paint={{
                      'fill-color': '#82D0F5',
                      'fill-opacity': 0.5,
                    }}
                  />
                  <Layer
                    id="outlineLayer"
                    type="line"
                    paint={{
                      'line-color': '#007DBB',
                      'line-width': 3,
                    }}
                  />
                </Source>
              case "Line":
                let lineCoordinates = e.line.map((e: any) => {
                     return [e.longitude, e.latitude];
                 });
                return (
                  <Source
                  key={e.id}
                    type="geojson"
                    data={{
                      type: 'Feature',
                      properties: {},
                      geometry: {
                        type: 'LineString',
                        coordinates: lineCoordinates,
                      },
                    }}
                  >
                    <Layer
                      id="lineLayer"
                      type="line"
                      layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                      }}
                      paint={{
                        'line-color': '#007DBB',
                        'line-width': 5,
                      }}
                    />
                  </Source>
                ); 
          }
          return <></>
        })} */}
                  </Map>
                </MapProvider>
              )}
            </div>
          </div>
        </Grid>

        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>New Feature</DialogTitle>
          <DialogContent>
            <FeatureEdit
              currentFeature={newFeature}
              onSubmit={onNewSubmit}
              options={options}
            ></FeatureEdit>
          </DialogContent>
        </Dialog>
      </Grid>)}
      {capturingManualMap && (
        <Box><CircularProgress></CircularProgress><Typography> Generating Map Capture</Typography></Box>
      )}
    </Page>
  );
}
