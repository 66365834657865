import { Field, Operator } from "../TypeDefinitions";

export default function getAvailableOperators(field: Field) {
  let availableOperators: Operator[] = ["not null"];
  switch (field?.inputType) {
    case "Number":
      availableOperators.push("<", ">", "==", "!==");
      break;
    case "Checklist":
      availableOperators.push(
        "contains",
        "does not contain",
        "contains one of",
        "does not contain one of"
      );
      break;
    case "DropDown":
    case "OptionSelect":
      availableOperators.push("==", "!==");
      break;
    case "YesNo":
      availableOperators.push("==");
  }
  return availableOperators;
}
