import {
  PageState,
  Action,
  Validation,
} from "../../components/TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function createValidation<
  E extends Extract<
    Action,
    {
      type: "createValidation";
    }
  >
>(action: E, draft: PageState): void {
  if (draft.editingFieldId === null) return;

  const newValidation: Validation = generateNewValidation(
    draft.formId,
    draft.editingFieldId
  );

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.push(newValidation);
}

function generateNewValidation(formId: string, fieldId: string): Validation {
  const newValidation: Validation = {
    formId: formId,
    id: uuidv4(),
    expressions: [],
    field: fieldId,
    message: null,
    value: null,
    onTrigger: "ValueChange",
    action: null,
  };
  return newValidation;
}
