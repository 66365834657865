import { PageState, Action } from "../../components/TypeDefinitions";

export function moveForm<
  E extends Extract<
    Action,
    {
      type: "moveForm";
    }
  >
>(action: E, draft: PageState): void {
  const { newIndex, initialIndex } = action.payload;

  const movedForm =
    draft.data.inspectionTypeTemplate.configuration.forms.splice(
      initialIndex,
      1
    )[0];

  draft.data.inspectionTypeTemplate.configuration.forms.splice(
    newIndex,
    0,
    movedForm
  );
}
