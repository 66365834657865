import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline' // a plugin!
import resourcePlugin from '@fullcalendar/resource' // a plugin!

export default function ResourceTimeline({ events, inspectors, setHoverOpen, setHoverEvent }) {

    const resources = inspectors.map((inspector) => {
        return {
            id: inspector.id,
            title: `${inspector.firstName} ${inspector.lastName}`,
            ...inspector
        }
    })
    return (
        <FullCalendar
            key={events.length + inspectors.length}
            height={'auto'}
            initialView='resourceTimelineMonth'
            scrollTime={Date.now()}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'resourceTimelineMonth,resourceTimelineWeek,resourceTimelineDay'
            }}
            events={events}
            eventClick={function(info) {
                setHoverEvent(info.event)
                setHoverOpen(true)
            }}
            resources={resources}
            initialResources={resources}
            schedulerLicenseKey="0623213103-fcs-1674152181"
            plugins={[resourcePlugin, resourceTimelinePlugin]}
        />
    )
}
