import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import React, { useContext } from "react";
import { FormCtx } from "../FormWrapper/BuilderWrapper";

export default function DeleteDialog() {
  const { state, dispatch } = useContext(FormCtx);
  return (
    <Dialog
      open={state.deleteConfig !== null}
      onClose={(e) => dispatch({ type: "toggleDelete", payload: null })}
    >
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {state.deleteConfig !== null && state.deleteConfig.deleteMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            dispatch({
              type: "toggleDelete",
              payload: null,
            })
          }
        >
          Cancel
        </Button>
        <Button
          color="error"
          onClick={(e) =>
            dispatch({
              type: "deleteField",
              payload: {
                fieldId: state.deleteConfig.fieldId,
              },
            })
          }
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
