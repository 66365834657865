import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { createQuery } from "../../../utils/gql-mui-rhf/QueryAction";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { GQLMutationAction } from "../../../utils/gql-mui-rhf/SingleMutation";
import FieldGroup from "./FieldGroup";
// components
import BackgroundCheck from "./BackgroundCheck";
import CredentialFields from "./CredentialFields";
import Confirmation from "../../../components/modals/confirmation";
import Page from "../../../components/Page";
// ----------------------------------------------------------------------
function createDraftRoleValue(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "createDraftRoleValue",
    onCompleted: (response) => {
      refetch();
      snackbar("Saved Field", "success");
    },
  });
}
function createDraftCredentialValue(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "createDraftCredentialValue",
    onCompleted: (response) => {
      refetch();
      snackbar("Saved Field", "success");
    },
  });
}
function submitRolesRequest(
  refetch,
  snackbar,
  handleSendSubmitEmail,
  navigate
) {
  return GQLMutationAction({
    mutationString: "submitRolesRequest",
    onCompleted: (response) => {
      handleSendSubmitEmail();
      snackbar("Submitted Request Information", "success");
      refetch();
      navigate();
    },
  });
}
function submitCreateEmail(onCompleted) {
  return GQLMutationAction({
    mutationString: "createEmail",
    onCompleted: (response) => {
      onCompleted();
    },
  });
}
const processRoles = (draftRoles) => {
  return draftRoles.map((draftRole) => {
    return {
      ...draftRole,
      draftRoleValues: draftRole.draftRoleValues.filter(
        (value) => value.roleTypeValue.active
      ),
      roleType: {
        ...draftRole.roleType,
        roleTypeValues: draftRole.roleType.roleTypeValues
          .filter((value) => value.active)
          .sort((a, b) => {
            const aIndex = draftRole.roleType.valueOrder.indexOf(a.id);
            const bIndex = draftRole.roleType.valueOrder.indexOf(b.id);
            return aIndex - bIndex;
          }),
      },
    };
  });
};
const processFormEmail = (rolesRequest, handleCreateEmail) => {
  let unprocessedRoles = rolesRequest.draftRoles;
  let processedRoles = processRoles(unprocessedRoles);
  
  let variables = { data: {} };
  variables["templateId"] = "d-d0b307a0a57b44679a384c0e17177d74";
  variables["to"] = rolesRequest.email;
  variables["data"][
    "url"
  ] = `${process.env.REACT_APP_URL}/onboarding/request/${rolesRequest.id}`;
  if (rolesRequest.entity === "PERSON")
    variables["data"][
      "name"
    ] = `${rolesRequest.firstName} ${rolesRequest.lastName}`;
  else variables["data"]["name"] = rolesRequest.name;
  variables["data"]["draftRoles"] = processedRoles;
  variables["data"] = JSON.stringify(variables.data);
  handleCreateEmail(variables);
};

const processSubmitEmail = (rolesRequest, handleCreateEmail) => {
  let variables = { data: {} };
  variables["templateId"] = "d-07c0810056d84dce8cad0f333bc7308f";
  variables["to"] = rolesRequest.email;
  variables["data"][
    "url"
  ] = `${process.env.REACT_APP_URL}/app/team/onboarding/request/${rolesRequest.id}`;
  if (rolesRequest.entity === "PERSON")
    variables["data"][
      "name"
    ] = `${rolesRequest.firstName} ${rolesRequest.lastName}`;
  else variables["data"]["name"] = rolesRequest.name;
  variables["data"] = JSON.stringify(variables.data);
  handleCreateEmail(variables);
};

const assessCredentialValue = (value) => {
  switch (value.type) {
    case "BOOLEAN":
      return value.booleanValue;
    case "TEXT":
      return value.stringValue;
    case "INTEGER":
      return value.integerValue;
    case "DECIMAL":
      return value.decimalValue;
    case "DATE":
      return value.dateValue;
    case "IMAGE":
      return value.stringValue;
    case "PDF":
      return value.stringValue;
    default:
      return value.stringValue;
  }
};
const assessValue = (value) => {
  switch (value.roleTypeValue.type) {
    case "BOOLEAN":
      return value.booleanValue;
    case "TEXT":
      return value.stringValue;
    case "INTEGER":
      return value.integerValue;
    case "DECIMAL":
      return value.decimalValue;
    case "DATE":
      return value.dateValue;
    case "IMAGE":
      return value.stringValue;
    case "PDF":
      return value.stringValue;
    default:
      return value.stringValue;
  }
};
const getDefaultValues = (draftRoleValues) => {
  const defaultValues = {};
  draftRoleValues.forEach((value) => {
    if (value?.roleTypeValue?.name)
      defaultValues[value.roleTypeValue.name] = assessValue(value);
  });
  return defaultValues;
};
const draftRoleValuesObject = (draftRoleValues) => {
  const defaultValues = {};
  draftRoleValues.forEach((value) => {
    if (value?.roleTypeValue?.name)
      defaultValues[value.roleTypeValue.name] = {
        value: assessValue(value),
        status: value.status,
      };
  });
  return defaultValues;
};
const getCredentialDefaultValues = (draftCredentialValues) => {
  const defaultValues = {};
  draftCredentialValues.forEach((value) => {
    if (value?.roleTypeCredentialValue?.name)
      defaultValues[value.roleTypeCredentialValue.name] =
        assessCredentialValue(value);
  });
  return defaultValues;
};
const draftCredentialValuesObject = (draftCredentialValues) => {
  const defaultValues = {};
  draftCredentialValues.forEach((value) => {
    if (value?.roleTypeCredentialValue?.name)
      defaultValues[value.roleTypeCredentialValue.name] = {
        value: assessCredentialValue(value),
        status: value.status,
      };
  });
  return defaultValues;
};

const getRequiredFields = (draftRoles, draftCredentials) => {
  let requiredFields = [];
  let requiredFieldsWithValues = [];
  let requiredFieldsWithoutValues = [];
  let draftRoleValues = {};
  draftRoles.forEach((draftRole) => {
    if (draftRole.draftRoleValues) {
      draftRole.draftRoleValues.forEach((value) => {
        if (!value?.roleTypeValue?.active) {
          return null;
        }
        draftRoleValues[value.roleTypeValue.name] = !!assessValue(value);
      });
    }
  });
  // Cycle through all fields and note whether they're required
  draftRoles.forEach((draftRole) => {
    draftRole?.roleType?.roleTypeValues.forEach((value) => {
      if (!value.active) {
        return null;
      }
      if (value.required && draftRoleValues[value.name]) {
        requiredFields.push(value);
        requiredFieldsWithValues.push(value);
      } else if (value.required && !draftRoleValues[value.name]) {
        requiredFields.push(value);
        requiredFieldsWithoutValues.push(value);
      }
    });
  });
  return {
    requiredFields,
    requiredFieldsWithValues,
    requiredFieldsWithoutValues,
  };
};
const getFieldGroups = (
  draftRole,
  defaultValues,
  draftRoleValues,
  activeField,
  setActiveField,
  addDraftRoleValue
) => {
  let fieldGroups = JSON.parse(
    JSON.stringify(draftRole.roleType.roleTypeValues)
  );
  const order = draftRole.roleType.valueOrder;
  if (order) {
    const arrayMap = fieldGroups.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue,
      }),
      {}
    );
    let orderedRows = order.map((key) => arrayMap[key]);
    let unorderedRows = fieldGroups.filter((item) => !order.includes(item.id));
    fieldGroups = [...orderedRows, ...unorderedRows];
  }
  // Clean any undefined row elements
  fieldGroups = fieldGroups.filter(Boolean);
  return fieldGroups.map((roleTypeValue, idx) => {
    return (
      roleTypeValue.active && (
        <FieldGroup
          key={idx}
          defaultValues={defaultValues}
          fieldStatus={
            draftRoleValues[roleTypeValue.name]
              ? draftRoleValues[roleTypeValue.name].status
              : "pending"
          }
          draftRole={draftRole}
          roleTypeValue={roleTypeValue}
          activeField={activeField}
          setActiveField={setActiveField}
          addDraftRoleValue={addDraftRoleValue}
        />
      )
    );
  });
};

export default function RoleRequest() {
  const navigate = useNavigate();
  const params = useParams();
  const [activeField, setActiveField] = useState(null);
  const rolesRequestId = params.id;
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const { loading, error, data, refetch } = createQuery("rolesRequest", {
    id: rolesRequestId,
  });
  const addDraftRoleValue = createDraftRoleValue(refetch, snackbar);
  const addDraftCredentialValue = createDraftCredentialValue(refetch, snackbar);
  const [confirmation, setConfirmation] = useState({ open: false });
  const closeConfirmation = () => {
    setConfirmation({ open: false });
  };
  const handleSetConfirmation = () =>
    setConfirmation({
      open: true,
      action: closeConfirmation,
      actionText: `Email has been sent${
        data.rolesRequest ? ` to ${data.rolesRequest.email}` : "."
      }`,
      alternateDisplay: "INFORMATION",
    });
  const handleSendSubmitEmail = () => {
    processSubmitEmail(data.rolesRequest, handleCreateEmail);
  };
  const handleCreateEmail = submitCreateEmail(handleSetConfirmation);
  const handleSendFormEmail = (e) => {
    processFormEmail(data.rolesRequest, handleCreateEmail);
  };
  const navigateToReview = () => {
    navigate("/app/team/onboarding/review");
  };
  const handleSubmitRolesRequest = submitRolesRequest(
    refetch,
    snackbar,
    handleSendSubmitEmail,
    navigateToReview
  );

  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    const hasBackgroundCheck = data?.rolesRequest?.draftRoles?.some(
      (role) => role?.roleType?.backgroundCheck ?? false
    );
    const requiredFieldStatus = getRequiredFields(
      data.rolesRequest.draftRoles,
      data.rolesRequest.draftCredentials
    );
    return (
      <Page title="Onboarding | FINBACK670">
        <Confirmation
          confirmation={confirmation}
          handleClose={closeConfirmation}
        />
        <Container maxWidth="xl">
          <Grid container direction={"column"} spacing={2}>
            <Grid item>
              <Typography variant="h4">Request Form</Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h4h7">
                  Please complete all required fields, or optionally:{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  sx={{ textTransform: "none" }}
                  disabled={data.rolesRequest.status === "submitted"}
                  onClick={handleSendFormEmail}
                  variant="outlined"
                >
                  {`Send to ${data.rolesRequest.email} to complete`}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h4h7">
                Note: All inputted, edited, and deleted data is saved in
                real-time by utilizing the icons to the right of each data
                field.
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h4h7">
                  To edit a data field, click:{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" startIcon={<EditIcon />}>
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h4h7">
                  To save edits to a datafield, click:{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" startIcon={<SaveIcon />}>
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Button variant="outlined" color="primary">
                  * indicates a required data field
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {data.rolesRequest.status === "submitted" && (
                <Box sx={{ pb: 5 }}>
                  <Typography variant="h7">
                    Request has been submitted for approval and is locked.
                  </Typography>
                </Box>
              )}
            </Grid>
            {hasBackgroundCheck && (
              <BackgroundCheck
                refetchRolesRequest={refetch}
                rolesRequest={data.rolesRequest}
              />
            )}
            {data.rolesRequest.draftRoles &&
              data.rolesRequest.draftRoles.map((draftRole) => {
                if (!draftRole.roleType) {
                  return null;
                }
                const defaultValues = draftRole.draftRoleValues
                  ? getDefaultValues(draftRole.draftRoleValues)
                  : {};
                const draftRoleValues = draftRole.draftRoleValues
                  ? draftRoleValuesObject(draftRole.draftRoleValues)
                  : {};
                return (
                  <Grid item key={draftRole.roleType.id}>
                    <Box sx={{ pb: 5 }}>
                      <Grid
                        container
                        direction="column"
                        alignItems={"flex-start"}
                        justifyContent={"center"}
                        spacing={2}
                      >
                        <Box sx={{ pb: 5 }}>
                          <Typography variant="h5">
                            {draftRole.roleType.name}
                          </Typography>
                        </Box>
                        {getFieldGroups(
                          draftRole,
                          defaultValues,
                          draftRoleValues,
                          activeField,
                          setActiveField,
                          addDraftRoleValue
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            {data.rolesRequest.draftCredentials &&
              data.rolesRequest.draftCredentials.map((draftCredential) => {
                if (
                  !draftCredential?.roleTypeCredential?.roleTypeCredentialValues
                    ?.length ??
                  true
                ) {
                  return null;
                }
                const defaultCredentialValues =
                  draftCredential.draftCredentialValues
                    ? getCredentialDefaultValues(
                        draftCredential.draftCredentialValues
                      )
                    : {};
                const draftCredentialValues =
                  draftCredential.draftCredentialValues
                    ? draftCredentialValuesObject(
                        draftCredential.draftCredentialValues
                      )
                    : {};

                return (
                  <Grid item key={draftCredential.credentialType.id}>
                    <Box sx={{ pb: 5 }}>
                      <Grid
                        container
                        direction="column"
                        alignItems={"flex-start"}
                        justifyContent={"center"}
                        spacing={2}
                      >
                        <Box sx={{ pb: 5 }}>
                          <Typography variant="h5">
                            {draftCredential.credentialType.name}
                          </Typography>
                        </Box>
                        <CredentialFields
                          draftCredential={draftCredential}
                          defaultCredentialValues={defaultCredentialValues}
                          draftCredentialValues={draftCredentialValues}
                          activeField={activeField}
                          setActiveField={setActiveField}
                          addDraftCredentialValue={addDraftCredentialValue}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            {requiredFieldStatus.requiredFields.length !==
              requiredFieldStatus.requiredFieldsWithValues.length && (
              <Grid item>
                <Typography>{`${
                  requiredFieldStatus.requiredFields.length -
                  requiredFieldStatus.requiredFieldsWithValues.length
                } fields missing`}</Typography>
              </Grid>
            )}
            <Grid item container spacing={8} direction={"row"}>
              <Grid item>
                <Button
                  sx={{ textTransform: "none" }}
                  disabled={data.rolesRequest.status === "submitted"}
                  onClick={handleSendFormEmail}
                  variant="outlined"
                >
                  {`Send to ${data.rolesRequest.email} to complete.`}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={
                    requiredFieldStatus.requiredFields.length !==
                      requiredFieldStatus.requiredFieldsWithValues.length ||
                    data.rolesRequest.status === "submitted"
                  }
                  onClick={async () => {
                    await handleSubmitRolesRequest({ id: rolesRequestId });
                  }}
                  variant="outlined"
                  startIcon={<SaveIcon />}
                >
                  Submit Request for Approval
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}
