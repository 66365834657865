import { useContext } from "react";
import { ProjectContext } from "../../utils/contexts/project";
import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-outline";
// import messageSquareFill from "@iconify/icons-eva/message-square-fill";
import archiveFill from "@iconify/icons-eva/archive-outline";
import smartphoneOutline from "@iconify/icons-eva/smartphone-outline";
import peopleFill from "@iconify/icons-eva/people-outline";
import clipboardOutline from "@iconify/icons-eva/clipboard-outline";
// import briefcase from "@iconify/icons-eva/briefcase-outline";
// import printer from "@iconify/icons-eva/printer-outline";
import fileAddFill from "@iconify/icons-eva/file-add-fill";
// import alert from "@iconify/icons-eva/alert-triangle-fill";
import list from "@iconify/icons-eva/list-fill";
import map from "@iconify/icons-eva/map-outline";
// import inbox from "@iconify/icons-eva/inbox-fill";
// import text from "@iconify/icons-eva/file-text-fill";
// import clipboard from "@iconify/icons-eva/clipboard-fill";
// import calendarOutline from "@iconify/icons-eva/calendar-outline";
import lock from "@iconify/icons-mdi/lock";
import userOutlined from "@iconify/icons-ant-design/user-outlined";
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const renderInspections = (project) => {
  //if (project?.childProjects?.length === 0) {
  return {
    title: "Inspections",
    icon: getIcon(list),
    children: [
      {
        title: "My Inspections",
        path: "/app/myInspections",
      },
      {
        title: "Inspection Types",
        path: "/app/inspectiontypes",
      },
      {
        title: "Import Addresses",
        path: "/app/inspections/addresses",
      },
      {
        title: "Create Inspections",
        path: "/app/inspections/create",
      },
      {
        title: "Schedule Inspections",
        path: "/app/inspections/schedule",
      },
      {
        title: "Data Replication",
        path: "/app/dataReplication",
      },
    ],
  };
  //}
};

const renderEnvironmentMapping = (project) => {
  return {
    title: "Environment Mapping",
    icon: getIcon(map),
    children: [
      {
        title: "Environment Map",
        path: "/app/environmentMapping/environmentMap",
      },
      {
        title: "Geo Rules",
        path: "/app/environmentMapping/geoRules",
      },
    ],
  };
};

const renderOnboarding = (project) => {
  return {
    title: "Onboarding Request",
    path: "/app/team/onboarding",
    icon: getIcon(fileAddFill),
    children: [
      {
        title: "New Firm",
        path: "/app/team/onboarding/newFirm",
      },
      {
        title: "New Personnel",
        path: "/app/team/onboarding/newUser",
      },
      {
        title: "Pending",
        path: "/app/team/onboarding/pending",
      },
      {
        title: "Review",
        path: "/app/team/onboarding/review",
      },
    ],
  };
};
const SidebarConfig = () => {
  const projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  return {
    main: {
      name: "main",
      sections: [
        {
          title: "dashboard",
          path: "/app/dashboard",
          icon: getIcon(pieChart2Fill),
        },
        {
          title: "Project Details",
          path: "/app/details",
          icon: getIcon(archiveFill),
        },
        // {
        //   title: "Asset Management",
        //   path: "/app/assets",
        //   icon: getIcon(smartphoneOutline),
        //   children: [
        //     {
        //       title: "Asset Types",
        //       path: "/app/assets/types",
        //     },
        //     {
        //       title: "Assets",
        //       path: "/app/assets/management",
        //     },
        //     {
        //       title: "Assignments",
        //       path: "/app/assets/assignments",
        //     },
        //     // {
        //     //   title: "Maintenance",
        //     //   path: "/app/assets/maintenance",
        //     // }
        //   ],
        // },
        {
          title: "Review",
          path: "/app/review",
          icon: getIcon(clipboardOutline),
        },
        // {
        //   title: "Reporting",
        //   path: "/app/reporting",
        //   icon: getIcon(archiveFill),
        // },
        {
          title: "My Team",
          path: "/app/team",
          icon: getIcon(peopleFill),
          children: [
            {
              title: "Personnel Directory",
              path: "/app/project/people",
            },
            {
              title: "Firm Directory",
              path: "/app/project/firms",
            },
            {
              title: "Credentials",
              path: "/app/project/credentials",
            },
            renderOnboarding(project),
          ],
        },
        renderInspections(project),
        renderEnvironmentMapping(project)
      ],
    },
    myAccount: {
      name: "myAccount",
      label: "My Account",
      sections: [
        {
          title: "My Profile",
          path: "/app/user/profile",
          icon: getIcon(userOutlined),
        },
        {
          title: "Security",
          icon: getIcon(lock),
          children: [
            {
              title: "Update Password",
              path: "/app/user/settings",
            },
          ],
        },
      ],
    },
    configurations: {
      name: "configurations",
      label: "Configurations",
      sections: [
        {
          title: "Credential Management",
          path: "/app/admin/credential",
        },
        {
          title: "Role Management",
          path: "/app/admin/roles",
        },
        {
          title: "Category Management",
          path: "/app/admin/category",
        },
        {
          title: "Firms",
          path: "/app/admin/firms",
        },
        // {
        //   title: "Firm Units",
        //   path: "/app/admin/firmunits",
        // },
        {
          title: "Projects",
          path: "/app/admin/projects",
        },
        // {
        //   title: "Project Firm Units",
        //   path: "/app/admin/projectfirmunit",
        // },
        {
          title: "Users",
          path: "/app/admin/users",
        },
        {
          title: "People",
          path: "/app/admin/people",
        },
        // {
        //   title: "Project People",
        //   path: "/app/admin/projectpeople",
        // },
        // {
        //   title: "Firm People",
        //   path: "/app/admin/firmpeople",
        // },
        // {
        //   title: "Firm Unit People",
        //   path: "/app/admin/firmunitpeople",
        // },
        // {
        //   title: "Developer Administration",
        //   path: "/app/admin/category",
        // },
      ],
    },
    projects: {
      name: "projects",
      label: "Projects",
      sections: [
        {
          title: "Projects",
          path: "/app/projects/list",
          icon: getIcon(list),
        },
      ],
    },
    firmUnits: {
      name: "firmUnits",
      label: "Firm Units",
      sections: [
        {
          title: "Firm Units",
          path: "/app/firm/firmUnits/list",
          icon: getIcon(list),
        },
      ],
    },
  };
};

export default SidebarConfig;
