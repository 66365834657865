import React from "react";
import {
  DataGridPremium,
  useGridApiRef,
  gridExpandedSortedRowEntriesSelector
} from "@mui/x-data-grid-premium";

function getInspectionTypeName(params) {
  if(!params.row.inspectionType){
    return ""
  }
  return `${params.row.inspectionType.name}`;
}

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowEntriesSelector(apiRef);

export default function AddressTable({inspectionLocations, setInspectionLocations, setAssignmentLocation}) {
  const apiRef = useGridApiRef();

  const onFilterChange = React.useCallback((filterModel) => {
    let rows = getFilteredRows({apiRef})
    setInspectionLocations(rows.map((row) => row.model))
    // Here you save the data you need from the filter model
  }, [apiRef, setInspectionLocations]);
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGridPremium
        apiRef={apiRef}
        rows={inspectionLocations}
        columns={[
            { editable: false, field: 'lis', headerName: "Control Number" },          
            { editable: false, field: 'inspectionTypeName', valueGetter: getInspectionTypeName, headerName: "Inspection Type" },          
            { editable: false, field: 'street1', headerName: "Street 1" },
            { editable: false, field: 'street2', headerName: "Street 2" },
            { editable: false, field: 'street3', headerName: "Street 3" },
            { editable: false, field: 'neighborhood', headerName: "Neighborhood" },
            { editable: false, field: 'state', headerName: "State" },
            { editable: false, field: 'zip', headerName: "Zip Code" },
        ]}
        pageSize={5}
        pageSizeOptions={[10, 25, 50]}
        onFilterModelChange={onFilterChange}
        onRowClick={(rowData) => setAssignmentLocation(rowData.row)}
      />
    </div>
  )
}
