import { useEffect, useState } from "react";
import TextField from "../../../utils/mui-rhf/TextField";
// material
import { Box, Container, Button, Typography, Grid } from "@mui/material";
// components
import Page from "../../../components/Page";
import Map from "./map";
import { Marker } from "react-map-gl";
import GQLTable from "../../../utils/gql-mui-rhf/Table";
import { useContext } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import { createLazyQuery } from "../../../utils/gql-mui-rhf/QueryAction";
import { GQLMutationAction } from "../../../utils/gql-mui-rhf/SingleMutation";

import { FormProvider, useForm } from "react-hook-form";
import { Importer, ImporterField } from "react-csv-importer";
import { useSnackbar } from "notistack";

// include the widget CSS file whichever way your bundler supports it
import "react-csv-importer/dist/index.css";
import { useMutation, useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";

// ----------------------------------------------------------------------
function generateMutation() {
  return GQLMutationAction({
    mutationString: "createProjectLocation",
    onCompleted: (response) => {
    },
  });
}
const CREATE_PROJECT_LOCATION = gql`
  mutation createProjectLocation($projectLocation: InputProjectLocationParams) {
    createProjectLocation(projectLocation: $projectLocation) {
      id
    }
  }
`;
const GET_PROJECT_LOCATIONS = gql`
  query ProjectLocations($projectId: ID!) {
    projectLocations(projectId: $projectId) {
      id
      description
      city
      buildingType
      identifier
      lat
      lis
      lng
      mapboxId
      name
      neighborhood
      owner
      parentId
      projectId
      state
      street1
      street2
      street3
      tenant
      unit
      zip
    }
  }
`;

async function createProjectLocations(
  rows,
  projectId,
  createProjectLocation,
  shouldRefetchTable,
  snackbar
) {
  let addressesImported = 0;
  let addressesFailed = 0;
  for (const row of rows) {
    const query = `${row.street1} ${row.street2} ${row.street3} ${row.city}, ${row.state} ${row.zip}`;

    const res = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        query
      )}.json?types=address&access_token=${
        process.env.REACT_APP_MAPBOX_TOKEN
      }&proximity=ip`
    ).then((response) => response.json());

    const address = res.features[0];
    const createdProjectLocation = await createProjectLocation({
      variables: {
        projectLocation: {
          ...row,
          lng: address.geometry.coordinates[0],
          lat: address.geometry.coordinates[1],
          mapboxId: address.id,
          projectId,
        },
      },
    });
    if (!!createdProjectLocation.data) addressesImported++;
    else addressesFailed++;
    console.log("CREATED: ", createdProjectLocation);
    setTimeout(function () {
      // code to be executed after 0.1 seconds
    }, 100);
  }

  if (addressesImported)
    snackbar(`${addressesImported} Addresss(es) imported`, "success");
  if (addressesFailed)
    snackbar(`${addressesFailed} Addresss(es) failed`, "error");
  shouldRefetchTable();
}

export default function Addresses() {
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  const [refetchTable, setRefetchTable] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showInputAddress, setShowInputAddress] = useState(false);
  const [mapMarkers, setMapMarkers] = useState([]);

  const [query, { loading, error, data, called }] = createLazyQuery("project");
  const methods = useForm({
    street1: null,
    street2: null,
    street3: null,
    city: null,
    state: null,
    zip: null,
  });
  const { handleSubmit, setValue, setError, clearErrors, formState } = methods;
  const { errors } = formState;
  const [addressesImported, setAddressesImported] = useState(0);
  const [addressesFailed, setAddressesFailed] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  const [createProjectLocation] = useMutation(CREATE_PROJECT_LOCATION, {
    onCompleted: (data) => {
      setAddressesImported(addressesImported + 1);
    },
    onError: (error) => {
      setAddressesFailed(addressesFailed + 1);
    },
  });
  const [
    getProjectLocations,
    { data: projectLocationsData, loading: projectLocationsLoading },
  ] = useLazyQuery(GET_PROJECT_LOCATIONS, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {},
  });
  const shouldRefetchTable = () => {
    getProjectLocations({
      variables: {
        projectId: project.id,
      },
    });
    setRefetchTable(true);
  };

  useEffect(() => {
    if (project && !loading && !called) {
      query({
        variables: {
          id: project.id,
        },
      });
    }
  }, [project, loading, called, query]);

  useEffect(() => {
    if (!loading && data && project && data.project.id !== project.id) {
      query({
        variables: { id: project.id },
      });
    }
  }, [project, loading, called, query, data]);

  useEffect(() => {
    if (project && !projectLocationsData && !projectLocationsLoading) {
      getProjectLocations({
        variables: {
          projectId: project.id,
        },
      });
    }
  }, [project]);

  useEffect(() => {
    if (projectLocationsData && !projectLocationsLoading) {
      let markers = [];
      projectLocationsData.projectLocations.map((m) => {
        markers.push(
          <Marker
            key={m.id}
            longitude={m.lng}
            latitude={m.lat}
            color="#00349c"
            anchor="bottom"
          ></Marker>
        );
      });
      setMapMarkers(markers);
    }
  }, [projectLocationsData]);

  useEffect(() => {}, [mapMarkers]);

  async function onSubmit(values) {
    await createProjectLocations(
      [values],
      project.id,
      createProjectLocation,
      shouldRefetchTable,
      snackbar
    );
    setShowInputAddress(false);
    methods.reset();
  }

  if (!project) {
    return null;
  }
  if (loading) {
    return null;
  }
  if (error) {
    debugger;
  }
  if (data && data.project) {
    // debugger;
    // if (data.project.id !== project.id) {
    //   refetch();
    // }
    return (
      <Page title="Inspections | FINBACK670">
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Project Addresses</Typography>
          </Box>
          <Map markers={mapMarkers}/>
          {!showImport && !showInputAddress && (
            <div style={{ marginTop: "32px", marginBottom: "32px" }}>
              {" "}
              <Button
                variant={"contained"}
                onClick={() => {
                  setShowImport(true);
                }}
              >
                Import Addresses
              </Button>{" "}
            </div>
          )}
          {!showInputAddress && !showImport && (
            <div style={{ marginTop: "32px", marginBottom: "32px" }}>
              {" "}
              <Button
                variant={"contained"}
                onClick={() => {
                  setShowInputAddress(true);
                }}
              >
                Input Single Address
              </Button>{" "}
            </div>
          )}
          {showImport && (
            <div style={{ marginTop: "32px", marginBottom: "32px" }}>
              <Importer
                dataHandler={async (rows, { startIndex }) => {
                  createProjectLocations(
                    rows,
                    project.id,
                    createProjectLocation,
                    shouldRefetchTable,
                    snackbar
                  );
                }}
                onClose={({ file, preview, fields, columnFields }) => {
                  setShowImport(false);
                }}
                defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
              >
                <ImporterField name="name" label="Name" optional />
                <ImporterField
                  name="description"
                  label="Description"
                  optional
                />
                <ImporterField name="street1" label="Street 1" />
                <ImporterField name="street2" label="Street 2" optional />
                <ImporterField name="street3" label="Street 3" optional />
                <ImporterField name="neighborhood" label="Neighborhood" />
                <ImporterField name="city" label="City" />
                <ImporterField name="state" label="State" />
                <ImporterField name="zip" label="Zip" />
              </Importer>
            </div>
          )}
          {showInputAddress && (
            <div style={{ marginTop: "32px", marginBottom: "32px" }}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2} padding={1}>
                    <Grid item spacing={2} xs={12}>
                      <TextField
                        key={1}
                        fullWidth
                        label={"Street 1"}
                        name={"street1"}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item spacing={2} xs={12}>
                      <TextField
                        key={1}
                        fullWidth
                        label={"Street 2"}
                        name={"street2"}
                      />
                    </Grid>
                    <Grid item spacing={2} xs={12}>
                      <TextField
                        key={1}
                        fullWidth
                        label={"Street 3"}
                        name={"street3"}
                      />
                    </Grid>
                    <Grid item spacing={2} xs={12}>
                      <TextField
                        key={1}
                        fullWidth
                        label={"Neighborhood"}
                        name={"neighborhood"}
                      />
                    </Grid>
                    <Grid item spacing={2} xs={4}>
                      <TextField
                        key={2}
                        fullWidth
                        label={"City"}
                        name={"city"}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item spacing={2} xs={4}>
                      <TextField
                        key={3}
                        fullWidth
                        label={"State"}
                        name={"state"}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item spacing={2} xs={4}>
                      <TextField
                        key={3}
                        fullWidth
                        label={"Zip"}
                        name={"zip"}
                        rules={{ required: true }}
                      />
                    </Grid>
                  </Grid>
                  <Button variant="contained" type="submit">
                    submit
                  </Button>
                </form>
              </FormProvider>
            </div>
          )}
          <GQLTable
            queryString={"projectLocations"}
            deleteMutationString={"deleteProjectLocation"}
            queryVariables={{ projectId: data.project.id }}
            updateModal={true}
            columnOrder={[
              "lis",
              "identifier",
              "street1",
              "street2",
              "street3",
              "neighborhood",
              "city",
              "state",
              "zip",
            ]}
            columnOptions={{
              lis: { headerName: "Control Number" },
              identifier: { headerName: "Location Number" },
            }}
            restrictedColumns={{
              parentId: true,
              mapboxId: true,
              lng: true,
              name: true,
              tenant: true,
              owner: true,
              lat: true,
              id: true,
              description: true,
              projectId: true,
            }}
            shouldRefetch={refetchTable}
            setShouldRefetch={setRefetchTable}
          />
        </Container>
      </Page>
    );
  }
}
