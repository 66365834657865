// components
import Page from "../../../components/Page";
import { useState, useCallback, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTopLevelFormInstance } from "../../Inspections/MyInspections/routes/InspectionWebView/controller";
import { CircularProgress, Stack } from "@mui/material";
import EnvironmentMap from ".";
import { gql, useMutation, useLazyQuery } from "@apollo/client";

const GET_INSPECTION = gql`
  query inspection($inspection: InputInspectionParams!) {
    inspection(inspection: $inspection) {
      inspectionLocation
      project{
        id
        projectLocation{
          id
          description
          city
          buildingType
          identifier
          lat
          lis
          lng
          mapboxId
          name
          neighborhood
          owner
          parentId
          projectId
          state
          street1
          street2
          street3
          tenant
          unit
          zip
        }
      }
    }
  }
`;

const CREATE_MAP_CAPTURE_SNAPSHOT = gql`
mutation CreateMapCaptureSnapshot(
  $mapCaptureSnapshot: InputMapCaptureSnapshotParams
) {
  createMapCaptureSnapshot(mapCaptureSnapshot: $mapCaptureSnapshot) {
    id
    description
    title
    metadata
    image
    inspectionId
    inspectionAssignmentId
    thoFormInstanceId
    thoFormId
    thoFormFieldId
    thoFormFieldInstanceId
    personId
    projectId
    isDeleted
  }
}
`;

const GET_PROJECT_LOCATION = gql`
  query ProjectLocation($projectLocationId: ID!) {
    projectLocation(projectLocationId: $projectLocationId) {
      id
      description
      city
      buildingType
      identifier
      lat
      lis
      lng
      mapboxId
      name
      neighborhood
      owner
      parentId
      projectId
      state
      street1
      street2
      street3
      tenant
      unit
      zip
    }
  }
`;

export default function EnvironmentMapMobile() {
    const params = useParams();
    const navigate = useNavigate();

  const [project, setProject] = useState(null);

  const [inspection, setInspection] = useState(null);

  const componentRef = useRef(null);
  const [mapCaptureArgs, setMapCaptureArgs] = useState(null);
  const [mapArgs, setMapArgs] = useState(null);
  const [environmentMap, setEnvironmentMap] = useState(null);
  const [inspectionLocation, setInspectionLocation] = useState(null);

  const [getInspection, {called, loading}] = useLazyQuery(GET_INSPECTION, {
    fetchPolicy: "network-only",
    onCompleted: (response) => { 
      setInspection(response.inspection);
      setProject({project: response.inspection.project});
      setMapCaptureArgs({
        formInstanceId: params.formInstanceId,
        formId: params.formId,
        topLevelFormInstanceId: params.topLevelFormInstanceId,
        formFieldId: params.formFieldId,
        formFieldInstanceId: params.formFieldInstanceId,
        personId: params.personId,
        projectId: params.projectId,
        inspectionId: params.inspectionId,
        inspectionAssignmentId: params.inspectionAssignmentId
      });
    },
  });
  
    const [getProjectLocation] = useLazyQuery(GET_PROJECT_LOCATION, {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setInspectionLocation(response.projectLocation);
      },
      onError: (response) => {},
    });
    const [createMapCaptureSnapshot] = useMutation(CREATE_MAP_CAPTURE_SNAPSHOT, {
      fetchPolicy: "network-only",
      onCompleted: (response) => {},
    });

  ///////Need from params: formInstaceId, formId, topLevelFormInstanceId, formFieldId, formFieldInstanceId, personId, projectId, inspectionId
  const handleUploadMapCapture = async (args) => {
    let topLevelFormInstanceId = args.captureArgs.topLevelFormInstanceId;

    let inspectionId = args.captureArgs.inspectionId;
    let inspectionAssignmentId = args.captureArgs.inspectionAssignmentId;
    let thoFormInstanceId = args.captureArgs.id;
    let thoFormId = args.captureArgs.formId;
    let thoFormFieldId = args.captureArgs.formFieldId;
    let thoFormFieldInstanceId = args.captureArgs.id;
    let personId = args.captureArgs.personId;
    let projectId = project.project.id;

    var mapCaptureToUpload = {
      inspectionId,
      projectId,
      personId,
      thoFormFieldInstanceId,
      thoFormFieldId,
      thoFormInstanceId,
      inspectionAssignmentId,
      thoFormId,
      topLevelFormInstanceId,
    };
    await createMapCaptureSnapshot({
      variables: {
        mapCaptureSnapshot: {
          mapCapture: mapCaptureToUpload,
          features: JSON.stringify(args.mapArgs.features),
          bounds: {
            northEast: args.mapArgs.viewport.bounds._ne,
            southWest: args.mapArgs.viewport.bounds._sw,
            center: {
              lng: args.mapArgs.viewport.viewState.longitude,
              lat: args.mapArgs.viewport.viewState.longitude,
            },
            zoom: args.mapArgs.viewport.viewState.zoom,
          },
        },
      },
    })
      .then((result) => {
        navigate(`../environmentMapping/mobileMapCaptureComplete`);
      })
      .catch((err) => {
        console.log(err);
      });
      
  };

  useEffect(() => {
    if (!called && !loading) {
      getInspection({variables: {inspection: {id: params.inspectionId}}});
    }
  }, []);

  useEffect(() => {
    if (inspection) {
      getProjectLocation({variables: {projectLocationId: inspection.inspectionLocation}});
    }
  }, [inspection]);

  useEffect(() => {
    if (project && inspection && inspectionLocation && componentRef && mapCaptureArgs) {
      let ev = (
        <EnvironmentMap
          addresses={[inspectionLocation]}
          componentRef={componentRef}
          showMeasurementLine={true}
          isCapture={true}
          mapCaptureArgs={mapCaptureArgs}
          handleUploadMapCapture={handleUploadMapCapture}
          mapArgs={mapArgs}
          setMapArgs={setMapArgs}
          project={project}
        ></EnvironmentMap>
      );
      setEnvironmentMap(ev);
    }
  }, [inspectionLocation]);
  
  return (
    <Page title="Environment Map | FINBACK670">
        {environmentMap && (
          environmentMap
        )}
        {!environmentMap && (
          <Stack>
            <CircularProgress></CircularProgress>
          </Stack>
        )}
    </Page>
  );
}
