import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "src/components/Page";
import { ProjectContext } from "src/utils/contexts/project";
import RuleEdit from "./ruleEdit";
import { useSnackbar } from "notistack";

const GET_GEO_RULES = gql`
  query GeoRules($geoRule: InputGeoRuleParams) {
    geoRules(geoRule: $geoRule) {
      id
      title
      description
      value
      unit
      featureType {
        id
        name
      }
      operand
    }
  }
`;

const GET_GEO_FEATURE_TYPES = gql`
  query GeoFeatureTypes {
    geoFeatureTypes {
      id
      name
      isDeleted
    }
  }
`;

const UPLOAD_GEO_RULE = gql`
  mutation UploadGeoRule($geoRule: InputGeoRuleParams) {
    uploadGeoRule(geoRule: $geoRule) {
      id
      title
      description
      value
      unit
      featureType {
        id
        name
      }
      operand
    }
  }
`;

export default function GeoRules() {
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  const [dataLoading, setDataLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [currentRule, setCurrentRule] = useState(null);
  const [unitOptions, setUnitOptions] = useState([
    { label: "MI", value: "MI" },
    { label: "FT", value: "FT" },
    { label: "KM", value: "KM" },
    { label: "M", value: "M" },
  ]);
  const [isNew, setIsNew] = useState(false);
  const [operandOptions, setOperandOptions] = useState([
    { label: "Equal To", value: "=" },
    { label: "Not Equal To", value: "!=" },
    { label: "Less Than", value: "<" },
    { label: "Less Than Or Equal To", value: "<=" },
    { label: "Greater Than", value: ">" },
    { label: "Greater Than Or Equal To", value: ">=" },
  ]);
  const [geoFeatureTypes, setGeoFeatureTypes] = useState([]);
  const [showRuleEdit, setShowRuleEdit] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [uploadGeoRule] = useMutation(UPLOAD_GEO_RULE, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {},
  });

  const [getGeoFeatureTypes, { called: featureTypesCalled }] = useLazyQuery(
    GET_GEO_FEATURE_TYPES,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setGeoFeatureTypes(response.geoFeatureTypes);
        var opts = [];
        for (var i = 0; i < response.geoFeatureTypes.length; i++) {
          let name = response.geoFeatureTypes[i].name;
          let doesExist = opts.some(function (ele) {
            return ele.label === name;
          });
          if (!doesExist) {
            opts.push({
              label: response.geoFeatureTypes[i].name,
              value: response.geoFeatureTypes[i].id,
            });
          }
        }
        setOptions(opts);
      },
      onError: (response) => {},
    }
  );

  const columns = [
    {
      field: "Edit",
      width: 50,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            setIsNew(false);
            setShowRuleEdit(true);
            setCurrentRule(params.row);
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      field: "id",
      headerName: "Id",
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "featureType",
      headerName: "Feature Type",
      flex: 1,
      valueGetter: (params) => params.row?.featureType?.name,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
    },
    {
      field: "unit",
      headerName: "Unit",
      flex: 1,
    },
    {
      field: "operand",
      headerName: "Operand",
      flex: 1,
    },
    {
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            setIsNew(false);
            setShowRuleEdit(false);
            setShowConfirmDelete(true);
            setCurrentRule(params.row);
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];
  const [geoRules, setGeoRules] = useState([]);
  const [getGeoRules, { called: getGeoRulesCalled }] = useLazyQuery(
    GET_GEO_RULES,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        var geoRules = response.geoRules;

        setGeoRules(geoRules);
        setDataLoading(false);
      },
      onError: (response) => {},
    }
  );

  useEffect(() => {
    //if(!projectTreeCalled){
    async function getData() {
      if (project) {
        await getGeoFeatureTypes();
        await getGeoRules({
          variables: {
            geoRule: {
              isDeleted: false,
            },
          },
        });
      }
    }
    let timer = setTimeout(() => {
      getData();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
    //}
  }, [project]);

  useEffect(() => {}, [showRuleEdit, isNew, showConfirmDelete]);

  useEffect(() => {}, [geoRules]);

  function handleClose() {
    setIsNew(false);
    setShowRuleEdit(false);
  }

  async function onCurrentSubmit(rule) {
    
    var variables = { ...rule };
    if (rule.value) {
      variables.value = parseFloat(rule.value);
    }
    if (rule.id) {
      variables.id = rule.id;
    }

    await uploadGeoRule({
      variables: {
        geoRule: variables,
      },
    }).then((result) => {
      var message = "";
      if (isNew) {
        message = "Create Rule";
      } else {
        message = "Update Rule";
      }
      if (result) {
        var newRule = result.data.uploadGeoRule;
        var ruleId = newRule.id;
        var rules = geoRules.slice();
        var existing = rules.filter((e) => e.id === ruleId);
        if (existing) {
          var index = rules.indexOf(existing[0]);
          if (index >= 0) {
            if (rule.isDeleted) {
              rules.splice(index, 1);
            } else {
              for (var propKey in rule) {
                newRule[propKey] = rule[propKey];
              }
              rules[index] = newRule;
            }
          } else {
            rules.push(newRule);
          }
        } else {
          if (!rule.isDeleted) {
            rules.push(newRule);
          }
        }
        setGeoRules([...rules]);
        snackbar(message + " Succeeded", "success");
      } else {
        snackbar(message + " Failed", "error");
      }
      setIsNew(false);
      setShowRuleEdit(false);
      setCurrentRule(null);
    });
  }

  return (
    <Page
      title="Geo Rules | FINBACK670"
      sx={{ height: "100%", maxHeight: "100%" }}
    >
      <Breadcrumbs>
        <Link to=".">Geo Rules List</Link>
      </Breadcrumbs>
      <Box height="0.5rem" />
      {
        <Button
          variant="outlined"
          onClick={() => {
            setIsNew(true);
            setShowRuleEdit(true);
          }}
          sx={{ marginBottom: "1rem" }}
        >
          Create New Geo Rule
        </Button>
      }

      {showRuleEdit && (
        <Dialog open={showRuleEdit} onClose={handleClose}>
          <DialogTitle>{isNew ? "New Rule" : "Edit Rule"}</DialogTitle>
          <DialogContent>
            <RuleEdit
              currentRule={currentRule}
              onSubmit={onCurrentSubmit}
              options={options}
              operandOptions={operandOptions}
              unitOptions={unitOptions}
              isNew={isNew}
            ></RuleEdit>
          </DialogContent>
        </Dialog>
      )}

      {showConfirmDelete && (
        <Dialog open={showConfirmDelete} onClose={handleClose}>
          <DialogTitle>
            Deleting this rule cannot be undone. Are you sure you wish to
            continue?
          </DialogTitle>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setCurrentRule(null);
                setShowConfirmDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                onCurrentSubmit({ isDeleted: true, id: currentRule.id });
                setCurrentRule(null);
                setShowConfirmDelete(false);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {dataLoading && <CircularProgress />}
      {geoRules && (
        <DataGrid
          rows={geoRules}
          columns={columns}
          getRowHeight={() => "auto"}
          columnVisibilityModel={{
            id: false,
          }}
        />
      )}
    </Page>
  );
}
