export default function deepEquality(a, b) {
  if (typeof a == "object" && a != null && typeof b == "object" && b != null) {
    var count = [0, 0];
    for (var key in a) count[0]++;
    for (key in b) count[1]++;
    if (count[0] - count[1] !== 0) {
      return false;
    }
    for (key in a) {
      if (!(key in b) || !deepEquality(a[key], b[key])) {
        return false;
      }
    }
    for (key in b) {
      if (!(key in a) || !deepEquality(b[key], a[key])) {
        return false;
      }
    }
    return true;
  } else {
    return a === b;
  }
}
