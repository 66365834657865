import { useContext, useState, useEffect, useRef } from "react";
import {
  Collapse,
  Stack,
  Tab,
  Tabs,
  Box,
  IconButton,
  Grid,
  alpha,
  useTheme,
  styled,
  Divider,
  Icon,
  CircularProgress,
} from "@mui/material";
import { ChevronLeft, ChevronRight, Menu, Error } from "@mui/icons-material";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ProjectContext } from "src/utils/contexts/project";
import { PersonContext } from "src/utils/contexts/person";
import palette from "src/theme/palette";
import "./report_styles.css";

const GET_INSPECTION_ASSIGNMENT_REPORTS = gql`
  query GetInspectionAssignmentReportResults(
    $reportResult: InputReportResultParams!
  ) {
    reportResults(reportResult: $reportResult) {
      id
      reportTypeTemplateId
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      documentUrl
      documentId
      documentBytes
      requestedBy
      fileName
      fileExtension
    }
  }
`;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ReportReview = ({
  generatingReports,
  inspection,
  formInstances,
  inspectionAssignmentId
}: {
  generatingReports: boolean;
  inspection: any;
  formInstances: any;
  inspectionAssignmentId: any;
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [reload, setReload] = useState(false);

  const [
    getReportResults,
    { data: reportResultData, loading: reportResultDataLoading, refetch },
  ] = useLazyQuery(GET_INSPECTION_ASSIGNMENT_REPORTS, {
    fetchPolicy: "network-only",
    onCompleted: (result: any) => {
      debugger;
    },
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const person: any = useContext(PersonContext);
  const project: any = useContext(ProjectContext);
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message: any, variant: any) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!reportResultData && !reportResultDataLoading) {
      getReportResults({
        variables: {
          reportResult: {
            inspectionAssignmentId: [inspectionAssignmentId],
          },
        },
      });
    }
  });

  useEffect(() => {}, [reportResultData]);

  useEffect(()=>{
    if(generatingReports){
      setReload(true);
    }
    if(!generatingReports && reload){
      setReload(false);
      refetch();
    }
  }, [generatingReports, reload])
  

  return (
    <div>
      <Box
        sx={{
          ...(open && {
            display: "none",
            width: "30px",
            height: "30px",
            padding: "5px",
            position: "fixed",
            top: 0,
            right: 0,
            alignContent: "center",
          }),
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
        >
          <Menu />
        </IconButton>
      </Box>
      <Grid container spacing={2} columns={12} maxHeight={"100%"}>
        <Collapse
          orientation="horizontal"
          in={open}
          sx={{ overflowY: "scroll", height: "100%" }}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Box position="relative" flex="1" padding="15px">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="form selector"
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                  display: "grid",
                },
                "& .MuiTabs-indicator": { display: "none" },
                "& button": {
                  borderRadius: 2,
                  border: "1px solid black",
                  margin: "5px",
                },
                "& button:hover": {
                  backgroundColor: alpha(palette.secondary.main, 0.25),
                },
                "& button.Mui-selected": {
                  backgroundColor: "rgba(18, 175, 45, .5)",
                },
              }}
            >
              {formInstances.map((fi: any, i: any) => (
                <Tab
                  key={fi.id}
                  label={
                    <Stack>
                      <p>{fi.name}</p>
                    </Stack>
                  }
                  {...a11yProps(i)}
                />
                //   <Typography variant="h6">{fi[1].name}</Typography>
              ))}
            </Tabs>
          </Box>
        </Collapse>
        {formInstances.map((fi: any, i: any) => {
          let reportResult = reportResultData
            ? reportResultData.reportResults.find((r: any) => {
                return r.thoFormInstanceId === fi.id;
              })
            : null;
          let file =
            "data:application/pdf;base64, " +
            (reportResult ? reportResult.documentBytes : "");
          return (
            <Box
              position="relative"
              flex="1"
              padding="15px"
              height="100%"
              display={i === value ? "" : "none"}
            >
              {reportResult && (
                 <object id="pdfViewer" data={file + "#toolbar=0"} type="application/pdf" width="100%" height="800px">
                </object>
              )}
              {generatingReports && <CircularProgress></CircularProgress>}
            </Box>
          );
        })}
      </Grid>
    </div>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
