import { useState, useEffect } from "react";
// material
import { CircularProgress, Grid } from "@mui/material";
// components
import { GQLForm } from "../../utils/gql-mui-rhf/FormProvider";
import { createQuery } from "../../utils/gql-mui-rhf/QueryAction";
import createLazyQuery from "../../utils/gql-mui-rhf/LazyQuery";
import { useContext } from "react";
import { ProjectContext } from "../../utils/contexts/project";
import { PersonContext } from "../../utils/contexts/person";
import { Select } from "../../utils/mui-rhf";
import PersonSelector from "./PersonSelector";
// ----------------------------------------------------------------------

export default function OnboardingUser({ setValue }) {
  let projectContext = useContext(ProjectContext);
  const [ownerFirm, setOwnerFirm] = useState(null);
  const { project } = projectContext;
  let personContext = useContext(PersonContext);
  const { person } = personContext;
  const personFirm = person.firmId;
  const onGetFirmUnit = (response) => {
    setOwnerFirm(response.firmUnit?.firm?.id);
  };
  const [getFirmUnit] = createLazyQuery("firmUnit", onGetFirmUnit);
  useEffect(() => {
    if (project.id) {
      getFirmUnit({
        variables: {
          id: project.projectOwner,
        },
      });
    }
  }, [project, getFirmUnit]);

  const { loading, error, data } = createQuery("credentialTypes", {
    active: true,
    entity: "PERSON",
  });
  if (!project) return <div>Select Project to Continue</div>;
  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    const options = data.credentialTypes
      .map((credentialType) => ({
        value: credentialType.id,
        name: credentialType.name,
        label: credentialType.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3} spacing={8}>
          <GQLForm
            mutationString={"createCredentialsRequest"}
            additionalVariables={{
              entity: "PERSON",
              projectId: project.id,
              active: true,
            }}
            skipFields={{
              id: true,
              active: true,
              credentialTypeId: true,
              email: true,
              firstName: true,
              lastName: true,
              entity: true,
              entityId: true,
              name: true,
              projectId: true,
            }}
            onError={(error) => {}}
            onCompleted={(response) => {
              setValue(2);
              // debugger;
              // snackbar("Onboarding Request Submitted", "success");
              // if (response.createRolesRequest)
              //     navigate(
              //         `/app/team/onboarding/request/${response.createRolesRequest.id}`
              //     );
            }}
          >
            <PersonSelector
              userIsOwner={personFirm === ownerFirm}
              project={project}
              person={person}
            />
            <Select
              style={{ marginBottom: "16px" }}
              rules={{ required: "select option" }}
              fullWidth
              label={"Credential"}
              name={"credentialTypeId"}
              options={options}
            />
          </GQLForm>
        </Grid>
      </Grid>
    );
  }
}
