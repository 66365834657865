import { PageState, Action } from "../../components/TypeDefinitions";

export function deleteReferenceMaterial<
  E extends Extract<
    Action,
    {
      type: "deleteReferenceMaterial";
    }
  >
>(action: E, draft: PageState): void {
  if (!draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds) {
    return;
  }

  if (
    draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds.includes(
      action.payload.referenceMaterialId
    )
  ) {
    const index =
      draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds.indexOf(
        action.payload.referenceMaterialId
      );
    draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds.splice(
      index,
      1
    );
  }
}
