import { DictionaryContents } from "src/utils/excelParse";
import { DictionaryLevel } from "../pages/ExcelMappingTool";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import onlyAlphabetChars from "src/utils/onlyAlphabetChars";

export default function updateField(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  fieldId: string,
  newExcelValue: string
) {
  if (!onlyAlphabetChars(newExcelValue)) return;
  newExcelValue = newExcelValue.toUpperCase();
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "specifiedLocation":
        {
          const enumeration = chain[1].enumeration;
          if (enumeration === false) return;
          const newChain = chain.slice(1);
          if (chain.length === 2) {
            if (chain[1].formId === chain[0].formId) {
              dictionary.enumerations[enumeration].fields![fieldId] =
                newExcelValue;
            } else {
              let subform =
                dictionary.enumerations[enumeration].subforms![chain[1].formId];
              if (subform.enumerable === false) {
                subform.fields![fieldId] = newExcelValue;
              }
            }
          } else {
            const nextDictionaryContent =
              dictionary.enumerations[enumeration].subforms;
            if (!nextDictionaryContent) return;
            updateField(
              nextDictionaryContent[chain[2].formId],
              newChain,
              fieldId,
              newExcelValue
            );
          }
        }
        break;
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (!dictionary.subforms) return;
          const nextDictionaryContent = dictionary.subforms[chain[1].formId];
          updateField(nextDictionaryContent, newChain, fieldId, newExcelValue);
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    switch (dictionary.enumerable) {
      case "specifiedLocation":
        if (chain[0].enumeration !== false) {
          dictionary.enumerations[chain[0].enumeration].fields![fieldId] =
            newExcelValue;
        }
        break;
      case "byOffset":
        dictionary.fields![fieldId] = newExcelValue;
        break;
      case false:
        dictionary.fields![fieldId] = newExcelValue;
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
    // if (dictionary.enumerable !== false) return;
    // if (!dictionary.fields) return;
    // dictionary.fields[fieldId] = newExcelValue;
  }
}
