import React, { FC, Fragment, memo } from "react";
import {
  Configuration,
  InputType,
} from "../../../../../Inspections/FormBuilder/components/TypeDefinitions";
import { decode } from "./components/utilities/decode";
import InspectionDisplay from "./components/InspectionDisplay";

export interface InspectionDisplayProps {
  config: Configuration;
  formJson: IFormJson;
}

const MultiInspectionDisplayWithoutMemo: FC<{
  thoFormInstances: IFormInstance[];
  config: Configuration;
  expandedDisplay?: boolean;
  updateSyncStatus?: (instanceId: string, isSyncing: boolean) => void;
}> = ({ thoFormInstances, expandedDisplay, updateSyncStatus, config }) => {
  const sortOrder = config.forms.map((form) => form.id);
  const sortedInstances = orderByField([...thoFormInstances], "id", sortOrder);
  return (
    <>
      {sortedInstances.map((thoFormInstance) => {
        const decodedJson = decode(thoFormInstance.formJson);
        
        return (
          <InspectionDisplay
            expandedDisplay={expandedDisplay}
            key={thoFormInstance.id}
            formJson={decodedJson}
            setIsSyncing={updateSyncStatus}
            config={config}
          />
        );
      })}
    </>
  );
};

const orderByField = <
  T extends { [key: string]: any },
  K extends keyof T,
  V extends T[K] & string
>(
  /** The elements you would like to sort by. */
  elements: T[],
  /** The property on the elements you would like to sort by.*/
  propertyToOrderBy: K,
  /**  The sort order of the properties. */
  orderDefinition: V[]
) => {
  let order: { [key: string]: number } = {};
  orderDefinition.forEach((element: string, i) => (order[element] = i));
  return elements.sort((prev, next) => {
    return order[prev[propertyToOrderBy]] - order[next[propertyToOrderBy]];
  });
};

const MultiInspectionDisplay = memo(
  MultiInspectionDisplayWithoutMemo,
  (prevProps, nextProps) => {
    return false;
  }
);

export default MultiInspectionDisplay;

export interface IFormInstance {
  formConfigurationId: string;
  formId: string;
  formJson: string;
  id: string;
  inspectionAssignmentId: string;
  inspectionId: string;
  inspectionTypeTemplateId: string;
  isDeleted: boolean;
  name: string;
  reference: string;
  referenceType: string;
  type: string;
}

export interface IFormJson {
  entityId: string | null;
  fields: IFormField[];
  formConfigurationId: string;
  formId: string;
  id: string;
  formattedAddress?: string;
  inspectionAssignmentId: string;
  inspectionId: string;
  inspectionTypeTemplateId: string;
  isDeleted: boolean;
  isDirty: boolean;
  isEmbedded: boolean;
  isValid: boolean;
  isVisible: boolean;
  name: string;
  parentFormId: string | null;
  parentFormInstanceId: string | null;
  reference: string;
  referenceType: string;
  subFormInstances: IFormJson[];
  type: string;
  needsInitialValidations: boolean;
  initialMapGenerated: boolean;
}

export interface ITopLevelFormJson extends IFormJson {
  controlNumber: string;
  inspectorName: string;
}

export interface IFormField {
  description: string;
  displayIndex: number;
  formFieldId: string;
  formId: string;
  formInput: IFormInput;
  formInstanceId: string;
  id: string;
  inputType: InputType;
  isDeleted: boolean;
  isDirty: boolean;
  isVisible: boolean;
  label: string;
  name: string;
  parentFormFieldId: string | null;
  parentFormFieldInstanceId: string | null;
  referenceMaterialIds: string[] | null;
}

export interface IFormInput {
  errors: string[];
  formFieldId: string;
  formFieldInstanceId: string;
  id: string;
  formValue?: IFormValue;
  isDeleted: boolean;
  isDirty: boolean;
  isValid: boolean;
  inputType: IInputType;
}

export interface IInputType {
  name: string;
  dataType: string;
  inputElement: string;
  isDirty: boolean;
  isDeleted: false;
}

export const InputDataType = {
  STRING: "STRING",
  DYNAMIC_LIST: "DYNAMIC_LIST",
  BOOL: "BOOL",
  NUMBER: "NUMBER",
  DATETIME: "DATETIME",
  JSON: "JSON",
  PHOTO_COLLECTION: "PHOTO_COLLECTION",
  SIGNATURE: "SIGNATURE",
  MAP_CAPTURE: "MAP_CAPTURE"
}

export const InputElement = {
  MULTILINE_TEXTBOX: "MULTILINE_TEXTBOX",
  CHECKBOX_LIST: "CHECKBOX_LIST",
  RADIO_BUTTON: "RADIO_BUTTON",
  RADIO_BUTTON_TRISTATE: "RADIO_BUTTON_TRISTATE",
  RADIO_BUTTON_LIST: "RADIO_BUTTON_LIST",
  NUMBER_BOX: "NUMBER_BOX",
  DROPDOWN: "DROPDOWN",
  CALENDAR_CLOCK: "CALENDAR_CLOCK",
  SINGLELINE_TEXTBOX: "SINGLELINE_TEXTBOX",
  EMAIL_TEXTBOX: "EMAIL_TEXTBOX",
  PHONE_TEXTBOX: "PHONE_TEXTBOX",
  CALENDAR: "CALENDAR",
  YEAR: "YEAR",
  CLOCK: "CLOCK",
  CONDITION_RATING: "CONDITION_RATING",
  PHOTO_COLLECTION: "PHOTO_COLLECTION",
  SIGNATURE: "SIGNATURE",
  MAP_CAPTURE: "MAP_CAPTURE"
}

export interface IFormValue {
  beginDateValue: null | string;
  booleanValue: null | boolean;
  createdAt: string | null;
  createdBy: string;
  doubleValue: null | number;
  endDateValue: null;
  entityId: string;
  firmId: string;
  formConfigurationId: string;
  formFieldId: string;
  formFieldInstanceId: string;
  formId: string;
  formInstanceId: string;
  id: string;
  inspectionAssignmentId: string;
  inspectionId: string;
  inspectionTemplateId: string;
  integerValue: null | number;
  isDeleted: boolean;
  isDirty: boolean;
  jsonValue: null | any;
  name: string;
  parentId: null;
  photoIds: string[] | null;
  referenceType: string;
  textValue: null | string;
  textValues: null | string[];
  valueType: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
}

export const inputTypes = [
    {name: "LongText", dataType: InputDataType.STRING, inputElement: InputElement.MULTILINE_TEXTBOX, isDeleted: false, isDirty: false},
    {name: "Text", dataType: InputDataType.STRING, inputElement: InputElement.SINGLELINE_TEXTBOX, isDeleted: false, isDirty: false},
    {name: "Email", dataType: InputDataType.STRING, inputElement: InputElement.EMAIL_TEXTBOX, isDeleted: false, isDirty: false},
    {name: "Phone", dataType: InputDataType.STRING, inputElement: InputElement.PHONE_TEXTBOX, isDeleted: false, isDirty: false},
    {name: "Checklist", dataType: InputDataType.DYNAMIC_LIST, inputElement: InputElement.CHECKBOX_LIST, isDeleted: false, isDirty: false},
    {name: "YesNo", dataType: InputDataType.BOOL, inputElement: InputElement.RADIO_BUTTON, isDeleted: false, isDirty: false},
    {name: "YesNoNA", dataType: InputDataType.BOOL, inputElement: InputElement.RADIO_BUTTON_TRISTATE, isDeleted: false, isDirty: false},
    {name: "Number", dataType: InputDataType.NUMBER, inputElement: InputElement.NUMBER_BOX, isDeleted: false, isDirty: false},
    {name: "OptionSelect", dataType: InputDataType.STRING, inputElement: InputElement.RADIO_BUTTON_LIST, isDeleted: false, isDirty: false},
    {name: "DropDown", dataType: InputDataType.STRING, inputElement: InputElement.DROPDOWN, isDeleted: false, isDirty: false},
    {name: "DateTime", dataType: InputDataType.DATETIME, inputElement: InputElement.CALENDAR_CLOCK, isDeleted: false, isDirty: false},
    {name: "Date", dataType: InputDataType.DATETIME, inputElement: InputElement.CALENDAR, isDeleted: false, isDirty: false},
    {name: "Year", dataType: InputDataType.DATETIME, inputElement: InputElement.YEAR, isDeleted: false, isDirty: false},
    {name: "Time", dataType: InputDataType.DATETIME, inputElement: InputElement.CLOCK, isDeleted: false, isDirty: false},
    {name: "DateTime", dataType: InputDataType.DATETIME, inputElement: InputElement.CALENDAR_CLOCK, isDeleted: false, isDirty: false},
    {name: "ConditionRating", dataType: InputDataType.JSON, inputElement: InputElement.CONDITION_RATING , isDeleted: false, isDirty: false},
    {name: "PhotoCollection", dataType: InputDataType.PHOTO_COLLECTION, inputElement: InputElement.PHOTO_COLLECTION , isDeleted: false, isDirty: false},
    {name: "Signature", dataType: InputDataType.SIGNATURE, inputElement: InputElement.SIGNATURE , isDeleted: false, isDirty: false},
    {name: "MapCapture", dataType: InputDataType.MAP_CAPTURE, inputElement: InputElement.MAP_CAPTURE, isDeleted: false, isDirty: false}
]
