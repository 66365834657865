import { useState, useEffect, useContext } from "react";
// material
import { Container, Grid, Button, Box, Typography } from '@mui/material';
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { Icon } from "@iconify/react";
import plusSquareOutline from "@iconify/icons-eva/plus-square-outline";

// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import CreateAsset from './create';
import { PersonContext } from "../../../utils/contexts/person";

const GET_ASSIGNMENTS = gql`
    query assignments($firmUnitId: ID) {
    assignments(firmUnitId: $firmUnitId) {
        active
        startTime
        endTime
        personId
        asset {
            id
            name
        }
    }
    }
`

export default function KpiList() {
    const [open, setOpen] = useState(false);
    let personContext = useContext(PersonContext);
    const handleClickOpen = () => {
        setOpen(true);
    };
    let columns = [
        {field: "asset", headerName: "Asset"},
        {field: "assignee", headerName: "Assignee"},
        {field: "startTime", headerName: "Start Time"},
        {field: "endTime", headerName: "End Time"},
    ];
    const addAssignment = () => {
        handleClickOpen()
    };

    const [assignments, setAssignments] = useState(null);
    const [getAssignments] = useLazyQuery(GET_ASSIGNMENTS, {
        onCompleted: (response) => {
            setAssignments(response.assignments)
        },
        onError: (response) => {
            debugger
        }
    });
    useEffect(
        () => {
            if (personContext.person && personContext.person.firmId && !assignments) {
                getAssignments({ variables: { firmUnitId: personContext.person.firmId } })
            }
        },
        [assignments, getAssignments, personContext]
    )
    if(!assignments)
        return null
    return (
        <Page title="Assets | FINBACK670">
            <Container maxWidth="xl">
                <CreateAsset open={open} setOpen={setOpen} />
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Assignments</Typography>
                </Box>
                <div style={{ height: 600, width: "100%" }}>
                <div style={{ paddingBottom: "16px" }}>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={4}
                    >
                        <Grid item>
                            <h4>Actions: </h4>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={addAssignment}
                                variant="outlined"
                                startIcon={<Icon icon={plusSquareOutline} />}
                            >
                                Add New
                            </Button>
                        </Grid>
                    </Grid>
                    </div>
                    <DataGridPremium
                        rows={assignments}
                        columns={columns}
                    />
                </div>
            </Container>
        </Page>
    );
}
