import { Action, PageState } from "../../components/TypeDefinitions";

export function updateValidationTrigger<
  E extends Extract<
    Action,
    {
      type: "updateValidationTrigger";
    }
  >
>(action: E, draft: PageState) {
  const { validationId, newValue } = action.payload;

  draft.data.inspectionTypeTemplate.configuration.forms
    .find((form) => form.id === draft.formId)
    ?.validations.every((validation) => {
      if (validation.id === validationId) {
        validation.onTrigger = newValue;
        return false;
      }
      return true;
    });
}
