import { Action, PageState } from "../../components/TypeDefinitions";

export function deletePossibleValue<
  E extends Extract<
    Action,
    {
      type: "deletePossibleValue";
    }
  >
>(action: E, draft: PageState): void {
  const { possibleValueId } = action.payload;
  const formId = draft.formId;
  const fieldId = draft.editingFieldId;
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => formId === form.id
    );

  if (formIndex === -1 || fieldId === null) return;
  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].fields.every(
    (field) => {
      if (
        field.id === fieldId &&
        (field.inputType === "Checklist" || field.inputType === "OptionSelect" || field.inputType ==="DropDown")
      ) {
        let possibleValueIndex = field.possibleValues.findIndex(
          (possibleValue) => possibleValueId === possibleValue.id
        );
        field.possibleValues.splice(possibleValueIndex, 1);
        field.possibleValues.forEach(
          (possibleValue, i) => (possibleValue.displayIndex = i)
        );
        return false;
      }
      return true;
    }
  );
}
