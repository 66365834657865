import React from "react";
import { useForm } from "react-hook-form";
//gql-mui-rhf
import { createQuery } from "../../utils/gql-mui-rhf/QueryAction";
import { GQLMutationAction } from "../../utils/gql-mui-rhf/SingleMutation";
import { useSnackbar } from "notistack";
// material
import {
  Container,
  Grid,
  Select,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../../components/Page";

// ----------------------------------------------------------------------

export default function RoleTypeCredential({
  roleType,
  shouldRefetch = () => {},
}) {
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const roleTypeId = roleType.id;
  const { loading, error, data, refetch } = createQuery("credentialTypes", {
    active: true,
    entity: roleType.entity,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  function generateMutation(refetch, snackbar, shouldRefetch) {
    return GQLMutationAction({
      mutationString: "createRoleTypeCredential",
      onCompleted: (response) => {
        refetch();
        shouldRefetch();
        snackbar("Added Field", "success");
      },
    });
  }
  const addRoleTypeCredentialValue = generateMutation(
    refetch,
    snackbar,
    shouldRefetch
  );
  const onSubmit = (data) =>
    addRoleTypeCredentialValue({
      roleTypeId,
      credentialTypeId: data.credentialTypeId,
    });

  console.log(errors);
  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    const credentialTypeOptions = data.credentialTypes.map((option) => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ));
    return (
      <Page title="Role  Management | FINBACK670">
        <Container maxWidth="xl" style={{ paddingBottom: "64pt" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems={"center"} spacing={4}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="cred-type-select-label">
                    {"Credential Type"}
                  </InputLabel>
                  <Select
                    label="Credential Type"
                    labelId="cred-type-select-label"
                    id="cred-type-select"
                    {...register("credentialTypeId", { required: true })}
                  >
                    {credentialTypeOptions}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Button type="submit" variant={"contained"}>
                  Add New Role Credential
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Page>
    );
  }
}
