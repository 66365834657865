import { gql, useMutation, useQuery } from "@apollo/client";
import { Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FileField } from "src/components/upload/FileField";
import { generateDictionary } from "src/utils/excelParse";
import { FormCtx } from "../../FormBuilder/FormWrapper/BuilderWrapper";
import uploadFileToBlob from "src/pages/Projects/Details/azure-storage-blob";
import { Configuration } from "../../FormBuilder/components/TypeDefinitions";

const REPORT_TEMPLATES_QUERY = gql`
  query ReportTypeTemplates(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplates(reportTypeTemplate: $reportTypeTemplate) {
      id
      reportType {
        id
        type
        description
      }
      templateUrl
    }
  }
`;

const CREATE_REPORT_TYPE = gql`
  mutation CreateReportType($reportType: InputReportTypeParams) {
    createReportType(reportType: $reportType) {
      type
      id
      description
    }
  }
`;

const CREATE_REPORT_TEMPLATE = gql`
  mutation CreateReportTypeTemplate(
    $reportTypeTemplate: InputReportTypeTemplateParams
  ) {
    createReportTypeTemplate(reportTypeTemplate: $reportTypeTemplate) {
      id
      configuration
      templateUrl
    }
  }
`;

const initialForm: {
  name: string;
  description: string;
  file: File | null;
  topLevelForm: Configuration
} = {
  name: "",
  description: "",
  file: null,
  topLevelForm: {forms: [], id: '', inspectionTypeTemplateId: ''}
};

const renderFormSelection = (questionnaires: { [key: string]: any }[], setForm: Function, form: { [key: string]: any } | null) => {

  const handleSelectChange = (event: SelectChangeEvent) => {
    // Find the selected questionnaire by its id
    const selectedQuestionnaire = questionnaires.find(q => q.id === event.target.value);
    if (selectedQuestionnaire) {
      setForm({...form, topLevelForm: selectedQuestionnaire});
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="questionnaire-select-label">Questionnaire</InputLabel>
      <Select
        labelId="questionnaire-select-label"
        id="questionnaire-select"
        value={form?.topLevelForm?.id || ''}
        label="Questionnaire"
        onChange={handleSelectChange}
      >
        {questionnaires.map((questionnaire) => (
          <MenuItem key={questionnaire.id} value={questionnaire.id}>
            {questionnaire.name} {/* Assuming each questionnaire has a 'name' property */}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function ReportSelect() {
  const { state } = useContext(FormCtx);
  const { inspectionTypeTemplateId } = useParams();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const navigate = useNavigate();
  const { data, loading } = useQuery(REPORT_TEMPLATES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      reportTypeTemplate: {
        inspectionTypeTemplateId: inspectionTypeTemplateId,
      },
    }
  });

  const [createReportType] = useMutation(CREATE_REPORT_TYPE);
  const [createReportTemplate] = useMutation(CREATE_REPORT_TEMPLATE);
  const questionnaires = state.data.inspectionTypeTemplate.configuration.forms.filter((form) => form.type === "Questionnaire" && form.id !== undefined && form.id !== null)
  const handleCreate = async () => {
    const createdType = await createReportType({
      variables: {
        reportType: {
          type: form.name,
          description: form.description,
        },
      },
    });
    debugger
    const config = JSON.stringify(
      generateDictionary(state.data.inspectionTypeTemplate.configuration, form.topLevelForm.id) || {}
    );
    debugger
    const fileUrlArray = await uploadFileToBlob(form.file);

    let matchingUrls: string[] = [];

    fileUrlArray.forEach((url: string) => {
      let segments: string[] = url.split('/');
      let fileName: string = segments.pop() || ''; // Extracts the last segment after the last slash
      if (fileName === form.file!.name) {
        matchingUrls.push(url);
      }
    });

    const fileUrl = matchingUrls[0];

    const createdTemplate = await createReportTemplate({
      variables: {
        reportTypeTemplate: {
          templateUrl: fileUrl,
          reportTypeId: createdType.data.createReportType.id,
          configuration: config,
          inspectionTypeTemplateId: inspectionTypeTemplateId,
        },
      },
    });
    // refetch()
    // navigate(createdTemplate.data.createReportTypeTemplate.id);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={async (e) => {
          setOpen(false);
          setTimeout(() => {
            setForm(initialForm);
          }, 500);
        }}
      >
        <DialogTitle>Create New Report</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2}>
            <TextField
              label="name"
              fullWidth
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
            <TextField
              label="description"
              fullWidth
              multiline
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
            {renderFormSelection(questionnaires, setForm, form)}
            <FileField
              loading={false}
              disabled={false}
              file={form.file}
              setFile={(blob) => {
                setForm({ ...form, file: blob });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h1>Report Templates</h1>
        <Button onClick={(e) => setOpen(true)}>Create New Report</Button>
      </div>
      {loading && <CircularProgress />}
      {data && (
        <DataGridPremium
          autoHeight
          columns={[
            {
              headerName: "Edit",
              field: "id",
              renderCell: (params: any) => {
                
                return (
                  <IconButton onClick={(e) => {
                    debugger
                    navigate(params.row.id)
                    }}>
                    <Edit />
                  </IconButton>
                );
              },
            },
            {
              headerName: "Report Type",
              field: "reportType",
              valueGetter: (params: any) => {
                return params.value.type;
              },
            },
            { headerName: "Template Link", field: "templateUrl" },
          ]}
          rows={data.reportTypeTemplates}
        />
      )}
    </>
  );
}
