import { Action, PageState } from "../../components/TypeDefinitions";
import updateIsVisibleFields from "./utilities/updateIsVisibleFields";

export function deleteGeoFeatureConfiguration<
  E extends Extract<
    Action,
    {
      type: "deleteGeoFeatureConfiguration";
    }
  >
>(action: E, draft: PageState): void {
  const { id } = action.payload;
  const formId = draft.formId;
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => formId === form.id
    );

  if (formIndex === -1) return;

  const geoFeatureConfigurationIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].geoFeatureConfigurations.findIndex((geoFeatureConfiguration) => geoFeatureConfiguration.id === id);

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].geoFeatureConfigurations.splice(geoFeatureConfigurationIndex, 1);

  updateIsVisibleFields(draft);
}
