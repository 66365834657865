import { useContext } from "react";
// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../../../components/Page";
import GQLTable from "../../../utils/gql-mui-rhf/Table";
import { PersonContext } from "../../../utils/contexts/person";

// ----------------------------------------------------------------------

export default function Assets() {
    let personContext = useContext(PersonContext);
    return (
        <Page title="Onboarding | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Asset Types</Typography>
                </Box>
                <Grid container spacing={3}>
                    <GQLTable
                        queryString={'assetTypes'}
                        addMutationVariables={{firmUnitId: personContext.person.firmId}}
                        queryVariables={{ "active": true, firmUnitId: personContext.person.firmId }}
                        addMutationString={'createAssetType'}
                        updateMutationString={'updateAssetType'}
                        deleteMutationString={'deleteAssetType'}
                        columnOptions={{ name: { width: 250 }, module: { width: 200 } }}
                        restrictedColumns={{ id: true, active: true, firmUnitId: true, depreciationMethod: true, assets: true }}
                    />
                </Grid>
            </Container>
        </Page>
    );
}

