import { Action, PageState } from "../../components/TypeDefinitions";

export function updateFormPrompt<
  E extends Extract<
    Action,
    {
      type: "updateFormPrompt";
    }
  >
>(action: E, draft: PageState): void {
  const { formPrompt } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].prompt =
    formPrompt;
}