import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import createLazyQuery from "src/utils/gql-mui-rhf/LazyQuery";
import { Select } from "../../../utils/mui-rhf";
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';

const GET_FIRM_UNITS = gql`
query FirmUnitsByIds($ids: [ID]) {
  firmUnitsByIds(ids: $ids) {
    id
    name
    firm {
      id
      name
    }
  }
}
`

// ----------------------------------------------------------------------
const onError = (e) => {
  debugger;
};

export default function FirmSelectors({ methods, project, person }) {
  const [firmUnitIds] = useState(
    project.projectFirmUnits.map((fU) => fU.firmUnitId)
  );
  const [firmUnits, setFirmUnits] = useState([]);

  const [getFirmUnitsById] = useLazyQuery(GET_FIRM_UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setFirmUnits(response.firmUnitsByIds);
    },
    onError: (e) => {
      debugger
    }
  });

  useEffect(() => {
    const ids = [project.projectOwner, ...firmUnitIds];
    const uniqueIds = [...new Set(ids)];
    getFirmUnitsById({ variables: { ids: uniqueIds } });
  }, [project, firmUnitIds, getFirmUnitsById]);

  const projectOwner = firmUnits.find((i) => i.id === project.projectOwner);

  const firmUnitOptions = [];
  firmUnits.forEach((firmUnit) => {
    if (projectOwner || person.firmId === firmUnit.firm.id) {
      firmUnitOptions.push({
        value: firmUnit.id,
        label: `${firmUnit.name} - ${firmUnit.firm.name}`,
        disabled: !projectOwner && person.firmId !== firmUnit.firm.id,
      });
    }
  });
  let projectFirmUnitId = methods.watch("projectFirmUnitId");
  useEffect(() => {
    if (methods.getValues("projectFirmUnitId")) {
      methods.setValue(
        "projectFirmId",
        firmUnits.find((el) => methods.getValues("projectFirmUnitId") === el.id)
          .firm.id
      );
    }
  }, [methods, projectFirmUnitId, firmUnits]);

  return (
    <Grid container spacing={3} width={1000}>
      <Grid item xs={12} sm={6} md={3}>
        <Select
          rules={{ required: "select option" }}
          fullWidth
          label={"Firm Unit"}
          name={"projectFirmUnitId"}
          options={firmUnitOptions}
        />
      </Grid>
    </Grid>
  );
}
