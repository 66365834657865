import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// material
import {
  Link,
  Box,
  Grid,
  Container,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { createQuery } from "../../utils/gql-mui-rhf/QueryAction";
import { TextField, Switch, DatePicker } from "../../utils/mui-rhf";
import { FormProvider, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import Checkmark from "@mui/icons-material/TaskAlt";
import SaveIcon from "@mui/icons-material/Save";
import Block from "@mui/icons-material/Block";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { GQLMutationAction } from "../../utils/gql-mui-rhf/SingleMutation";
import { GQLSyncMutationAction } from "../../utils/gql-mui-rhf/SyncMutation";
// import _ from 'lodash';
// components
import Page from "../../components/Page";
import FileUpload from "./FileUpload";
// ----------------------------------------------------------------------
function createDraftCredentialValue(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "createDraftCredentialValue",
    onCompleted: (response) => {
      refetch();
      snackbar("Saved Field", "success");
    },
  });
}

function approveOrRejectValue(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "updateDraftCredentialValue",
    onCompleted: (response) => {
      refetch();
      snackbar("Field Approved", "success");
    },
  });
}

function approveCredentialsRequest(refetch, snackbar, navigate) {
  return GQLSyncMutationAction({
    mutationString: "approveCredentialsRequest",
    onCompleted: (response) => {
      refetch();
      snackbar("Approved Request", "success");
      navigate();
    },
  });
}

function rejectCredentialsRequest(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "rejectCredentialsRequest",
    onCompleted: (response) => {
      refetch();
      snackbar("Submitted Request Information", "success");
    },
  });
}
const renderField = (
  credentialTypeValue,
  activeField,
  fieldStatus,
  setValue,
  defaultValues
) => {
  const disabled =
    credentialTypeValue.id !== activeField || fieldStatus === "submitted";
  switch (credentialTypeValue.type) {
    case "BOOLEAN":
      return (
        <FormGroup>
          <FormControlLabel
            name={credentialTypeValue.name}
            disabled={disabled}
            control={<Switch defaultChecked />}
            label={
              credentialTypeValue.label
                ? credentialTypeValue.label
                : credentialTypeValue.name
            }
          />
        </FormGroup>
      );
    case "IMAGE":
      return (
        <FileUpload
          disabled={disabled}
          credentialTypeValue={credentialTypeValue}
          setValue={setValue}
          defaultValues={defaultValues}
        />
      );
    case "PDF":
      return (
        <FileUpload
          disabled={disabled}
          credentialTypeValue={credentialTypeValue}
          setValue={setValue}
          defaultValues={defaultValues}
        />
      );
    case "TEXT":
      return (
        <TextField
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={credentialTypeValue.name}
          label={
            credentialTypeValue.label
              ? credentialTypeValue.label
              : credentialTypeValue.name
          }
          placeholder={credentialTypeValue.placeholder}
        />
      );
    case "INTEGER":
      return (
        <TextField
          type="number"
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={credentialTypeValue.name}
          label={
            credentialTypeValue.label
              ? credentialTypeValue.label
              : credentialTypeValue.name
          }
          placeholder={credentialTypeValue.placeholder}
        />
      );
    case "DECIMAL":
      return (
        <TextField
          type="number"
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={credentialTypeValue.name}
          label={
            credentialTypeValue.label
              ? credentialTypeValue.label
              : credentialTypeValue.name
          }
          placeholder={credentialTypeValue.placeholder}
        />
      );
    case "DATE":
      return (
        <DatePicker
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={credentialTypeValue.name}
          label={
            credentialTypeValue.label
              ? credentialTypeValue.label
              : credentialTypeValue.name
          }
          placeholder={credentialTypeValue.placeholder}
        />
      );
    default:
      return (
        <TextField
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={credentialTypeValue.name}
          label={
            credentialTypeValue.label
              ? credentialTypeValue.label
              : credentialTypeValue.name
          }
          placeholder={credentialTypeValue.placeholder}
        />
      );
  }
};
const getMutationVariables = (credentialTypeValue, data) => {
  switch (credentialTypeValue.type) {
    case "BOOLEAN":
      return {
        booleanValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
    case "TEXT":
      return {
        stringValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
    case "INTEGER":
      return {
        integerValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
    case "DECIMAL":
      return {
        decimalValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
    case "DATE":
      return {
        dateValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
    case "IMAGE":
      return {
        dateValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
    case "PDF":
      return {
        dateValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
    default:
      return {
        stringValue: data[credentialTypeValue.name],
        type: credentialTypeValue.type,
      };
  }
};
const assessValue = (value) => {
  switch (value.credentialTypeValue.type) {
    case "BOOLEAN":
      return value.booleanValue;
    case "TEXT":
      return value.stringValue;
    case "IMAGE":
      return value.stringValue;
    case "PDF":
      return value.stringValue;
    case "INTEGER":
      return value.integerValue;
    case "DECIMAL":
      return value.decimalValue;
    case "DATE":
      return value.dateValue;
    default:
      return value.stringValue;
  }
};
const getDefaultValues = (draftCredentialValues) => {
  const defaultValues = {};
  draftCredentialValues.forEach((value) => {
    if (value.credentialTypeValue?.active) {
      defaultValues[value.credentialTypeValue.name] = assessValue(value);
    }
  });
  return defaultValues;
};
const draftCredentialValuesObject = (draftCredentialValues) => {
  const defaultValues = {};
  draftCredentialValues.forEach((value) => {
    if (value.credentialTypeValue?.active) {
      defaultValues[value.credentialTypeValue.name] = {
        id: value.id,
        value: assessValue(value),
        status: value.status,
      };
    }
  });
  return defaultValues;
};
const ReviewDocuments = ({ filteredValues, draftCredentialValues }) => {
  return (
    <Grid container direction={"column"}>
      {filteredValues.map((filteredValue) => {
        const value = draftCredentialValues[filteredValue.name]
          ? draftCredentialValues[filteredValue.name]
          : {};
        if (
          !!value.stringValue &&
          (filteredValue.type === "IMAGE" || filteredValue.type === "PDF")
        ) {
          return (
            <Grid item>
              <Typography variant="h7">{`${filteredValue.name}: `}</Typography>
              <Link target="_blank" rel="noreferrer" href={value.stringValue}>
                Open
              </Link>
            </Grid>
          );
        } else if (
          !value.stringValue &&
          (filteredValue.type === "IMAGE" || filteredValue.type === "PDF")
        ) {
          return (
            <Grid item>
              <Typography variant="h7">{`${filteredValue.name}: `}</Typography>
              <Typography variant="h7">Not Uploaded</Typography>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};
const FieldGroup = ({
  defaultValues,
  value,
  fieldStatus,
  credentialTypeValue,
  draftCredential,
  activeField,
  setActiveField,
  addDraftCredentialValue,
}) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue } = methods;
  const onSubmit = (data) => {
    const variables = getMutationVariables(credentialTypeValue, data);
    addDraftCredentialValue({
      ...variables,
      type: credentialTypeValue.type,
      credentialTypeValueId: credentialTypeValue.id,
      draftCredentialId: draftCredential.id,
    });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          key={credentialTypeValue.id}
          spacing={0}
          container
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Grid item xs={2.75}>
            {renderField(
              credentialTypeValue,
              activeField,
              fieldStatus,
              setValue,
              defaultValues
            )}
          </Grid>
          <Grid item xs={1}>
            <Button
              disabled={
                value.status === "APPROVED" || value.status === "REJECTED"
              }
              onClick={() => {
                setActiveField(credentialTypeValue.id);
              }}
              variant="outlined"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              disabled={
                value.status === "APPROVED" || value.status === "REJECTED"
              }
              type={"submit"}
              variant="outlined"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
const getValueStatus = (filteredValues, draftCredentialValues) => {
  let valueStatus = { approved: [], rejected: [], submitted: [], all: [] };
  let status;
  filteredValues.forEach((value) => {
    if (!draftCredentialValues[value.name]) {
      return;
    }
    status = draftCredentialValues[value.name].status;
    valueStatus.all.push(value);
    switch (status) {
      case "APPROVED":
        valueStatus.approved.push(value);
        break;
      case "REJECTED":
        valueStatus.rejected.push(value);
        break;
      case "SUBMITTED":
        valueStatus.submitted.push(value);
        break;
      default:
        break;
    }
  });
  return valueStatus;
};

const processCredentials = (draftCredentials) => {
  return draftCredentials.map((credential) => {
    return {
      ...credential,
      draftCredentialValues: credential?.draftCredentialValues,
      credentialType: {
        ...credential.credentialType,
        credentialTypeValues:
          credential?.credentialType?.credentialTypeValues?.filter(
            (value) => value.active
          ),
      },
    };
  });
};

const processApproveCredentialsRequest = async (
  credentialsRequest,
  approvalMethods
) => {
  approvalMethods.approveCredentialsRequest({ id: credentialsRequest.id });
};
export default function CredentialRequestReview() {
  const navigate = useNavigate();
  const params = useParams();
  const credentialsRequestId = params.id;
  const { loading, error, data, refetch } = createQuery("credentialsRequest", {
    id: credentialsRequestId,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [activeField, setActiveField] = useState(null);
  const [activeCredentialIndex, setActiveCredentialIndex] = useState(0);
  const [activeValueIndex, setActiveValueIndex] = useState(0);
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const addDraftCredentialValue = createDraftCredentialValue(refetch, snackbar);
  const navigateToReview = () => {
    navigate("/app/project/credentials");
  };
  const handleApproveCredentialsRequest = approveCredentialsRequest(
    refetch,
    snackbar,
    navigateToReview
  );
  const approvalMethods = {
    approveCredentialsRequest: handleApproveCredentialsRequest,
  };
  const handleRejectCredentialsRequest = rejectCredentialsRequest(
    refetch,
    snackbar
  );
  const changeValueStatus = approveOrRejectValue(refetch, snackbar);
  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    const draftCredentials = processCredentials(
      data.credentialsRequest.draftCredentials
    );
    const draftCredential = draftCredentials[activeCredentialIndex];
    const credentialsLength = draftCredentials.length;
    const draftCredentialType = draftCredential.credentialType;
    const draftCredentialTypeValues = draftCredentialType.credentialTypeValues;
    let filteredValues = [...draftCredentialTypeValues];
    const order = draftCredential.credentialType.valueOrder;
    if (order) {
      const arrayMap = filteredValues.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue,
        }),
        {}
      );
      let orderedRows = order.map((key) => arrayMap[key]);
      let unorderedRows = filteredValues.filter(
        (item) => !order.includes(item.id)
      );
      filteredValues = [...orderedRows, ...unorderedRows];
    }
    filteredValues = filteredValues.filter((n) => n !== undefined);
    const defaultValues =
      draftCredential.draftCredentialValues &&
      getDefaultValues(draftCredential.draftCredentialValues);
    const draftCredentialValues =
      draftCredential.draftCredentialValues &&
      draftCredentialValuesObject(draftCredential.draftCredentialValues);
    const credentialTypeValue =
      draftCredentialTypeValues && filteredValues[activeValueIndex];
    const valueStatus = getValueStatus(filteredValues, draftCredentialValues);
    const value = draftCredentialValues[filteredValues[activeValueIndex].name]
      ? draftCredentialValues[filteredValues[activeValueIndex].name]
      : {};
    const valuesLength = filteredValues && filteredValues.length;

    return (
      <Page title="Onboarding | FINBACK670">
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Review Form</Typography>
          </Box>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Box sx={{ pb: 1 }}>
                <Typography variant="h6">{`Reference Documents for ${draftCredential.credentialType.name}`}</Typography>
              </Box>
            </Grid>
            <Grid item>
              {draftCredentialTypeValues && (
                <ReviewDocuments
                  filteredValues={filteredValues}
                  defaultValues={defaultValues}
                  draftCredentialValues={draftCredentialValues}
                  value={value}
                  draftCredential={draftCredential}
                  credentialTypeValue={credentialTypeValue}
                  activeField={activeField}
                />
              )}
            </Grid>
            <Grid item>
              <Box sx={{ pb: 1 }}>
                <Typography variant="h6">{`Review field for ${draftCredential.credentialType.name}`}</Typography>
              </Box>
            </Grid>
            <Grid item>
              {draftCredentialTypeValues && (
                <FieldGroup
                  defaultValues={defaultValues}
                  draftCredentialValues={draftCredentialValues}
                  value={value}
                  draftCredential={draftCredential}
                  credentialTypeValue={credentialTypeValue}
                  activeField={activeField}
                  setActiveField={setActiveField}
                  addDraftCredentialValue={addDraftCredentialValue}
                />
              )}
            </Grid>
            {(value.status === "APPROVED" || value.status === "REJECTED") && (
              <Grid item>
                <Box sx={{ pb: 1 }}>
                  <Typography variant="h6">{`Field is ${value.status.toLowerCase()}.`}</Typography>
                </Box>
              </Grid>
            )}
            <Grid item container spacing={2} direction="row">
              <Grid item>
                <Button
                  disabled={
                    value.status === "APPROVED" ||
                    value.status === "REJECTED" ||
                    !value.id
                  }
                  onClick={() => {
                    changeValueStatus({
                      id: value.id,
                      type: value.type,
                      status: "APPROVED",
                    });
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<Checkmark />}
                >
                  Accept Field
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={
                    value.status === "APPROVED" ||
                    value.status === "REJECTED" ||
                    !value.id
                  }
                  onClick={() => {
                    changeValueStatus({
                      id: value.id,
                      type: value.type,
                      status: "REJECTED",
                    });
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<Block />}
                >
                  Reject Field
                </Button>
              </Grid>
            </Grid>
            <Grid item container spacing={2} direction="row">
              <Grid item>
                <Button
                  disabled={
                    activeCredentialIndex === 0 && activeValueIndex === 0
                  }
                  onClick={() => {
                    if (activeValueIndex > 0) {
                      let newActiveValueIndex = activeValueIndex - 1;
                      setActiveValueIndex(newActiveValueIndex);
                    } else if (
                      activeValueIndex === 0 &&
                      activeCredentialIndex > 0
                    ) {
                      let newActiveCredentialIndex = activeCredentialIndex - 1;
                      let newActiveValueIndex =
                        draftCredentials[newActiveCredentialIndex]
                          .credentialType.credentialTypeValues.length - 1;
                      setActiveValueIndex(newActiveValueIndex);
                      setActiveCredentialIndex(newActiveCredentialIndex);
                    }
                  }}
                  color="info"
                  variant="contained"
                  startIcon={<KeyboardDoubleArrowLeftIcon />}
                >
                  Previous Field
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    if (activeValueIndex < valuesLength - 1) {
                      let newActiveValueIndex = activeValueIndex + 1;
                      setActiveValueIndex(newActiveValueIndex);
                    } else if (
                      activeValueIndex === valuesLength - 1 &&
                      activeCredentialIndex < credentialsLength - 1
                    ) {
                      let newActiveCredentialIndex = activeCredentialIndex + 1;
                      setActiveValueIndex(0);
                      setActiveCredentialIndex(newActiveCredentialIndex);
                    }
                  }}
                  disabled={
                    activeCredentialIndex === credentialsLength - 1 &&
                    activeValueIndex === valuesLength - 1
                  }
                  color="info"
                  variant="contained"
                  endIcon={<KeyboardDoubleArrowRightIcon />}
                >
                  Next Field
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6">{`${valueStatus.submitted.length} item(s) pending review`}</Typography>
              <Typography variant="h6">{`${valueStatus.approved.length} item(s) approved`}</Typography>
              <Typography variant="h6">{`${valueStatus.rejected.length} item(s) rejected`}</Typography>
            </Grid>
            <Grid item container spacing={2} direction="row">
              <Grid item>
                <Button
                  disabled={
                    valueStatus.all.length !== valueStatus.approved.length
                  }
                  onClick={() => {
                    processApproveCredentialsRequest(
                      data.credentialsRequest,
                      approvalMethods
                    );
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<Checkmark />}
                >
                  Submit Completed Review
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={data.credentialsRequest.status === "submitted"}
                  onClick={() => {
                    handleRejectCredentialsRequest({
                      id: credentialsRequestId,
                    });
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<Block />}
                >
                  Permanently Reject
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}
