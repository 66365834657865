import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "src/utils/contexts/auth";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const GET_PERSON = gql`
  query Person($person: InputPersonParams) {
    person(person: $person) {
      id
      avatar
      email
      firmId
      firstName
      lastName
      mailingAddressId
      phone1
      phone2
      preferences
      userId
      homepage
      projectFavorite
    }
  }
`;
let PersonContext = React.createContext(null);

function PersonProvider({ children }) {
  let { token, user } = useContext(AuthContext);
  const [person, setPerson] = useState(null);
  const [getPerson] = useLazyQuery(GET_PERSON, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setPerson(response.person);
      // if(response.person.homepage)
      // navigate(response.person.homepage);
    },
    onError: (response) => {
      debugger;
    },
  });
  useEffect(() => {
    if (token && user && !person) {
      getPerson({ variables: { person: { userId: user.id } } });
    }
  }, [token, user, person, getPerson]);
  let value = { person, setPerson };
  return (
    <PersonContext.Provider value={value}>{children}</PersonContext.Provider>
  );
}

export { PersonContext, PersonProvider };
