import React, { useState } from 'react';
import uploadFileToBlob, { isStorageConfigured } from './azure-storage-blob';
import { Grid, Button } from '@mui/material';

const storageConfigured = isStorageConfigured();


const App = ({ roleTypeValue, setValue, disabled, defaultValues, unload, setUnload }) => {
    // all blobs in container
    const [blobList, setBlobList] = useState([]);

    // current file to upload into container
    const [fileSelected, setFileSelected] = useState(null);
    const [itemString, setItemString] = useState(null);

    // UI/form management
    const [uploading, setUploading] = useState(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));

    const onFileChange = (event) => {
        // capture file into state
        setFileSelected(event.target.files[0]);
    };

    const onFileUpload = async () => {
        // prepare UI
        setUploading(true);
        // *** UPLOAD TO AZURE STORAGE ***
        const blobsInContainer = await uploadFileToBlob(fileSelected);
        const string = `https://thoxuploads.blob.core.windows.net/thox/${fileSelected.name}`
        setValue(roleTypeValue.name, string)
        // prepare UI for results
        setBlobList(blobsInContainer);
        setItemString(string)
        // reset state/form
        setFileSelected(null);
        setUploading(false);
        // Setinput key
        setInputKey(Math.random().toString(36));
    };

    // display form
    const DisplayForm = () => (
        <Grid container>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    component="label"
                    disabled={disabled}
                >
                    Browse
                    <input
                        type="file"
                        hidden
                        disabled={disabled}
                        name={roleTypeValue.name}
                        onChange={onFileChange}
                        key={inputKey || ''}
                    />
                </Button>
            </Grid>
            <Grid item xs={6}>
                <Button disabled={disabled} type="submit" onClick={onFileUpload}>
                    Upload!
                </Button>
            </Grid>
        </Grid>
    )

    // display file name and image
    const DisplayImageFromContainer = () => {
        const imageURL = blobList.find(item => itemString === item)
        if (imageURL.slice(-3) === 'pdf') {
            return <div>
                New Upload
                <br />
                <embed src={imageURL} width="375" height="375" type="application/pdf" />
            </div>
        } else {
            return (
                <div>
                    New Upload
                    <br />
                    <img src={imageURL} alt={imageURL} height="200" />
                </div>
            );
        }
    };
    const RenderCurrentImage = (imageURL) => {
        if (imageURL.slice(-3) === 'pdf') {
            return <embed src={imageURL} width="375" height="375" type="application/pdf" />
        } else {
            return <img src={imageURL} alt={imageURL} height="200" />
        }
    };

    return (
        <div>
            {defaultValues[roleTypeValue.name] && <div>
                Current
                <br />
                {RenderCurrentImage(defaultValues[roleTypeValue.name])}
            </div>}
            {storageConfigured && !uploading && DisplayForm()}
            {storageConfigured && uploading && <div>Uploading</div>}
            {storageConfigured && blobList.length > 0 && itemString && DisplayImageFromContainer()}
            {/* {storageConfigured && defaultValues[roleTypeValue.name] && DisplayImage(defaultValues[roleTypeValue.name])} */}
        </div>
    );
};

export default App;
