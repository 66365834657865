// material
import { Grid, Button, Container, Avatar, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import { FormProvider, useForm } from "react-hook-form";
import { useContext, useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import { useCallback } from "react";
// components
import { GQLMutationAction } from "../../utils/gql-mui-rhf/SingleMutation";
import { Select } from "../../utils/mui-rhf";
import { PersonContext } from "../../utils/contexts/person";
import Page from "../../components/Page";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";

// TODO correct variable naming
const GET_PROJECTS = gql`
  query projectPersons($personId: ID) {
    projectPersons(personId: $personId) {
      personId
      project {
        id
        name
      }
    }
  }
`;

const GET_PERSON = gql`
  query Person($person: InputPersonParams) {
    person(person: $person) {
      id
      avatar
      email
      firmId
      firstName
      lastName
      mailingAddressId
      phone1
      phone2
      preferences
      userId
      homepage
      projectFavorite
    }
  }
`;
// ----------------------------------------------------------------------
function updatePerson(snackbar, setImageSrc, setCroppedImageUrl, setPerson) {
  return GQLMutationAction({
    mutationString: "updatePerson",
    onCompleted: (response) => {
      snackbar("Profile Updated", "success");
      //refetch();
      setImageSrc(null);
      setCroppedImageUrl(null);
      setPerson(null);
    },
    onError: (response) => {
      snackbar("Profile Updated", "error");
      //refetch();
    },
  });
}
const homepageOptions = [
  { value: "/app/dashboard", label: "Dashboard" },
  { value: "/app/details", label: "Project Details" },
  { value: "/app/review", label: "Review" },
];

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const getCroppedImg = (imageSrc, pixelCrop) => {
  const image = new Image();
  image.src = imageSrc;
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return canvas;
};

export default function UserSettings() {
  const [currImageSrc, setCurrImageSrc] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  let personContext = useContext(PersonContext);
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };
  const [person, setPerson] = useState(null);
  const [projects, setProjects] = useState(null);
  const [getProjects] = useLazyQuery(GET_PROJECTS, {
    onCompleted: (response) => {
      setProjects(
        response.projectPersons.map((project) => {
          return { label: project.project.name, value: project.project.id };
        })
      );
    },
    onError: (response) => {},
  });

  const [getPerson] = useLazyQuery(GET_PERSON, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setPerson(response.person);
      getProjects({ variables: { personId: response.person.id } });
    },
    onError: (response) => {},
  });
  const handleUpdatePerson = updatePerson(
    snackbar,
    setImageSrc,
    setCroppedImageUrl,
    setPerson
  );
  const methods = useForm();
  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    const updatePayload = {
      id: person.id,
    };

    if (data.homepage || data.homepage === "") {
      updatePayload.homepage = data.homepage;
    }

    if (data.projectFavorite || data.projectFavorite === "") {
      updatePayload.projectFavorite = data.projectFavorite;
    }

    if (croppedImageUrl || croppedImageUrl === person.avatar) {
      updatePayload.avatar = croppedImageUrl;
    }

    handleUpdatePerson(updatePayload);
  };
  useEffect(() => {
    if (!person && personContext && personContext.person) {
      getPerson({
        variables: { person: { userId: personContext.person.userId } },
      });
    }
    if(person && personContext && personContext.person){

      setCurrImageSrc(person.avatar);
    }
  }, [person, getPerson, personContext]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(()=>{

  }, [currImageSrc])

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };

  const createCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      const base64 = croppedImage.toDataURL("image/jpeg");
      setCroppedImageUrl(base64);
    } catch (e) {
      console.error(e);
    }
  };

  if (!person) {
    return null;
  }
  
  return (
    <Page title="User Settings | FINBACK670">
      <Container maxWidth="xl">
        <Grid container spacing={3} style={{ paddingTop: "16px" }}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                spacing={0}
                container
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  {homepageOptions && (<Select
                    defaultValue={person.homepage}
                    minWidth={200}
                    variant={"outlined"}
                    id="outlined-basic"
                    label={"Homepage"}
                    name={"homepage"}
                    options={homepageOptions}
                  />)}
                  
                </Grid>
                <Grid item xs={12}>
                  {projects && (<Select
                    defaultValue={person.projectFavorite}
                    minWidth={200}
                    variant={"outlined"}
                    id="outlined-basic"
                    label={"Default Project"}
                    name={"projectFavorite"}
                    options={projects}
                  />)}
                  
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 5 }}>
                  <Typography variant="h6">Current Profile Image</Typography>
                  <div
                    style={{ width: 200, height: 200, position: "relative" }}
                  >
                    <Avatar
                      alt="Current"
                      style={{ maxWidth: "100%", width: 200, height: 200 }}
                      src={currImageSrc}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sx={{marginTop: 5, marginBottom: 5}}>
                  <Button variant="contained" component="label">
                    Select New Profile Image
                    <input
                      type="file"
                      onChange={onFileChange}
                      accept="image/*"
                      hidden
                    />
                    </Button>
                </Grid>
                {imageSrc && (
                  <Grid item xs={12}>
                  <Typography variant="h6">Crop Selected Image:</Typography>
                    <div
                      style={{ width: 200, height: 200, position: "relative" }}
                    >
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                    {imageSrc && (
                      <Button
                        style={{ marginTop: "16px", marginBottom: "16px" }}
                        variant="contained"
                        onClick={createCroppedImage}
                      >
                        Select Cropped Image
                      </Button>
                    )}
                  </Grid>
                )}
                {croppedImageUrl && (
                  <Grid item xs={12}>
                  <Typography variant="h6">Cropped Image:</Typography>
                    <div
                      style={{
                        marginBottom: "16px",
                        width: 200,
                        height: 200,
                        position: "relative",
                      }}
                    >
                      <img
                        alt="Crop"
                        style={{ maxWidth: "100%" }}
                        src={croppedImageUrl}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    type={"submit"}
                    variant="outlined"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Container>
    </Page>
  );
}
