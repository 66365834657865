import React, { useContext } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { FormCtx } from "../FormWrapper/BuilderWrapper";

export default function DnDContext({ formState, setFormState, children }) {
  const { dispatch } = useContext(FormCtx);
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (source == null || destination == null) return;
    if (result.destination.droppableId !== "formDisplayDroppable") return;

    /* INFO: When draggable is from the input sidebar, 
            AND it is dropped somewhere in the form display,
            it will be added to the form state at the specified display index.
    */
    if (result.source.droppableId === "inputButtonDroppable") {
      dispatch({
        type: "createField",
        payload: {
          inputType: result.draggableId,
          displayIndex: result.destination.index,
        },
      });
      return;
    }

    /* INFO: 
        When draggable is from the form display,
        AND it is dropped somewhere in the form display,
        it will be updated in the state to present at the new display index.
    */
    dispatch({
      type: "moveField",
      payload: {
        oldDisplayIndex: source.index,
        newDisplayIndex: destination.index,
      },
    });
  };

  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
}
