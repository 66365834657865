import { gql, useMutation } from "@apollo/client";
import { Box, Button, FormLabel } from "@mui/material";
import { useContext } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import { TextField, Switch } from "src/utils/mui-rhf";

// const createInspectionType = gql`
//   mutation Mutation($inspectionType: InputInspectionTypeParams) {
//     createInspectionType(inspectionType: $inspectionType) {
//       id
//       projectId
//       name
//       description
//     }
//   }
// `;

const createInspectionType = gql`
mutation CreateInspectionType($inspectionType: InputInspectionTypeParams) {
  createInspectionType(inspectionType: $inspectionType) {
    id
    projectId
    name
    description
  }
}`

export default function CreateInspectionType() {
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  const methods = useForm();
  const navigate = useNavigate();
  const { handleSubmit } = methods;

  const [createInspectionTypeMutation] = useMutation(createInspectionType, {
    onCompleted: (data) => {
      console.log("Successfully created Inspection Type:");
      console.log(data);
      console.log(data.createInspectionType.id);
    },
    onError: (err) => {
      console.log(err.message);
      err.clientErrors?.forEach((err) =>
        console.log("CLIENT ERR: " + err.message)
      );
      err.graphQLErrors?.forEach((cliErr) =>
        console.log("GQL ERR: " + cliErr.message)
      ); 
    },
  });

  async function callInspectionCreation(formData) {
    const inspectionTypeVariables = {
      variables: {
        inspectionType: {
          ...formData,
          projectId: project.id,
          defaultInspectionTypeId: "ab11f23e-40df-4a16-971c-30340f236738",
        },
      },
    };

    createInspectionTypeMutation(inspectionTypeVariables)
      .then((inspectionType) =>
        navigate(`../${inspectionType.data.createInspectionType.id}`)
      )
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Page>
      <Box
        sx={{
          paddingTop: "4rem",
          paddingLeft: "4rem",
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit((formData) =>
              callInspectionCreation(formData)
            )}
          >
            <Box
              maxWidth={"sm"}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <FormLabel>Create New Inspection</FormLabel>
              <TextField label="Inspection Type" name="name" fullWidth />
              <TextField
                multiline
                label="Description"
                fullWidth
                name="description"
              />
              <Switch label="Mobile Collection" name="isMobile"/>
              <Switch label="Web Collection" name="isWeb"/>
              <Button
                variant="contained"
                sx={{ alignSelf: "end", marginTop: "1rem" }}
                type="submit"
                onClick={handleSubmit((formData) =>
                  callInspectionCreation(formData)
                )}
              >
                Create Inspection Type
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Page>
  );
}
