import {
  List,
  ListItemText,
  ListSubheader,
  MenuItem,
  Stack,
} from "@mui/material";
import { FC, useContext } from "react";
import { ReferenceCtx, ReferenceMaterial, ReferenceMaterialResponse } from "..";
import { gql, useQuery } from "@apollo/client";
import { AddReferenceToListButton } from "./AddReferenceToListButton";

const generateRefMatObj = (refMats: string[]) => {
  let obj: any = {};
  refMats.forEach((refMat, i) => (obj["x".repeat(i + 1)] = { id: refMat }));
  return obj;
};

const REFERENCE_MATERIAL_QUERY = (referenceMaterialIds: string[]) => {
  const matLoop = referenceMaterialIds.map((refId, i) => {
    const xId = "x".repeat(i + 1);
    return `
    ${xId}: referenceMaterials(referenceMaterial: $${xId}) {
    id
    documentId
    stringContents
    isDeleted
    title
  }`;
  });

  const referenceMaterialVariableDeclarations = () => {
    let decString = "";
    referenceMaterialIds.forEach(
      (refMat, i) =>
        (decString += `$${"x".repeat(i + 1)}: InputReferenceMaterialParams!${
          referenceMaterialIds.length > 1 &&
          referenceMaterialIds.length !== i + 1
            ? ", "
            : ""
        }`)
    );
    return decString;
  };

  const docNode = gql`
    query ReferenceMaterials(${referenceMaterialVariableDeclarations()}) {${matLoop}
    }
  `;

  return docNode;
};

export const ListOfReference = () => {
  const { context } = useContext(ReferenceCtx);
  const { referenceMaterialIds, config } = context;

  const { data } = useQuery<ReferenceMaterialResponse>(
    REFERENCE_MATERIAL_QUERY(referenceMaterialIds),
    {
      variables: generateRefMatObj(referenceMaterialIds),
      fetchPolicy: "network-only",
    }
  );

  const selectedId = config.type === "edit" ? config.selectedId : null;

  return (
    <List
      subheader={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ListSubheader>Reference Materials</ListSubheader>
          <AddReferenceToListButton />
        </Stack>
      }
      sx={{ width: "400px" }}
    >
      {data &&
        Object.keys(data).map((key, i) => {
          
          const objToAccess: any = data;
          const reference: any = objToAccess[key][0];
          if (reference) {
            return (
              <Reference
                referenceMaterial={reference}
                selected={
                  selectedId !== null &&
                  reference &&
                  selectedId === reference.id
                }
              />
            );
          }
          return <></>;
        })}
    </List>
  );
};

const Reference: FC<{
  referenceMaterial: ReferenceMaterial;
  selected: boolean;
}> = ({ referenceMaterial, selected }) => {
  function getSecondaryText() {
    if (referenceMaterial.documentId) return "Document";
    return "Free Text";
  }

  const { context, setContext } = useContext(ReferenceCtx);

  return (
    <MenuItem
      selected={selected}
      onClick={(e) =>
        setContext({
          ...context,
          config: { selectedId: referenceMaterial.id, type: "edit" },
        })
      }
    >
      <ListItemText
        primary={referenceMaterial?.title || ""}
        secondary={getSecondaryText()}
      />
    </MenuItem>
  );
};

export function getReferenceMaterialById(
  refMatResponse: ReferenceMaterialResponse,
  id: string
) {
  const refMaterialWithId = refMatResponse.data.find(
    (refMat) => Object.keys(refMat)[0] === id
  );
  if (!refMaterialWithId) return null;
  return refMaterialWithId[Object.keys(refMaterialWithId)[0]];
}
