import { PageState, Action } from "../../components/TypeDefinitions";

export function movePossibleValue<
  E extends Extract<
    Action,
    {
      type: "movePossibleValue";
    }
  >
>(action: E, draft: PageState): void {
  if (draft.editingFieldId === null) return;
  const { newIndex, initialIndex } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  const fieldCopy = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.find((field) => field.id === draft.editingFieldId);

  if (fieldCopy === undefined) return;
  if (
    fieldCopy.inputType !== "Checklist" &&
    fieldCopy.inputType !== "OptionSelect"
  )
    return;

  const possibleValueCopy = fieldCopy.possibleValues.splice(initialIndex, 1)[0];

  fieldCopy.possibleValues.splice(newIndex, 0, possibleValueCopy);

  fieldCopy.possibleValues.forEach(
    (possibleValue, i) => (possibleValue.displayIndex = i)
  );
  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.forEach((field) => {
    if (field.id === fieldCopy.id) {
      field = fieldCopy;
    }
  });
}
