import { useParams } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { createQuery } from "../../../utils/gql-mui-rhf/QueryAction";
import { TextField, Switch, DatePicker } from "../../../utils/mui-rhf";
import { FormProvider, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { GQLMutationAction } from "../../../utils/gql-mui-rhf/SingleMutation";
// components
import Confirmation from "../../../components/modals/confirmation";
import Page from "../../../components/Page";
import FileUpload from "./FileUpload";
// ----------------------------------------------------------------------
function createDraftRoleValue(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "createDraftRoleValue",
    onCompleted: (response) => {
      refetch();
      snackbar("Saved Field", "success");
    },
  });
}
function submitRolesRequest(
  refetch,
  snackbar,
  handleSendSubmitEmail,
  handleSetConfirmation
) {
  return GQLMutationAction({
    mutationString: "submitRolesRequest",
    onCompleted: (response) => {
      refetch();
      handleSendSubmitEmail();
      handleSetConfirmation();
      snackbar("Submitted Request Information", "success");
    },
  });
}
function submitCreateEmail() {
  return GQLMutationAction({
    mutationString: "createEmail",
    onCompleted: (response) => {},
  });
}

const processSubmitEmail = (rolesRequest, handleCreateEmail) => {
  let variables = { data: {} };
  variables["templateId"] = "d-07c0810056d84dce8cad0f333bc7308f";
  variables["to"] = rolesRequest.email;
  variables["data"][
    "url"
  ] = `${process.env.REACT_APP_URL}/app/team/onboarding/request/${rolesRequest.id}`;
  if (rolesRequest.entity === "PERSON")
    variables["data"][
      "name"
    ] = `${rolesRequest.firstName} ${rolesRequest.lastName}`;
  else variables["data"]["name"] = rolesRequest.name;
  variables["data"] = JSON.stringify(variables.data);
  handleCreateEmail(variables);
};

const renderField = (
  roleTypeValue,
  activeField,
  fieldStatus,
  setValue,
  defaultValues
) => {
  const disabled =
    roleTypeValue.id !== activeField || fieldStatus === "submitted";
  switch (roleTypeValue.type) {
    case "BOOLEAN":
      return (
        <FormGroup>
          <FormControlLabel
            name={roleTypeValue.name}
            disabled={disabled}
            control={<Switch defaultChecked />}
            label={
              roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name
            }
          />
        </FormGroup>
      );
    case "IMAGE":
      return (
        <FileUpload
          disabled={disabled}
          roleTypeValue={roleTypeValue}
          setValue={setValue}
          defaultValues={defaultValues}
        />
      );
    case "PDF":
      return (
        <FileUpload
          disabled={disabled}
          roleTypeValue={roleTypeValue}
          setValue={setValue}
          defaultValues={defaultValues}
        />
      );
    case "TEXT":
      return (
        <TextField
          fullWidth
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    case "INTEGER":
      return (
        <TextField
          fullWidth
          type="number"
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    case "DECIMAL":
      return (
        <TextField
          fullWidth
          type="number"
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    case "DATE":
      return (
        <DatePicker
          fullWidth
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    default:
      return (
        <TextField
          fullWidth
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
  }
};
const getMutationVariables = (roleTypeValue, data) => {
  switch (roleTypeValue.type) {
    case "BOOLEAN":
      return {
        booleanValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "TEXT":
      return {
        stringValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "INTEGER":
      return {
        integerValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "DECIMAL":
      return {
        decimalValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "DATE":
      return { dateValue: data[roleTypeValue.name], type: roleTypeValue.type };
    case "IMAGE":
      return {
        stringValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "PDF":
      return {
        stringValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    default:
      return {
        stringValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
  }
};
const assessValue = (value) => {
  switch (value.roleTypeValue.type) {
    case "BOOLEAN":
      return value.booleanValue;
    case "TEXT":
      return value.stringValue;
    case "INTEGER":
      return value.integerValue;
    case "DECIMAL":
      return value.decimalValue;
    case "DATE":
      return value.dateValue;
    case "IMAGE":
      return value.stringValue;
    case "PDF":
      return value.stringValue;
    default:
      return value.stringValue;
  }
};
const getDefaultValues = (draftRoleValues) => {
  const defaultValues = {};
  draftRoleValues.forEach((value) => {
    defaultValues[value.roleTypeValue.name] = assessValue(value);
  });
  return defaultValues;
};
const draftRoleValuesObject = (draftRoleValues) => {
  const defaultValues = {};
  draftRoleValues.forEach((value) => {
    defaultValues[value.roleTypeValue.name] = {
      value: assessValue(value),
      status: value.status,
    };
  });
  return defaultValues;
};

const getRequiredFields = (draftRoles) => {
  let requiredFields = [];
  let requiredFieldsWithValues = [];
  let requiredFieldsWithoutValues = [];
  let draftRoleValues = {};
  draftRoles.forEach((draftRole) => {
    if (draftRole.draftRoleValues) {
      draftRole.draftRoleValues.forEach((value) => {
        if (!value.roleTypeValue.active) {
          return null;
        }
        draftRoleValues[value.roleTypeValue.name] = !!assessValue(value);
      });
    }
  });
  // Cycle through all fields and note whether they're required
  draftRoles.forEach((draftRole) => {
    draftRole.roleType.roleTypeValues.forEach((value) => {
      if (!value.active) {
        return null;
      }
      if (value.required && draftRoleValues[value.name]) {
        requiredFields.push(value);
        requiredFieldsWithValues.push(value);
      } else if (value.required && !draftRoleValues[value.name]) {
        requiredFields.push(value);
        requiredFieldsWithoutValues.push(value);
      }
    });
  });
  return {
    requiredFields,
    requiredFieldsWithValues,
    requiredFieldsWithoutValues,
  };
};
const FieldGroup = ({
  defaultValues,
  fieldStatus,
  roleTypeValue,
  draftRole,
  activeField,
  setActiveField,
  addDraftRoleValue,
}) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, setValue } = methods;
  const onSubmit = (data) => {
    let variables = getMutationVariables(roleTypeValue, data);

    if (variables.type === "INTEGER") {
      variables.integerValue = parseInt(variables.integerValue);
    }
    if (variables.type === "DECIMAL") {
      variables.decimalValue = parseFloat(variables.decimalValue);
    }

    addDraftRoleValue({
      ...variables,
      type: roleTypeValue.type,
      roleTypeValueId: roleTypeValue.id,
      draftRoleId: draftRole.id,
    });
    setActiveField(null);
  };
  const editDisabled = activeField !== null || fieldStatus === "submitted";
  const saveDisabled =
    roleTypeValue.id !== activeField || fieldStatus === "submitted";
  return (
    <Grid item>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            key={roleTypeValue.id}
            spacing={2}
            container
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Grid width="200px" item>
              {`${
                roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name
              }${roleTypeValue.required ? "*" : ""}:`}
            </Grid>
            <Grid width="400px" item>
              {renderField(
                roleTypeValue,
                activeField,
                fieldStatus,
                setValue,
                defaultValues
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={editDisabled}
                onClick={() => {
                  setActiveField(roleTypeValue.id);
                }}
                variant="outlined"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={saveDisabled}
                type={"submit"}
                variant="outlined"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  );
};
const getFieldGroups = (
  draftRole,
  defaultValues,
  draftRoleValues,
  activeField,
  setActiveField,
  addDraftRoleValue
) => {
  let fieldGroups = JSON.parse(
    JSON.stringify(draftRole.roleType.roleTypeValues)
  );
  const order = draftRole.roleType.valueOrder;
  if (order) {
    const arrayMap = fieldGroups.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: currentValue,
      }),
      {}
    );
    let orderedRows = order.map((key) => arrayMap[key]);
    let unorderedRows = fieldGroups.filter((item) => !order.includes(item.id));
    fieldGroups = [...orderedRows, ...unorderedRows];
  }
  // Clean any undefined row elements
  fieldGroups = fieldGroups.filter(Boolean);
  return fieldGroups.map((roleTypeValue, idx) => {
    return (
      roleTypeValue.active && (
        <FieldGroup
          key={idx}
          defaultValues={defaultValues}
          fieldStatus={
            draftRoleValues[roleTypeValue.name]
              ? draftRoleValues[roleTypeValue.name].status
              : "pending"
          }
          draftRole={draftRole}
          roleTypeValue={roleTypeValue}
          activeField={activeField}
          setActiveField={setActiveField}
          addDraftRoleValue={addDraftRoleValue}
        />
      )
    );
  });
};
export default function RoleRequest() {
  const params = useParams();
  const [activeField, setActiveField] = useState(null);
  const rolesRequestId = params.id;
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const { loading, error, data, refetch } = createQuery("rolesRequest", {
    id: rolesRequestId,
  });
  const addDraftRoleValue = createDraftRoleValue(refetch, snackbar);

  //Confirmation
  const [confirmation, setConfirmation] = useState({ open: false });
  const closeConfirmation = () => {
    setConfirmation({ open: false });
  };
  const handleSetConfirmation = () =>
    setConfirmation({
      open: true,
      action: closeConfirmation,
      actionText: `Your submission has been sent for review. Once the review has been completed, you will be notified${
        data.rolesRequest ? ` at ${data.rolesRequest.email}` : ""
      } of the status of the onboarding information request${
        data.rolesRequest && data.rolesRequest.name
          ? ` for ${data.rolesRequest.name}`
          : ""
      }. Thank you!`,
      alternateDisplay: "INFORMATION",
    });
  const handleCreateEmail = submitCreateEmail();
  const handleSendSubmitEmail = () => {
    processSubmitEmail(data.rolesRequest, handleCreateEmail);
  };
  const handleSubmitRolesRequest = submitRolesRequest(
    refetch,
    snackbar,
    handleSendSubmitEmail,
    handleSetConfirmation
  );
  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    const requiredFieldStatus = getRequiredFields(data.rolesRequest.draftRoles);
    return (
      <Page title="Onboarding | FINBACK670">
        <Confirmation
          confirmation={confirmation}
          handleClose={closeConfirmation}
        />
        <Container maxWidth="xl">
          <Grid container direction={"column"} spacing={2}>
            <Grid item>
              <Typography variant="h4">Request Form</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4h7">
                Note: All inputted, edited, and deleted data is saved in
                real-time by utilizing the icons to the right of each data
                field.
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h4h7">
                  To edit a data field, click:{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" startIcon={<EditIcon />}>
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item>
                <Typography variant="h4h7">
                  To save edits to a datafield, click:{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" startIcon={<SaveIcon />}>
                  Save
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Button variant="outlined" color="primary">
                  * indicates a required data field
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {data.rolesRequest.status === "submitted" && (
                <Box sx={{ pb: 5 }}>
                  <Typography variant="h7">
                    Request has been submitted for approval and is locked.
                  </Typography>
                </Box>
              )}
            </Grid>
            {data.rolesRequest.draftRoles &&
              data.rolesRequest.draftRoles.map((draftRole) => {
                if (!draftRole.roleType) {
                  return null;
                }
                const defaultValues = draftRole.draftRoleValues
                  ? getDefaultValues(draftRole.draftRoleValues)
                  : {};
                const draftRoleValues = draftRole.draftRoleValues
                  ? draftRoleValuesObject(draftRole.draftRoleValues)
                  : {};
                return (
                  <Grid item key={draftRole.roleType.id}>
                    <Box sx={{ pb: 5 }}>
                      <Grid
                        container
                        direction="column"
                        alignItems={"flex-start"}
                        justifyContent={"center"}
                        spacing={2}
                      >
                        <Box sx={{ pb: 5 }}>
                          <Typography variant="h5">
                            {draftRole.roleType.name}
                          </Typography>
                        </Box>
                        {getFieldGroups(
                          draftRole,
                          defaultValues,
                          draftRoleValues,
                          activeField,
                          setActiveField,
                          addDraftRoleValue
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            {requiredFieldStatus.requiredFields.length !==
              requiredFieldStatus.requiredFieldsWithValues.length && (
              <Grid item container spacing={8} direction={"row"}>
                <Grid item>
                  <Typography>{`${
                    requiredFieldStatus.requiredFields.length -
                    requiredFieldStatus.requiredFieldsWithValues.length
                  } fields missing`}</Typography>
                </Grid>
                {requiredFieldStatus.requiredFieldsWithoutValues.map(
                  (value) => (
                    <Grid item>
                      <Typography>{`${value.name} missing.`}</Typography>
                    </Grid>
                  )
                )}
              </Grid>
            )}
            <Grid item container spacing={8} direction={"row"}>
              <Grid item>
                <Button
                  disabled={
                    requiredFieldStatus.requiredFields.length !==
                      requiredFieldStatus.requiredFieldsWithValues.length ||
                    data.rolesRequest.status === "submitted"
                  }
                  onClick={() => {
                    handleSubmitRolesRequest({ id: rolesRequestId });
                  }}
                  variant="outlined"
                  startIcon={<SaveIcon />}
                >
                  Submit Request for Approval
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}
