import { useState, useContext, useEffect } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from "react-hook-form";
import { gql } from '@apollo/client';
import Select from '../../../utils/mui-rhf/Select';
import DateTimePicker from '../../../utils/mui-rhf/DateTimePicker';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PersonContext } from "../../../utils/contexts/person";

const GET_ASSETS = gql`
    query assets($firmUnitId: ID) {
    assets(firmUnitId: $firmUnitId) {
        name
        id
    }
    }
`

const CREATE_ASSIGNMENT = gql`
    mutation createAssignment($asset: InputAssetParams){
      createAssignment(asset: $asset) {
        id
        name
      }
    }
  `;
export default function CreateAsset({ open, setOpen, projectId }) {
    let personContext = useContext(PersonContext);
    const onCompleted = () => { }
    const [createAssignment] = useMutation(CREATE_ASSIGNMENT, { onCompleted });

    const handleClose = () => {
        setOpen(false);
    };

    const methods = useForm({ defaultValues: { operationValues: [] } });
    const { handleSubmit } = methods;
    const onSubmit = (data) => {
        createAssignment({ variables: { assignment: { 
            active: data.active,
            startTime: data.startTime,
            endTime: data.endTime,
            personId: data.personId,
            firmUnitId: data.firmUnitId,
            assetId: data.assetId,
         } } })
    }
    const [assets, setAssets] = useState(null);
    const [getAssets] = useLazyQuery(GET_ASSETS, {
        onCompleted: (response) => {
            setAssets(response.assets.map((assetType) => {
                return { label: assetType.name, value: assetType.id }
            }))
        },
        onError: (response) => {
            debugger
        }
    });
    useEffect(
        () => {
            if (personContext.person.firmId && !assets) {
                getAssets({ variables: { firmUnitId: personContext.person.firmId } })
            }
        },
        [assets, getAssets, personContext]
    )
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Assignment</DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Select minWidth={200} required label={"Asset"} name={'assetId'} options={assets} />
                            </Grid>
                            <Grid item xs={12}>
                                <DateTimePicker type={"number"} required label={"Start Time"} name={'startTime'} />
                            </Grid>
                            <Grid item xs={12}>
                                <DateTimePicker type={"number"} required label={"End Time"} name={'endTime'} />
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type={'submit'}>Save</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}