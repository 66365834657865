import React from "react";
import { Route, Routes } from "react-router-dom";
import PDFMappingTool from "./pages/PDFMappingTool";
import ReportSelect from "./pages/ReportSelect";

export default function PDFMapping() {
  return (
    <Routes>
      <Route path="/" element={<ReportSelect />} />
      <Route path="/:reportTypeTemplateId" element={<PDFMappingTool />} />
    </Routes>
  );
}
