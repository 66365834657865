import { gql, useMutation } from "@apollo/client";
import { Delete } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

const DELETE_WASHOUT = gql`
  mutation DeleteWashoutNote($deleteWashoutNoteId: ID!) {
    deleteWashoutNote(id: $deleteWashoutNoteId) {
      id
    }
  }
`;

export default function WashoutNote({
  note,
  person,
  refetch,
}: {
  note: any;
  person: any;
  refetch: () => void;
}) {
  const [deleteWashout] = useMutation(DELETE_WASHOUT, {
    variables: {
      deleteWashoutNoteId: note.id,
    },
    onCompleted: () => refetch(),
  });
  return (
    <Stack
      direction="row"
      sx={{ borderBottom: "solid  1px lightgrey", margin: "0.5rem" }}
    >
      <Stack direction={"column"} flexGrow={1}>
        <Typography>{note.note}</Typography>
        <Typography variant="subtitle2" sx={{ alignSelf: "end" }}>
          {note.createdPerson.firstName +
            " " +
            note.createdPerson.lastName +
            " - " +
            format(new Date(note.insertedAt), "MM/dd/yyyy - p")}
        </Typography>
      </Stack>
      {person.id === note.createdPerson.id && (
        <IconButton onClick={() => deleteWashout()}>
          <Delete />
        </IconButton>
      )}
    </Stack>
  );
}
