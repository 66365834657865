import { PageState, Action } from "../../components/TypeDefinitions";
export function deleteReferenceMaterialFromField<
  E extends Extract<
    Action,
    {
      type: "deleteReferenceMaterialFromField";
    }
  >
>(action: E, draft: PageState): void {
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  if (formIndex === -1) return;
  const fieldIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.findIndex((field) => field.id === action.payload.fieldId);
  if (fieldIndex === -1) return;
  const currField =
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex].fields[
      fieldIndex
    ];

  if (!draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds) {
    draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds = [];

    return;
  }
  
  var fieldReferenceMaterials: any = Object.values(currField.referenceMaterialIds as any);
  if(fieldReferenceMaterials.includes(action.payload.referenceMaterialId)){
    var refIndex = fieldReferenceMaterials.findIndex((e:any) => e === action.payload.referenceMaterialId);
    fieldReferenceMaterials.splice(refIndex, 1);

  Object.assign(
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex].fields[
      fieldIndex
    ],
    {referenceMaterialIds: fieldReferenceMaterials}
  );
  }
}
