import { PageState, Action } from "../../components/TypeDefinitions";

export function copyField<
  E extends Extract<
    Action,
    {
      type: "copyField";
    }
  >
>(action: E, draft: PageState): void {
  const { fieldId } = action.payload;

  let formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  let originalField = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.find((field) => field.id === fieldId);

  if (!originalField) return;

  let fieldCopy = { ...originalField };

  draft.fieldToCopy = fieldCopy;
}
