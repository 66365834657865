import { useContext, useEffect } from "react";
import { FormCtx } from "../FormWrapper/BuilderWrapper";
import { FormControl } from "@mui/base";
import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormLabel,
} from "@mui/material";

import { Error, Add, ExpandMore, Clear } from "@mui/icons-material";
import { gql, useLazyQuery } from "@apollo/client";

const GET_GEO_RULES = gql`
  query GeoRules($geoRule: InputGeoRuleParams) {
    geoRules(geoRule: $geoRule) {
      id
      title
      description
      value
      unit
      featureType {
        id
        name
      }
      operand
    }
  }
`;

export default function GeoFeatureConfigurationDisplay() {
  const { state, dispatch } = useContext(FormCtx);

  const [
    getGeoRules,
    { data: geoRulesData, loading: geoRulesLoading, called: getGeoRulesCalled },
  ] = useLazyQuery(GET_GEO_RULES, {
    fetchPolicy: "network-only",
    onError: (response) => {},
  });

  useEffect(() => {
    if (!getGeoRulesCalled) {
      getGeoRules();
    }
  });
  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );
  const geoFeatureConfigurations = form?.geoFeatureConfigurations ?? [];
  return (
    <>
      {geoFeatureConfigurations.length > 0 && geoRulesData ? (
        <Stack sx={{padding: "10px"}}>
          {geoFeatureConfigurations.map((config, i) => {
            const configHasError = state.errors.some(
              (error) => error.id === config.id
            );
            return (<Stack
              sx={{ backgroundColor: "#F0F0F0"}}
              padding={1}
              maxHeight="100%"
              borderRadius={1}
            >
              <Accordion key={config.id}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <Typography>
                      {"Geo Feature Configuration " + (i + 1)}
                    </Typography>
                    {configHasError && <Error htmlColor="red" />}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <FormControl>
                        <FormLabel>
                          Query geo features for the following rule:
                        </FormLabel>

                        <TextField
                          select
                          sx={{ margin: "5px", width: "50%"}}
                          size="small"
                          label="Geo Rule"
                          value={config?.geoRuleId ?? null}
                          error={config?.geoRuleId === null}
                          onChange={(e) =>
                            dispatch({
                              type: "updateGeoFeatureConfiguration",
                              payload: {
                                id: config.id,
                                geoRuleId: e.target.value,
                              },
                            })
                          }
                        >
                          {geoRulesData.geoRules.map((option, i) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                      <Button
                        color="error"
                        endIcon={<Clear />}
                        onClick={() =>
                          dispatch({
                            type: "deleteGeoFeatureConfiguration",
                            payload: {
                              id: config.id,
                            },
                          })
                        }
                      >
                        Delete
                      </Button>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1.5rem",
                      }}
                    >
                      <FormControl>
                        <FormLabel>Return </FormLabel>
                        <TextField
                          sx={{ width: "100px"}}
                          helperText=""
                          size="small"
                          label=""
                          value={config?.count ?? null}
                          onChange={(e) =>
                            dispatch({
                              type: "updateGeoFeatureConfiguration",
                              payload: {
                                id: config.id,
                                count: e.target.value,
                              },
                            })
                          }
                        ></TextField>
                        <FormLabel>
                          {" "}
                          features {"(i.e. 10. Leave blank to return all)."}
                        </FormLabel>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1.5rem",
                      }}
                    >
                      <FormControl>
                        <FormLabel>Order by distance</FormLabel>

                        <TextField
                          select
                          sx={{ margin: "5px"}}
                          size="small"
                          label=""
                          value={config?.order ?? "asc"}
                          error={config?.order === null}
                          onChange={(e) =>
                            dispatch({
                              type: "updateGeoFeatureConfiguration",
                              payload: {
                                id: config.id,
                                order: e.target.value,
                              },
                            })
                          }
                        >
                          <MenuItem key={"asc"} value={"asc"}>
                            {"Ascending"}
                          </MenuItem>
                          <MenuItem key={"desc"} value={"desc"}>
                            {"Descending"}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
        </Stack>
            );
          })}
          <Button
            sx={{ alignSelf: "center", marginTop: "0.5rem" }}
            variant="contained"
            size="small"
            endIcon={<Add />}
            onClick={() =>
              dispatch({ type: "createGeoFeatureConfiguration" })
            }
          >
            Add Geo Feature Configuration
          </Button></Stack>
      ) : (
        <>
          {geoRulesData && (
            <Stack
              sx={{
                alignSelf: "center",
                width: "100%"
              }}
            >
              <Typography sx={{ alignSelf: "center" }} color="GrayText">
                No geo feature configurations on this form yet.
              </Typography>
              <Button
                sx={{ alignSelf: "center", marginTop: "0.5rem" }}
                variant="contained"
                size="small"
                endIcon={<Add />}
                onClick={() =>
                  dispatch({ type: "createGeoFeatureConfiguration" })
                }
              >
                Add Geo Feature Configuration
              </Button>
            </Stack>
          )}
        </>
      )}
    </>
  );
}
