import { IFormField, IFormJson } from "..";
import { memo } from "react";
import { Stack } from "@mui/material";
import InspectionItem from "./InspectionItem";
import { Form } from "src/pages/Inspections/FormBuilder/components/TypeDefinitions";

interface FieldDisplayProps {
  formJson: IFormJson;
  form: Form;
  updateFormJson: (newJson: IFormJson) => void;
  callback?: any;
}

const FieldDisplayForForm = ({
  formJson,
  form,
  updateFormJson,
  callback
}: FieldDisplayProps) => {
  if (!formJson.fields || formJson.fields.length < 1) return null;
  if (formJson.fields) {
    const fieldUpdater = (updatedField: IFormField) => {
      const fieldIndex = formJson.fields.findIndex(
        (field) => field.id === updatedField.id
      );
      if (fieldIndex === -1) {
        return;
      }
      let formJsonCopy: IFormJson = JSON.parse(JSON.stringify(formJson));
      formJsonCopy.fields[fieldIndex] = updatedField;
      updateFormJson(formJsonCopy);
    };
    return (
      <Stack direction="column" paddingLeft="2rem" spacing="2rem">
        {formJson.fields.map((field: IFormField) => (
          <MemoizedInspectionItem
            field={field}
            formJson={formJson}
            fieldUpdater={fieldUpdater}
            form={form}
            callback={callback}
          />
        ))}
      </Stack>
    );
  } else return null;
};

export const MemoizedInspectionItem = memo(
  InspectionItem,
  (prevProps, nextProps) => {
    if (JSON.stringify(prevProps.field) === JSON.stringify(nextProps.field)) {
      return true;
    } else {
      return false;
    }
  }
);

export default FieldDisplayForForm;

// const checkFieldAgainstValidation = ({
//   validation,
//   field,
// }: {
//   validation: Validation;
//   field: IFormField;
// }) => {};
// const checkFieldAgainstExpression = ({expression, field}: {expression: Expression, field: IFormField}) => {
//   if (field.formId === expression.fieldToCompare) {

//   }
//   if (field.id === expression.)
// }
