import {
  Dictionary,
  DictionaryContents,
  MappingObject,
  generateDictionary,
} from "src/utils/excelParse";
import { DictionaryLevel } from "../pages/ExcelMappingTool";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import { Configuration } from "../../FormBuilder/components/TypeDefinitions";

export type ChangeEnumerationParams =
  | {
      type: Exclude<DictionaryContents["enumerable"], "groupMember">;
    }
  | {
      type: "groupMember";
      groupParent: string;
    };
export default function changeEnumerationType(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  newType: ChangeEnumerationParams,
  config: Configuration
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          changeEnumerationType(
            dictionary.subforms[chain[1].formId],
            newChain,
            newType,
            config
          );
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          const newDictionary =
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId];
          changeEnumerationType(newDictionary, newChain, newType, config);
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    let subforms: Dictionary | null = null;
    let fields: MappingObject | null = null;
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        subforms = dictionary.subforms;
        fields = dictionary.fields;
        break;
      case "specifiedLocation":
        subforms = dictionary.enumerations[0].subforms;
        fields = dictionary.enumerations[0].fields;
        break;
      case "groupMember":
        const groupToDictionary = generateDictionary(
          config,
          chain[0].formId,
          false
        );
        if (groupToDictionary) {
          const groupedContent = groupToDictionary[
            Object.keys(groupToDictionary)[0]
          ] as DictionaryContents & { enumerable: false };
          subforms = groupedContent.subforms;
          fields = groupedContent.fields;
        }
    }
    let dictionaryContent: DictionaryContents;
    switch (newType.type) {
      case false:
        dictionaryContent = {
          enumerable: false,
          fields: fields,
          subforms: subforms,
          remaps: dictionary.remaps,
          name: dictionary.name,
        };
        break;
      case "byOffset":
        dictionaryContent = {
          enumerable: "byOffset",
          fields: fields,
          subforms: subforms,
          remaps: dictionary.remaps,
          name: dictionary.name,
          offsetLocations: [],
        };
        break;
      case "specifiedLocation":
        dictionaryContent = {
          enumerable: "specifiedLocation",
          name: dictionary.name,
          remaps: dictionary.remaps,
          enumerations: [
            {
              fields: fields,
              subforms: subforms,
            },
          ],
        };
        break;
      case "groupMember":
        dictionaryContent = {
          enumerable: "groupMember",
          parent: newType.groupParent,
          name: dictionary.name,
          remaps: dictionary.remaps,
        };
        break;
      default:
        exhaustiveGuard(newType);
    }
    for (var variableKey in dictionary) {
      delete dictionary[variableKey as keyof DictionaryContents];
    }

    for (var key in dictionaryContent) {
      // @ts-ignore
      dictionary[key] = dictionaryContent[key];
    }
    // debugger;
  }
}
