import { useContext, useState, useCallback, useEffect } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import { PersonContext } from "../../../utils/contexts/person";
import {
  Avatar,
  FormControl,
  InputLabel,
  ListItemAvatar,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";

const GET_PROJECT_FAVORITES = gql`
  query projectPersons($personId: ID) {
    projectPersons(personId: $personId) {
      personId
      project {
        id
        name
        createdBy
        description
        end
        invoiceTemplate
        locationId
        popEnd
        popStart
        parentProject {
          id
        }
        projectLogo
        projectNumber
        projectOwner
        start
        childProjects {
          id
        }
        projectPersons {
          id
          personId
          fieldReady
        }
        projectFirmUnits {
          firmUnitId
        }
        projectLocation {
          city
          description
          mapboxId
          name
          owner
          state
          street1
          street2
          street3
          tenant
          zip
          lat
          lng
          neighborhood
        }
        offeror {
          id
          name
          phone
          email
          authorizedSignerName
          authorizedSignerTitle
          remittanceAddress {
            city
            description
            mapboxId
            name
            owner
            state
            street1
            street2
            street3
            tenant
            zip
            lat
            lng
            neighborhood
          }
          administeredOfficerName
          administeredOfficerAddress {
            city
            description
            mapboxId
            name
            owner
            state
            street1
            street2
            street3
            tenant
            zip
            lat
            lng
            neighborhood
          }
          administeredOfficerEmail
          administeredOfficerPhone
          paymentAgencyName
          paymentAgencyAddress {
            city
            description
            mapboxId
            name
            owner
            state
            street1
            street2
            street3
            tenant
            zip
            lat
            lng
            neighborhood
          }
          paymentAgencyEmail
          paymentAgencyPhone
          address {
            city
            description
            mapboxId
            name
            owner
            state
            street1
            street2
            street3
            tenant
            zip
            lat
            lng
            neighborhood
          }
        }
        client {
          id
          contractNumber
          solicitationNumber
          dateIssued
          requisition
          issuedBy
          name
          pointOfContact
          email
          telephone
          fax
          code
          contractingOfficerName
          contractingOfficerEmail
          promptPaymentDiscount
          address {
            city
            description
            mapboxId
            name
            owner
            state
            street1
            street2
            street3
            tenant
            zip
            lat
            lng
            neighborhood
          }
        }
      }
    }
  }
`;
export default function ProjectSelect() {
  let projectContext = useContext(ProjectContext);
  const { project, setProject } = projectContext;
  let personContext = useContext(PersonContext);
  const { person } = personContext;
  const [projectFavorites, setProjectFavorites] = useState(null);
  const [getProjectFavorites] = useLazyQuery(GET_PROJECT_FAVORITES, {
    onCompleted: (response) => {
      setProjectFavorites(
        response.projectPersons.map((project) => project.project)
      );
      if (!project && person.projectFavorite) {
        if (!localStorage.getItem("project")) {
          const projectFavorite = response.projectPersons.find(
            (project) => project.project.id === person.projectFavorite
          ).project;
          setProject(projectFavorite);
        }
      }
      // INFO: This line was breaking the ProjectContext when Fort Bouton was selected.
      if (!project && !person.projectFavorite) {
        setProject(response.projectPersons[0].project);
      }
    },
    onError: (response) => {
      // debugger;
    },
  });
  const onLoad = useCallback(() => {
    if (person && !projectFavorites) {
      getProjectFavorites({ variables: { personId: person.id } });
    }
    if (projectFavorites && !person) {
      setProjectFavorites([]);
    }
  }, [person, projectFavorites, getProjectFavorites]);
  useEffect(() => {
    onLoad();
  }, [onLoad]);
  const handleChange = (e) => {
    let project = projectFavorites.find(
      (project) => project.id === e.target.value
    );
    setProject(project);
  };

  function getInitials(name) {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0));
    return initials.join("");
  }

  return (
    <FormControl sx={{ width: "12rem", marginRight: "1rem" }}>
      <InputLabel>Project</InputLabel>
      <Select
        labelId="project-label"
        id="project-select"
        value={project ? project.id : ""}
        size="small"
        onChange={handleChange}
        input={<OutlinedInput label="Project" />}
      >
        {projectFavorites &&
          projectFavorites.map((project, idx) => (
            <MenuItem
              value={project.id}
              key={idx}
              onClick={() => {
                setProject(project);
              }}
            >
              <Stack direction={"row"} alignItems="center">
                <ListItemAvatar>
                  <Avatar
                    alt={`Avatar for ${project.name}`}
                    src={`${project.projectLogo}`}
                  >
                    {getInitials(project.name)}
                  </Avatar>
                </ListItemAvatar>
                <Typography>{project.name}</Typography>
              </Stack>
            </MenuItem>
          ))}
        {/* <Stack direction="column" justifyContent="stretch" alignItems="center">
          <Button
            endIcon={<FormatListBulleted />}
            sx={{ width: "100%", borderRadius: 0 }}
            onClick={() => navigate('/app/projects/list')}
          >
            View All Projects
          </Button>
        </Stack> */}
      </Select>
    </FormControl>
  );
}
