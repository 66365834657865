import { Action, PageState } from "../../components/TypeDefinitions";

export function updateValidationMessage<
  E extends Extract<
    Action,
    {
      type: "updateValidationMessage";
    }
  >
>(action: E, draft: PageState) {
  const { validationId, messageValue } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  if (formIndex === -1) return;
  const validationIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.findIndex((validation) => validation.id === validationId);
  if (validationIndex === -1) return;

  if (
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex]
      .validations[validationIndex].action === "Error"
  ) {
    draft.data.inspectionTypeTemplate.configuration.forms[
      formIndex
    ].validations[validationIndex].message = messageValue;
  }
}
