import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Configuration } from "../../FormBuilder/components/TypeDefinitions";
import {
  Dispatch,
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { Dictionary, DictionaryContents } from "src/utils/excelParse";
import { useImmerReducer } from "use-immer";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import updateField from "../actions/updateField.js";
import { SyncHandler } from "../../FormBuilder/FormWrapper/SyncHandler";
import createMapping from "../actions/createMapping";
import deleteMapping from "../actions/deleteMapping";
import useDebounce from "../../../../utils/useDebounce";
import { PDFDocument, PDFField } from "pdf-lib";

const getReport = gql`
  query ReportTypeTemplate(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplate(reportTypeTemplate: $reportTypeTemplate) {
      templateUrl
      inspectionTypeTemplate {
        id
        name
        description
        start
        end
        configuration
        isDeleted
      }
      id
      configuration
    }
  }
`;

const updateReport = gql`
  mutation UpdateReportTypeTemplate(
    $reportTypeTemplate: InputReportTypeTemplateParams
  ) {
    updateReportTypeTemplate(reportTypeTemplate: $reportTypeTemplate) {
      id
      configuration
    }
  }
`;

const PDFMappingTool = () => {
  const { inspectionTypeTemplateId, reportTypeTemplateId } = useParams();
  const params = useParams();
  const { data, loading } = useQuery(getReport, {
    fetchPolicy: "network-only",
    variables: {
      reportTypeTemplate: {
        id: reportTypeTemplateId
      }
    }
  });

  if (data) {
    console.log("initial dictionary");
    console.log(data.reportTypeTemplate.configuration);
  }

  return (
    <>
      {loading && <CircularProgress />}
      {data && (
        <ExcelMapDisplay
          config={data.reportTypeTemplate.inspectionTypeTemplate.configuration}
          initialDictionary={data.reportTypeTemplate.configuration}
          initialFile={data.reportTypeTemplate.templateUrl}
        />
      )}
    </>
  );
};

export type Action =
  | {
      type: "updateField";
      payload: {
        chain: DictionaryLevel[];
        fieldId: string;
        newPdfValue: string;
        fieldAnswer: string;
      };
    }
  | {
      type: "addOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        enumerationStartLocation: string;
      };
    }
  | {
      type: "deleteOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        index: number;
      };
    }
  | {
      type: "updateOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        index: number;
        newValue: number;
      };
    }
  | {
      type: "openOffsetDialog";
      payload: {
        chain: DictionaryLevel[];
      };
    }
  | {
      type: "autofillNextField";
      payload: {
        chain: DictionaryLevel[];
        currentColumnValue: string;
        nextFieldId: string;
        specifiedLocationIndex?: number;
      };
    }
  | {
      type: "changeEnumerateType";
      payload: {
        chain: DictionaryLevel[];
        newType: any;
      };
    }
  | {
      type: "addSpecificLocation";
      payload: {
        chain: DictionaryLevel[];
      };
    }
  | {
      type: "createMapping";
      payload: {
        chain: DictionaryLevel[];
        newRemap: {
          mappedFrom: string | "bool-val-true" | "bool-val-false";
          mappedTo: string;
        };
      };
    }
  | {
      type: "deleteMapping";
      payload: {
        chain: DictionaryLevel[];
        mappedFromValue: string;
      };
    };

type PageState = {
  dictionary: Dictionary;
  addOffsetState: null | DictionaryLevel[];
  config: Configuration;
};

function reducer(draft: PageState | null, action: Action) {
  if (draft === null) return;
  switch (action.type) {
    case "updateField":
      for (const content in draft.dictionary) {
        updateField(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.fieldId,
          action.payload.newPdfValue,
          action.payload.fieldAnswer
        );
        break;
      }
      break;
    case "addOffsetLocation":
      break;
    case "deleteOffsetLocation":
      break;
    case "updateOffsetLocation":
      break;
    case "openOffsetDialog":
      draft.addOffsetState = action.payload.chain;
      break;
    case "autofillNextField":
      break;
    case "changeEnumerateType":
      break;
    case "addSpecificLocation":
      break;
    case "createMapping":
      for (const content in draft.dictionary) {
        createMapping(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.newRemap
        );
      }
      break;
    case "deleteMapping":
      for (const content in draft.dictionary) {
        deleteMapping(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.mappedFromValue
        );
      }
      break;
    default:
      exhaustiveGuard(action);
  }
}

const ExcelMapCtx = createContext<{
  draft: PageState;
  dispatch: Dispatch<Action>;
} | null>(null);

const ExcelMapDisplay: FC<{
  config: Configuration;
  initialDictionary: Dictionary;
  initialFile: string;
}> = ({ config, initialDictionary, initialFile }) => {

  const topLevelFormId = Object.keys(initialDictionary)[0]

  const [updateTemplate] = useMutation(updateReport);

  const { reportTypeTemplateId } = useParams();

  const [pageState, dispatch] = useImmerReducer(
    reducer,
    initialDictionary
      ? {
          dictionary: initialDictionary,
          addOffsetState: null,
          config: config
        }
      : null
  );

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const initialFileUrl = new URL(initialFile);
    fetch(initialFileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const filename = initialFile.split("/").pop() || "defaultName";
        const newFile = new File([blob], filename, { type: blob.type });
        setFile(newFile);
      })
      .catch((error) => console.error("Error fetching initial file:", error));
  }, []);

  const [fieldNames, setFieldNames] = useState<string[]>([]);

  const syncing = useDebounce({
    stateToWatch: pageState?.dictionary || null,
    debounceTimeoutMS: 1000,
    callback: (state) => {
      if (!state) return;
      return updateTemplate({
        variables: {
          reportTypeTemplate: {
            id: reportTypeTemplateId,
            configuration: JSON.stringify(state)
          }
        }
      });
    }
  });

  async function extractFieldNames(pdfBuffer: ArrayBuffer): Promise<string[]> {
    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(pdfBuffer);

    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Get all fields in the form
    const fields: PDFField[] = form.getFields();

    // Extract the names of the fields
    const fieldNames: string[] = fields.map((field) => field.getName());

    return fieldNames;
  }

  useEffect(() => {
    (async () => {
      if (!file) return;
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (event.target?.result instanceof ArrayBuffer) {
          const buffer = event.target.result;
          const extractedFieldNames = await extractFieldNames(buffer);
          setFieldNames(extractedFieldNames);
          console.log(extractedFieldNames);
        }
      };
      reader.readAsArrayBuffer(file);
    })();
  }, [file]);

  pageState && console.log(pageState.dictionary);

  return (
    <>
      {pageState && topLevelFormId && (
        <>
          <SyncHandler syncing={syncing} errors={[]}>
            <ExcelMapCtx.Provider
              value={{ draft: pageState, dispatch: dispatch }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <input
                  style={{ display: "none" }}
                  accept="application/pdf"
                  id="contained-button-file"
                  multiple
                  type="file"
                  value=""
                  onChange={(e) => {
                    let uploadFile =
                      (e.target as HTMLInputElement).files !== null
                        ? (e.target as HTMLInputElement).files![0]
                        : null;
                    if (!uploadFile) return;
                    setFile(uploadFile);
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                {file && <Typography fontWeight="bold">{file.name}</Typography>}
              </Stack>
              <DictionaryRecurse
                config={config}
                key={JSON.stringify(pageState.dictionary[topLevelFormId])}
                topLevelFormId={topLevelFormId}
                dictionary={pageState.dictionary[topLevelFormId]}
                fieldNames={fieldNames}
              />
            </ExcelMapCtx.Provider>
          </SyncHandler>
        </>
      )}
    </>
  );
};

export type DictionaryLevel = {
  formId: string;
  enumeration: number | false;
  name: string;
};

const renderIndividualField = (
  dictionary: any,
  field: any,
  fieldNames: any,
  level: any,
  dispatch: any
) => {
  switch (field.inputType) {
    case "YesNo":
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel id="yes-simple-select-label">
                Select Yes Field
              </InputLabel>
              <Select
                labelId="yes-simple-select-label"
                id="yes-simple-select"
                value={dictionary?.fields?.[field.id]?.["Yes"] ?? ""}
                label="Select PDF Field"
                onChange={(event) => {
                  dispatch({
                    type: "updateField",
                    payload: {
                      fieldId: field.id,
                      newPdfValue: event.target.value,
                      fieldAnswer: "yes",
                      chain: level
                    }
                  });
                }}
              >
                {fieldNames &&
                  fieldNames.map((fieldName: any) => (
                    <MenuItem key={fieldName + "-yes"} value={fieldName}>
                      {fieldName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ minWidth: 250 }}>
              <InputLabel id="no-simple-select-label">
                Select No Field
              </InputLabel>
              <Select
                labelId="no-simple-select-label"
                id="no-simple-select"
                value={dictionary?.fields?.[field.id]?.["no"] ?? ""}
                label="Select PDF Field"
                autoWidth
                onChange={(event) => {
                  dispatch({
                    type: "updateField",
                    payload: {
                      fieldId: field.id,
                      newPdfValue: event.target.value,
                      fieldAnswer: "no",
                      chain: level
                    }
                  });
                }}
              >
                {fieldNames &&
                  fieldNames.map((fieldName: any) => (
                    <MenuItem key={fieldName + "-no"} value={fieldName}>
                      {fieldName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      );
    case "OptionSelect":
      return (
        <Grid container spacing={2}>
          {field.possibleValues.map((possibleValue: any) => {
            return (
              <Grid item xs={12}>
                <FormControl sx={{ minWidth: 250 }}>
                  <InputLabel id="demo-simple-select-label">{`${possibleValue.name}`}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dictionary?.fields?.[field.id]?.[possibleValue.name] ?? ""}
                    label={"Select PDF Field"}
                    autoWidth
                    onChange={(event) => {
                      dispatch({
                        type: "updateField",
                        payload: {
                          fieldId: field.id,
                          newPdfValue: event.target.value,
                          fieldAnswer: possibleValue.name,
                          chain: level
                        }
                      });
                    }}
                  >
                    {fieldNames &&
                      fieldNames.map((fieldName: any) => {
                        return (
                          <MenuItem value={fieldName}>{fieldName}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
      );

    case "LongText":
      return (
        <div>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-label">{`Select Text Field`}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={dictionary.fields![field.id]!["longText"] || ""}
              label={"Select PDF Field"}
              onChange={(event) => {
                dispatch({
                  type: "updateField",
                  payload: {
                    fieldId: field.id,
                    newPdfValue: event.target.value,
                    fieldAnswer: "longText",
                    chain: level
                  }
                });
              }}
            >
              {fieldNames &&
                fieldNames.map((fieldName: any) => {
                  return <MenuItem value={fieldName}>{fieldName}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </div>
      );
    case "MapCapture":
      const mapCaptureValue = dictionary.fields?.[field.id]?.mapCapture ?? "";
      return (
        <div>
          <FormControl sx={{ minWidth: 250 }}>
            <InputLabel id="demo-simple-select-label">{`Select Map Field`}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mapCaptureValue}
              label={"Select PDF Field"}
              onChange={(event) => {
                dispatch({
                  type: "updateField",
                  payload: {
                    fieldId: field.id,
                    newPdfValue: event.target.value,
                    fieldAnswer: "mapCapture",
                    chain: level
                  }
                });
              }}
            >
              {fieldNames &&
                fieldNames.map((fieldName: any) => {
                  return <MenuItem value={fieldName}>{fieldName}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </div>
      );
    default:
  }
};
const DictionaryRecurse = ({
  dictionary,
  topLevelFormId,
  config,
  dictionaryLevel,
  fieldNames
}: {
  dictionary: DictionaryContents;
  config: Configuration;
  topLevelFormId: string;
  dictionaryLevel?: DictionaryLevel[];
  fieldNames?: string[];
}) => {
  const form = config.forms.find((form) => topLevelFormId === form.id);
  const ctx = useContext(ExcelMapCtx);

  if (!form || !ctx) {
    return <></>;
  }

  const { dispatch } = ctx;

  const level =
    dictionaryLevel === undefined
      ? ([
          { formId: form.id, enumeration: false, name: form.name }
        ] as DictionaryLevel[])
      : dictionaryLevel;

  if (!dictionary || dictionary.enumerable === undefined) {
    debugger;
  }
  switch (dictionary.enumerable) {
    case "byOffset":
    case false:
      return (
        <div
          style={{
            border: "1px solid green",
            paddingLeft: "1rem",
            marginTop: "0.5rem",
            paddingBottom: "1rem"
          }}
        >
          <h1 style={{ fontWeight: "bold" }}>{form.name}</h1>

          {form.fields.map((field, fieldIndex) => {
            // const pdfMapping = dictionary.fields![field.id] || "";
            return (
              <div style={{ paddingBottom: "0.5rem" }}>
                <p>{field.label}</p>
                <FormControl
                  margin={"normal"}
                  size={"medium"}
                  sx={{ width: "250px" }}
                >
                  {renderIndividualField(
                    dictionary,
                    field,
                    fieldNames,
                    level,
                    dispatch
                  )}
                </FormControl>
              </div>
            );
          })}
          {dictionary.subforms &&
            form.subformIds.length > 0 &&
            form.subformIds.map((subformId) => {
              const subDictionary = dictionary.subforms![subformId];
              if (!subDictionary) return null;
              const newLevel = [
                ...level,
                {
                  enumeration: false,
                  formId: subformId,
                  name: subDictionary.name
                }
              ] as DictionaryLevel[];
              return (
                <DictionaryRecurse
                  key={JSON.stringify(newLevel)}
                  dictionary={subDictionary}
                  config={config}
                  topLevelFormId={subformId}
                  dictionaryLevel={newLevel}
                />
              );
            })}
        </div>
      );
    case "specifiedLocation":
      return <></>;
    case "groupMember":
      return <></>;
    default:
      exhaustiveGuard(dictionary);
  }
};

export default PDFMappingTool;
