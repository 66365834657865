import PropTypes from "prop-types";
import { useEffect } from "react";
import { useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar } from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import { ProjectContext } from "../../utils/contexts/project";
// material
import { alpha } from "@mui/material/styles";

//
import sidebarConfig from "./SidebarConfig";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.25),
  color: theme.palette.primary.main,
  height: "100%",
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const projectContext = useContext(ProjectContext);
  const {project} = projectContext;
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  
  const currentSidebarConfig = (function () {
    const currentSidebarConfig = sidebarConfig()
    if (pathname.startsWith("/app/admin")) return currentSidebarConfig.configurations;
    if (pathname.startsWith("/app/user")) return currentSidebarConfig.myAccount;
    if (pathname.startsWith("/app/projects")) return currentSidebarConfig.projects;
    if (pathname.startsWith("/app/firmUnits")) return currentSidebarConfig.firmUnits;
    if (!project) return currentSidebarConfig.projects;
    return currentSidebarConfig.main;
  })();

  function getInitials(name) {
    const words = name.split(" ");
    const initials = words.map(word => word.charAt(0));
    return initials.join("");
  }

  const renderContent = (
    <RootStyle>
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, backgroundColor: "white", marginBottom: '24px', paddingTop: '38px', paddingBottom: '0px' }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>
      {currentSidebarConfig.name === "main" && (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" component={RouterLink} to="/app/details">
            <AccountStyle sx={{backgroundColor: "text.disabled" }}>
              <Avatar src={project.projectLogo} alt="photoURL" >
              {getInitials(project.name)}</Avatar>
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6" sx={{ color: "primary.main" }}>
                  {project.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {/* INFO: Additional client info will go here. */}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box>
      )}
      {currentSidebarConfig.label && (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <AccountStyle sx={{backgroundColor: "text.secondary" }}>
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="h6" 
                textAlign="center"
                sx={{ color: "primary.main" }}
              >
                {currentSidebarConfig.label}
              </Typography>
            </Box>
          </AccountStyle>
        </Box>
      )}

      <NavSection
        navConfig={currentSidebarConfig.sections}
        backButton={currentSidebarConfig.name !== "main"}
        label={currentSidebarConfig.label}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar></RootStyle>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          style={{backgroundColor: 'blue'}}
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
