import {
    Action,
    Expression,
    PageState,
  } from "../../components/TypeDefinitions";
  
  export function updateGeoRuleToCompare<
    E extends Extract<
      Action,
      {
        type: "updateGeoRuleToCompare";
      }
    >
  >(action: E, draft: PageState) {
    const { geoRuleToCompareId, validationId, expressionId } = action.payload;
  
    const currentFormIndex =
      draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
        (form) => form.id === draft.formId
      );
    const currentValidationIndex =
      draft.data.inspectionTypeTemplate.configuration.forms[
        currentFormIndex
      ].validations.findIndex((validation) => validation.id === validationId);
  
    const currentExpressionIndex =
      draft.data.inspectionTypeTemplate.configuration.forms[
        currentFormIndex
      ].validations[currentValidationIndex].expressions.findIndex(
        (expression) => expression.id === expressionId
      );
  
    const currentExpression =
      draft.data.inspectionTypeTemplate.configuration.forms[currentFormIndex]
        .validations[currentValidationIndex].expressions[currentExpressionIndex];
  
    if (
      currentFormIndex === -1 ||
      currentValidationIndex === -1 ||
      currentExpressionIndex === -1
    )
      return;
  
    let expression: Expression = {
      id: expressionId,
      fieldToCompare: null,
      geoRuleToCompare: geoRuleToCompareId,
      type: "GeoRule",
      formValidationId: validationId,
      order: currentExpression.order,
      linkedBy: currentExpression.linkedBy,
      operator: null,
      value: null,
    };
  
    draft.data.inspectionTypeTemplate.configuration.forms[
      currentFormIndex
    ].validations[currentValidationIndex].expressions[currentExpressionIndex] =
      expression;
    return;
  }
  