// material
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Container } from "@mui/material";
// ----------------------------------------------------------------------
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";

const GET_INSPECTION_EVENT_LOGS = gql`
  query InspectionEventLogs(
    $inspectionEventLog: InputInspectionEventLogParams
  ) {
    inspectionEventLogs(inspectionEventLog: $inspectionEventLog) {
      inspectionAssignmentId
      inspectionEventType
      inspectionId
      currentValue
      description
      deviceId
      fieldInstanceId
      formInstanceId
      formInstanceTree
      formValueId
      lat
      long
      masterFormInstanceId
      objId
      objType
      personId
      previousValue
      projectId
      projectLocationId
      id
      timestamp
    }
  }
`;

export default function InspectionEventLogsList(props) {
  let logVariables = {
    inspectionEventLog: {
      inspectionAssignmentId: props.inspectionAssignmentId,
      inspectionId: props.inspectionId,
    },
  };

  const { loading, error, data } = useQuery(GET_INSPECTION_EVENT_LOGS, {
    variables: logVariables,
    fetchPolicy: "network-only",
  });

  if (!props) {
    return null;
  }
  if (loading) {
    return null;
  }
  if (error) {
  }

  return data ? (
    <Container maxWidth="xl">
      <div style={{ height: 600, width: "100%", paddingTop: 50 }}>
        <h2>Event Logs</h2>
        <DataGridPremium
          getRowHeight={() => "auto"}
          rows={data.inspectionEventLogs}
          loading={!data}
          columns={[
            { editable: false, field: "id", headerName: "Id", width: 400 },
            {
              editable: false,
              field: "inspectionEventType",
              headerName: "Event",
              width: 200,
            },
            {
              editable: false,
              field: "description",
              headerName: "Description",
              width: 400,
            },
            {
              editable: false,
              field: "currentValue",
              headerName: "Current Value",
            },
            {
              editable: false,
              field: "timestamp",
              headerName: "Timestamp",
              width: 200,
              valueFormatter: (params) =>
                moment
                  .utc(params?.value)
                  .local()
                  .format("YYYY/MM/DD hh:mm:ss a"),
            },
            { editable: false, field: "personId", headerName: "Person Id" },
            {
              editable: false,
              field: "inspectionId",
              headerName: "Inspection Id",
            },
            {
              editable: false,
              field: "inspectionAssignmentId",
              headerName: "Inspection Assignment Id",
            },
            {
              editable: false,
              field: "masterFormInstanceId",
              headerName: "Master Form Instance Id",
            },
            {
              editable: false,
              field: "formInstanceId",
              headerName: "Form Instance Id",
            },
            {
              editable: false,
              field: "formInstanceTree",
              headerName: "Form Instance Tree",
            },
            {
              editable: false,
              field: "formValueId",
              headerName: "Form Value Id",
            },
            { editable: false, field: "projectId", headerName: "Project Id" },
            {
              editable: false,
              field: "projectLocationId",
              headerName: "Project Location Id",
            },
            {
              editable: false,
              field: "previousValue",
              headerName: "Previous Value",
            },
            {
              editable: false,
              field: "currentValue",
              headerName: "Current Value",
            },
            { editable: false, field: "lat", headerName: "Lat" },
            { editable: false, field: "long", headerName: "Long" },
            { editable: false, field: "deviceId", headerName: "Device Id" },
            { editable: false, field: "objId", headerName: "Object Id" },
            { editable: false, field: "objType", headerName: "Object Type" },
          ]}
          columnVisibilityModel={{
            id: false,
            inspectionId: false,
            personId: false,
            inspectionAssignmentId: false,
            masterFormInstanceId: false,
            formInstanceId: false,
            formInstanceTree: false,
            formValueId: false,
            projectId: false,
            projectLocationId: false,
            previousValue: false,
            currentValue: false,
            lat: false,
            long: false,
            deviceId: false,
            objId: false,
            objType: false,
          }}
          pageSize={5}
          pageSizeOptions={[10, 25, 50]}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { excelOptions: { allColumns: true } } }}
        />
      </div>
    </Container>
  ) : (
    <></>
  );
}
