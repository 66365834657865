import { Action, PageState } from "../../components/TypeDefinitions";

export function setForm<
  E extends Extract<
    Action,
    {
      type: "setForm";
    }
  >
>(action: E, draft: PageState): void {
  const { formId } = action.payload;

  draft.navigate(`../${formId}`);
}
