import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import AddressSelect from "src/components/map/AddressSelect";
import AssignmentSelect from "./components/AssignmentSelect";
import { IFormInstance } from "./components/InspectionDisplay";
import PhotoDisplay from "./components/PhotoDisplay";
import { SyncDisplay } from "./components/SyncDisplay";
import Washouts from "./Washouts";
import MultiInspectionDisplay from "./components/InspectionDisplay";
import { Check, Download, OpenInNew, Recycling, Report, RestartAlt } from "@mui/icons-material";
import { decode } from "./components/InspectionDisplay/components/utilities/decode";
import {
  Dictionary,
  excelColumnToInteger,
  excelParse,
  integerToExcelColumn,
} from "src/utils/excelParse";
import { dictionaryLog } from "src/dictionaryLog";
import { utils, writeFile } from "xlsx";
import InspectionEventLogs from "src/pages/Inspections/Inspection/InspectionEventLogs";
import InspectionStatusLogs from "src/pages/Inspections/Inspection/InspectionStatusLogs";
import { useSearchParamsState } from "src/utils/useSearchParamsState";
import { ReportReview } from "./ReportReview";
import PhotoGallery from "./components/PhotoGallery";
import { PersonContext } from "src/utils/contexts/person";
import { InspectionWebView } from "src/pages/Inspections/MyInspections/routes/InspectionWebView";
import { WebViewWrapper } from "src/pages/Inspections/MyInspections/routes/InspectionWebView/wrapper";
import { decodeFromBase64 } from "pdf-lib";
import { genPDFDownload } from "src/pages/Inspections/MyInspections/routes/InspectionWebView/downloadReport";
import { generateReports } from "src/pages/Reporting/GenerateReports";

const INSPECTION_QUERY = gql`
  query Inspection($inspection: InputInspectionParams!) {
    inspection(inspection: $inspection) {
      id
      number
      contactPhone
      contactName
      inspectionLocation
      inspectionType {
        id
      }
      inspectionAssignments {
        id
        status
        start
      }
      inspectionStatuses {
        statusDate
        status
        id
        createdPersonId
      }
    }
  }
`;

const INSPECTION_TYPE_QUERY = gql`
  query InspectionType($inspectionType: InputInspectionTypeParams) {
    inspectionType(inspectionType: $inspectionType) {
      id
      name
      inspectionTypeTemplates {
        id
        name
        description
        start
        end
        configuration
        isDeleted
      }
      isDeleted
    }
  }
`;
const APPROVE_REPORTS = gql`
  mutation ApproveReports($reportApproval: InputReportApprovalParams) {
    approveReports(reportApproval: $reportApproval) {
      result
    }
  }
`;

const THO_FORM_INSTANCES = gql`
  query ThoFormInstances($thoFormInstance: InputThoFormInstanceParams) {
    thoFormInstances(thoFormInstance: $thoFormInstance) {
      id
      inspectionId
      inspectionAssignmentId
      formId
      formConfigurationId
      inspectionTypeTemplateId
      reference
      referenceType
      formJson
      name
      type
      isDeleted
    }
  }
`;

const LOCATION = gql`
  query ProjectLocation($projectLocationId: ID!) {
    projectLocation(projectLocationId: $projectLocationId) {
      city
      state
      street1
      zip
    }
  }
`;

const UPLOAD_REPORT_GENERATION_REQUEST = gql`
  mutation UploadReportGenerationRequest(
    $reportGenerationRequest: InputReportGenerationRequestParams!
  ) {
    uploadReportGenerationRequest(
      reportGenerationRequest: $reportGenerationRequest
    ) {
      id
    }
  }
`;

const REPORT_GENERATION_REQUESTS = gql`
  query ReportGenerationRequests(
    $reportGenerationRequest: InputReportGenerationRequestParams!
  ) {
    reportGenerationRequests(
      reportGenerationRequest: $reportGenerationRequest
    ) {
      id
      metadata
      personId
      jobId
      jobStatus
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      destinationReportResultIds
    }
  }
`;

const GET_INSPECTION_ASSIGNMENT_REPORTS = gql`
  query GetInspectionAssignmentReportResults(
    $reportResult: InputReportResultParams!
  ) {
    reportResults(reportResult: $reportResult) {
      id
      reportTypeTemplateId
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      documentUrl
      documentId
      documentBytes
      requestedBy
      fileName
      fileExtension
    }
  }
`;

export default function SelectedAssignment({
  inspectionId,
}: {
  inspectionId: string;
}) {
  const { data, loading, refetch } = useQuery(INSPECTION_QUERY, {
    fetchPolicy: "network-only",
    variables: { inspection: { id: inspectionId } },
  });

  const funRef = useRef<any>(null);
  const person: any = useContext(PersonContext);
  const [searchParams] = useSearchParams();

  const [inspectionTypeQuery, { data: inspectionTypeData }] = useLazyQuery(
    INSPECTION_TYPE_QUERY
  );
  const [locationQuery, { data: locationData }] = useLazyQuery(LOCATION);

  const [thoFormInstances, setThoFormInstance] = useState<IFormInstance[]>([]);

  const [tab, setTab] = useState(0);

  const [generatingReports, setGeneratingReports] = useState(false);

  const [formInstancesQuery] = useLazyQuery(THO_FORM_INSTANCES, {
    onCompleted: (data) => {
      setThoFormInstance(data.thoFormInstances ?? []);
    },
  });

  const [approveReports] = useMutation(APPROVE_REPORTS, {
    onCompleted: (result) => {
      refetch();
    },
  });

  const [uploadReportGenerationRequest] = useMutation(
    UPLOAD_REPORT_GENERATION_REQUEST,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {},
    }
  );

  const [getReportGenerationRequests] = useLazyQuery(
    REPORT_GENERATION_REQUESTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        if (response.reportGenerationRequests) {
          var requests = response.reportGenerationRequests;
          if (requests.length > 0) {
            var request = requests[0];
            if (request.jobStatus === "completed") {
              setGeneratingReports(false);
              refetch();
            }
          }
        }
      },
    }
  );

  const [
    getReportResults,
    { data: reportResultData, loading: reportResultDataLoading },
  ] = useLazyQuery(GET_INSPECTION_ASSIGNMENT_REPORTS, {
    fetchPolicy: "network-only",
    onCompleted: (result: any) => {
    },
  });

  const [downloadingReports, setDownloadingReports] = useState(false);

  const [assignmentId, setAssignmentId] = useSearchParamsState(
    "assignment",
    ""
  );

  const [assignment, setAssignment] = useState(null);

  useEffect(()=>{

  }, [downloadingReports]);

  useEffect(() => {
    if (data && !loading) {
      inspectionTypeQuery({
        variables: {
          inspectionType: {
            id: data.inspection.inspectionType.id,
          },
        },
      });

      locationQuery({
        variables: {
          projectLocationId: data.inspection.inspectionLocation,
        },
      });
    }
  }, [loading, data, inspectionTypeQuery, locationQuery]);

  useEffect(() => {
    if (data && !assignmentId) {
      if (data.inspection.inspectionAssignments.length > 0) {
        const getMostRecentInspectionAssignment = () => {
          let currentMostRecent: any;
          let index = 0;
          data.inspection.inspectionAssignments.forEach(
            (ia: any, i: number) => {
              if (i === 0) {
                currentMostRecent = ia.start;
              } else {
                if (ia.start > currentMostRecent) {
                  index = i;
                  currentMostRecent = ia.start;
                }
              }
            }
          );
          return data.inspection.inspectionAssignments[index];
        };
        let mostRecent = getMostRecentInspectionAssignment();
        if (mostRecent) {
          setAssignmentId(mostRecent.id);
          setAssignment(mostRecent);
        }
      }
    }
    if (data && assignmentId) {
      let currAssignment = data.inspection.inspectionAssignments.find(
        (i: any) => {
          return i.id === assignmentId;
        }
      );
      setAssignment(currAssignment);
    }
  }, [data, assignmentId, setAssignmentId]);

  useEffect(() => {
    if (assignmentId) {
      formInstancesQuery({
        variables: {
          thoFormInstance: { inspectionAssignmentId: assignmentId },
        },
      });
    }
  }, [assignmentId, formInstancesQuery]);

  useEffect(() => {}, [assignment]);

  useEffect(() => {
    if (generatingReports) {
      funRef.current = setInterval(() => {
        getReportGenerationRequests({
          variables: {
            reportGenerationRequest: {
              inspectionAssignmentId: assignmentId,
            }
          },
        });
      }, 5000);
      return () => {
        clearInterval(funRef.current);
      };
    }
  }, [generatingReports]);

  function getStatusWithDate() {
    if (assignment) {
      let myAssignment: any = assignment;
      let dateText = new Date(myAssignment.start).toDateString();
      return (
        <Typography variant="h6">
          {dateText} - {myAssignment.status}
        </Typography>
      );
    }
  }

  function getStatus() {
    if (assignment) {
      let myAssignment: any = assignment;
      return myAssignment.status;
    }
    return "";
  }

  return (
    <>
      {data && inspectionTypeData && person && (
        <Box>
          <Stack direction="row" spacing={2}>
            <Stack
              flexDirection="column"
              maxWidth="sm"
              paddingTop="5px"
              flex={1}
              spacing={1}
            >
              {locationData && (
                <Typography variant="h4">
                  {locationData.projectLocation.street1},{" "}
                  {locationData.projectLocation.city},{" "}
                  {locationData.projectLocation.state}{" "}
                  {locationData.projectLocation.zip}
                </Typography>
              )}
              {assignmentId ? (
                getStatusWithDate()
              ) : (
                <Typography>Loading assignment...</Typography>
              )}
              <Typography variant="body1">
                Inspection Id: {inspectionId}
              </Typography>
              <Typography variant="body1">
                Inspection Assignment Id: {assignmentId}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{ width: "50%" }}
            >
              {thoFormInstances && thoFormInstances.length > 0 && (
                <Button
                  variant="contained"
                  endIcon={<Check />}
                  sx={{
                    alignSelf: "start",
                    backgroundColor: "green",
                    display: getStatus() !== "Approved" ? "" : "none",
                  }}
                  onClick={(e) => {
                    approveReports({
                      variables: {
                        reportApproval: {
                          inspectionAssignmentIds: [assignmentId],
                          personId: person.person.id,
                        },
                      },
                    });
                  }}
                >
                  Approve
                </Button>
              )}
              {thoFormInstances && thoFormInstances.length > 0 && !downloadingReports && (
                <Button
                  variant="contained"
                  endIcon={<Download />}
                  sx={{
                    alignSelf: "start",
                    display: getStatus() === "Approved" ? "" : "none",
                  }}
                  onClick={(e) => {
                    setDownloadingReports(true);
                    getReportResults({
                      variables:{
                        reportResult:{
                          inspectionAssignmentId: [assignmentId]
                        }
                      }
                    }).then((results:any)=>{
                      var address =  locationData.projectLocation.street1 + 
                      " " + locationData.projectLocation.city + 
                      ", " + locationData.projectLocation.state + 
                      " " + locationData.projectLocation.zip;

                      let pdfs:any = [];
                      results.data.reportResults.forEach((report:any)=>{
                        pdfs.push({
                          fileName: report.fileName,
                          bytes: decodeFromBase64(report.documentBytes)
                        })
                      });
                      genPDFDownload([{address: address, pdfs: pdfs}]);
                      setDownloadingReports(false);
                    })
                  }}
                >
                  Download Reports
                </Button>
              )}
              
              {thoFormInstances &&
                thoFormInstances.length > 0 &&
                !generatingReports && (
                  <Button
                    variant="contained"
                    endIcon={<RestartAlt />}
                    sx={{ alignSelf: "start" }}
                    onClick={(e) => {
                      var reportGenerationRequest = {
                        inspectionId: inspectionId,
                        inspectionAssignmentId: assignmentId,
                        personId: person.person.id,
                        jobStatus: "executing",
                      };
                      setGeneratingReports(true);
                      uploadReportGenerationRequest({
                        variables: {
                          reportGenerationRequest: reportGenerationRequest,
                        },
                      });
                    }}
                  >
                    Generate Reports
                  </Button>
                )}
              <Dialog open={generatingReports} maxWidth="lg">
                <DialogTitle>Generating Reports   <CircularProgress></CircularProgress></DialogTitle>
                <DialogContent>
                  Please do not close the browser window.
                </DialogContent>
              </Dialog>
              <Link
                to={{
                  pathname: `./${assignmentId}/${inspectionTypeData.inspectionType.id}/view`,
                  search: createSearchParams(searchParams).toString(),
                }}
                target="_blank"
              >
                <Button endIcon={<OpenInNew />}>Web View</Button>
              </Link>
            </Stack>
            {/* <Documentation /> */}
          </Stack>
          <Box
            width="100%"
            sx={{ borderTop: "solid 2px lightgrey", marginTop: "1rem" }}
          >
            <Tabs
              value={tab}
              onChange={(e: SyntheticEvent, newTab: number) => setTab(newTab)}
            >
              <Tab label="Report View" />
              <Tab label="Status Log" />
              <Tab label="Event Log" />
              <Tab label="Washouts" />
              <Tab label="Photos" />
              <Tab label="Inspection View" />
            </Tabs>
            {tab === 0 && (
              <Box sx={{ marginTop: 5 }}>
                <ReportReview
                  generatingReports={generatingReports}
                  inspection={data.inspection}
                  formInstances={[...thoFormInstances].sort(function(a, b) {
                    return a.name.localeCompare(b.name);
                 })}
                  inspectionAssignmentId={assignmentId}
                />
              </Box>
            )}
            {tab === 1 && (
              <InspectionStatusLogs
                inspectionStatuses={data.inspection.inspectionStatuses}
              />
            )}
            {tab === 2 && (
              <InspectionEventLogs
                inspectionId={inspectionId}
                inspectionAssignmentId={assignmentId}
              />
            )}
            {tab === 3 && <Washouts inspectionId={inspectionId} />}
            {tab === 4 && <PhotoGallery assignmentId={assignmentId} />}
            {tab === 5 && (

              <WebViewWrapper inspectionAssignmentId={assignmentId}>
                <InspectionWebView editMode={true} />
              </WebViewWrapper>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

const ExportButton = ({ formInstance }: { formInstance: IFormInstance }) => {
  function download() {
    const form = decode(formInstance.formJson);
    let excelObject: any = {};
    const dictionary = dictionaryLog as Dictionary;
    const contents = dictionary[Object.keys(dictionary)[0]];
    if (contents.enumerable === "groupMember") return;
    excelParse({
      excelObject,
      formJson: form,
      dictionaryObject: contents,
    });
    let highest = 0;
    Object.keys(excelObject).forEach((key) => {
      const columnNumber = excelColumnToInteger(key);
      if (columnNumber > highest) highest = columnNumber;
    });
    let newObj: any = {};
    let index = 1;
    let headers: string[] = [];
    if (!highest) return;
    while (index < highest) {
      const column = integerToExcelColumn(index);
      newObj[column] = excelObject[column];
      headers.push(column);
      index++;
    }
    let wb: any = {};
    utils.sheet_add_json(wb.Sheets["M. Example_Database"], [newObj], {
      header: headers,
      origin: "A4",
      skipHeader: true,
    });
    writeFile(wb, "report.xlsx");
  }

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<Download />}
        sx={{ alignSelf: "start" }}
        onClick={(e) => download()}
      >
        Export
      </Button>
    </>
  );
};

const ExportPDFButton = ({ formInstance }: { formInstance: IFormInstance }) => {
  function download() {
    if (!formInstance) {
      return;
    }
    const form = decode(formInstance.formJson);
    let excelObject: any = {};
    const dictionary = dictionaryLog as Dictionary;
    const contents = dictionary[Object.keys(dictionary)[0]];
    if (contents.enumerable === "groupMember") return;
    excelParse({
      excelObject,
      formJson: form,
      dictionaryObject: contents,
    });
  }

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<Download />}
        sx={{ alignSelf: "start" }}
        onClick={(e) => download()}
      >
        Export PDF
      </Button>
    </>
  );
};
