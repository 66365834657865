import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useControl } from "react-map-gl";
import type { ControlPosition } from "react-map-gl";
import redMapPin from "src/red-map-pin.jpg";
import polyStripesRed from "src/polyStripesRed.png";
import polyStripesBlue from "src/polyStripesBlue.png";
import * as turf from "@turf/turf";

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  geoFeatures: any[];
  onCreate: (evt: { features: object[] }) => any;
  onUpdate: (evt: { features: object[]; action: string }) => void;
  onDelete: (evt: { features: object[] }) => void;
  selectionChange: (evt: { features: object[] }) => boolean;
  setDraw: any;
  setMap: any;
  updateTextLayer: any;
};

export default function DrawControl(props: DrawControlProps) {
  let draw = useControl<MapboxDraw>(
    (ctx) =>
      new MapboxDraw({
        ...props,
        userProperties: true,
        styles: [
          {
            id: "gl-draw-polygon-fill-inactive",
            type: "fill",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Polygon"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "fill-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "fill-outline-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "fill-opacity": 0.1,
            },
          },
          {
            id: "gl-draw-polygon-fill-active",
            type: "fill",
            filter: [
              "all",
              ["==", "active", "true"],
              ["==", "$type", "Polygon"],
            ],
            paint: {
              "fill-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "fill-outline-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "fill-opacity": 0.1,
            },
          },
          {
            id: "gl-draw-polygon-midpoint",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "meta", "midpoint"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": "#fbb03b",
            },
          },
          {
            id: "gl-draw-polygon-stroke-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Polygon"],
              ["!=", "mode", "static"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#3bb2d0",
              "line-width": 2,
            },
          },
          {
            id: "gl-draw-polygon-stroke-active",
            type: "line",
            filter: [
              "all",
              ["==", "active", "true"],
              ["==", "$type", "Polygon"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": "#fbb03b",
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          {
            id: "gl-draw-line-inactive",
            type: "line",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "LineString"],
              ["!=", "mode", "static"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-width": 2,
              "line-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
            },
          },
          {
            id: "gl-draw-line-active",
            type: "line",
            filter: [
              "all",
              ["==", "$type", "LineString"],
              ["==", "active", "true"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "line-dasharray": [0.2, 2],
              "line-width": 2,
            },
          },
          {
            id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "circle-radius": 5,
              "circle-color": "#fff",
            },
          },
          {
            id: "gl-draw-polygon-and-line-vertex-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "meta", "vertex"],
              ["==", "$type", "Point"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
            },
          },
          {
            id: "gl-draw-point-point-stroke-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Point"],
              ["==", "meta", "feature"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "circle-radius": 5,
              "circle-opacity": 1,
              "circle-color": "#fff",
            },
          },
          {
            id: "gl-draw-point-inactive",
            type: "circle",
            filter: [
              "all",
              ["==", "active", "false"],
              ["==", "$type", "Point"],
              ["==", "meta", "feature"],
              ["!=", "mode", "static"],
            ],
            paint: {
              "circle-radius": 3,
              "circle-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
            },
          },
          {
            id: "gl-draw-point-stroke-active",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["==", "active", "true"],
              ["!=", "meta", "midpoint"],
            ],
            paint: {
              "circle-radius": 7,
              "circle-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
            },
          },
          {
            id: "gl-draw-point-active",
            type: "circle",
            filter: [
              "all",
              ["==", "$type", "Point"],
              ["!=", "meta", "midpoint"],
              ["==", "active", "true"],
            ],
            paint: {
              "circle-radius": 5,
              "circle-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
            },
          },
          {
            id: "gl-draw-polygon-fill-static",
            type: "fill",
            filter: [
              "all",
              ["==", "mode", "static"],
              ["==", "$type", "Polygon"],
            ],
            paint: {
              "fill-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "fill-outline-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "fill-opacity": 0.1,
            },
          },
          {
            id: "gl-draw-polygon-stroke-static",
            type: "line",
            filter: [
              "all",
              ["==", "mode", "static"],
              ["==", "$type", "Polygon"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#3bb2d0",
                "#3bb2d0",
              ],
              "line-width": 2,
            },
          },
          {
            id: "gl-draw-line-static",
            type: "line",
            filter: [
              "all",
              ["==", "mode", "static"],
              ["==", "$type", "LineString"],
            ],
            layout: {
              "line-cap": "round",
              "line-join": "round",
            },
            paint: {
              "line-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
              "line-width": 2,
            },
          },
          {
            id: "gl-draw-point-static",
            type: "circle",
            filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
            paint: {
              "circle-radius": 5,
              "circle-color": [
                "match",
                ["get", "user_type"], // get the property
                "Annotation",
                "#ff0000",
                "#3bb2d0",
              ],
            },
          },
        ],
      }),
    async (context) => {
      context.map.on("draw.create", props.onCreate);
      context.map.on("draw.update", (e)=>{
        props.onUpdate(e);
      });
      context.map.on("draw.delete", props.onDelete);
      context.map.on("draw.selectionchange", (e) => {
        props.selectionChange(e);
      });
      
      var features = props.geoFeatures;
      features.forEach((e: any, index) => {
        switch (e.shape) {
          case "ST_Point":
            draw.add({
              type: "Feature",
              properties: {
                text: e.name,
                id: e.id,
                name: e.name,
                type: e.type,
                fillColorInactive: "#000000",
              },
              geometry: {
                type: "Point",
                coordinates: [e.point.longitude, e.point.latitude],
              },
              id: e.id,
            });
            break;
          case "ST_Polygon":
            let polygonCoordinates = e.polygon.map((e: any) => {
              return e.map((f: any) => {
                return [f.longitude, f.latitude];
              });
            });
            draw.add({
              type: "Feature",
              properties: {
                text: e.name,
                id: e.id,
                name: e.name,
                type: e.type,
                fillColorInactive: "#000000",
              },
              geometry: {
                type: "Polygon",
                coordinates: polygonCoordinates,
              },
              id: e.id,
            });
            break;
          case "ST_MultiPolygon":
            let multiPolygonCoordinates = e.multiPolygon.map((e: any) => {
              return e.map((f: any) => {
                return f.map((g: any) => {
                  return [g.longitude, g.latitude];
                });
              });
            });
            draw.add({
              type: "Feature",
              properties: {
                text: e.name,
                id: e.id,
                name: e.name,
                type: e.type,
                fillColorInactive: "#000000",
              },
              geometry: {
                type: "MultiPolygon",
                coordinates: multiPolygonCoordinates,
              },
              id: e.id,
            });
            break;
          case "ST_LineString":
            let lineCoordinates = e.line.map((e: any) => {
              return [e.longitude, e.latitude];
            });
            draw.add({
              type: "Feature",
              properties: {
                text: e.name,
                id: e.id,
                name: e.name,
                type: e.type,
                fillColorInactive: "#000000",
              },
              geometry: {
                type: "LineString",
                coordinates: lineCoordinates,
              },
              id: e.id,
            });
            break;
          case "ST_MultiLineString":
            let multiLineCoordinates = e.multiLine.map((e: any) => {
              return e.map((f: any) => {
                return [f.longitude, f.latitude];
              });
            });
            draw.add({
              type: "Feature",
              properties: {
                text: e.name,
                id: e.id,
                name: e.name,
                type: e.type,
                fillColorInactive: "#000000",
              },
              geometry: {
                type: "MultiLineString",
                coordinates: multiLineCoordinates,
              },
              id: e.id,
            });
            break;
        }
      });
      var myMap: any = context.map.getMap();
      props.setDraw(draw);
      props.setMap(myMap);

      var switchy: any = document.getElementById("remover");
      
      let all = draw.getAll();
      
      let allNotLines = {
        type: "FeatureCollection",
        features: all.features.filter(
          (el) =>
            el.geometry.type !== "LineString" &&
            el.geometry.type !== "MultiLineString"
        ),
      };
      let allLines = {
        type: "FeatureCollection",
        features: all.features.filter(
          (el) =>
            el.geometry.type === "LineString" ||
            el.geometry.type === "MultiLineString"
        ),
      };
      
      myMap.on("styleimagemissing", (e: any) => {
        if (!myMap.hasImage("redMapPin")) {
          myMap.loadImage(redMapPin, (error: any, image: any) => {
            if (error) throw error;
            if (!myMap.hasImage("redMapPin")) {
              myMap.addImage("redMapPin", image, { sdf: true });
            }
          });
        }

        if (!myMap.hasImage("polyStripesRed")) {
          myMap.loadImage(polyStripesRed, function (err: any, image: any) {
            // Throw an error if something went wrong
            if (err) throw err;

            // Declare the image
            if (!myMap.hasImage("polyStripesRed")) {
              myMap.addImage("polyStripesRed", image);
            }
          });
        }

        if (!myMap.hasImage("polyStripesBlue")) {
          myMap.loadImage(polyStripesBlue, function (err: any, image: any) {
            // Throw an error if something went wrong
            if (err) throw err;

            // Declare the image
            if (!myMap.hasImage("polyStripesBlue")) {
              myMap.addImage("polyStripesBlue", image);
            }
          });
        }
      });



    var textPoints = [];
    for(let i=0; i<allNotLines.features.length; i++){
      let f:any = allNotLines.features[i];
      let point = turf.center(f);
      point.properties = f.properties;
      console.log(point);
      textPoints.push(point);
    }

    let textPointFeatureCollection = {
      type: "FeatureCollection",
      features: textPoints
    }

      myMap.addLayer({
        id: "textLayer",
        type: "symbol",
        source: {
          type: "geojson",
          data: textPointFeatureCollection, 
        },
        paint: {
          "text-color": "#000000",
          "text-halo-color": "#ffffff",
          "text-halo-width": 3,
        },
        layout: {
          "text-field": ["get", "text"],
          "text-rotation-alignment": "auto",
          "text-allow-overlap": true,
          "text-size": 12,
          "text-anchor": "center",
        },
      });

      myMap.addLayer({
        id: "lineTextLayer",
        type: "symbol",
        source: {
          type: "geojson",
          data: allLines, 
        },
        paint: {
          "text-color": "#000000",
          "text-halo-color": "#ffffff",
          "text-halo-width": 3,
        },
        layout: {
          "text-field": ["get", "text"],
          "text-rotation-alignment": "auto",
          "symbol-placement": "line-center",
          "text-allow-overlap": true,
          "text-size": 12,
          "text-anchor": "top",
          //"text-offset": ["literal", [-0.84, 0.23]]
        },
      });
      
      myMap.addLayer(
        {
          id: "satellite",
          source: {
            type: "raster",
            url: "mapbox://mapbox.satellite",
            tileSize: 256,
          },
          type: "raster",
          layout: { visibility: "none" },
        },
        "admin-2-boundaries-dispute"
      );

      myMap.addLayer(
        {
          id: "polyPatternFill",
          type: "fill",
          source: {
            type: "geojson",
            data: all, //Initially this is empty
          },
          filter: ["all", ["==", "$type", "Polygon"]],
          paint: {
            "fill-pattern": [
              "match",
              ["get", "user_type"], // get the property
              "Runway",
              "polyStripesRed",
              "polyStripesBlue",
            ],
          },
        },
        "admin-2-boundaries-dispute"
      );

      switchy.addEventListener("click", function () {
        myMap.removeLayer("textLayer");
        myMap.removeSource("textLayer");
        myMap.removeLayer("lineTextLayer");
        myMap.removeSource("lineTextLayer");
        switchy = document.getElementById("remover");
        var isOn = switchy.attributes["data"].value;
        if (isOn === "satellite-turned-on") {
          switchy.setAttribute("data", "satellite-turned-off");
          myMap.setLayoutProperty("satellite", "visibility", "none");
          switchy.innerHTML = "Add satellite";
        } else {
          switchy.setAttribute("data", "satellite-turned-on");
          myMap.setLayoutProperty("satellite", "visibility", "visible");
          switchy.innerHTML = "Remove satellite";
        }
        
          myMap.removeLayer("polyPatternFill");
          myMap.removeSource("polyPatternFill");
          var color = "";
          var opositeColor = "";
          if (switchy.className.includes("satellite-turned-on")) {
            color = "#ffffff";
            opositeColor = "#000000";
          } else {
            color = "#000000";
            opositeColor = "#ffffff";
          }
          var currAll = draw.getAll();
          let currAllNotLines = {
            type: "FeatureCollection",
            features: currAll.features.filter(
              (el) =>
                el.geometry.type != "LineString" &&
                el.geometry.type != "MultiLineString"
            ),
          };
      
          var currTextPoints:any = [];
          for (var i = 0; i < currAllNotLines.features.length; i++) {
            let f:any = currAllNotLines.features[i];
            let point = turf.center(f);
            point.properties = f.properties;
            
            currTextPoints.push(point);
          }
      
          let currTextPointFeatureCollection = {
            type: "FeatureCollection",
            features: currTextPoints,
          };
      
          let currAllLines = {
            type: "FeatureCollection",
            features: currAll.features.filter(
              (el) =>
                el.geometry.type == "LineString" ||
                el.geometry.type == "MultiLineString"
            ),
          };
      
          var currLineTextPoints = [];
      
          for (var i = 0; i < currAllLines.features.length; i++) {
            let f:any = currAllLines.features[i];
            let point = turf.center(f);
            point.properties = f.properties;
            console.log(point);
            currLineTextPoints.push(point);
          }
      
          let lineTextPointFeatureCollection = {
            type: "FeatureCollection",
            features: currLineTextPoints,
          };
      
          myMap.addLayer(
            {
              id: "polyPatternFill",
              type: "fill",
              source: {
                type: "geojson",
                data: currAll, //Initially this is empty
              },
              filter: ["all", ["==", "$type", "Polygon"]],
              paint: {
                "fill-pattern": [
                  "match",
                  ["get", "user_type"], // get the property
                  "Runway",
                  "polyStripesRed",
                  "polyStripesBlue",
                ],
              },
            },
            "admin-2-boundaries-dispute"
          );
      
          myMap.addLayer({
            id: "textLayer",
            type: "symbol",
            source: {
              type: "geojson",
              //data: allNotLines, //Initially this is empty
              data: currTextPointFeatureCollection,
            },
            paint: {
              "text-color": "#000000",
              "text-halo-color": "#ffffff",
              "text-halo-width": 3,
            },
            layout: {
              "text-field": ["get", "text"],
              "text-rotation-alignment": "auto",
              "text-allow-overlap": true,
              "text-size": 12,
              "text-anchor": "center",
            },
          });
      
          myMap.addLayer({
            id: "lineTextLayer",
            type: "symbol",
            source: {
              type: "geojson",
              data: currAllLines, //Initially this is empty
            },
            paint: {
              "text-color": "#000000",
              "text-halo-color": "#ffffff",
              "text-halo-width": 3,
            },
            layout: {
              "text-field": ["get", "text"],
              "text-rotation-alignment": "auto",
              "symbol-placement": "line-center",
              "text-allow-overlap": true,
              "text-size": 12,
              "text-anchor": "top",
              //"text-offset": ["literal", [-0.84, 0.23]]
            },
          });
      });
      //console.log(myMap.getStyle().layers);

    },
    (context) => {
      context.map.off("draw.create", props.onCreate);
      context.map.off("draw.update", props.onUpdate);
      context.map.off("draw.delete", props.onDelete);
    },
    {
      position: props.position,
    }
  );

  return null;
}
