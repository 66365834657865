import { useState, useEffect } from 'react';
//gql-mui-rhf
import { ProjectContext } from "../../../utils/contexts/project";
import createLazyQuery from "../../../utils/gql-mui-rhf/LazyQuery";
import { useContext } from "react";

import Projects from './list';
import ProjectRoles from './projectRoles';
import FieldReadyRoles from './fieldReadyRoles';
import ProjectContacts from './projectContacts.js';
import ProjectContactTypes from './projectContactTypes';
import ProjectPeople from '../People';
import ProjectProfile from './profile';
import OfferorProfile from './offeror';
import ClientProfile from './client';

// material
import { Box, Container, Tabs, Tab } from '@mui/material';
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function FirmView() {
    let projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [value, setValue] = useState(0);
    const [currentProject, setCurrentProject] = useState(null);
    const onGetProject = (response) => {
        setCurrentProject(response.project)
    }
    const [getProject, queryMethods] = createLazyQuery("project", onGetProject);
    useEffect(() => {
        if (project && project.id) {
            getProject({
                variables: {
                    id: project.id,
                }
            })
        }
    }, [project, getProject])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title="Dashboard | FINBACK670">
            <Container maxWidth="xl" style={{ paddingBottom: '64pt' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
                        <Tab label="Project Attributes" {...a11yProps(0)} />
                        <Tab label="Project People" {...a11yProps(1)} />
                        <Tab label="Project Contacts" {...a11yProps(2)} />
                        <Tab label="Sub-Projects" {...a11yProps(3)} />
                        <Tab label="Project Roles" {...a11yProps(4)} />
                        <Tab label="Field Ready Roles" {...a11yProps(5)} />
                        <Tab label="Configure Project Contacts" {...a11yProps(6)} />
                        {/* {(!project.parentProject?.id ?? true) && <Tab label="Client" {...a11yProps(7)} />}
                        {(!project.parentProject?.id ?? true) && <Tab label="Offeror" {...a11yProps(8)} />} */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {currentProject && <ProjectProfile project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {currentProject && <ProjectPeople project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {currentProject && <ProjectContacts project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {currentProject && <Projects setValue={setValue} projectId={currentProject.id} firmUnitId={currentProject.projectOwner} />}
                </TabPanel>
                <TabPanel value={value} index={4}>
                    {currentProject && <ProjectRoles project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
                <TabPanel value={value} index={5}>
                    {currentProject && <FieldReadyRoles project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
                <TabPanel value={value} index={6}>
                    {currentProject && <ProjectContactTypes project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
                <TabPanel value={value} index={7}>
                    {currentProject && <ClientProfile project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
                <TabPanel value={value} index={8}>
                    {currentProject && <OfferorProfile project={currentProject} refetchProject={queryMethods.refetch} />}
                </TabPanel>
            </Container>
        </Page >
    );
}
