// components
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button } from "@mui/material";
import TextField from "../../../utils/mui-rhf/TextField";
import Select from "../../../utils/mui-rhf/Select";
// ----------------------------------------------------------------------

export default function RuleEdit({ currentRule, onSubmit, options, unitOptions, operandOptions, isNew }) {
  const methods = useForm();
  const { handleSubmit, setValue, setError, clearErrors, formState } = methods;
  const { errors } = formState;

  const submit = (values) => {
    onSubmit(values);
  };

  useEffect(() => {
    if (currentRule) {

        methods.reset();
        setValue("id", currentRule.id);
      setValue("title", currentRule.title);
      setValue("description", currentRule.description);
      setValue("value", currentRule.value);
      setValue("unit", currentRule.unit);
      setValue("operand", currentRule.operand);
      setValue("featureTypeId", currentRule.featureType.id);
    }
  }, [currentRule, setValue]);

  return (
    <div style={{ width: "100%" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12}>
              <TextField
                disabled={false}
                key={3}
                fullWidth
                label={"Title"}
                name={"title"}
                sx={{width: "100%"}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={false}
                key={3}
                fullWidth
                label={"Description"}
                name={"description"}
                sx={{width: "100%"}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={false}
                key={3}
                fullWidth
                label={"Value"}
                name={"value"}
                sx={{width: "100%"}}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                options={unitOptions}
                fullWidth
                label={"Unit"}
                name={"unit"}
                rules={{ required: true }}
                sx={{width: "100%"}}
              />
              {errors && <span style={{ color: "red" }}>{errors.message}</span>}
            </Grid>
            <Grid item xs={12}>
              <Select
                options={operandOptions}
                fullWidth
                label={"Operand"}
                name={"operand"}
                rules={{ required: true }}
                sx={{width: "100%"}}
              />
              {errors && <span style={{ color: "red" }}>{errors.message}</span>}
            </Grid>
            <Grid item xs={12}>
              <Select
                options={options}
                fullWidth
                label={"Feature Type"}
                name={"featureTypeId"}
                rules={{ required: true }}
                sx={{width: "100%"}}
              />
              {errors && <span style={{ color: "red" }}>{errors.message}</span>}
            </Grid>
          </Grid>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
