import { Route, Routes } from "react-router-dom";
// components
import { InspectionTypeSelect } from "./pages/InspectionAssignment";
import InspectionAssignments from "./pages/InspectionAssignments";
import ExpandedDisplayView from "./pages/InspectionAssignment/components/ExpandedDisplayView";
import { InspectionWebView } from "../Inspections/MyInspections/routes/InspectionWebView";
import { WebViewWrapper } from "../Inspections/MyInspections/routes/InspectionWebView/wrapper";

// ----------------------------------------------------------------------

export default function Review() {
  return (
    <Routes>
      <Route path="/" element={<InspectionAssignments />} />
      <Route path="/:controlNumber" element={<InspectionTypeSelect />} />
      <Route
        path="/:controlNumber/:assignmentId/:inspectionTypeId/expanded"
        element={<ExpandedDisplayView />}
      />
      <Route
        path="/:controlNumber/:assignmentId/:inspectionTypeId/view"
        element={
          <WebViewWrapper>
            <InspectionWebView editMode={true} />
          </WebViewWrapper>
        }
      />
      {/* <Route
        path="/:inspectionTypeId/:inspectionTypeTemplateId/*"
        element={<Test />}
      /> */}
      {/* <Route path="/:inspectionTypeId" element={<InspectionTypePage />} />;
      <Route path="/create" element={<CreateInspectionType />} /> */}
    </Routes>
  );
}
