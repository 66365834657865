import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

export const MUIRHFSelect = ({
  defaultValue,
  options,
  children,
  disabled,
  description,
  name,
  rules,
  label,
  minWidth,
  ...rest
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ""}
      rules={rules}
      render={({ field, fieldState: { invalid } }) => {
        return (
          <FormControl sx={{ m: 1, minWidth: minWidth ? minWidth : 120 }} disabled={disabled} {...rest} error={invalid}>
            <InputLabel id={123}>{label}</InputLabel>
            <Select
              {...field}
              margin="dense"
              label={label}
              color={invalid ? "error" : "primary"}
              disabled={disabled}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {invalid && (
              <FormHelperText error={true}>Selection Required</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default MUIRHFSelect;
