import * as React from 'react';
// material
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import GQLTable from '../../utils/gql-mui-rhf/Table';
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RoleManagement() {
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Dashboard | FINBACK670">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Role Management</Typography>
        </Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Person Roles" {...a11yProps(0)} />
          <Tab label="Firm Roles" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <GQLTable
            queryString={'roleTypes'}
            queryVariables={{ "active": true, "entity": "PERSON" }}
            addMutationVariables={{"entity": "PERSON"}}
            addMutationString={'createRoleType'}
            deleteMutationString={'deleteRoleType'}
            updateMutationString={'updateRoleType'}
            updateModal={true}
            columnOptions={{ name: { width: 250 }, module: {width: 200}}}
            columnOrder={['name', 'module']}
            restrictedColumns={{ id: true, valueOrder: true, active: true, entity: true, module: true, roleTypeValues: true, roleTypeCredentials: true }}
            onRowClick={(rowData) => {
              navigate(`/app/admin/role/${rowData.id}`)
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GQLTable
            queryString={'roleTypes'}
            queryVariables={{ "active": true, "entity": "FIRM" }}
            addMutationVariables={{"entity": "FIRM"}}
            addMutationString={'createRoleType'}
            deleteMutationString={'deleteRoleType'}
            updateMutationString={'updateRoleType'}
            updateModal={true}
            columnOptions={{ name: { width: 250 }, module: {width: 200}}}
            columnOrder={['name', 'module']}
            restrictedColumns={{ id: true, valueOrder: true,  active: true, entity: true, module: true, roleTypeValues: true, roleTypeCredentials: true }}
            onRowClick={(rowData) => {
              navigate(`/app/admin/role/${rowData.id}`)
            }}
          />
        </TabPanel>
      </Container>
    </Page>
  );
}
