import { Action, PageState } from "../../components/TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function duplicateForm<
  E extends Extract<
    Action,
    {
      type: "duplicateForm";
    }
  >
>(action: E, draft: PageState): void {
  const { formId, newFormName } = action.payload;

  const form = draft.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === formId
  );

  if (!form) return;

  const formCopy = { ...form };

  let newId = uuidv4();
  formCopy.id = newId;
  formCopy.name = newFormName;
  draft.data.inspectionTypeTemplate.configuration.forms.push(formCopy);
}
