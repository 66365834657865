import { NavigateFunction } from "react-router-dom";

export interface PageState {
  data: Data;
  formId: string;
  fieldToCopy: Field | null;
  editingFieldId: string | null;
  navigate: NavigateFunction;
  errors: ErrorLink[];
  refetch: () => void;
  deleteConfig: {
    fieldId: string;
    deleteMessage: DeleteMessage;
  } | null;
}

export type ErrorLink = {
  fieldId: string;
  validationId?: string;
  errorMessage: string;
};

export type DeleteMessage =
  | "This field does not have a label. Please add a label or delete this field."
  | "Delete this field?";

export interface Data {
  inspectionTypeTemplate: InspectionTypeTemplate;
}

export interface InspectionTypeTemplate {
  id: string;
  name: string;
  description: string;
  start: Date;
  end: Date;
  configuration: Configuration;
}

export interface Configuration {
  forms: Form[];
  id: string;
  inspectionTypeTemplateId: string;
  referenceMaterialIds?: string[];
}

export interface FormElement {
  fields: Field[];
  formConfigurationId: string;
  subformIds: string[];
  id: string;
  name: string;
  validations: Validation[];
  isEmbedded: boolean;
  canAddSubForms?: boolean;
  canAddAsSubForm?: boolean;
  canAddFields?: boolean;
  canCopy?: boolean;
  prompt: string;
  groupParentTo?: string[];
  groupMemberOf?: string;
  geoFeatureConfigurations: GeoFeatureConfiguration[];
}

export interface GeoFeatureConfiguration {
  id: string;
  geoRuleId: string | null;
  count: number | null;
  order: string;
}

export interface ComponentForm extends FormElement {
  type: "ComponentInspection";
  references: Component[];
  canCopy?: true;
  canAddFields?: true;
  canAddSubForms?: true;
  canAddAsSubForm?: true;
}
export interface RoomForm extends FormElement {
  type: "RoomInspection";
  references: Room[];
  canCopy?: true;
  canAddFields?: true;
  canAddSubForms?: true;
  canAddAsSubForm?: true;
}

export interface SubForm extends FormElement {
  type: "SubForm";
  canCopy?: true;
  canAddFields?: true;
  canAddSubForms?: true;
  canAddAsSubForm?: true;
}
export interface Questionnaire extends FormElement {
  type: "Questionnaire";
  canCopy?: true;
  canAddFields?: true;
  canAddSubForms?: true;
  canAddAsSubForm?: true;
}
export interface ExteriorForm extends FormElement {
  type: "ExteriorInspection";
  canCopy?: true;
  canAddFields?: true;
  canAddSubForms?: true;
  canAddAsSubForm?: true;
}
export interface InteriorForm extends FormElement {
  type: "InteriorInspection";
  canCopy?: true;
  canAddFields?: true;
  canAddSubForms?: true;
  canAddAsSubForm?: true;
}
export interface RightOfEntry extends FormElement {
  type: "RightOfEntry";
  canCopy?: false;
  canAddFields?: false;
  canAddSubForms?: false;
  canAddAsSubForm?: false;
}
export interface ESignature extends FormElement {
  type: "ESignature";
  canCopy?: false;
  canAddFields?: false;
  canAddSubForms?: false;
  canAddAsSubForm?: false;
}
export interface SpecialConditions extends FormElement {
  type: "SpecialConditions";
  canCopy?: false;
  canAddFields?: false;
  canAddSubForms?: false;
  canAddAsSubForm?: false;
}
export interface AdditionalPhotos extends FormElement {
  type: "AdditionalPhotos";
  canCopy?: false;
  canAddFields?: false;
  canAddSubForms?: false;
  canAddAsSubForm?: false;
}
export interface TimeLog extends FormElement {
  type: "TimeLog";
  canCopy?: false;
  canAddFields?: false;
  canAddSubForms?: false;
  canAddAsSubForm?: false;
}

export type Form =
  | ComponentForm
  | Questionnaire
  | RoomForm
  | SubForm
  | ExteriorForm
  | InteriorForm
  | RightOfEntry
  | ESignature
  | SpecialConditions
  | TimeLog
  | AdditionalPhotos;

export type FormType = Form["type"];

export type InputType = Field["inputType"];

// type Extends<T, U extends T> = U;

export const AppointmentLevelFormTypes = [
  "ExteriorInspection",
  "InteriorInspection",
  "Questionnaire",
] as const satisfies readonly Form["type"][];

export type AppointmentLevelFormType =
  (typeof AppointmentLevelFormTypes)[number];

export const SpecialFormTypes = [
  "ESignature",
  "TimeLog",
  "RightOfEntry",
  "SpecialConditions",
  "AdditionalPhotos",
] as const satisfies readonly Form["type"][];

export type SpecialFormType = (typeof SpecialFormTypes)[number];

export type Field =
  | TextField
  | LongTextField
  | PhoneField
  | YesNoField
  | ChecklistField
  | OptionSelectField
  | DropDownField
  | EmailField
  | NumberField
  | TimeField
  | YearField
  | DateField
  | DateTimeField
  | PhotoCollectionField
  | SignatureField
  | MapCaptureField
  | DescriptionField;

interface BaseField {
  displayIndex: number;
  formId: string;
  id: string;
  isRequired: boolean;
  description: string;
  isVisible: boolean;
  label: string;
  name: string;
  referenceMaterialIds?: string[];
}

export interface TextField extends BaseField {
  inputType: "Text";
  placeholder: string;
}

export interface DescriptionField extends BaseField {
  inputType: "Description";
}
export interface MapCaptureField extends BaseField {
  inputType: "MapCapture";
}
export interface LongTextField extends BaseField {
  inputType: "LongText";
  placeholder: string;
}

export interface PhoneField extends BaseField {
  inputType: "Phone";
}

export interface YesNoField extends BaseField {
  inputType: "YesNo";
}

export interface ChecklistField extends BaseField {
  inputType: "Checklist";
  possibleValues: PossibleValue[];
}

export interface OptionSelectField extends BaseField {
  inputType: "OptionSelect";
  possibleValues: PossibleValue[];
}

export interface DropDownField extends BaseField {
  inputType: "DropDown";
  possibleValues: PossibleValue[];
}

export interface EmailField extends BaseField {
  inputType: "Email";
}

export interface NumberField extends BaseField {
  inputType: "Number";
}

export interface TimeField extends BaseField {
  inputType: "Time";
}

export interface YearField extends BaseField {
  inputType: "Year";
  minDate: string | "today" | null;
  maxDate: string | "today" | null;
}
export interface DateField extends BaseField {
  inputType: "Date";
  minDate: string | "today" | null;
  maxDate: string | "today" | null;
}

export interface DateTimeField extends BaseField {
  inputType: "DateTime";
  minDate: string | "today" | "yesterday" | "tomorrow" | null;
  maxDate: string | "today" | "yesterday" | "tomorrow" | null;
}
export interface PhotoCollectionField extends BaseField {
  inputType: "PhotoCollection";
}
export interface SignatureField extends BaseField {
  inputType: "Signature";
}

export interface PossibleValue {
  description: string;
  displayIndex: number;
  formFieldId: string;
  id: string;
  label: string;
  name: string;
  value: string;
}

export interface BaseValidation {
  expressions: Expression[];
  field: string;
  formId: string;
  id: string;
  onTrigger: ValidationTrigger | null;
  message: string | null;
  value: any | null;
}

export interface InitialValidation extends BaseValidation {
  action: null;
}

export interface ErrorValidation extends BaseValidation {
  action: "Error";
  message: string;
}

export interface AnswerValidation extends BaseValidation {
  action: "Answer";
  value: any;
}
export interface ShowValidation extends BaseValidation {
  action: "Show";
}

export type Validation =
  | ErrorValidation
  | ShowValidation
  | AnswerValidation
  | InitialValidation;

export type ValidationAction = Validation["action"];

export type ValidationTrigger = "ValueChange" | "FormSave" | "FormCreate";

export interface Expression {
  fieldToCompare: string | null;
  geoRuleToCompare: string | null;
  formValidationId: string;
  id: string;
  linkedBy: "AND" | "OR" | null;
  operator: Operator | null;
  type: ExpressionType | null;
  order: number;
  value: any;
}

export type ExpressionType = "Field" | "GeoRule";

export type Operator =
  | ">"
  | "<"
  | "=="
  | "!=="
  | "contains"
  | "does not contain"
  | "contains one of"
  | "does not contain one of"
  | "not null";

export type Action =
  | {
      type: "x_Set_Form_Do_Not_Call";
      payload: {
        formId: string;
      };
    }
  | {
      type: "setForm";
      payload: {
        formId: string;
      };
    }
  | {
      type: "updateFormName";
      payload: {
        formName: string;
      };
    }
  | {
      type: "updateFormPrompt";
      payload: {
        formPrompt: string;
      };
    }
  | {
      type: "updateGeoFeatureConfiguration";
      payload: {
        id: string;
        geoRuleId: string;
        count: number;
        order: string;
      };
    }
  | {
      type: "deleteGeoFeatureConfiguration";
      payload: {
        id: string;
      };
    }
  | {
      type: "createGeoFeatureConfiguration";
    }
  | {
      type: "updateFormType";
      payload: {
        formType: FormType;
      };
    }
  | {
      type: "updateField";
      payload: {
        /**
         * INFO:
         * The newValues property takes an object of properties and new values to be set on the field. Only values which change need to be included in this field.
         * The input type is a required property on this object.
         */
        newValues: AtLeast<Field, "inputType">;
        fieldId: string;
      };
    }
  | {
      type: "toggleDelete";
      payload: {
        fieldId: string;
        deleteMessage: DeleteMessage;
      } | null;
    }
  | {
      type: "deleteField";
      payload: {
        fieldId: string;
      };
    }
  | {
      type: "createField";
      payload: {
        inputType: InputType | "paste";
        displayIndex?: number;
      };
    }
  | {
      type: "moveField";
      payload: {
        oldDisplayIndex: number;
        newDisplayIndex: number;
      };
    }
  | {
      type: "createPossibleValue";
    }
  | {
      type: "updatePossibleValue";
      payload: {
        label: string;
        possibleValueId: string;
      };
    }
  | {
      type: "movePossibleValue";
      payload: {
        initialIndex: number;
        newIndex: number;
      };
    }
  | {
      type: "deletePossibleValue";
      payload: {
        possibleValueId: string;
      };
    }
  | {
      type: "createValidation";
    }
  | {
      type: "deleteValidation";
      payload: {
        validationId: string;
      };
    }
  | {
      type: "updateValidationAction";
      payload: {
        newValue: ValidationAction;
        validationId: string;
      };
    }
  | {
      type: "updateValidationTrigger";
      payload: {
        newValue: ValidationTrigger;
        validationId: string;
      };
    }
  | {
      type: "updateValidationMessage";
      payload: {
        messageValue: string;
        validationId: string;
      };
    }
  | {
      type: "updateValidationValue";
      payload: {
        value: any;
        validationId: string;
      };
    }
  | {
      type: "duplicateForm";
      payload: {
        formId: string;
        newFormName: string;
      };
    }
  | {
      type: "createExpression";
      payload: {
        validationId: string;
      };
    }
  | {
      type: "deleteExpression";
      payload: {
        validationId: string;
        expressionId: string;
      };
    }
  | {
      type: "updateFieldToCompare";
      payload: {
        validationId: string;
        expressionId: string;
        fieldToCompareId: string;
      };
    }
  | {
      type: "updateGeoRuleToCompare";
      payload: {
        validationId: string;
        expressionId: string;
        geoRuleToCompareId: string;
      };
    }
  | {
      type: "updateExpression";
      payload: {
        validationId: string;
        expressionId: string;
        paramToUpdate: Partial<
          Omit<Expression, "id" | "formValidationId" | "fieldToCompare">
        > & {};
      };
    }
  | {
      type: "updateSubformEmbedded";
      payload: {
        subformId: string;
        isEmbeddedValue: boolean;
      };
    }
  | {
      type: "closeEditor";
    }
  | {
      type: "setEditor";
      payload: {
        fieldId: string;
      };
    }
  | {
      type: "createSubform";
      payload: {
        parentFormId: string;
        newFormType: FormType;
      };
    }
  | {
      type: "createForm";
      payload: {
        type: FormType;
      };
    }
  | {
      type: "deleteForm";
      payload: {
        formId: string;
      };
    }
  | {
      type: "linkSubForm";
      payload: {
        subFormId: string;
        parentFormId: string;
      };
    }
  | {
      type: "unlinkSubForm";
      payload: {
        subFormId: string;
        parentFormId: string;
      };
    }
  | {
      type: "updateReferences";
      payload:
        | {
            inspectionType: "ComponentInspection";
            references: Component[];
          }
        | {
            inspectionType: "RoomInspection";
            references: Room[];
          };
    }
  | {
      type: "copyField";
      payload: {
        fieldId: string;
      };
    }
  | {
      type: "moveSubform";
      payload: {
        initialIndex: number;
        newIndex: number;
        parentFormId?: string;
      };
    }
  | {
      type: "moveForm";
      payload: {
        initialIndex: number;
        newIndex: number;
      };
    }
  | {
      type: "addReferenceMaterial";
      payload: {
        referenceMaterialId: string;
      };
    }
  | {
      type: "addReferenceMaterialToField";
      payload: {
        referenceMaterialId: string;
        fieldId: string;
      };
    }
  | {
      type: "deleteReferenceMaterialFromField";
      payload: {
        referenceMaterialId: string;
        fieldId: string;
      };
    }
  | {
      type: "deleteReferenceMaterial";
      payload: {
        referenceMaterialId: string;
      };
    }
  | {
      type: "addGrouping";
      payload: {
        parentFormId: string;
      } & (
        | {
            /**
             * If present, will attempt to group child field under parent.
             * Will only succeed if all fields match between parent and child.
             */
            type: "assignExistingFormToGroup";
            childFormId: string;
          }
        | {
            /**
             * Form name for newly instantiated child form.
             */
            type: "createNewChildForm";
            newFormName: string;
          }
      );
    };

export type ActionType = Action["type"];

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type ReferenceProps = {
  referenceName: string;
  referenceAbbreviation: string;
  referenceType: "Room" | "Component";
};

export type Component = (typeof components)[number];

export const components = [
  {
    referenceName: "Door",
    referenceAbbreviation: "DOR",
    referenceType: "Component",
  },
  {
    referenceName: "Window",
    referenceAbbreviation: "WIN",
    referenceType: "Component",
  },
] as const satisfies readonly ReferenceProps[];

export type Room = (typeof rooms)[number];

export const rooms = [
  {
    referenceName: "Bathroom",
    referenceAbbreviation: "BTH",
    referenceType: "Room",
  },
  {
    referenceName: "Bedroom",
    referenceAbbreviation: "BED",
    referenceType: "Room",
  },
  {
    referenceName: "Closet",
    referenceAbbreviation: "CLO",
    referenceType: "Room",
  },
  {
    referenceName: "Dining Room",
    referenceAbbreviation: "DIN",
    referenceType: "Room",
  },
  {
    referenceName: "Family Room",
    referenceAbbreviation: "FAM",
    referenceType: "Room",
  },
  {
    referenceName: "Foyer",
    referenceAbbreviation: "FOY",
    referenceType: "Room",
  },
  {
    referenceName: "Hallway",
    referenceAbbreviation: "HAL",
    referenceType: "Room",
  },
  {
    referenceName: "Foyer/Hallway",
    referenceAbbreviation: "HAL",
    referenceType: "Room",
  },
  {
    referenceName: "Kitchen",
    referenceAbbreviation: "KIT",
    referenceType: "Room",
  },
  {
    referenceName: "Living Room",
    referenceAbbreviation: "LIV",
    referenceType: "Room",
  },
  {
    referenceName: "Apartment",
    referenceAbbreviation: "APT",
    referenceType: "Room",
  },
  {
    referenceName: "Arts &amp; Crafts",
    referenceAbbreviation: "ANC",
    referenceType: "Room",
  },
  {
    referenceName: "Attic",
    referenceAbbreviation: "ATT",
    referenceType: "Room",
  },
  {
    referenceName: "Auditorium",
    referenceAbbreviation: "AUD",
    referenceType: "Room",
  },
  {
    referenceName: "Balcony",
    referenceAbbreviation: "BAL",
    referenceType: "Room",
  },
  {
    referenceName: "Basement",
    referenceAbbreviation: "BAS",
    referenceType: "Room",
  },
  {
    referenceName: "Building Entrance",
    referenceAbbreviation: "BUE",
    referenceType: "Room",
  },
  {
    referenceName: "Bulk Head",
    referenceAbbreviation: "BUH",
    referenceType: "Room",
  },
  {
    referenceName: "Cafeteria",
    referenceAbbreviation: "CAF",
    referenceType: "Room",
  },
  {
    referenceName: "Cashier Room",
    referenceAbbreviation: "CSR",
    referenceType: "Room",
  },
  {
    referenceName: "Classroom",
    referenceAbbreviation: "CLA",
    referenceType: "Room",
  },
  {
    referenceName: "Clerk Room",
    referenceAbbreviation: "CLR",
    referenceType: "Room",
  },
  {
    referenceName: "Coatroom",
    referenceAbbreviation: "COA",
    referenceType: "Room",
  },
  {
    referenceName: "Community Room",
    referenceAbbreviation: "CMR",
    referenceType: "Room",
  },
  {
    referenceName: "Compactor Room",
    referenceAbbreviation: "CPR",
    referenceType: "Room",
  },
  {
    referenceName: "Computer Room",
    referenceAbbreviation: "CPT",
    referenceType: "Room",
  },
  {
    referenceName: "Copy Room",
    referenceAbbreviation: "CYP",
    referenceType: "Room",
  },
  {
    referenceName: "Corridor",
    referenceAbbreviation: "COR",
    referenceType: "Room",
  },
  {
    referenceName: "Courtyard",
    referenceAbbreviation: "COU",
    referenceType: "Room",
  },
  {
    referenceName: "Dinette",
    referenceAbbreviation: "DET",
    referenceType: "Room",
  },
  {
    referenceName: "Examination Room",
    referenceAbbreviation: "EXM",
    referenceType: "Room",
  },
  {
    referenceName: "Excercise Room",
    referenceAbbreviation: "EXR",
    referenceType: "Room",
  },
  {
    referenceName: "Fire Escape",
    referenceAbbreviation: "FIR",
    referenceType: "Room",
  },
  {
    referenceName: "Gameroom",
    referenceAbbreviation: "GAM",
    referenceType: "Room",
  },
  { referenceName: "Gym", referenceAbbreviation: "GYM", referenceType: "Room" },
  {
    referenceName: "Gym Office",
    referenceAbbreviation: "GYO",
    referenceType: "Room",
  },
  {
    referenceName: "Interior",
    referenceAbbreviation: "INT",
    referenceType: "Room",
  },
  { referenceName: "Lab", referenceAbbreviation: "LAB", referenceType: "Room" },
  {
    referenceName: "Ladies Room",
    referenceAbbreviation: "LDY",
    referenceType: "Room",
  },
  {
    referenceName: "Laundry",
    referenceAbbreviation: "LRY",
    referenceType: "Room",
  },
  {
    referenceName: "Library",
    referenceAbbreviation: "LIB",
    referenceType: "Room",
  },
  {
    referenceName: "Lobby",
    referenceAbbreviation: "LBY",
    referenceType: "Room",
  },
  {
    referenceName: "Locker Room",
    referenceAbbreviation: "LKR",
    referenceType: "Room",
  },
  {
    referenceName: "Maintenance Room",
    referenceAbbreviation: "MNR",
    referenceType: "Room",
  },
  {
    referenceName: "Meeting Room",
    referenceAbbreviation: "MTG",
    referenceType: "Room",
  },
  {
    referenceName: "Mens Room",
    referenceAbbreviation: "MEN",
    referenceType: "Room",
  },
  {
    referenceName: "Nurse",
    referenceAbbreviation: "NUR",
    referenceType: "Room",
  },
  {
    referenceName: "Office",
    referenceAbbreviation: "OFF",
    referenceType: "Room",
  },
  {
    referenceName: "Office Manager",
    referenceAbbreviation: "OFM",
    referenceType: "Room",
  },
  {
    referenceName: "Office Security",
    referenceAbbreviation: "OFY",
    referenceType: "Room",
  },
  {
    referenceName: "Office-Asst Mngr",
    referenceAbbreviation: "OFA",
    referenceType: "Room",
  },
  {
    referenceName: "Office-Bookkeeper",
    referenceAbbreviation: "OFB",
    referenceType: "Room",
  },
  {
    referenceName: "Office-Director",
    referenceAbbreviation: "OFD",
    referenceType: "Room",
  },
  {
    referenceName: "Office-Paint Inspect",
    referenceAbbreviation: "OFI",
    referenceType: "Room",
  },
  {
    referenceName: "Office-Receptionist",
    referenceAbbreviation: "OFR",
    referenceType: "Room",
  },
  {
    referenceName: "Office-Secretary",
    referenceAbbreviation: "OFS",
    referenceType: "Room",
  },
  {
    referenceName: "Office-Superintendent",
    referenceAbbreviation: "OFT",
    referenceType: "Room",
  },
  {
    referenceName: "Paint Shop",
    referenceAbbreviation: "PAS",
    referenceType: "Room",
  },
  {
    referenceName: "Pantry",
    referenceAbbreviation: "PAN",
    referenceType: "Room",
  },
  {
    referenceName: "Playground",
    referenceAbbreviation: "PLG",
    referenceType: "Room",
  },
  {
    referenceName: "Playroom",
    referenceAbbreviation: "PLA",
    referenceType: "Room",
  },
  {
    referenceName: "Pool Room",
    referenceAbbreviation: "PLR",
    referenceType: "Room",
  },
  {
    referenceName: "Public Hallway",
    referenceAbbreviation: "PUH",
    referenceType: "Room",
  },
  {
    referenceName: "Public Health Assist",
    referenceAbbreviation: "PHA",
    referenceType: "Room",
  },
  {
    referenceName: "Recreation Room",
    referenceAbbreviation: "REC",
    referenceType: "Room",
  },
  {
    referenceName: "Roof",
    referenceAbbreviation: "ROF",
    referenceType: "Room",
  },
  {
    referenceName: "Roof Landing",
    referenceAbbreviation: "ROL",
    referenceType: "Room",
  },
  {
    referenceName: "Shower",
    referenceAbbreviation: "SWR",
    referenceType: "Room",
  },
  {
    referenceName: "Slop Sink",
    referenceAbbreviation: "SLS",
    referenceType: "Room",
  },
  {
    referenceName: "Staff Room",
    referenceAbbreviation: "STF",
    referenceType: "Room",
  },
  {
    referenceName: "Stage",
    referenceAbbreviation: "STG",
    referenceType: "Room",
  },
  {
    referenceName: "Staircase",
    referenceAbbreviation: "STC",
    referenceType: "Room",
  },
  {
    referenceName: "Staircase A",
    referenceAbbreviation: "SCA",
    referenceType: "Room",
  },
  {
    referenceName: "Staircase B",
    referenceAbbreviation: "SCB",
    referenceType: "Room",
  },
  {
    referenceName: "Staircase C",
    referenceAbbreviation: "SCC",
    referenceType: "Room",
  },
  {
    referenceName: "Storage",
    referenceAbbreviation: "STO",
    referenceType: "Room",
  },
  {
    referenceName: "Supply Room",
    referenceAbbreviation: "SUP",
    referenceType: "Room",
  },
  {
    referenceName: "Tankroom",
    referenceAbbreviation: "TNK",
    referenceType: "Room",
  },
  {
    referenceName: "Tenant Room",
    referenceAbbreviation: "TEN",
    referenceType: "Room",
  },
  {
    referenceName: "Utility Room",
    referenceAbbreviation: "UTR",
    referenceType: "Room",
  },
  {
    referenceName: "Vacant",
    referenceAbbreviation: "VAC",
    referenceType: "Room",
  },
  {
    referenceName: "Vestibule",
    referenceAbbreviation: "VST",
    referenceType: "Room",
  },
  {
    referenceName: "Vision/Hearing",
    referenceAbbreviation: "VIH",
    referenceType: "Room",
  },
  {
    referenceName: "Waiting Room",
    referenceAbbreviation: "WAT",
    referenceType: "Room",
  },
  {
    referenceName: "Access Ramp",
    referenceAbbreviation: "ACR",
    referenceType: "Room",
  },
  {
    referenceName: "Activity Room",
    referenceAbbreviation: "ACT",
    referenceType: "Room",
  },
  {
    referenceName: "Addition",
    referenceAbbreviation: "ADD",
    referenceType: "Room",
  },
  {
    referenceName: "Back Yard",
    referenceAbbreviation: "BAC",
    referenceType: "Room",
  },
  {
    referenceName: "Bar Room",
    referenceAbbreviation: "BAR",
    referenceType: "Room",
  },
  {
    referenceName: "Basketball Court",
    referenceAbbreviation: "BSC",
    referenceType: "Room",
  },
  {
    referenceName: "Boat Dock",
    referenceAbbreviation: "BOD",
    referenceType: "Room",
  },
  {
    referenceName: "Boiler Room",
    referenceAbbreviation: "BOR",
    referenceType: "Room",
  },
  {
    referenceName: "Breakfast Room",
    referenceAbbreviation: "BRR",
    referenceType: "Room",
  },
  {
    referenceName: "Breezeway",
    referenceAbbreviation: "BZW",
    referenceType: "Room",
  },
  {
    referenceName: "Bridge",
    referenceAbbreviation: "BRG",
    referenceType: "Room",
  },
  {
    referenceName: "Building Exterior",
    referenceAbbreviation: "BUX",
    referenceType: "Room",
  },
  {
    referenceName: "Car Port",
    referenceAbbreviation: "CAR",
    referenceType: "Room",
  },
  {
    referenceName: "Cashier Cage",
    referenceAbbreviation: "CAS",
    referenceType: "Room",
  },
  {
    referenceName: "Cashiers Office",
    referenceAbbreviation: "CSO",
    referenceType: "Room",
  },
  {
    referenceName: "Cellar",
    referenceAbbreviation: "CEL",
    referenceType: "Room",
  },
  {
    referenceName: "Conference Room",
    referenceAbbreviation: "CFR",
    referenceType: "Room",
  },
  {
    referenceName: "Connector",
    referenceAbbreviation: "CON",
    referenceType: "Room",
  },
  {
    referenceName: "Control",
    referenceAbbreviation: "CNL",
    referenceType: "Room",
  },
  {
    referenceName: "Crawl Space",
    referenceAbbreviation: "CRS",
    referenceType: "Room",
  },
  {
    referenceName: "Deck",
    referenceAbbreviation: "DEK",
    referenceType: "Room",
  },
  {
    referenceName: "Deli",
    referenceAbbreviation: "DEL",
    referenceType: "Room",
  },
  { referenceName: "Den", referenceAbbreviation: "DEN", referenceType: "Room" },
  {
    referenceName: "Dressing Room",
    referenceAbbreviation: "DRR",
    referenceType: "Room",
  },
  {
    referenceName: "Dumpster Enclosure",
    referenceAbbreviation: "DUE",
    referenceType: "Room",
  },
  {
    referenceName: "Electrical Closet",
    referenceAbbreviation: "ELC",
    referenceType: "Room",
  },
  {
    referenceName: "Electrical Room",
    referenceAbbreviation: "ELR",
    referenceType: "Room",
  },
  {
    referenceName: "Elevator",
    referenceAbbreviation: "ELV",
    referenceType: "Room",
  },
  {
    referenceName: "Elevator Room",
    referenceAbbreviation: "EVR",
    referenceType: "Room",
  },
  {
    referenceName: "Entrance",
    referenceAbbreviation: "ENT",
    referenceType: "Room",
  },
  {
    referenceName: "Exterior Closet",
    referenceAbbreviation: "XCL",
    referenceType: "Room",
  },
  {
    referenceName: "Exterior Hall",
    referenceAbbreviation: "XHL",
    referenceType: "Room",
  },
  {
    referenceName: "Exterior Play Area",
    referenceAbbreviation: "XPA",
    referenceType: "Room",
  },
  {
    referenceName: "Fitness Room",
    referenceAbbreviation: "FIT",
    referenceType: "Room",
  },
  {
    referenceName: "Front Porch",
    referenceAbbreviation: "FRP",
    referenceType: "Room",
  },
  {
    referenceName: "Furnace Room",
    referenceAbbreviation: "FUR",
    referenceType: "Room",
  },
  {
    referenceName: "Gallery",
    referenceAbbreviation: "GAL",
    referenceType: "Room",
  },
  {
    referenceName: "Garage",
    referenceAbbreviation: "GRG",
    referenceType: "Room",
  },
  {
    referenceName: "Gazebo",
    referenceAbbreviation: "GAZ",
    referenceType: "Room",
  },
  {
    referenceName: "Greenhouse",
    referenceAbbreviation: "GRN",
    referenceType: "Room",
  },
  {
    referenceName: "Gymnasium",
    referenceAbbreviation: "GYA",
    referenceType: "Room",
  },
  {
    referenceName: "Household",
    referenceAbbreviation: "HOU",
    referenceType: "Room",
  },
  {
    referenceName: "HVAC Closet",
    referenceAbbreviation: "HVC",
    referenceType: "Room",
  },
  {
    referenceName: "Laboratory",
    referenceAbbreviation: "LAY",
    referenceType: "Room",
  },
  {
    referenceName: "Laundry Room",
    referenceAbbreviation: "LAU",
    referenceType: "Room",
  },
  {
    referenceName: "Leasing Office",
    referenceAbbreviation: "LEO",
    referenceType: "Room",
  },
  {
    referenceName: "Living Area",
    referenceAbbreviation: "LIA",
    referenceType: "Room",
  },
  {
    referenceName: "Living Room/Kitchen",
    referenceAbbreviation: "LVK",
    referenceType: "Room",
  },
  {
    referenceName: "Loft",
    referenceAbbreviation: "LFT",
    referenceType: "Room",
  },
  {
    referenceName: "Lounge",
    referenceAbbreviation: "LOU",
    referenceType: "Room",
  },
  {
    referenceName: "Lunch Room",
    referenceAbbreviation: "LUN",
    referenceType: "Room",
  },
  {
    referenceName: "Mail House/Shed",
    referenceAbbreviation: "MAI",
    referenceType: "Room",
  },
  {
    referenceName: "Maintenance Shop",
    referenceAbbreviation: "MNS",
    referenceType: "Room",
  },
  {
    referenceName: "Mechanical Room",
    referenceAbbreviation: "MEC",
    referenceType: "Room",
  },
  {
    referenceName: "Men's Locker Room",
    referenceAbbreviation: "MLR",
    referenceType: "Room",
  },
  {
    referenceName: "Men's Restroom",
    referenceAbbreviation: "MRR",
    referenceType: "Room",
  },
  {
    referenceName: "Meter Room",
    referenceAbbreviation: "MTR",
    referenceType: "Room",
  },
  {
    referenceName: "Miscellaneous Room",
    referenceAbbreviation: "MSC",
    referenceType: "Room",
  },
  {
    referenceName: "Mud Room",
    referenceAbbreviation: "MUD",
    referenceType: "Room",
  },
  {
    referenceName: "Multipurpose Room",
    referenceAbbreviation: "MLP",
    referenceType: "Room",
  },
  { referenceName: "N/A", referenceAbbreviation: "NTA", referenceType: "Room" },
  {
    referenceName: "Nurse's Office",
    referenceAbbreviation: "NUO",
    referenceType: "Room",
  },
  {
    referenceName: "Office-H.A.",
    referenceAbbreviation: "OFH",
    referenceType: "Room",
  },
  {
    referenceName: "Parking Lot",
    referenceAbbreviation: "PRK",
    referenceType: "Room",
  },
  {
    referenceName: "Patio",
    referenceAbbreviation: "PAT",
    referenceType: "Room",
  },
  {
    referenceName: "Pool",
    referenceAbbreviation: "POL",
    referenceType: "Room",
  },
  {
    referenceName: "Pool Pump House",
    referenceAbbreviation: "PPH",
    referenceType: "Room",
  },
  {
    referenceName: "Porch",
    referenceAbbreviation: "POR",
    referenceType: "Room",
  },
  {
    referenceName: "Racquetball Court",
    referenceAbbreviation: "RAC",
    referenceType: "Room",
  },
  {
    referenceName: "Rear Porch",
    referenceAbbreviation: "REP",
    referenceType: "Room",
  },
  {
    referenceName: "Refuse Room",
    referenceAbbreviation: "RFR",
    referenceType: "Room",
  },
  {
    referenceName: "Repair Room",
    referenceAbbreviation: "RPR",
    referenceType: "Room",
  },
  {
    referenceName: "Room",
    referenceAbbreviation: "ROM",
    referenceType: "Room",
  },
  {
    referenceName: "Sauna",
    referenceAbbreviation: "SAU",
    referenceType: "Room",
  },
  {
    referenceName: "Security Office",
    referenceAbbreviation: "SEC",
    referenceType: "Room",
  },
  {
    referenceName: "Shed",
    referenceAbbreviation: "SHD",
    referenceType: "Room",
  },
  {
    referenceName: "Soil",
    referenceAbbreviation: "SOL",
    referenceType: "Room",
  },
  {
    referenceName: "Storage Room",
    referenceAbbreviation: "SGR",
    referenceType: "Room",
  },
  {
    referenceName: "Store",
    referenceAbbreviation: "STE",
    referenceType: "Room",
  },
  {
    referenceName: "Sub-basement",
    referenceAbbreviation: "SUB",
    referenceType: "Room",
  },
  {
    referenceName: "Sun Room",
    referenceAbbreviation: "SUN",
    referenceType: "Room",
  },
  {
    referenceName: "Superintendant Shop",
    referenceAbbreviation: "SIS",
    referenceType: "Room",
  },
  {
    referenceName: "Tanning Room",
    referenceAbbreviation: "TAN",
    referenceType: "Room",
  },
  {
    referenceName: "Tennis Court",
    referenceAbbreviation: "TNC",
    referenceType: "Room",
  },
  {
    referenceName: "Training Room",
    referenceAbbreviation: "TRR",
    referenceType: "Room",
  },
  {
    referenceName: "Trash Room",
    referenceAbbreviation: "TRA",
    referenceType: "Room",
  },
  {
    referenceName: "Tunnel",
    referenceAbbreviation: "TUN",
    referenceType: "Room",
  },
  {
    referenceName: "Underground Conduit",
    referenceAbbreviation: "UGC",
    referenceType: "Room",
  },
  {
    referenceName: "Vending Room",
    referenceAbbreviation: "VEN",
    referenceType: "Room",
  },
  {
    referenceName: "Volleyball Court",
    referenceAbbreviation: "VBC",
    referenceType: "Room",
  },
  {
    referenceName: "Warehouse",
    referenceAbbreviation: "WAR",
    referenceType: "Room",
  },
  {
    referenceName: "Women's Locker Room",
    referenceAbbreviation: "WLR",
    referenceType: "Room",
  },
  {
    referenceName: "Women's Restroom",
    referenceAbbreviation: "WRR",
    referenceType: "Room",
  },
  {
    referenceName: "Work Room",
    referenceAbbreviation: "WRK",
    referenceType: "Room",
  },
] as const satisfies readonly ReferenceProps[];
