import { jsonToGraphQLQuery, VariableType } from "json-to-graphql-query";
import { gql } from "@apollo/client";

function checkFields(inputObject, inputObjects) {
  return inputObject.inputFields.map((inputField, index) => {
    if (inputField.type.kind === "INPUT_OBJECT") {
      return {
        ...inputField,
        fields: checkFields(inputObjects[inputField.type.name], inputObjects),
      };
    } else if (
      inputField.ofType &&
      inputField.type.kind === "NON_NULL" &&
      inputField.ofType.kind === "INPUT_OBJECT"
    ) {
      return {
        ...inputField,
        fields: checkFields(
          inputObjects[inputField.type.ofType.name],
          inputObjects
        ),
      };
    } else if (
      inputField.type.kind === "SCALAR" ||
      inputField.type.kind === "NON_NULL"
    )
      return inputField;
    else if (inputField.type.kind === "ENUM") {
      return { ...inputField };
    } else if (inputField.type.kind === "LIST") {
      return { ...inputField };
    } else {
      console.error("Unhandled Input Field Type: ", inputField.type.kind);
      return null;
    }
  });
}
export function getMutationInputs(mutation, schema) {
  let inputObjects = schema.inputObjects;
  let mutationInput;
  if (!mutation) {
    return [];
  }
  let mutationInputs = mutation.args.map((arg) => {
    mutationInput = {
      name: arg.name,
      description: arg.description,
      type: arg.type,
    };
    if (arg.type.kind === "INPUT_OBJECT") {
      mutationInput.fields = checkFields(
        inputObjects[arg.type.name],
        inputObjects
      );
    } else if (arg.type.ofType && arg.type.ofType.kind === "INPUT_OBJECT") {
      mutationInput.fields = checkFields(
        inputObjects[arg.type.ofType.name],
        inputObjects
      );
    }
    return mutationInput;
  });
  return mutationInputs;
}

function processReturnFields(
  fields,
  schema,
  parentObjectArray,
  capturedFields = {}
) {
  let returnObject = {};
  let returnName;
  let returnType;
  let object;
  fields.forEach((field) => {
    returnName = field.type.name;
    returnType = field.type.kind;
    switch (returnType) {
      case "OBJECT":
        if (parentObjectArray.includes(field.name)) return;
        parentObjectArray = [...parentObjectArray, field.name];
        object = schema.objects[returnName];
        capturedFields = { ...capturedFields, [field.name]: true };
        returnObject = {
          ...returnObject,
          [field.name]: processReturnFields(
            object.fields,
            schema,
            parentObjectArray,
            capturedFields
          ),
        };
        break;
      case "SCALAR":
        returnObject = { ...returnObject, [field.name]: true };
        break;
      case "NON_NULL":
        returnObject = { ...returnObject, [field.name]: true };
        break;
      case "ENUM":
        returnObject = { ...returnObject, [field.name]: true };
        break;
      case "LIST":
        if (parentObjectArray.includes(field.name)) return;
        parentObjectArray = [...parentObjectArray, field.name];
        if (
          field.type.ofType &&
          field.type.ofType.kind === "OBJECT" &&
          !capturedFields[field.name]
        ) {
          capturedFields = { ...capturedFields, [field.name]: true };
          returnObject = {
            ...returnObject,
            [field.name]: processReturnFields(
              schema.objects[field.type.ofType.name].fields,
              schema,
              parentObjectArray,
              capturedFields
            ),
          };
        } else if (capturedFields[field.name])
          returnObject = { ...returnObject, [field.name]: false };
        else returnObject = { ...returnObject, [field.name]: true };
        break;
      default:
        console.log("Error Unaccounted Return Type", returnType);
    }
  });
  return returnObject;
}

export function getMutationReturn(mutation, schema) {
  if (!mutation) {
    return {};
  }
  const returnName = mutation.type.name;
  const returnType = mutation.type.kind;
  let object;
  let string;
  let returnObject = {};
  switch (returnType) {
    case "OBJECT":
      object = schema.objects[returnName];
      returnObject = {
        ...returnObject,
        ...processReturnFields(object.fields, schema, [returnName]),
      };
      break;
    case "SCALAR":
      returnObject = { ...returnObject, [mutation.name]: true };
      break;
    case "NON_NULL":
      returnObject = { ...returnObject, [mutation.ofType.name]: true };
      break;
    case "LIST":
      string = '[' + mutation.type.ofType.name + ']'
      returnObject = {
        ...returnObject,
        [string]: true
      }
      break;
    default:
      console.log("Error Unaccounted Return Type", returnType);
  }
  return returnObject;
}

// Desired Format:
// {
//   email: "String!",
//   password: "String!",
// }
function generateVariable(input) {
  let kind = input.type.kind;
  let nullable = !(kind === "NON_NULL");
  let variable;
  if (nullable && kind === "SCALAR")
    variable = { [input.name]: `${input.type.name}` };
  else if (
    input.type.ofType &&
    !nullable &&
    input.type.ofType.kind === "SCALAR"
  )
    variable = { [input.name]: `${input.type.ofType.name}!` };
  else if (
    input.type.ofType &&
    !nullable &&
    input.type.ofType.kind === "ENUM"
  ) {
    variable = { [input.name]: `${input.type.ofType.name}!` };
  }
  else if (nullable && kind === "ENUM")
    variable = { [input.name]: `${input.type.name}` };
  else if (nullable && kind === "BOOLEAN")
    variable = { [input.name]: `${input.type.name}!` };
  else if (nullable && kind === "LIST") {
    variable = { [input.name]: `[${input.type.ofType.name}!]` };
  }
  else if (input.type.ofType && !nullable && input.type.ofType.kind === "LIST") {
    variable = { [input.name]: `[${input.type.ofType.ofType.name}]!` };
  }
  else console.error("Unhandled Mutation Variable Generation: ", kind);
  return variable;
}

function generateVariables(inputs) {
  let variables = {};
  inputs.forEach((input) => {
    if (!!input && input.fields) {
      variables = { ...variables, ...generateVariables(input.fields) };
    } else if (input) {
      variables = { ...variables, ...generateVariable(input) };
    }
  });
  return variables;
}



// {
//   user: {
//     email: new VariableType("email"),
//     password: new VariableType("password"),
//   },
// }
function generateArg(name) {
  return { [name]: new VariableType(`${name}`) };
}
function generateArgs(inputs) {
  let args = {};
  inputs.forEach((input) => {
    if (!!input && input.fields) {
      args = { ...args, [input.name]: { ...generateArgs(input.fields) } };
    } else if (input) {
      args = { ...args, ...generateArg(input.name) };
    }
  });
  return args;
}
export function generateMutation(
  mutationInputs,
  mutationReturn,
  mutationString
) {
  // const variables = generateVariables(mutationInputs);
  // const args = generateArgs(mutationInputs);
  const variablesDeclaration = Object.entries(generateVariables(mutationInputs))
    .map(([name, type]) => `$${name}: ${type}`)
    .join(", ");
    const mutationBody = jsonToGraphQLQuery({
      [mutationString]: {
        __args: generateArgs(mutationInputs),
        ...mutationReturn,
      },
    }, { pretty: true });
  // Wrap the generated mutation body with the operation name and variables declaration
  const fullMutation = `mutation ${mutationString}(${variablesDeclaration}) { ${mutationBody} }`;

  return gql`${fullMutation}`;
}
