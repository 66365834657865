// material
import { Box, Button, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
// ----------------------------------------------------------------------
const GET_REPORT = gql`
query inspectionAssignmentReport($id: ID!) {
    inspectionAssignmentReport(id: $id){
        id
    }
}
`;

export default function Reporting() {
    
    const [getReport] = useLazyQuery(GET_REPORT, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
        }
      });
    const handleGetReport = () => getReport({ variables: { id: "123" }})
    return (
        <Page title="Reporting | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Reporting</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button onClick={handleGetReport}>Get Report</Button>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
