import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
// material
import {
  Link,
  Box,
  Grid,
  Container,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { createQuery } from "../../../utils/gql-mui-rhf/QueryAction";
import { TextField, Switch, DatePicker } from "../../../utils/mui-rhf";
import { FormProvider, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import Checkmark from "@mui/icons-material/TaskAlt";
import SaveIcon from "@mui/icons-material/Save";
import Block from "@mui/icons-material/Block";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { GQLMutationAction } from "../../../utils/gql-mui-rhf/SingleMutation";
import { GQLSyncMutationAction } from "../../../utils/gql-mui-rhf/SyncMutation";
import { useNavigate } from "react-router-dom";
// import _ from 'lodash';
// components
import Page from "../../../components/Page";
import FileUpload from "../RoleRequest/FileUpload";
// ----------------------------------------------------------------------
function createDraftRoleValue(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "createDraftRoleValue",
    onCompleted: (response) => {
      refetch();
      snackbar("Saved Field", "success");
    },
  });
}
function approveOrRejectValue(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "updateDraftRoleValue",
    onCompleted: (response) => {
      refetch();
      snackbar("Field Approved", "success");
    },
  });
}
function approveRolesRequest(refetch, snackbar) {
  return GQLSyncMutationAction({
    mutationString: "approveRolesRequest",
    onCompleted: (response) => {
      refetch();
      snackbar("Submitted Request Information", "success");
    },
  });
}
function createPerson() {
  return GQLSyncMutationAction({
    mutationString: "createPerson",
    onCompleted: (response) => {
      return response.createPerson;
    },
    onError: (response) => {
      debugger;
    },
  });
}
function createUser() {
  return GQLSyncMutationAction({
    mutationString: "signup",
    onCompleted: (response) => {
      return response.signup;
    },
    onError: (response) => {},
  });
}
function createFirm() {
  return GQLSyncMutationAction({
    mutationString: "createFirm",
    onCompleted: (response) => {
      return response.createFirm;
    },
  });
}
function createFirmUnit() {
  return GQLSyncMutationAction({
    mutationString: "createFirmUnit",
    onCompleted: (response) => {
      return response.createFirmUnit;
    },
  });
}
function createFirmPerson() {
  return GQLSyncMutationAction({
    mutationString: "createFirmPerson",
    onCompleted: (response) => {
      return response.createFirmPerson;
    },
  });
}
function sendTemporaryPassword() {
  return GQLSyncMutationAction({
    mutationString: "createEmail",
    onCompleted: (response) => {
      return response.createEmail;
    },
  });
}
function createFirmUnitPerson() {
  return GQLSyncMutationAction({
    mutationString: "createFirmUnitPerson",
    onCompleted: (response) => {
      return response.createFirmUnitPerson;
    },
  });
}
function createProjectPerson() {
  return GQLSyncMutationAction({
    mutationString: "createProjectPerson",
    onCompleted: (response) => {
      return response.createProjectPerson;
    },
  });
}
function createProjectFirmUnit() {
  return GQLSyncMutationAction({
    mutationString: "createProjectFirmUnit",
    onCompleted: (response) => {
      return response.createProjectFirmUnit;
    },
  });
}
function rejectRolesRequest(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "rejectRolesRequest",
    onCompleted: (response) => {
      refetch();
      snackbar("Submitted Request Information", "success");
    },
  });
}
const renderField = (
  roleTypeValue,
  activeField,
  fieldStatus,
  setValue,
  defaultValues
) => {
  if(!roleTypeValue) {
    return null
  }
  const disabled =
    roleTypeValue.id !== activeField || fieldStatus === "submitted";
  console.log(roleTypeValue.name);
  console.log(defaultValues[roleTypeValue.name]);
  switch (roleTypeValue.type) {
    case "BOOLEAN":
      return (
        <FormGroup>
          <FormControlLabel
            name={roleTypeValue.name}
            disabled={disabled}
            control={<Switch defaultChecked />}
            label={
              roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name
            }
          />
        </FormGroup>
      );
    case "IMAGE":
      return (
        <FileUpload
          disabled={disabled}
          roleTypeValue={roleTypeValue}
          setValue={setValue}
          defaultValues={defaultValues}
        />
      );
    case "PDF":
      return (
        <FileUpload
          disabled={disabled}
          roleTypeValue={roleTypeValue}
          setValue={setValue}
          defaultValues={defaultValues}
        />
      );
    case "TEXT":
      return (
        <TextField
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    case "INTEGER":
      return (
        <TextField
          type="number"
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    case "DECIMAL":
      return (
        <TextField
          type="number"
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    case "DATE":
      return (
        <DatePicker
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
    default:
      return (
        <TextField
          disabled={disabled}
          variant={disabled ? "filled" : "outlined"}
          id="outlined-basic"
          name={roleTypeValue.name}
          label={roleTypeValue.label ? roleTypeValue.label : roleTypeValue.name}
          placeholder={roleTypeValue.placeholder}
        />
      );
  }
};
const getMutationVariables = (roleTypeValue, data) => {
  switch (roleTypeValue.type) {
    case "BOOLEAN":
      return {
        booleanValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "TEXT":
      return {
        stringValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "INTEGER":
      return {
        integerValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "DECIMAL":
      return {
        decimalValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
    case "DATE":
      return { dateValue: data[roleTypeValue.name], type: roleTypeValue.type };
    case "IMAGE":
      return { dateValue: data[roleTypeValue.name], type: roleTypeValue.type };
    case "PDF":
      return { dateValue: data[roleTypeValue.name], type: roleTypeValue.type };
    default:
      return {
        stringValue: data[roleTypeValue.name],
        type: roleTypeValue.type,
      };
  }
};
const assessValue = (value) => {
  switch (value.roleTypeValue.type) {
    case "BOOLEAN":
      return value.booleanValue;
    case "TEXT":
      return value.stringValue;
    case "IMAGE":
      return value.stringValue;
    case "PDF":
      return value.stringValue;
    case "INTEGER":
      return value.integerValue;
    case "DECIMAL":
      return value.decimalValue;
    case "DATE":
      return value.dateValue;
    default:
      return value.stringValue;
  }
};
const getDefaultValues = (draftRoleValues) => {
  const defaultValues = {};
  draftRoleValues.forEach((value) => {
    if (value.roleTypeValue.active) {
      defaultValues[value.roleTypeValue.name] = assessValue(value);
    }
  });
  return defaultValues;
};
const draftRoleValuesObject = (draftRoleValues) => {
  const defaultValues = {};
  draftRoleValues.forEach((value) => {
    if (value.roleTypeValue.active) {
      defaultValues[value.roleTypeValue.name] = {
        id: value.id,
        value: assessValue(value),
        status: value.status,
      };
    }
  });
  return defaultValues;
};
const ReviewDocuments = ({ filteredValues, draftRoleValues }) => {
  return (
    <Grid container direction={"column"}>
      {filteredValues.map((filteredValue) => {
        const value = draftRoleValues[filteredValue.name]
          ? draftRoleValues[filteredValue.name]
          : {};
        if (
          !!value.value &&
          (filteredValue.type === "IMAGE" || filteredValue.type === "PDF")
        ) {
          return (
            <Grid item>
              <Typography variant="h7">{`${filteredValue.name}: `}</Typography>
              <Link target="_blank" rel="noreferrer" href={value.value}>
                Open
              </Link>
            </Grid>
          );
        } else if (
          !value.value &&
          (filteredValue.type === "IMAGE" || filteredValue.type === "PDF")
        ) {
          return (
            <Grid item>
              <Typography variant="h7">{`${filteredValue.name}: `}</Typography>
              <Typography variant="h7">Not Uploaded</Typography>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};
const FieldGroup = ({
  defaultValues,
  value,
  fieldStatus,
  roleTypeValue,
  draftRole,
  activeField,
  setActiveField,
  addDraftRoleValue,
}) => {
  const methods = useForm({ defaultValues });
  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods, methods.reset]);
  const { handleSubmit, setValue } = methods;
  const onSubmit = (data) => {
    const variables = getMutationVariables(roleTypeValue, data);
    addDraftRoleValue({
      ...variables,
      type: roleTypeValue.type,
      roleTypeValueId: roleTypeValue.id,
      draftRoleId: draftRole.id,
    });
  };
  if(!roleTypeValue){
    return null
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          key={roleTypeValue.id}
          spacing={2}
          container
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Grid item xs={4}>
            {renderField(
              roleTypeValue,
              activeField,
              fieldStatus,
              setValue,
              defaultValues
            )}
          </Grid>
          <Grid item xs={1}>
            <Button
              disabled={
                value.status === "APPROVED" || value.status === "REJECTED"
              }
              onClick={() => {
                setActiveField(roleTypeValue.id);
              }}
              variant="outlined"
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              disabled={
                value.status === "APPROVED" || value.status === "REJECTED"
              }
              type={"submit"}
              variant="outlined"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
const getValueStatus = (filteredValues, draftRoleValues) => {
  let valueStatus = { approved: [], rejected: [], submitted: [], all: [] };
  let status;
  filteredValues.forEach((value) => {
    if (!draftRoleValues[value.name]) {
      return;
    }
    status = draftRoleValues[value.name].status;
    valueStatus.all.push(value);
    switch (status) {
      case "APPROVED":
        valueStatus.approved.push(value);
        break;
      case "REJECTED":
        valueStatus.rejected.push(value);
        break;
      case "SUBMITTED":
        valueStatus.submitted.push(value);
        break;
      default:
        break;
    }
  });
  return valueStatus;
};
const processRoles = (draftRoles) => {
  return draftRoles.map((draftRole) => {
    return {
      ...draftRole,
      draftRoleValues:
        draftRole.draftRoleValues &&
        draftRole.draftRoleValues.filter(
          (value) => value.roleTypeValue.active === true
        ),
      roleType: {
        ...draftRole.roleType,
        roleTypeValues:
          draftRole.roleType &&
          draftRole.roleType.roleTypeValues &&
          draftRole.roleType.roleTypeValues.filter(
            (value) => value.active === true
          ),
      },
    };
  });
};
const generatePassword = () => {
  let length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const processApproveRolesRequest = async (
  rolesRequest,
  approvalMethods,
  handleCreateUser
) => {
  let entity = rolesRequest.entity;
  let firmId = rolesRequest.projectFirmId;
  let firmUnitId = rolesRequest.projectFirmUnitId;
  let firm = null;
  let firmUnit = null;
  if (entity === "FIRM") {
    // Create Firm
    
    firm = await approvalMethods.createFirm({ name: rolesRequest.name });
    
    firmUnit = await approvalMethods.createFirmUnit({
      name: rolesRequest.name,
      firmId: firm.data.createFirm.id,
    });
    
    await approvalMethods.createProjectFirmUnit({
      projectId: rolesRequest.projectId,
      firmUnitId: firmUnit.data.createFirmUnit.id,
    });
    
    firm = firm.data.createFirm;
    firmUnit = firmUnit.data.createFirmUnit;
    firmId = firm.id;
    firmUnitId = firmUnit.id;
  }

  // Send temporary password
  let password = generatePassword();
  // TO-DO remove before production
  // password = "Carolina777!";
  let email = rolesRequest.email.toLowerCase();
  // Create User
  let user = await handleCreateUser({ email, password });
  user = user.data.signup.user;
  // Create Person
  let person = await approvalMethods.createPerson({
    email,
    firstName: rolesRequest.firstName,
    lastName: rolesRequest.lastName,
    userId: user.id,
    firmId,
  });
  person = person.data.createPerson;
  // Create Project Person
  let projectPerson = await approvalMethods.createProjectPerson({
    personId: person.id,
    projectId: rolesRequest.projectId,
  });
  // Create Firm Person
  let firmPerson = await approvalMethods.createFirmPerson({
    firmId: firmId,
    personId: person.id,
  });
  let firmUnitPerson = await approvalMethods.createFirmUnitPerson({
    firmUnitId: firmUnitId,
    personId: person.id,
  });
  let approveRolesRequest = await approvalMethods.approveRolesRequest({
    firmId,
    personId: person.id,
    id: rolesRequest.id,
  });
  if (
    user &&
    person &&
    projectPerson &&
    firmPerson &&
    firmUnitPerson &&
    approveRolesRequest
  ) {
    approvalMethods.fullyApproved();
  }
  // Send Email with temporary password
  let emailSent = await approvalMethods.sendTemporaryPassword({
    templateId: "d-6bf594f3b39f44378288c6d254c81d6e",
    to: email,
    data: JSON.stringify({ password }),
  });
  return {
    projectPerson,
    firmPerson,
    firmUnitPerson,
    approveRolesRequest,
    emailSent,
  };
};
export default function RoleRequestReview() {
  let navigate = useNavigate();
  const fullyApproved = () => navigate(`/app/project/people`);

  const params = useParams();
  const rolesRequestId = params.id;
  const { loading, error, data, refetch } = createQuery("rolesRequest", {
    id: rolesRequestId,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [activeField, setActiveField] = useState(null);
  const [activeRoleIndex, setActiveRoleIndex] = useState(0);
  const [activeValueIndex, setActiveValueIndex] = useState(0);
  let filteredValues = [];
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const addDraftRoleValue = createDraftRoleValue(refetch, snackbar);
  const handleCreateUser = createUser();
  const handleCreateFirm = createFirm();
  const handleCreateFirmUnit = createFirmUnit();
  const handleCreateProjectFirmUnit = createProjectFirmUnit();
  const handleCreatePerson = createPerson();
  const handleCreateProjectPerson = createProjectPerson();
  const handleCreateFirmPerson = createFirmPerson();
  const handleSendTemporaryPassword = sendTemporaryPassword();
  const handleCreateFirmUnitPerson = createFirmUnitPerson();
  const handleApproveRolesRequest = approveRolesRequest(refetch, snackbar);
  const approvalMethods = {
    createUser: handleCreateUser,
    createFirm: handleCreateFirm,
    createFirmUnit: handleCreateFirmUnit,
    createProjectFirmUnit: handleCreateProjectFirmUnit,
    createPerson: handleCreatePerson,
    createProjectPerson: handleCreateProjectPerson,
    approveRolesRequest: handleApproveRolesRequest,
    createFirmPerson: handleCreateFirmPerson,
    createFirmUnitPerson: handleCreateFirmUnitPerson,
    sendTemporaryPassword: handleSendTemporaryPassword,
    fullyApproved,
  };
  const handleRejectRolesRequest = rejectRolesRequest(refetch, snackbar);
  const changeValueStatus = approveOrRejectValue(refetch, snackbar);
  if (loading) return <CircularProgress />;
  if (error) {
    debugger;
    return <div>{`${error}`}</div>;
  }
  if (data) {
    const draftRoles = processRoles(data.rolesRequest.draftRoles);
    const draftRole = draftRoles[activeRoleIndex];
    const rolesLength = draftRoles.length;
    const draftRoleType = draftRole.roleType;
    const draftRoleTypeValues = draftRoleType.roleTypeValues;
    filteredValues = draftRoleTypeValues ? [...draftRoleTypeValues] : [];
    if (!filteredValues) {
      refetch();
    }
    const order = draftRole.roleType.valueOrder;
    if (order) {
      const arrayMap = filteredValues.reduce(
        (accumulator, currentValue) => ({
          ...accumulator,
          [currentValue.id]: currentValue,
        }),
        {}
      );
      let orderedRows = order.map((key) => arrayMap[key]);
      let unorderedRows = filteredValues.filter(
        (item) => !order.includes(item.id)
      );
      filteredValues = [...orderedRows, ...unorderedRows];
    }
    filteredValues = filteredValues.filter((n) => n !== undefined);
    const defaultValues =
      draftRole.draftRoleValues && getDefaultValues(draftRole.draftRoleValues);
    const draftRoleValues =
      draftRole.draftRoleValues &&
      draftRoleValuesObject(draftRole.draftRoleValues);
    const roleTypeValue =
      draftRoleTypeValues && filteredValues[activeValueIndex];
    const valueStatus = getValueStatus(filteredValues, draftRoleValues);
    let value = {};
    if (filteredValues && activeValueIndex in filteredValues) {
      const name = filteredValues[activeValueIndex].name;
      if (name in draftRoleValues) {
        value = draftRoleValues[name];
      }
    }
    const valuesLength = filteredValues && filteredValues.length;
    if (!activeRoleIndex && activeRoleIndex !== 0) {
      debugger;
    }
    return (
      <Page title="Onboarding | FINBACK670">
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Review Form</Typography>
          </Box>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Box sx={{ pb: 1 }}>
                <Typography variant="h6">{`Reference Documents for ${draftRole.roleType.name}`}</Typography>
              </Box>
            </Grid>
            <Grid item>
              {draftRoleTypeValues && (
                <ReviewDocuments
                  filteredValues={filteredValues}
                  defaultValues={defaultValues}
                  draftRoleValues={draftRoleValues}
                  value={value}
                  draftRole={draftRole}
                  roleTypeValue={roleTypeValue}
                  activeField={activeField}
                />
              )}
            </Grid>
            <Grid item>
              <Box sx={{ pb: 1 }}>
                <Typography variant="h6">{`Review field for ${draftRole.roleType.name}`}</Typography>
              </Box>
            </Grid>
            <Grid item>
              {draftRoleTypeValues && (
                <FieldGroup
                  defaultValues={defaultValues}
                  draftRoleValues={draftRoleValues}
                  value={value}
                  draftRole={draftRole}
                  roleTypeValue={roleTypeValue}
                  activeField={activeField}
                  setActiveField={setActiveField}
                  addDraftRoleValue={addDraftRoleValue}
                />
              )}
            </Grid>
            {(value.status === "APPROVED" || value.status === "REJECTED") && (
              <Grid item>
                <Box sx={{ pb: 1 }}>
                  <Typography variant="h6">{`Field is ${value.status.toLowerCase()}.`}</Typography>
                </Box>
              </Grid>
            )}
            <Grid item container spacing={2} direction="row">
              <Grid item>
                <Button
                  disabled={
                    value.status === "APPROVED" ||
                    value.status === "REJECTED" ||
                    !value.id
                  }
                  onClick={() => {
                    changeValueStatus({
                      id: value.id,
                      type: value.type,
                      status: "APPROVED",
                    });
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<Checkmark />}
                >
                  Accept Field
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={
                    value.status === "APPROVED" ||
                    value.status === "REJECTED" ||
                    !value.id
                  }
                  onClick={() => {
                    changeValueStatus({
                      id: value.id,
                      type: value.type,
                      status: "REJECTED",
                    });
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<Block />}
                >
                  Reject Field
                </Button>
              </Grid>
            </Grid>
            <Grid item container spacing={2} direction="row">
              <Grid item>
                <Button
                  disabled={activeRoleIndex === 0 && activeValueIndex === 0}
                  onClick={() => {
                    if (activeValueIndex > 0) {
                      let newActiveValueIndex = activeValueIndex - 1;
                      setActiveValueIndex(newActiveValueIndex);
                    } else if (activeValueIndex === 0 && activeRoleIndex > 0) {
                      let newActiveRoleIndex = activeRoleIndex - 1;
                      let newActiveValueIndex =
                        draftRoles[newActiveRoleIndex].roleType.roleTypeValues
                          .length - 1;
                      setActiveValueIndex(newActiveValueIndex);
                      setActiveRoleIndex(newActiveRoleIndex);
                    }
                  }}
                  color="info"
                  variant="contained"
                  startIcon={<KeyboardDoubleArrowLeftIcon />}
                >
                  Previous Field
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    if (activeValueIndex < valuesLength - 1) {
                      let newActiveValueIndex = activeValueIndex + 1;
                      setActiveValueIndex(newActiveValueIndex);
                    } else if (
                      activeValueIndex === valuesLength - 1 &&
                      activeRoleIndex < rolesLength - 1
                    ) {
                      let newActiveRoleIndex = activeRoleIndex + 1;
                      setActiveValueIndex(0);
                      setActiveRoleIndex(newActiveRoleIndex);
                    }
                  }}
                  disabled={
                    activeRoleIndex === rolesLength - 1 &&
                    activeValueIndex === valuesLength - 1
                  }
                  color="info"
                  variant="contained"
                  endIcon={<KeyboardDoubleArrowRightIcon />}
                >
                  Next Field
                </Button>
              </Grid>
            </Grid>
            {/* <Grid item>
              <FormGroup>
                <FormControlLabel name={"Show Previously Reviewed Fields"} control={<Checkbox />} label={"Show Previously Reviewed Fields"} />
              </FormGroup>
            </Grid> */}
            <Grid item>
              <Typography variant="h6">{`${valueStatus.submitted.length} item(s) pending review`}</Typography>
              <Typography variant="h6">{`${valueStatus.approved.length} item(s) approved`}</Typography>
              <Typography variant="h6">{`${valueStatus.rejected.length} item(s) rejected`}</Typography>
            </Grid>
            <Grid item container spacing={2} direction="row">
              <Grid item>
                <Button
                  disabled={
                    valueStatus.all.length !== valueStatus.approved.length
                  }
                  onClick={() => {
                    processApproveRolesRequest(
                      data.rolesRequest,
                      approvalMethods,
                      handleCreateUser
                    );
                  }}
                  color="primary"
                  variant="contained"
                  startIcon={<Checkmark />}
                >
                  Submit Completed Review
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={data.rolesRequest.status === "submitted"}
                  onClick={() => {
                    handleRejectRolesRequest({ id: rolesRequestId });
                  }}
                  color="error"
                  variant="contained"
                  startIcon={<Block />}
                >
                  Permanently Reject
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}
