import { useState, useEffect } from "react";
// material
import {
  Box,
  Grid,
  Container,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
// components
import Page from "../../../components/Page";
import { GQLForm } from "../../../utils/gql-mui-rhf/FormProvider";
import { Checkbox } from "../../../utils/mui-rhf";
import { useContext } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import { PersonContext } from "../../../utils/contexts/person";
import FirmSelector from "./firmSelectors";

// ----------------------------------------------------------------------
const GET_PROJECT_ROLES = gql`
query projectRoles($projectId: ID) {
  projectRoles(projectId: $projectId) {
    project {
      id
      name
    }
    role {
      id
      name
      faRole
    }
  }}
`;

export default function OnboardingUser() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [projectRoles, setProjectRoles] = useState([]);
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  let personContext = useContext(PersonContext);
  const { person } = personContext;

  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [getProjectRoles] = useLazyQuery(GET_PROJECT_ROLES, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setProjectRoles(response.projectRoles);
    }
  });

  useEffect(() => {
    if (project?.id) {
      getProjectRoles({
        variables: {
          projectId: project.id,
        },
      });
    }
  }, [project, getProjectRoles]);

  if (!project) return <div>Select Project to Continue</div>;
    const options = projectRoles
      .filter((item) => !item.role.faRole)
      .map((projectRole) => ({
        value: projectRole.role.id,
        name: projectRole.role.name,
        label: projectRole.role.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <Page title="Onboarding | FINBACK670">
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Onboard New Personnel</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <GQLForm
                mutationString={"createRolesRequest"}
                additionalVariables={{
                  entity: "PERSON",
                  projectId: project.id,
                }}
                skipFields={{
                  projectFirmId: true,
                  projectFirmUnitId: true,
                  projectId: true,
                  name: true,
                  entity: true,
                  id: true,
                  roleTypes: true,
                  faRole: true,
                  backgroundCheckSsn: true,
                }}
                defaultValues={{ roleTypes: [] }}
                inputOrder={{
                  "First Name": { order: 1 },
                  "Last Name": { order: 2 },
                }}
                onError={(error) => {}}
                onCompleted={(response) => {
                  snackbar("Onboarding Request Submitted", "success");
                  if (response.createRolesRequest)
                    navigate(
                      `/app/team/onboarding/request/${response.createRolesRequest.id}`
                    );
                }}
              >
                <FirmSelector project={project} person={person} />
                <Grid item xs={12} style={{ marginBottom: 32 }}>
                  <Checkbox
                    rules={{ required: "select one or many" }}
                    description={"Roles"}
                    fieldName={"roleTypes"}
                    options={options}
                  />
                </Grid>
              </GQLForm>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
}
