import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { GQLForm } from "src/utils/gql-mui-rhf/FormProvider";
import { AuthContext } from "src/utils/contexts/auth";
import { PersonContext } from "src/utils/contexts/person";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import jwt_decode from "jwt-decode";

const GET_PERSON = gql`
query Person($person: InputPersonParams) {
  person(person: $person) {
    id
    avatar
    email
    firmId
    firstName
    lastName
    mailingAddressId
    phone1
    phone2
    preferences
    userId
    homepage
  }}
`;

// ----------------------------------------------------------------------

export default function LoginForm(params) {
  const [showLogin, shouldShowLogin] = useState(true);
  let navigate = useNavigate();
  let auth = useContext(AuthContext);
  let { setPerson } = useContext(PersonContext);
  let link = params.from ? params.from : "/app";
  const [getPerson] = useLazyQuery(GET_PERSON, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setPerson(response.person);
      if(response.person.homepage){
        link = response.person.homepage;
      }
      debugger;
      navigate(link);
    }
  });

  return (
    <Grid container spacing={3} width={1000}>
      <Grid item xs={12} sm={6} md={3} width={500}>
        <GQLForm
          mutationString="signin"
          onErrorMessage="Invalid email or password"
          onCompleted={(response) => {
            if (response.signin) {
              debugger;
              auth.signin(response.signin);
              getPerson({ variables: { person:{ userId: response.signin.user.id} } });
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
