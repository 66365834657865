import deepEquality from "src/utils/deepEquality";
import { Action, Form, PageState } from "../../components/TypeDefinitions";

import { v4 as uuidv4 } from "uuid";

export function addGrouping<
  E extends Extract<
    Action,
    {
      type: "addGrouping";
    }
  >
>(action: E, draft: PageState): void {
  const { parentFormId } = action.payload;

  const form = draft.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === parentFormId
  );

  if (!form) return;
  let childToAssign: Form | undefined;
  switch (action.payload.type) {
    case "assignExistingFormToGroup": {
      let existingChildId = action.payload.childFormId;
      let foundChild =
        draft.data.inspectionTypeTemplate.configuration.forms.find(
          (f) => f.id === existingChildId
        );
      if (deepEquality(foundChild, form)) {
        childToAssign = foundChild;
      }
      break;
    }
    case "createNewChildForm": {
      childToAssign = Object.assign({}, form);
      childToAssign.id = uuidv4();
      draft.data.inspectionTypeTemplate.configuration.forms.push(childToAssign);
    }
  }
  if (!childToAssign) return;
  childToAssign.groupMemberOf = form.id;
  if (form.groupParentTo === undefined) {
    form.groupParentTo = [childToAssign.id];
  } else {
    form.groupParentTo.push(childToAssign.id);
  }
}
