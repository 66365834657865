import { useEffect, useLayoutEffect, useRef, useState } from "react";

/**
 * Custom React Hook for watching state<S> and firing off a function/promise which takes that state as its argument after a debounce period has elapsed.
 * Requires stateToWatch, debounceTimeoutMS, and handleSync to function.
 * */
export default function useDebounce<T, F>({
  stateToWatch,
  debounceTimeoutMS,
  callback,
  shouldDebounceRun,
  disabled,
}: {
  /** The state which will be watched for changes and passed into the handleSync function after debounce period. */
  stateToWatch: T;
  /** The time (in milliseconds) to wait before executing the callback function. */
  debounceTimeoutMS: number;
  /** The function which gets called after the debounce timeout by taking the watched state as an argument. */
  callback: (state?: T) => Promise<F> | F;
  /** Function run on the state to determine if the debounce function should run.  */
  shouldDebounceRun?: (state?: T) => boolean;
  /** Manually disable debounce and callback from running. Defaults to false. */
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState(false);

  const syncDisabledUntilChanges = useRef(true);
  const initialLoad = useRef(true);
  const initialValue = useRef<T>();
  useLayoutEffect(() => {
    if (disabled === true) {
      return;
    }
    if (shouldDebounceRun) {
      if (!shouldDebounceRun(stateToWatch)) return;
    } else {
      if (initialLoad.current) {
        initialValue.current = stateToWatch;
        initialLoad.current = false;
        return;
      }
      if (syncDisabledUntilChanges.current) {
        if (
          JSON.stringify(initialValue.current) !== JSON.stringify(stateToWatch)
        ) {
          syncDisabledUntilChanges.current = false;
        } else {
          return;
        }
      }
    }

    setLoading(true);
  }, [setLoading, stateToWatch, disabled, shouldDebounceRun]);

  const executeAfterDelay = async () => {
    await callback(stateToWatch);
    setLoading(false);
  };
  useEffect(() => {
    if (!loading) return;
    let timer = setTimeout(executeAfterDelay, debounceTimeoutMS);
    return () => clearTimeout(timer);
  });

  return loading;
}
