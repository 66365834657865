import { Action, PageState } from "../../components/TypeDefinitions";

export function updateSubformEmbedded<
  E extends Extract<
    Action,
    {
      type: "updateSubformEmbedded";
    }
  >
>(action: E, draft: PageState) {
  const { subformId, isEmbeddedValue } = action.payload;

  draft.data.inspectionTypeTemplate.configuration.forms.every((form) => {
    if (form.id === subformId) {
      form.isEmbedded = isEmbeddedValue;
      return false;
    }
    return true;
  });
}
