import * as React from "react";

import { useParams } from "react-router-dom";
//gql-mui-rhf
import { createQuery } from "../../utils/gql-mui-rhf/QueryAction";
import RoleTypeView from "./RoleTypeView";
import RoleTypeCredential from "./RoleTypeCredential";
import RoleTypeCredentialViews from "./RoleTypeCredentialViews";
// material
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../../components/Page";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const breadcrumbs = [
  <Link to="/app/admin/roles/">Roles List</Link>,
  <Typography key="3" color="text.primary">
    Role View
  </Typography>,
];

export default function RoleView() {
  const params = useParams();
  const roleId = params.id;
  const [value, setValue] = React.useState(0);
  const [refetchRoleCredentials, setRefetchRoleCredentials] =
    React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { loading, error, data } = createQuery("roleType", { id: roleId });
  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    return (
      <Page title="Dashboard | FINBACK670">
        <Container maxWidth="xl" style={{ paddingBottom: "64pt" }}>
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Role Management</Typography>
          </Box>
          <Box sx={{ pb: 5 }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Role Attributes" {...a11yProps(0)} />
              <Tab label="Role Credentials" {...a11yProps(1)} />
              <Tab label="Role Permisions" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ pb: 2 }}>
              <Typography variant="h5">{`Role Attributes: ${data.roleType.name}`}</Typography>
            </Box>
            <RoleTypeView roleId={roleId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ pb: 2 }}>
              <Typography variant="h5">{`Role Credentials: ${data.roleType.name}`}</Typography>
            </Box>
            <RoleTypeCredential
              roleType={data.roleType}
              shouldRefetch={() => {
                setRefetchRoleCredentials(true);
              }}
            />
            <RoleTypeCredentialViews
              roleTypeId={data.roleType.id}
              refetchRoleCredentials={refetchRoleCredentials}
              shouldRefetch={() => {
                setRefetchRoleCredentials(false);
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box sx={{ pb: 2 }}>
              <Typography variant="h5">{`Role Permissions: ${data.roleType.name}`}</Typography>
            </Box>
          </TabPanel>
        </Container>
      </Page>
    );
  }
}
