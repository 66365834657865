
const processScalar = (type) => {
    return type
}
const processEnum = (type) => {
    return type
}
const processObject = (type) => {
    return type
}
const processInputObject = (type) => {
    return type
}
const processMutations = (mutations) => {
    let processedMutations = {};
    mutations.fields.forEach((mutation) => processedMutations = { ...processedMutations, [mutation.name]: mutation })
    return processedMutations
}
const processQueries = (queries) => {
    let processedQueries = {};
    queries.fields.forEach((query) => processedQueries = { ...processedQueries, [query.name]: query })
    return processedQueries
}


const ProcessSchema = (data) => {
    if(!data){return null}
    console.log("Begin Schema Processing")
    let schema = data.__schema;
    let processedSchema = { schema };
    let types = schema.types;
    let mutations = {};
    let queries = {};
    let inputObjects = {};
    let scalars = {};
    let objects = {};
    let enums = {};
    // Process High Level Mutations, Queries, and Input Objects
    // Process Mutations to generate their specific set of Input Objects, Fields, and Input Values
    types.forEach((type) => {
        switch (type.kind) {
            case 'SCALAR':
                switch (type.name) {
                    // Case fall through all of the default graphql definitions
                    case 'Boolean':
                    case 'ID':
                    case 'String':
                        break;
                    default:
                        scalars = { ...scalars, [type.name]: processScalar(type) }
                }
                break;
            case 'ENUM':
                enums = {...enums, [type.name]: processEnum(type)}
                break;
            case 'INPUT_OBJECT':
                inputObjects = { ...inputObjects, [type.name]: processInputObject(type) }
                break;
            case 'OBJECT':
                switch (type.name) {
                    // Case fall through all of the default graphql definitions
                    case '__Schema':
                    case '__Type':
                    case '__TypeKind':
                    case '__Field':
                    case '__InputValue':
                    case '__EnumValue':
                    case '__Directive':
                    case '__DirectiveLocation':
                        break;
                    case 'Mutation':
                
                        mutations = { ...mutations, ...processMutations(type) }
                        break;
                    case 'Query':
                
                        queries = { ...queries, ...processQueries(type) }
                        break;
                    default:
                        objects = { ...objects, [type.name]: processObject(type) }
                }
                break;
            default:
                console.error("Uncaptured Type: ", type.kind)
        }
    })
    processedSchema = {
        schema,
        scalars,
        mutations,
        enums,
        inputObjects,
        objects,
        queries
    }
    return processedSchema
}

export default ProcessSchema;

