import {
  IFormField,
  IFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import {
  FieldProperties,
  FormInstanceMap,
  FormMapWithOrder,
  getField,
  recurseSubformInstances,
} from "..";
import {
  Configuration,
  Field,
} from "src/pages/Inspections/FormBuilder/components/TypeDefinitions";

export default function processFormJsonReturnStatus(
  formJson: IFormJson,
  formMap: FormMapWithOrder,
  fieldProperties: FieldProperties,
  configuration: Configuration
): "delete" | "changeOccurred" | "noChange" {
  const formData = formMap.formMap[formJson.formId];
  if (!formData) {
    return "delete";
  }

  let updatedAField = false;

  let displayIndex: {
    [displayIndex: number]: {
      indexInFormJson: number;
    };
  } = {};

  formJson.fields.forEach((formField, i) => {
    if (formField.isDeleted) return;
    const field =
      formData.form.fields[formData.fieldsIndexMap[formField.formFieldId]];
    if (!field) {
      updatedAField = true;
      return;
    }

    if (field.displayIndex !== i) updatedAField = true;
    displayIndex[field.displayIndex] = { indexInFormJson: i };
    const propertiesToSync = ["displayIndex", "name", "label"] as const;

    propertiesToSync.forEach((property) => {
      if (formField[property] !== field[property]) {
        // @ts-ignore
        formField[property] = field[property];
        updatedAField = true;
      }
    });

    if (
      formField.formInput.formValue &&
      (formField.inputType === "OptionSelect" ||
        formField.inputType === "DropDown") &&
      (field.inputType === "OptionSelect" || field.inputType === "DropDown")
    ) {
      if (
        formField.formInput.formValue!.textValue &&
        !field.possibleValues
          .map((pv) => pv.label)
          .includes(formField.formInput.formValue!.textValue)
      ) {
        formField.formInput.formValue.textValue = null;
        updatedAField = true;
      }
    }
  });

  // Add back in missing fields
  let missingDisplayIndexes: number[] = [];

  formData.form.fields.forEach((field, i) => {
    if (displayIndex[i] === undefined) {
      missingDisplayIndexes.push(i);
    }
  });
  const date = new Date();
  const now = date.toISOString();
  missingDisplayIndexes.forEach((i) => {
    // if (i === 0) debugger;
    formJson.fields.push(
      getField(fieldProperties, now, formData.form.fields[i])
    );
    displayIndex[i] = { indexInFormJson: formJson.fields.length - 1 };
    updatedAField = true;
  });

  // Re-order fields
  let fieldsWithCorrectDisplayOrdering: IFormField[] = [];

  formData.form.fields.forEach((field, i) => {
    if (!displayIndex[i]) debugger;
    const formFieldToPush = formJson.fields[displayIndex[i].indexInFormJson];
    fieldsWithCorrectDisplayOrdering.push(formFieldToPush);
  });

  formJson.fields = fieldsWithCorrectDisplayOrdering;
  let subformMap: {
    [id: string]: number;
  } = {};
  formData.form.subformIds.forEach((subformId, subformIndex) => {
    subformMap[subformId] = subformIndex;
  });

  let reOrderedSubformArray = Array<IFormJson>(formData.form.subformIds.length);

  // Process each subform formJson
  let deleteSubformAtIndex: number[] = [];
  let madeChangesToSubforms = false;
  formJson.subFormInstances.forEach((subformInstance, subformIndex) => {
    const intendedIndex = subformMap[subformInstance.formId];

    // If this subform isn't in the subform array defined in the template, do nothing. This prevents the stale form from being persisted in the formJson.
    if (intendedIndex === undefined) {
      madeChangesToSubforms = true;
      return;
    }

    // Change detected in form ordering
    if (subformIndex !== intendedIndex) madeChangesToSubforms = true;
    let processingResult = processFormJsonReturnStatus(
      subformInstance,
      formMap,
      fieldProperties,
      configuration
    );

    // Store the now-processed form
    reOrderedSubformArray[intendedIndex] = subformInstance;
    if (processingResult === "delete") {
      madeChangesToSubforms = true;
      // TODO
    }
    if (processingResult === "changeOccurred") madeChangesToSubforms = true;
  });

  if (formData.form.subformIds.length > formJson.subFormInstances.length) {
    formData.form.subformIds.forEach((id, index) => {
      if (!reOrderedSubformArray[index]) {
        madeChangesToSubforms = true;
        reOrderedSubformArray[index] = recurseSubformInstances({
          configuration,
          parentFormId: formData.form.id,
          formsMap: formMap,
          inspectionAssignmentId: fieldProperties.inspectionAssignmentId,
          inspectionTypeTemplateId: fieldProperties.inspectionTemplateId,
          inspectionId: fieldProperties.inspectionId,
          parentFormInstanceId: formJson.id,
          firmId: fieldProperties.firmId,
          personId: fieldProperties.personId,
        })[index];
      }
    });
  }

  if (madeChangesToSubforms) {
    formJson.subFormInstances = reOrderedSubformArray;
  }
  return deleteSubformAtIndex.length || updatedAField || madeChangesToSubforms
    ? "changeOccurred"
    : "noChange";
}
