import { PageState, Action } from "../../components/TypeDefinitions";

export function moveSubform<
  E extends Extract<
    Action,
    {
      type: "moveSubform";
    }
  >
>(action: E, draft: PageState): void {
  const { newIndex, initialIndex } = action.payload;
  const formIdToUpdate = action.payload.parentFormId
    ? action.payload.parentFormId
    : draft.formId;

  const form = draft.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === formIdToUpdate
  );

  if (!form) return;

  const subformIdCopy = form.subformIds.splice(initialIndex, 1)[0];

  form.subformIds.splice(newIndex, 0, subformIdCopy);
}
