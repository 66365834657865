import { Route, Routes } from "react-router-dom";
import CreateInspectionType from "./CreateInspectionType";
import InspectionTypesTable from "./InspectionTypesTable";
import InspectionTypePage from "./InspectionTypePage";
import FormBuilder from "./FormBuilder";

export default function Inspections() {
  return (
    <>
      <Routes>
        <Route path="/" element={<InspectionTypesTable />} />
        <Route
          path="/:inspectionTypeId/:inspectionTypeTemplateId/*"
          element={<FormBuilder />}
        />
        <Route path="/:inspectionTypeId" element={<InspectionTypePage />} />;
        <Route path="/create" element={<CreateInspectionType />} />
      </Routes>
    </>
  );
}
