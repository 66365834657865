// material
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { Container } from "@mui/material";
// ----------------------------------------------------------------------
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";

const PEOPLE_QUERY = gql`
  query PersonsByIds($ids: [ID]) {
    personsByIds(ids: $ids) {
      id
      lastName
      firstName
    }
  }
`;

export default function InspectionStatusLogs({ inspectionStatuses }) {
  const peopleIds = inspectionStatuses.map((status) => status.createdPersonId);
  const { data } = useQuery(PEOPLE_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      ids: peopleIds,
    },
  });

  return (
    <>
      {data && (
        <Container maxWidth="xl">
          <div style={{ height: 600, width: "100%", paddingTop: 50 }}>
            <h2>Status Logs</h2>
            <DataGridPremium
              getRowHeight={() => "auto"}
              rows={inspectionStatuses}
              columns={[
                { editable: false, field: "id", headerName: "Id", width: 400 },
                {
                  editable: false,
                  field: "status",
                  headerName: "Status",
                  width: 200,
                },
                {
                  editable: false,
                  field: "statusDate",
                  headerName: "Timestamp",
                  width: 200,
                  valueFormatter: (params) =>
                    moment(params?.value).format("YYYY/MM/DD hh:mm:ss A"),
                },
                {
                  editable: false,
                  field: "createdPersonId",
                  headerName: "Created By",
                  valueFormatter: (params) => {
                    const person = data.personsByIds.find(
                      (person) => person.id === params.value
                    );
                    if (!person) return "";
                    return person.firstName + " " + person.lastName;
                  },
                },
              ]}
              columnVisibilityModel={{
                id: false,
                inspectionId: false,
                personId: false,
                inspectionAssignmentId: false,
                masterFormInstanceId: false,
                formInstanceId: false,
                formInstanceTree: false,
                formValueId: false,
                projectId: false,
                projectLocationId: false,
                previousValue: false,
                currentValue: false,
                lat: false,
                long: false,
                deviceId: false,
                objId: false,
                objType: false,
              }}
              pageSize={5}
              pageSizeOptions={[10, 25, 50]}
              slots={{ toolbar: GridToolbar }}
              slotProps={{ toolbar: { excelOptions: { allColumns: true } } }}
            />
          </div>
        </Container>
      )}
    </>
  );
}
