import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  Icon,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { FC, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PersonContext } from "src/utils/contexts/person";
import { fDateTimeSuffix } from "src/utils/formatTime";
import { ProjectContext } from "src/utils/contexts/project";
import { Add, Clear, Delete, MoreHoriz } from "@mui/icons-material";
import WashoutCommentDialog, {
  WashoutCommentDialogConfiguration,
} from "./components/WashoutCommentDialog";
import { format } from "date-fns";
import { formatDate } from "@fullcalendar/core";
import WashoutNote from "./components/WashoutNote";

const WASHOUTS_QUERY = gql`
  query Washouts($washout: InputWashoutParams!) {
    washouts(washout: $washout) {
      id
      assignedPerson {
        id
      }
      currentStatus
      description
      isDeleted
      referenceId
      referenceType
      reportedDate
      reportingPersonId
      title
      inspectionId
      inspectionAssignmentId
      washoutNotes {
        note
        id
        insertedAt
        createdPerson {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
const DELETE_WASHOUT = gql`
  mutation DeleteWashout($deleteWashoutId: ID!) {
    deleteWashout(id: $deleteWashoutId) {
      id
    }
  }
`;

type CreateWashout =
  | {
      type: "closed";
    }
  | {
      type: "open";
      description: string;
      title: string;
      assignedPersonId: string;
      currentStatus: string;
      isDeleted: boolean;
      reportedDate: string;
      inspectionId: string;
      inspectionAssignmentId: string;
      referenceId: string;
      referenceType: string;
      reportingPersonId: string;
    };

export default function Washouts({
  inspectionId,
  controlWashout,
}: {
  inspectionId: string;
  controlWashout?: {
    filterByRefenceId: string;
    referenceType: string;
    clearFilter: () => void;
    updateWashouts: () => void;
  };
}) {
  // const { inspection } = useContext<any>(InspectionCtx);
  const { person } = useContext<any>(PersonContext);
  const [searchParams] = useSearchParams();
  const assignmentId = searchParams.get("assignment");
  const [createWashout, setCreateWashout] = useState<CreateWashout>({
    type: "closed",
  });
  const { data, error, refetch } = useQuery(WASHOUTS_QUERY, {
    variables: { washout: { inspectionAssignmentId: assignmentId } },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const refetchWashouts = () => {
    refetch({
      variables: { washout: { inspectionAssignmentId: assignmentId } },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });
    if (controlWashout) {
      controlWashout.updateWashouts();
    }
  };

  const [createMutation] = useMutation(CREATE_WASHOUT, {
    onCompleted: () => refetchWashouts(),
  });

  async function handleCreate() {
    const newWashoutObj: any = createWashout;
    delete newWashoutObj.type;
    const date = new Date(Date.now()).toISOString();
    newWashoutObj.reportedDate = date;
    createMutation({ variables: { washout: { ...createWashout } } }).then(
      (res) => refetch()
    );
  }

  if (createWashout.type === "open") {
    console.log(person);
  }

  const getDisplayedWashouts = (data: any) => {
    return data.washouts.filter((washout: any) => {
      const included = controlWashout
        ? washout.referenceId === controlWashout.filterByRefenceId
        : true;
      return included;
    });
  };
  return (
    <>
      {error && <p>{error.message}</p>}
      {data && (
        <>
          <Stack direction="column">
            <Button
              endIcon={<Add />}
              variant="contained"
              sx={{
                height: "3rem",
                borderRadius: "8",
                margin: "15px",
                width: "100px",
              }}
              onClick={(e) => {
                setCreateWashout({
                  type: "open",
                  title: "",
                  description: "",
                  assignedPersonId: "",
                  isDeleted: false,
                  reportedDate: "",
                  referenceId: controlWashout
                    ? controlWashout.filterByRefenceId
                    : assignmentId || "",
                  referenceType: controlWashout
                    ? controlWashout.referenceType
                    : "inspectionAssignment",
                  reportingPersonId: person.id || "",
                  currentStatus: "open",
                  inspectionId: inspectionId,
                  inspectionAssignmentId: assignmentId || "",
                });
              }}
            >
              Add
            </Button>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Typography>
                {getDisplayedWashouts(data).length || "No"} washouts
                {!!controlWashout?.filterByRefenceId
                  ? " for selected field."
                  : "."}
              </Typography>
              {controlWashout && (
                <Button
                  endIcon={<Clear />}
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={controlWashout.clearFilter}
                >
                  Remove Filter
                </Button>
              )}
            </Stack>
            <Box sx={{ overflowY: "scroll" }}>
              {getDisplayedWashouts(data).map((washout: any, i: number) => (
                <Washout
                  even={i % 2 === 0}
                  washout={washout}
                  refetch={refetchWashouts}
                />
              ))}
            </Box>
          </Stack>
          <Dialog
            open={createWashout.type === "open"}
            onClose={(e) => setCreateWashout({ type: "closed" })}
            fullWidth
          >
            <DialogTitle>Create New Washout</DialogTitle>
            <DialogContent>
              <Stack
                width="100%"
                direction="column"
                spacing={2}
                paddingY="1rem"
              >
                <PersonSelector
                  assignedTo={
                    createWashout.type === "open"
                      ? createWashout.assignedPersonId
                      : ""
                  }
                  onChange={(personId) => {
                    const date = new Date();
                    const dateString = date.toISOString();
                    createWashout.type === "open" &&
                      setCreateWashout({
                        ...createWashout,
                        assignedPersonId: personId,
                        reportedDate: dateString,
                      });
                  }}
                />
                <TextField
                  fullWidth
                  label="Title"
                  multiline
                  value={
                    createWashout.type === "open" ? createWashout.title : ""
                  }
                  onChange={(e) =>
                    createWashout.type === "open" &&
                    setCreateWashout({
                      ...createWashout,
                      title: e.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  label="Description"
                  onChange={(e) =>
                    createWashout.type === "open" &&
                    setCreateWashout({
                      ...createWashout,
                      description: e.target.value,
                    })
                  }
                  value={
                    createWashout.type === "open"
                      ? createWashout.description
                      : ""
                  }
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => handleCreate()}>Create</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

const CREATE_WASHOUT = gql`
  mutation CreateWashout($washout: InputWashoutParams) {
    createWashout(washout: $washout) {
      id
      assignedPerson {
        id
        firstName
        lastName
      }
      currentStatus
      description
      isDeleted
      referenceId
      referenceType
      reportedDate
      reportingPersonId
      title
      inspectionId
      inspectionAssignmentId
    }
  }
`;

const UPDATE_WASHOUT = gql`
  mutation UpdateWashout($washout: InputWashoutParams) {
    updateWashout(washout: $washout) {
      id
      assignedPerson {
        id
      }
      currentStatus
      description
      isDeleted
      referenceId
      referenceType
      reportedDate
      reportingPersonId
      title
      inspectionId
      inspectionAssignmentId
    }
  }
`;

const Washout: FC<{
  even: boolean;
  washout: any;
  refetch: () => void;
}> = ({ even, washout, refetch }) => {
  const [washoutNoteState, setWashoutNoteState] =
    useState<WashoutCommentDialogConfiguration>({ state: "closed" });
  const { person }: any = useContext(PersonContext);
  const [updateWashout, { data: updateData }] = useMutation(UPDATE_WASHOUT);
  const { id, assignedPerson, description, title } =
    updateData?.data?.updateWashout || washout;
  const assignedPersonId = assignedPerson?.id;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteWashout] = useMutation(DELETE_WASHOUT);

  const handleDelete = async () => {
    await deleteWashout({ variables: { deleteWashoutId: washout.id } });
    refetch();
    handleClose();
  };
  return (
    <div
      style={
        even
          ? {
              backgroundColor: "whitesmoke",
              padding: "0.5rem",
              borderBottom: "solid  1px lightgrey",
            }
          : { padding: "0.5rem", borderBottom: "solid  1px lightgrey" }
      }
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="0.5rem"
      >
        <PersonSelector
          assignedTo={assignedPersonId}
          onChange={(personId) =>
            updateWashout({
              variables: { washout: { id: id, assignedPersonId: personId } },
            })
          }
        />
        <div
          style={{
            backgroundColor:
              washout.currentStatus === "open" ? "pink" : "lightgreen",
            padding: "0 0.25rem",
            borderRadius: 5,
          }}
        >
          <Typography
            variant="subtitle2"
            color={
              washout.currentStatus === "open" ? "darkred" : "darkolivegreen"
            }
          >
            {washout.currentStatus}
          </Typography>
        </div>
      </Stack>
      <Typography fontWeight="bold">{title}</Typography>
      <Typography>{description}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">{`${format(
          new Date(washout.reportedDate),
          "MM/dd/yyyy - p"
        )}`}</Typography>
        <IconButton onClick={handleClick}>
          <MoreHoriz />
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
          <MenuItem
            onClick={(e) => {
              setWashoutNoteState({ state: "add" });
            }}
          >
            Add Note
          </MenuItem>
        </Menu>
      </Stack>
      {washout.washoutNotes !== undefined && washout.washoutNotes.length > 0 ? (
        washout.washoutNotes.map((note: any) => (
          <WashoutNote note={note} person={person} refetch={refetch} />
        ))
      ) : (
        <></>
      )}
      <WashoutCommentDialog
        configuration={washoutNoteState}
        handleClose={() => {
          setWashoutNoteState({ state: "closed" });
          setAnchorEl(null);
        }}
        washoutId={washout.id}
        refetch={refetch}
      />
    </div>
  );
};

const PersonSelector: FC<{
  assignedTo: string;
  onChange: (personId: string) => any;
}> = ({ assignedTo, onChange }) => {
  const [projectPersonsQuery, { data }] = useLazyQuery(GET_PERSONS);
  const [personsByIdsQuery, { data: persons }] = useLazyQuery(PERSONS_BY_IDS);
  const project: any = useContext(ProjectContext);

  useEffect(() => {
    if (project) {
      projectPersonsQuery({
        variables: {
          projectId: project.project.id,
        },
      });
    }
  }, [project, projectPersonsQuery]);

  useEffect(() => {
    if (data && data.projectPersons.length > 0) {
      personsByIdsQuery({
        variables: {
          ids: data.projectPersons.map((pp: any) => pp.id),
        },
      });
    }
  }, [data, personsByIdsQuery]);
  return (
    <>
      {data && persons && (
        <Select
          onChange={(e) => onChange(e.target.value as string)}
          size="small"
          value={assignedTo}
          sx={{ borderRadius: 100, width: "13rem" }}
          renderValue={(value) => {
            const selectedPerson = persons.personsByIds.find(
              (person: any) => person.id === value
            );
            if (!selectedPerson) {
              return null;
            }
            return (
              <ListItemText
                primary={`${selectedPerson.firstName} ${selectedPerson.lastName}`}
              />
            );
          }}
        >
          {persons.personsByIds.map((person: any, i: number) => (
            <MenuItem value={person.id}>
              <ListItemText
                primary={`${person.firstName} ${person.lastName}`}
              />
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

const GET_PERSONS = gql`
  query ProjectPersons($projectId: ID) {
    projectPersons(projectId: $projectId) {
      personId
    }
  }
`;

const PERSONS_BY_IDS = gql`
  query PersonsByIds($ids: [ID]) {
    personsByIds(ids: $ids) {
      id
      firstName
      lastName
    }
  }
`;
