import { CheckCircle, ExpandMore } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { FormCtx } from "./BuilderWrapper";
export const SyncHandler: FC<{
  children: JSX.Element | JSX.Element[];
  syncing: boolean;
  errors: ErrorLink[];
}> = ({ children, syncing, errors }) => {
  const color = syncing ? "orange" : errors.length > 0 ? "red" : "green";

  return (
    <>
      <Box
        position="fixed"
        top={100}
        right={100}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        border={`1px dashed ${color}`}
        borderRadius={1}
        paddingX={1}
        zIndex={9999}
      >
        <Typography variant="h5" color={color} marginRight={1}>
          {syncing
            ? "Syncing"
            : errors.length > 0
            ? `Synced Blocked By ${
                errors.length > 1 ? errors.length + " Errors" : "Error"
              }`
            : "Synced"}
        </Typography>
        {syncing ? (
          <CircularProgress size={14} sx={{ color: "orange" }} />
        ) : errors.length === 0 ? (
          <CheckCircle fontSize="small" htmlColor={color} />
        ) : (
          <ErrorDisplayMenuButton color="red" errors={errors} />
        )}
      </Box>
      {children}
    </>
  );
};

const ErrorDisplayMenuButton: FC<{ color: string; errors: ErrorLink[] }> = ({
  color,
  errors,
}) => {
  const { dispatch } = useContext(FormCtx);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (fieldId: string) => {
    // TODO: open link to relevant validation
    dispatch({
      type: "setEditor",
      payload: {
        fieldId: fieldId,
      },
    });
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label="view-errors"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
      >
        <ExpandMore htmlColor={color} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={(e) => setAnchorEl(null)}>
        {errors.map((error) => (
          <MenuItem
            key={error.errorMessage + "-" + error.fieldId}
            onClick={(e) => handleSelect(error.fieldId)}
          >
            {error.errorMessage}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface ErrorLink {
  fieldId: string;
  errorMessage: string;
}
