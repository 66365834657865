import { AttachFile } from "@mui/icons-material";
import { Button, CircularProgress, Stack, styled } from "@mui/material";
import React, { ChangeEvent, FC, useEffect } from "react";

export const FileField: FC<{
  file: File | null;
  loading: boolean;
  disabled: boolean;
  acceptableMIMEType?: string;
  setFile: (blob: File) => void;
}> = ({ file, setFile, loading, disabled, acceptableMIMEType }) => {
  const handleSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    let fileInput = event.target.files ? event.target.files[0] : null;
    if (fileInput === null) return;
    setFile(fileInput);
  };

  return (
    <Stack direction="column" height={"100%"} spacing={2}>
      <CustomButton
        sx={{ alignSelf: "center" }}
        endIcon={loading ? <CircularProgress size={12} /> : <AttachFile />}
        variant="outlined"
        component="label"
        disabled={disabled}
      >
        Upload
        <input
          type="file"
          accept={acceptableMIMEType}
          hidden
          onChange={handleSelect}
        />
      </CustomButton>
      {file && <FileDisplay file={file} />}
    </Stack>
  );
};

type ExtraProps = {
  component: React.ElementType;
};
const CustomButton = styled(Button)<ExtraProps>({});

const FileDisplay: FC<{ file: File }> = ({ file }) => {
  const url = URL.createObjectURL(file);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(url);
    };
  });

  if (file.type.includes("image")) {
    return <img src={url} alt={"uploaded"} />;
  } else if (file.type.includes("pdf")) {
    return <iframe src={url} title="document" />;
  } else {
    return <p>{file.name}</p>;
  }
};
