import * as React from 'react';
// material
import { Container} from '@mui/material';
// components
import Page from '../../components/Page';
import PeopleList from './list';

// ----------------------------------------------------------------------


export default function PeopleView() {
    

    return (
        <Page title="Dashboard | FINBACK670">
            <Container maxWidth="xl" style={{ paddingBottom: '64pt' }}>
                {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="People" {...a11yProps(0)} />
                        <Tab label="Delete Person" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}> */}
                    <PeopleList />
                {/* </TabPanel>
                <TabPanel value={value} index={1}>
                    <GQLForm mutationString='deletePerson' />
                </TabPanel> */}
            </Container>
        </Page >
    );
}
