import { gql, useLazyQuery } from "@apollo/client";
import { Edit } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React, { useContext, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { PersonContext } from "src/utils/contexts/person";
import { InspectionWebView } from "./routes/InspectionWebView";
import { WebViewWrapper } from "./routes/InspectionWebView/wrapper";
import { ProjectContext } from "src/utils/contexts/project";

export const MyInspections = () => {
  return (
    <Routes>
      <Route path="/" element={<MyInspectionsTable />} />
      <Route
        path="/:assignmentId"
        element={
          <WebViewWrapper>
            <InspectionWebView />
          </WebViewWrapper>
        }
      />
    </Routes>
  );
};

const MY_INSPECTIONS = gql`
  query InspectionAssignments(
    $inspectionAssignment: InputInspectionAssignmentParams!
  ) {
    inspectionAssignments(inspectionAssignment: $inspectionAssignment) {
      id
      personId
      firmPersonId
      inspection {
        id
        number
        location {
          id
          city
          description
          mapboxId
          name
          owner
          parentId
          state
          street1
          street2
          street3
          tenant
          zip
          identifier
          lis
          unit
          buildingType
          projectId
          lat
          lng
          neighborhood
        }
        inspectionType {
          name
        }
      }
      inspectionId
      start
      end
      formChangesAcknowledged
      frontElevationPhotoCaptured
      isCheckedIn
      notesAcknowledged
      eSignatureAcknowledged
      rightOfEntryCaptured
      onsiteComplete
      inaccessible
      stopWorkHazardPresent
      status
      requiresDrawing
      isDeleted
      isMobile
      isWeb
    }
  }
`;

const MyInspectionsTable = () => {
  const person: any = useContext(PersonContext);
  const project: any = useContext(ProjectContext);
  const [inspectionsQuery, { data, loading }] = useLazyQuery(MY_INSPECTIONS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (person.person && project.project) {
      inspectionsQuery({
        variables: {
          inspectionAssignment: {
            personId: person.person.id,
            projectId: project.project.id,
            isWeb: true,
          },
        },
      });
    }
  }, [person, project, inspectionsQuery]);

  return (
    <>
      <Typography variant="h3">My Inspections</Typography>
      {loading && <CircularProgress />}
      {data && (
        <DataGridPremium
          rows={data.inspectionAssignments}
          columns={[
            {
              field: "edit",
              headerName: "",
              width: 10,
              renderCell: (params: any) => {
                return (
                  <Link to={`${params.row.id}`}>
                    <Edit />
                  </Link>
                );
              },
            },
            {
              // field: "number",
              field: "control_number",
              headerName: "Control Number",
              width: 200,
              valueGetter: ({ row }) => {
                let res = row?.inspection?.number;
                if (!res) return null;
                return res;
              },
              groupingValueGetter: ({ row }) => {
                let res = row?.inspection?.number;
                if (!res) return null;
                return res;
              },
            },
            {
              type: "string",
              field: "inspection",
              headerName: "Address",
              valueGetter: addressValueGetter,
              groupingValueGetter: addressValueGetter,
            },
            {
              field: "start",
              headerName: "Start Date",
              width: 120,
              type: "date",
              valueGetter: ({ value }) => value && new Date(value),
            },
            {
              field: "end",
              headerName: "End Date",
              type: "date",
              valueGetter: ({ value }) => value && new Date(value),
              width: 120,
            },
            {
              width: 300,
              field: "type",
              headerName: "Inspection Type",
              valueGetter: ({ row }) => {
                let res = row?.inspection?.inspectionType?.name;
                if (!res) return null;
                return res;
              },
            },
          ]}
        />
      )}
    </>
  );
};

const addressValueGetter = ({ row }: { row: any }) => {
  const value = row?.inspection?.location;
  if (!value) return null;
  const res =
    (value.street1 ? value.street1 : "") +
    (value.unit ? " " + value.unit : "") +
    (value.street2 ? " " + value.street2 : "") +
    (value.street3 ? " " + value.street3 : "") +
    ", " +
    value.city +
    ", " +
    value.state +
    ", " +
    value.zip;
  return res;
};
