import { Configuration } from "../pages/Inspections/FormBuilder/components/TypeDefinitions";
import {
  IFormField,
  IFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import { exhaustiveGuard } from "./exhaustiveGuard";

type ParseObject = {
  dictionaryObject: Exclude<DictionaryContents, { enumerable: "groupMember" }>;
  formJson: IFormJson;
  excelObject: any;
  enumerableCount?: number;
  offset?: number;
  remaps?: {
    [key: string]: string;
  };
};

export function excelParse({
  dictionaryObject,
  formJson,
  excelObject,
  enumerableCount,
  offset,
  remaps,
}: ParseObject) {
  remaps = { ...remaps, ...dictionaryObject.remaps };
  // Handle fields
  let nameSpaceLocation: string | null = null;
  switch (dictionaryObject.enumerable) {
    case false:
    case "byOffset":
      if (
        dictionaryObject.fields &&
        dictionaryObject.fields["use-nameSpace"] !== undefined
      ) {
        nameSpaceLocation = dictionaryObject.fields["use-nameSpace"];
      }
      break;
    case "specifiedLocation": {
      const fields =
        dictionaryObject.enumerations[enumerableCount as number].fields;
      if (fields === null) return;
      if (fields["use-nameSpace"] !== undefined)
        nameSpaceLocation = fields["use-nameSpace"];
    }
  }

  if (nameSpaceLocation)
    setValue(
      excelObject,
      nameSpaceLocation,
      formJson.name.trim(),
      offset,
      remaps
    );

  formJson.fields.forEach((field) => {
    let excelMapping: string | null = null;
    switch (dictionaryObject.enumerable) {
      case false:
      case "byOffset": {
        if (dictionaryObject.fields === null) return;
        excelMapping = dictionaryObject.fields[field.formFieldId];
        break;
      }
      case "specifiedLocation":
        {
          const fields =
            dictionaryObject.enumerations[enumerableCount as number].fields;
          if (fields === null) return;
          excelMapping = fields[field.formFieldId];
        }
        break;
      default:
        exhaustiveGuard(dictionaryObject);
    }
    if (fieldIsValid(field) && excelMapping) {
      let fieldValue = extractValue(field);
      let valueToSet = fieldValue;
      if (fieldValue !== null)
        setValue(excelObject, excelMapping, valueToSet, offset, remaps);
    }
  });
  // Handle subforms
  if (formJson.subFormInstances && formJson.subFormInstances.length) {
    let enumerationLog: EnumerationLog = {};
    formJson.subFormInstances.forEach((subformInstance) => {
      if (!subformInstance.isVisible || subformInstance.isDeleted) return;

      const subDictionaryConfig = getSubdictionary(
        subformInstance.formId,
        dictionaryObject,
        enumerableCount
      );
      if (subDictionaryConfig === null) return;

      const { subDictionary, formId } = subDictionaryConfig;

      let newOffset = offset || 0;

      if (subDictionary !== null) {
        if (subDictionary.enumerable !== false) {
          if (enumerationLog[formId] === undefined) enumerationLog[formId] = -1;
          enumerationLog[formId]++;
        }

        if (subDictionary.enumerable === "byOffset") {
          newOffset =
            newOffset +
            (enumerationLog[formId] > 0
              ? subDictionary.offsetLocations[enumerationLog[formId] - 1] +
                (offset !== undefined ? offset : 0)
              : 0);
        }

        excelParse({
          dictionaryObject: subDictionary,
          formJson: subformInstance,
          excelObject,
          offset: newOffset,
          remaps: remaps,
          enumerableCount: subDictionary.enumerable
            ? enumerationLog[subformInstance.formId]
            : undefined,
        });
      }
    });
  }
}

function fieldIsValid(field: IFormField) {
  return field.isVisible && !field.isDeleted;
}

function extractValue(field: IFormField) {
  const formValue = field.formInput.formValue;
  const fieldType = field.formInput.formValue?.valueType || null;
  let returnValue = null;
  if (fieldType !== null && formValue !== undefined) {
    switch (fieldType) {
      case "InputDataType.STRING":
        returnValue = formValue.textValue;
        break;
      case "InputDataType.BOOL":
        returnValue = formValue.booleanValue;
        break;
      case "InputDataType.DATETIME":
        const value = formValue.beginDateValue;
        if (!value) return value;
        switch (field.inputType) {
          case "Date":
            returnValue = new Date(value).toLocaleDateString();
            break;
          case "DateTime":
            returnValue = new Date(value).toLocaleString();
            break;
          case "Time":
            returnValue = new Date(value).toLocaleTimeString("en-US");
        }
        break;
      case "InputDataType.NUMBER":
        return formValue.doubleValue ?? formValue.integerValue;
      default:
        
    }
    // switch (fieldType) {
    //   case 'InputDataType.STRING':
    //     {
    //       return formValue.textValue;
    //     }
    //   case 'InputDataType.DYNAMIC_LIST':
    //     {
    //       return formValue.textValues;
    //     }
    //   case 'InputDataType.BOOL':
    //     {
    //       return formValue.booleanValue;
    //     }
    //   case 'InputDataType.NUMBER':
    //     {
    //       return formValue.doubleValue ?? formValue.integerValue;
    //     }
    //   case 'InputDataType.DATETIME':
    //     {
    //       return formValue.beginDateValue;
    //     }
    //   case 'InputDataType.JSON':
    //     {
    //       return formValue.jsonValue;
    //     }
    //   case 'InputDataType.PHOTO_COLLECTION':
    //     {
    //       PhotoCollection? result = inputInstance.formValue!.photoCollection;
    //       if(result == null){
    //         try{
    //           result = await PhotoCollection.getLocalInstanceByReference(inputInstance.formValue!.id, Constants.thoFormValueEntityString);
    //         }
    //         catch(e){}
    //       }
    //       return result;
    //     }
    //   case 'InputDataType.SIGNATURE':
    //     {
    //       Signature? result = inputInstance.formValue!.signature;
    //       if(result == null){
    //         try{
    //           result = (await Signature.getLocalByReference(inputInstance.formValue!.id)).first;
    //         }
    //         catch(e){}
    //       }
    //       return result;
    //     }
    // }
  }
  return returnValue;
}

type EnumerationLog = {
  [key: string]: number;
};

export type MappingObject = { [key: string]: string };

export type DictionaryContents = (
  | {
      enumerable: "specifiedLocation";
      enumerations: {
        subforms: Dictionary | null;
        fields: MappingObject | null;
      }[];
    }
  | {
      enumerable: "byOffset";
      fields: MappingObject | null;
      subforms: Dictionary | null;
      offsetLocations: number[];
    }
  | {
      enumerable: false;
      subforms: Dictionary | null;
      fields: MappingObject | null;
    }
  | {
      enumerable: "groupMember";
      parent: string;
    }
) & {
  name: string;
  remaps?: {
    [key: string]: string;
  };
};

export type Dictionary = {
  [key: string]: DictionaryContents;
};

export function generateDictionary(
  config: Configuration,
  topLevelFormId?: string,
  forceType?: Exclude<DictionaryContents["enumerable"], "groupMember">
) {
  let dictionary: Dictionary = {};
  const form = config.forms.find((form) =>
    topLevelFormId ? form.id === topLevelFormId : form.type === "Questionnaire"
  );
  if (!form) return null;
  const enumerable: DictionaryContents["enumerable"] = forceType
    ? forceType
    : form.type === "RoomInspection"
    ? "byOffset"
    : false;
  let fieldsObject: { [key: string]: string } = {};
  if (form.fields)
    form.fields.forEach((field) => {
      fieldsObject[field.id] = "";
    });

  let fields = Object.keys(fieldsObject).length === 0 ? null : fieldsObject;
  let subforms: Dictionary = {};

  if (form.subformIds)
    form.subformIds.forEach((subformId) => {
      let subformDictionary = generateDictionary(config, subformId);
      if (subformDictionary) {
        subforms = { ...subforms, ...subformDictionary };
      }
    });
  const subformsToWrite = Object.keys(subforms).length ? subforms : null;
  let newContent: DictionaryContents | null = null;
  switch (enumerable) {
    case "byOffset":
      newContent = {
        enumerable: "byOffset",
        name: form.name,
        fields: fields,
        subforms: subformsToWrite,
        offsetLocations: [],
      };
      break;
    case "specifiedLocation":
      newContent = {
        enumerable: "specifiedLocation",
        name: form.name,
        enumerations: [
          {
            fields: fields,
            subforms: subforms,
          },
        ],
      };
      break;
    case false:
      newContent = {
        enumerable: false,
        name: form.name,
        fields: fields,
        subforms: subforms,
      };
      break;
    default:
      exhaustiveGuard(enumerable);
  }
  dictionary[form.id] = newContent;

  return dictionary;
}

function setValue(
  excelObject: any,
  excelMapping: string,
  fieldValue: any,
  withOffset?: number,
  remaps?: {
    [key: string]: string;
  }
) {
  let valueToSet = fieldValue;
  if (remaps) {
    if (typeof fieldValue === "string" && remaps[fieldValue] !== undefined) {
      valueToSet = remaps[fieldValue];
    } else if (fieldValue === true && remaps["bool-val-true"] !== undefined) {
      valueToSet = remaps["bool-val-true"];
    } else if (fieldValue === false && remaps["bool-val-false"] !== undefined) {
      valueToSet = remaps["bool-val-false"];
    }
  }
  if (!withOffset) {
    excelObject[excelMapping] = valueToSet;
  } else {
    const excelMappingWithOffset = integerToExcelColumn(
      excelColumnToInteger(excelMapping) + withOffset
    );
    excelObject[excelMappingWithOffset] = valueToSet;
  }
}

export function excelColumnToInteger(columnString: string) {
  for (var p = 0, n = 0; p < columnString.length; p++) {
    n = columnString[p].charCodeAt(0) - 64 + n * 26;
  }
  return n;
}

export function integerToExcelColumn(int: number) {
  let columnName = "";
  while (int > 0) {
    let rem = int % 26;
    if (rem === 0) {
      columnName += "Z";
      int = Math.floor(int / 26) - 1;
    } else {
      columnName += String.fromCharCode(rem - 1 + "A".charCodeAt(0));
      int = Math.floor(int / 26);
    }
  }
  return columnName.split("").reverse().join("");
}

export function getLowestLocation(obj: any) {
  let lowestKey: null | string = null;
  let lowestValue = Infinity;

  function traverse(obj: any) {
    if (obj.hasOwnProperty("fields")) {
      const fields = obj["fields"];
      for (let k in fields) {
        const excelMapping = fields[k];
        if (excelMapping) {
          const integerValue = excelColumnToInteger(excelMapping);
          if (integerValue < lowestValue) {
            lowestKey = k;
            lowestValue = integerValue;
          }
        }
      }
    }

    for (let k in obj) {
      if (typeof obj[k] === "object" && obj[k] !== null) {
        traverse(obj[k]);
      }
    }
  }

  traverse(obj);
  const returnedLowestValue = Number.isFinite(lowestValue) ? lowestValue : null;
  return { key: lowestKey, value: returnedLowestValue };
}

export function getHighestLocation(obj: any) {
  let highestKey: null | string = null;
  let highestValue = -Infinity;

  function traverse(obj: any) {
    if (obj.hasOwnProperty("fields")) {
      const fields = obj["fields"];
      for (let k in fields) {
        const excelMapping = fields[k];
        if (excelMapping) {
          const integerValue = excelColumnToInteger(excelMapping);
          if (integerValue > highestValue) {
            highestKey = k;
            highestValue = integerValue;
          }
        }
      }
    }

    for (let k in obj) {
      if (typeof obj[k] === "object" && obj[k] !== null) {
        traverse(obj[k]);
      }
    }
  }

  traverse(obj);
  const returnedhighestValue = Number.isFinite(highestValue)
    ? highestValue
    : null;
  return { key: highestKey, value: returnedhighestValue };
}

type GetSubDictionaryReturn = {
  subDictionary: Exclude<DictionaryContents, { enumerable: "groupMember" }>;
  formId: string;
} | null;

const getSubdictionary = (
  formId: string,
  parentDictionary: Exclude<DictionaryContents, { enumerable: "groupMember" }>,
  enumeration?: number
): GetSubDictionaryReturn => {
  let subDictionary: DictionaryContents | null = null;
  switch (parentDictionary.enumerable) {
    case "byOffset":
    case false:
      subDictionary = parentDictionary.subforms
        ? parentDictionary.subforms[formId] ?? null
        : null;
      break;
    case "specifiedLocation":
      subDictionary =
        parentDictionary.enumerations && enumeration !== undefined
          ? parentDictionary.enumerations![enumeration].subforms![formId] ??
            null
          : null;
      break;
    default:
      exhaustiveGuard(parentDictionary);
  }
  if (subDictionary !== null && subDictionary.enumerable === "groupMember") {
    return getSubdictionary(
      subDictionary.parent,
      parentDictionary,
      enumeration
    );
  } else {
    return subDictionary === null
      ? null
      : { subDictionary: subDictionary, formId: formId };
  }
};
