import { gql, useQuery } from "@apollo/client";
import { IFormInstance, IFormJson } from "./InspectionDisplay";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { decode } from "./InspectionDisplay/components/utilities/decode";
import { Configuration } from "src/pages/Inspections/FormBuilder/components/TypeDefinitions";

const QUERY = gql`
  query InspectionAssignment(
    $thoFormInstance: InputThoFormInstanceParams
    $inspectionType: InputInspectionTypeParams
  ) {
    thoFormInstances(thoFormInstance: $thoFormInstance) {
      id
      inspectionId
      inspectionAssignmentId
      formId
      formConfigurationId
      inspectionTypeTemplateId
      reference
      referenceType
      formJson
      name
      type
      isDeleted
    }
    inspectionType(inspectionType: $inspectionType) {
      inspectionTypeTemplates {
        id
        configuration
      }
    }
  }
`;
const ExpandedDisplayView = () => {
  const { assignmentId, inspectionTypeId } = useParams();
  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      thoFormInstance: {
        inspectionAssignmentId: assignmentId,
      },
      inspectionType: {
        id: inspectionTypeId,
      },
    },
  });

  return (
    <>
      {loading && <CircularProgress />}
      {data && (
        <FlatRenderAll
          formInstances={data.thoFormInstances}
          config={data.inspectionType.inspectionTypeTemplates[0].configuration}
        />
        // <InspectionDisplay
        //   expandedDisplay={true}
        //   thoFormInstances={data.thoFormInstances}
        //   config={data.inspectionType.inspectionTypeTemplates[0].configuration}
        // />
      )}
    </>
  );
};

const FlatRenderAll = ({
  formInstances,
  config,
}: {
  formInstances: IFormInstance[];
  config: Configuration;
}) => {
  return (
    <>
      {formInstances.map((instance) => (
        <FlatRenderFormInstance
          formJson={decode(instance.formJson)}
          config={config}
          level={0}
        />
      ))}
    </>
  );
};

const FlatRenderFormInstance = ({
  formJson,
  config,
  level,
}: {
  formJson: IFormJson;
  config: Configuration;
  level: number;
}) => {
  const colors = [
    "red",
    "green",
    "orange",
    "purple",
    "yellow",
    "blue",
    "violet",
  ];
  return (
    <>
      <h1
        style={{ fontSize: "1.25rem", fontWeight: "bold", marginLeft: "5px" }}
      >
        <>{"   " + formJson.name}</>
      </h1>
      {formJson.fields.map((field) => {
        if (!field.formInput.formValue) return null;
        const text = field.formInput.formValue.textValue;
        const texts = field.formInput.formValue.textValues;
        // const beginDate = field.formInput.formValue?.beginDateValue;
        // const endDate = field.formInput.formValue?.endDateValue;
        const integer = field.formInput.formValue.integerValue;
        const double = field.formInput.formValue.doubleValue;
        const bool = field.formInput.formValue.booleanValue;
        return (
          field.isVisible && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p>{field.label}</p>
              {text && <AnswerB innerText={text} />}
              {texts && <AnswerB innerText={texts.join(" - ")} />}
              {integer && <AnswerB innerText={integer.toString()} />}
              {double && <AnswerB innerText={double.toString()} />}
              {bool !== null && bool !== undefined && (
                <AnswerB innerText={bool ? "Yes" : "No"} />
              )}
            </div>
          )
        );
      })}
      {formJson.subFormInstances &&
        formJson.subFormInstances.map((subform) => (
          <div
            style={{
              marginLeft: "0.75rem",
              marginBottom: "0.25rem",
              padding: "0.25rem",
              borderLeft: `2px solid ${colors[level]}`,
            }}
          >
            <FlatRenderFormInstance
              formJson={subform}
              config={config}
              level={level + 1}
            />
          </div>
        ))}
    </>
  );
};

function AnswerB({ innerText }: { innerText: string }) {
  return <b style={{ marginLeft: "0.25rem" }}>{innerText}</b>;
}
export default ExpandedDisplayView;
