import { gql, useLazyQuery } from "@apollo/client";
import { AttachFile } from "@mui/icons-material";
import { Button, CircularProgress, Stack, styled } from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useState } from "react";

const QUERY_DOCUMENT = gql`
  query Documents($document: InputDocumentParams!) {
    documents(document: $document) {
      id
      title
      description
      documentBytes
      url
      fileExtension
      fileName
      isDeleted
      insertedAt
      updatedAt
    }
  }
`;

type ReferenceParams = {
  title: string;
  id: string;
  stringContents: string | null;
  documentId?: string | null;
  [x: string]: any;
} | null;

export const DocumentationHandler: FC<{
  title: string;
  setReference: (newData: ReferenceParams) => void;
  reference: ReferenceParams;
  file: File | null;
  setFile: (blob: File) => void;
}> = ({ title, file, setFile, reference }) => {
  const [getDocument, { data, loading }] = useLazyQuery(QUERY_DOCUMENT, {
    onCompleted: (data) => {
      console.log("RESPONSE DATA");
      console.log(data);
      setBase64ref(
        data.documents[0].fileExtension,
        data.documents[0].documentBytes
      );
    },
  });

  useEffect(() => {
    if (reference && reference.documentId) {
      getDocument({ variables: { document: { id: reference.documentId } } });
    }
  }, [reference, getDocument]);

  const handleSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    let fileInput = event.target.files ? event.target.files[0] : null;
    if (fileInput === null) return;
    setFile(fileInput);
  };

  async function setBase64ref(extension: string, base64: string) {
    const mimeType = extension.includes("pdf")
      ? "application/pdf"
      : "image/png";
    const byteString = atob(base64);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeType });
    const file = new File([blob], "filename", { type: mimeType });
    setFile(file);
    // const fetchString = `data:${mimeType};base64,${base64}`;
    // const base64response = await fetch(fetchString);
    // console.log(fetchString);
    // const res = await base64response.blob() as File;

    // setFile(res);
  }

  if (data && data.updateDocument) {
  }

  const disabled = !title || loading;
  return (
    <Stack direction="column" height={"100%"} spacing={2}>
      <CustomButton
        sx={{ alignSelf: "center" }}
        endIcon={loading ? <CircularProgress size={12} /> : <AttachFile />}
        variant="outlined"
        component="label"
        disabled={disabled}
      >
        Upload Document <input type="file" hidden onChange={handleSelect} />
      </CustomButton>
      {file && <FileDisplay file={file} />}
    </Stack>
  );
};

type ExtraProps = {
  component: React.ElementType;
};
const CustomButton = styled(Button)<ExtraProps>({});

const FileDisplay: FC<{ file: File }> = ({ file }) => {
  const [objectUrl, setObjectUrl] = useState("");

  useEffect(() => {
    const url = URL.createObjectURL(file);
    setObjectUrl(url);

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [file]);

  if (file.type.includes("image")) {
    return <img src={objectUrl} alt={"asdf"} />;
  }
  return <iframe src={objectUrl} title="document" />;
};
