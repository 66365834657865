import { Action, PageState } from "../../components/TypeDefinitions";

export function updateFormType<
  E extends Extract<
    Action,
    {
      type: "updateFormType";
    }
  >
>(action: E, draft: PageState): void {
  const { formType } = action.payload;

  let form = draft.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === draft.formId
  );
  let formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  if (!form) return;

  if (form.type === formType) return;

  let formCopy = {
    id: form.id,
    name: form.name,
    formConfigurationId: form.formConfigurationId,
    fields: form.fields,
    validations: form.validations,
    subformIds: form.subformIds,
    isEmbedded: form.isEmbedded,
    prompt: form.prompt,
    geoFeatureConfigurations: []
  };

  switch (formType) {
    case "ComponentInspection":
    case "RoomInspection":
      form = { ...formCopy, references: [], type: formType, 
        canAddFields: true, 
        canAddAsSubForm: true, 
        canCopy: true, 
        canAddSubForms: true };
      break;
    case "SubForm":
    case "ExteriorInspection":
    case "InteriorInspection":
    case "Questionnaire":
      form = { ...formCopy, references: [], type: formType, 
        canAddFields: true, 
        canAddAsSubForm: true, 
        canCopy: true, 
        canAddSubForms: true};
      break;
    case "RightOfEntry":
    case "ESignature":
    case "SpecialConditions":
      case "TimeLog":
    case "AdditionalPhotos":
      form = { ...formCopy, type: formType, 
        canAddFields: false, 
        canAddAsSubForm: false, 
        canCopy: false, 
        canAddSubForms: false };
      break;
    default:
      const _exhaustiveCheck: never = formType;
      return _exhaustiveCheck;
  }

  if (!form) return;

  draft.data.inspectionTypeTemplate.configuration.forms[formIndex] = form;
}
