import React from "react";
import { gql, useQuery } from "@apollo/client";
import { createContext, Dispatch } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { createField } from "./FormActions/CreateField";
import {
  Data,
  Action,
  PageState,
  ErrorLink,
  Validation,
} from "../components/TypeDefinitions";
import { useImmerReducer } from "use-immer";
import { updateField } from "./FormActions/UpdateField";
import { closeEditor } from "./FormActions/CloseEditor";
import { setEditor } from "./FormActions/SetEditor";
import { toggleDelete } from "./FormActions/ToggleDelete";
import { deleteField } from "./FormActions/DeleteField";
import { moveField } from "./FormActions/MoveField";
import { deleteValidation } from "./FormActions/DeleteValidation";
import { updateValidationAction } from "./FormActions/UpdateValidationAction";
import { createValidation } from "./FormActions/CreateValidation";
import { createExpression } from "./FormActions/CreateExpression";
import { createPossibleValue } from "./FormActions/CreatePossibleValue";
import { updatePossibleValue } from "./FormActions/UpdatePossibleValue";
import { movePossibleValue } from "./FormActions/MovePossibleValue";
import { deletePossibleValue } from "./FormActions/DeletePossibleValue";
import { setForm } from "./FormActions/SetForm";
import { createSubform } from "./FormActions/CreateSubform";
import { updateFormName } from "./FormActions/UpdateFormName";
import { updateFormPrompt } from "./FormActions/UpdateFormPrompt";
import SelectFormPage from "../../SelectFormPage";
import { createForm } from "./FormActions/CreateForm";
import { deleteForm } from "./FormActions/DeleteForm";
import { linkSubForm } from "./FormActions/LinkSubForm";
import { unlinkSubForm } from "./FormActions/UnlinkSubForm";
import { updateExpression } from "./FormActions/UpdateExpression";
import { updateFieldToCompare } from "./FormActions/UpdateFieldToCompare";
import { updateGeoRuleToCompare } from "./FormActions/UpdateGeoRuleToCompare";
import { updateValidationMessage } from "./FormActions/updateValidationMessage";
import { deleteExpression } from "./FormActions/DeleteExpression";
import { updateValidationTrigger } from "./FormActions/UpdateValidationTrigger";
import { updateSubformEmbedded } from "./FormActions/UpdateSubformEmbedded";
import { CircularProgress, Stack } from "@mui/material";
import { updateReferences } from "./FormActions/UpdateReferences";
import { duplicateForm } from "./FormActions/DuplicateForm";
import { copyField } from "./FormActions/copyField";
import { updateFormType } from "./FormActions/UpdateFormType";
import { moveSubform } from "./FormActions/MoveSubform";
import DisplayValidations from "./DisplayValidations";
import { addReferenceMaterial } from "./FormActions/AddReferenceMaterial";
import { addReferenceMaterialToField } from "./FormActions/AddReferenceMaterialToField";
import { deleteReferenceMaterialFromField } from "./FormActions/DeleteReferenceMaterialFromField";
import { deleteReferenceMaterial } from "./FormActions/DeleteReferenceMaterial";
import ExcelMapping from "../../ExcelMapping";
import PDFMapping from "../../PDFMapping";
import { addGrouping } from "./FormActions/AddGrouping";
import { moveForm } from "./FormActions/moveForm";
import { updateValidationValue } from "./FormActions/UpdateValidationValue";
import { createGeoFeatureConfiguration } from "./FormActions/CreateGeoFeatureConfiguration";
import { updateGeoFeatureConfiguration } from "./FormActions/UpdateGeoFeatureConfiguration";
import { deleteGeoFeatureConfiguration } from "./FormActions/DeleteGeoFeatureConfiguration";

const getInspectionQuery = gql`
  query getFieldsQuery($inspectionTypeTemplateId: ID!) {
    inspectionTypeTemplate(id: $inspectionTypeTemplateId) {
      id
      configuration
      start
      end
    }
  }
`;

interface BuilderWrapperProps {
  children: JSX.Element;
}

export default function BuilderWrapper({ children }: BuilderWrapperProps) {
  const { inspectionTypeTemplateId } = useParams();
  const { data, loading, error, refetch } = useQuery<Data, any>(
    getInspectionQuery,
    {
      variables: {
        inspectionTypeTemplateId: inspectionTypeTemplateId,
      },
    }
  );

  return (
    <>
      {loading && (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {data && (
        <LoadedBuilder initialData={data} refetch={refetch}>
          {children}
        </LoadedBuilder>
      )}
      {error && <h1>{error.message}</h1>}
    </>
  );
}

interface LoadedBuilderProps {
  initialData: Data;
  children: JSX.Element;
  refetch: () => void;
}

function reducer(draft: PageState, action: Action) {
  switch (action.type) {
    case "x_Set_Form_Do_Not_Call": {
      draft.formId = action.payload.formId;
      draft.editingFieldId = null;
      break;
    }
    case "setForm": {
      setForm(action, draft);
      break;
    }
    case "updateFormName": {
      updateFormName(action, draft);
      break;
    }
    case "updateFormPrompt": {
      updateFormPrompt(action, draft);
      break;
    }
    case "createField":
      createField(action, draft);
      break;
    case "updateField":
      updateField(action, draft);
      break;
    case "moveField":
      moveField(action, draft);
      break;
    case "createPossibleValue":
      createPossibleValue(action, draft);
      break;
    case "updatePossibleValue":
      updatePossibleValue(action, draft);
      break;
    case "movePossibleValue":
      movePossibleValue(action, draft);
      break;
    case "deletePossibleValue":
      deletePossibleValue(action, draft);
      break;
    case "toggleDelete":
      toggleDelete(action, draft);
      break;
    case "deleteField":
      deleteField(action, draft);
      break;
    case "setEditor":
      setEditor(action, draft);
      break;
    case "closeEditor":
      closeEditor(action, draft);
      break;
    case "createValidation":
      createValidation(action, draft);
      break;
    case "deleteValidation":
      deleteValidation(action, draft);
      break;
    case "createExpression":
      createExpression(action, draft);
      break;
    case "updateValidationAction":
      updateValidationAction(action, draft);
      break;
    case "updateValidationMessage":
      updateValidationMessage(action, draft);
      break;
    case "updateValidationValue":
      updateValidationValue(action, draft);
      break;
    case "updateValidationTrigger":
      updateValidationTrigger(action, draft);
      break;
    case "updateExpression":
      updateExpression(action, draft);
      break;
    case "deleteExpression":
      deleteExpression(action, draft);
      break;
    case "updateFieldToCompare":
      updateFieldToCompare(action, draft);
      break;
    case "updateGeoRuleToCompare":
      updateGeoRuleToCompare(action, draft);
      break;
    case "createSubform":
      createSubform(action, draft);
      break;
    case "createForm":
      createForm(action, draft);
      break;
    case "deleteForm":
      deleteForm(action, draft);
      break;
    case "linkSubForm":
      linkSubForm(action, draft);
      break;
    case "unlinkSubForm":
      unlinkSubForm(action, draft);
      break;
    case "updateSubformEmbedded":
      updateSubformEmbedded(action, draft);
      break;
    case "updateReferences":
      updateReferences(action, draft);
      break;
    case "duplicateForm":
      duplicateForm(action, draft);
      break;
    case "copyField":
      copyField(action, draft);
      break;
    case "updateFormType":
      updateFormType(action, draft);
      break;
    case "moveSubform":
      moveSubform(action, draft);
      break;
    case "addReferenceMaterial":
      addReferenceMaterial(action, draft);
      break;
    case "addReferenceMaterialToField":
      addReferenceMaterialToField(action, draft);
      break;
    case "deleteReferenceMaterialFromField":
      deleteReferenceMaterialFromField(action, draft);
      break;
    case "deleteReferenceMaterial":
      deleteReferenceMaterial(action, draft);
      break;
    case "addGrouping":
      addGrouping(action, draft);
      break;
    case "moveForm":
      moveForm(action, draft);
      break;
    case "createGeoFeatureConfiguration":
      createGeoFeatureConfiguration(action, draft);
      break;
    case "updateGeoFeatureConfiguration":
      updateGeoFeatureConfiguration(action, draft);
      break;
    case "deleteGeoFeatureConfiguration":
      deleteGeoFeatureConfiguration(action, draft);
      break;
    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
  reducerMiddleware(draft);
}

export const FormCtx = createContext<{
  state: PageState;
  dispatch: Dispatch<Action>;
}>(undefined!);

function LoadedBuilder({
  initialData,
  refetch,
  children,
}: LoadedBuilderProps): JSX.Element {
  let navigate = useNavigate();
  let initialState: PageState = {
    errors: [],
    data: initialData,
    formId: "",
    editingFieldId: null,
    deleteConfig: null,
    fieldToCopy: null,
    navigate: navigate,
    refetch: refetch,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  return (
    <>
      <FormCtx.Provider value={{ state: state, dispatch: dispatch }}>
        <Routes>
          <Route path="/" element={<SelectFormPage />} />
          <Route
            path="/validations"
            element={
              <DisplayValidations
                config={state.data.inspectionTypeTemplate.configuration}
              />
            }
          />
          <Route path="/excelMapping/*" element={<ExcelMapping />} />
          <Route path="/pdfMapping/*" element={<PDFMapping />} />
          <Route path=":formId" element={children} />
        </Routes>
      </FormCtx.Provider>
    </>
  );
}

function reducerMiddleware(draft: PageState) {
  let errors = generateErrors(draft);
  draft.errors = errors;
}

function generateErrors(state: PageState): ErrorLink[] {
  let errorLinks: ErrorLink[] = [];
  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );
  const fields = form?.fields;
  if (!fields || fields.length === 0) return errorLinks;
  fields.forEach((field) => {
    if (!field.label) {
      errorLinks.push({
        errorMessage: "Label is required",
        fieldId: field.id,
      });
    }
    if (field.inputType === "Date" || field.inputType === "DateTime") {
      if (field.minDate === "") {
        errorLinks.push({
          errorMessage:
            "You must select a date when specifying a minimum date field.",
          fieldId: field.id,
        });
      }
      if (field.maxDate === "") {
        errorLinks.push({
          errorMessage:
            "You must select a date when specifying a maximum date field.",
          fieldId: field.id,
        });
      }
    }
  });
  form.validations.forEach((validation: Validation) => {
    if (validation.action === null) {
      errorLinks.push({
        errorMessage: "Validation created without on-trigger action.",
        fieldId: validation.field,
        validationId: validation.id,
      });
    }
    if (validation.expressions.length === 0) {
      errorLinks.push({
        errorMessage: "Validation created without conditions.",
        fieldId: validation.field,
        validationId: validation.id,
      });
    }
    validation.expressions.forEach((expression) => {
      if (!expression.fieldToCompare && expression.type === "Field") {
        errorLinks.push({
          errorMessage: "Validation condition is missing field to compare.",
          fieldId: validation.field,
          validationId: validation.id,
        });
        return;
      }
      if (!expression.geoRuleToCompare && expression.type === "GeoRule") {
        errorLinks.push({
          errorMessage: "Validation condition is missing field to compare.",
          fieldId: validation.field,
          validationId: validation.id,
        });
        return;
      }
      if (expression.value === null && expression.operator !== "not null") {
        errorLinks.push({
          errorMessage: "Validation condition is missing value.",
          fieldId: validation.field,
          validationId: validation.id,
        });
      }
    });
  });
  return errorLinks;
}
