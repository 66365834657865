import React from "react";
import { Route, Routes } from "react-router-dom";
import ExcelMappingTool from "./pages/ExcelMappingTool";
import ReportSelect from "./pages/ReportSelect";

export default function ExcelMapping() {
  return (
    <Routes>
      <Route path="/" element={<ReportSelect />} />
      <Route path="/:reportTypeTemplateId" element={<ExcelMappingTool />} />
    </Routes>
  );
}
