import { Add, Cancel, DragHandle } from "@mui/icons-material";
import { Button, FormLabel, IconButton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormCtx } from "../FormWrapper/BuilderWrapper";

export default function PossibleValues() {
  const { state, dispatch } = useContext(FormCtx);

  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );

  const field = form.fields.find((field) => field.id === state.editingFieldId);

  if (
    field.inputType !== "Checklist" &&
    field.inputType !== "OptionSelect" &&
    field.inputType !== "DropDown"
  )
    return <></>;

  const values = field.possibleValues;
  return (
    <>
      <FormLabel sx={{ marginBottom: "0.5rem" }}>
        Possible Field Values
      </FormLabel>
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result;
          if (source == null || destination == null) return;
          let oldIndex = source.index;
          let newIndex = destination.index;
          dispatch({
            type: "movePossibleValue",
            payload: {
              initialIndex: oldIndex,
              newIndex: newIndex,
            },
          });
        }}
      >
        <Droppable droppableId="possibleValuesDroppable">
          {(provided, snapshot) => (
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {values.map((value) => (
                <OptionDraggable
                  key={value.id}
                  label={value.label}
                  id={value.id}
                  index={value.displayIndex}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <Button
          size="small"
          variant="contained"
          endIcon={<Add />}
          sx={{ alignSelf: "center" }}
          onClick={() => dispatch({ type: "createPossibleValue" })}
        >
          Add
        </Button>
      </DragDropContext>
    </>
  );
}

function OptionDraggable({ label, id, index }) {
  const { dispatch } = useContext(FormCtx);
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "0.5rem",
            backgroundColor: "white",
          }}
        >
          <IconButton {...provided.dragHandleProps}>
            <DragHandle color="disabled" />
          </IconButton>
          <TextField
            fullWidth
            label=""
            value={label ?? ""}
            size="small"
            onChange={(e) =>
              dispatch({
                type: "updatePossibleValue",
                payload: {
                  label: e.target.value,
                  possibleValueId: id,
                },
              })
            }
          />
          <IconButton
            onClick={() =>
              dispatch({
                type: "deletePossibleValue",
                payload: {
                  possibleValueId: id,
                },
              })
            }
          >
            <Cancel />
          </IconButton>
        </Box>
      )}
    </Draggable>
  );
}
