// components
import { useEffect, useState } from "react";

import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button } from "@mui/material";
import TextField from "../../../utils/mui-rhf/TextField";
import Select from "../../../utils/mui-rhf/Select";
// ----------------------------------------------------------------------

export default function FeatureEdit({ currentFeature, onSubmit, options }) {
  const methods = useForm({ name: "", type: "[ Select Type ]" });
  const { handleSubmit, setValue, setError, clearErrors, formState } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
  };
  const { errors } = formState;

  const submit = (values) => {
    onSubmit(values, snackbar);
  };

  useEffect(() => {
    if (currentFeature) {
        methods.reset();
      setValue("name", currentFeature.properties.name);
      setValue("type", currentFeature.properties.type);
    }
  }, [currentFeature, setValue]);

  return (
    <div style={{ width: "100%" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12}>
              <TextField
                disabled={false}
                key={3}
                fullWidth
                label={"Name"}
                name={"name"}
                sx={{width: "100%"}}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                options={options}
                fullWidth
                label={"Type"}
                name={"type"}
                rules={{ required: true }}
                sx={{width: "100%"}}
              />
              {errors && <span style={{ color: "red" }}>{errors.message}</span>}
            </Grid>
          </Grid>
          <Button variant="contained" type="submit">
            submit
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
