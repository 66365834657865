import { DictionaryContents } from "src/utils/excelParse";
import { DictionaryLevel } from "../pages/ExcelMappingTool";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";

export default function createMapping(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  newRemap: {
    mappedFrom: string | "bool-val-true" | "bool-val-false";
    mappedTo: string;
  }
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          createMapping(
            dictionary.subforms[chain[1].formId],
            newChain,
            newRemap
          );
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          const newDictionary =
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId];
          createMapping(newDictionary, newChain, newRemap);
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    if (!dictionary.remaps) dictionary.remaps = {};
    dictionary.remaps[newRemap.mappedFrom] = newRemap.mappedTo;
  }
}
