import { PageState, Action } from "../../components/TypeDefinitions";
export function addReferenceMaterial<
  E extends Extract<
    Action,
    {
      type: "addReferenceMaterial";
    }
  >
>(action: E, draft: PageState): void {
  if (!draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds) {
    draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds = [];

    return;
  }

  if (
    !draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds.includes(
      action.payload.referenceMaterialId
    )
  ) {
    draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds.push(
      action.payload.referenceMaterialId
    );
  }
}
