import React, { useContext, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LineHighlightPlot, LinePlot } from "@mui/x-charts/LineChart";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { AllSeriesType } from "@mui/x-charts/models";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { ChartsAxisHighlight } from "@mui/x-charts/ChartsAxisHighlight";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { Gauge } from "@mui/x-charts";
import { Stack, Box, Grid, Paper, CircularProgress, TextField } from "@mui/material";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import SummaryWidget from "../../components/dashboard/cards/SummaryWidget";
import { AttachMoney, House, CalendarMonth, Task } from "@mui/icons-material";
import { PersonContext } from "src/utils/contexts/person";
import { ProjectContext } from "src/utils/contexts/project";
import { FirmContext } from "src/utils/contexts/firm";
import { last } from "pdf-lib";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const GET_DASHBOARD_DATA = gql`
  query DashboardData($dashboardData: InputDashboardDataParams!) {
    dashboardData(dashboardData: $dashboardData) {
      onsiteCompletedInspections {
        date
        inspectionId
      }
      scheduledAssignments {
        date
        inspectionAssignmentId
      }
      damagedAddresses
      unscheduledAddresses
      reportsGenerated
    }
  }
`;

// const series = [
//   {
//     type: 'bar',
//     yAxisId: 'close',
//     label: 'Close Price',
//     color: 'yellow',
//     data: alphabetStock.map((day) => day.close),
//     highlightScope: { highlight: 'item' },
//   },
//   {
//     type: 'bar',
//     yAxisId: 'low',
//     label: 'Low Price',
//     color: 'red',
//     data: alphabetStock.map((day) => day.low),
//     highlightScope: { highlight: 'item' },
//   },
//   {
//     type: 'bar',
//     yAxisId: 'high',
//     label: 'High Price',
//     color: 'green',
//     data: alphabetStock.map((day) => day.high),
//     highlightScope: { highlight: 'item' },
//   }
// ];

export default function DashboardApp() {
  const person = useContext(PersonContext);
  const project = useContext(ProjectContext);
  const firmCtx = useContext(FirmContext);
  const [inspectionsGraphData, setInspectionsGraphData] = useState([]);
  const [inspectionsGraphSeries, setInspectionsGraphSeries] = useState([]);
  const [damagedAddresses, setDamagedAddresses] = useState(null);
  const [unscheduled, setUnscheduled] = useState(null);
  const [reportsGenerated, setReportsGenerated] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [reload, setReload] = useState(false);
  function getDates () {
    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push({date: currentDate.toLocaleDateString(), onsiteComplete: 0, scheduled: 0});
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }
  // const getAllDates = () => {
  //     const firstDate = new Date(dateRange[0]);
  //     const lastDate = new Date(dateRange[1]);
  //     let datelength = Date.parse(lastDate) / 86400000 - Date.parse(firstDate) / 86400000 + 1;
  //     let dateArr = Array(datelength);
  //     let dateArrKeys = [...dateArr.keys()];
  //     let formattedDateArr = dateArrKeys.map((k) => {
  //       return {
  //           date: new Date(86400000 * k + Date.parse(firstDate)).toLocaleDateString()
  //       };
  //   });
  //     const dates = formattedDateArr;
  //     return dates;
  // };

  const [
    dashboardQuery,
    {
      data: dashbaordData,
      loading: dashboardLoading,
      called: dashbaordDataCalled,
    },
  ] = useLazyQuery(GET_DASHBOARD_DATA, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result.dashboardData) {
        let dates = getDates();
        result.dashboardData.onsiteCompletedInspections.forEach((a) => {
          let date = new Date(a.date).toLocaleDateString();

          let existing = dates.findIndex((i) => {
            return i.date == date;
          });
          if (existing >= 0) {
            let existingData = dates[existing];
            existingData.onsiteComplete++;
            dates[existing] = existingData;
          } else {
            dates.push({
              date: date,
              onsiteComplete: 1,
              scheduled: 0,
            });
          }
        });
        result.dashboardData.scheduledAssignments.forEach((a) => {
          let date = new Date(a.date).toLocaleDateString();
          let existing = dates.findIndex((i) => {
            return i.date == date;
          });
          if (existing >= 0) {
            let existingData = dates[existing];
            existingData.scheduled++;
            dates[existing] = existingData;
          } else {
            dates.push({
              date: date,
              onsiteComplete: 0,
              scheduled: 1,
            });
          }
        });



        let inspectionsSeries = [
          {
            type: "bar",
            yAxisId: "inspections",
            label: "Scheduled",
            color: "blue",
            data: dates.map((date) => date.scheduled),
            highlightScope: { highlight: "item" },
          },
          {
            type: "bar",
            yAxisId: "inspections",
            label: "Onsite Complete",
            color: "green",
            data: dates.map((date) => date.onsiteComplete),
            highlightScope: { highlight: "item" },
          },
        ];

        setInspectionsGraphData(dates);
        setInspectionsGraphSeries(inspectionsSeries);

        setDamagedAddresses(result.dashboardData.damagedAddresses);
        setUnscheduled(result.dashboardData.unscheduledAddresses);
        setReportsGenerated(result.dashboardData.reportsGenerated);
      }
    },
  });

  useEffect(() => {
    if (!dateRange) {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      setDateRange([firstDay, lastDay]);
    }
  });

  useEffect(() => {}, [
    inspectionsGraphData,
    damagedAddresses,
    unscheduled,
    reportsGenerated,
  ]);

  useEffect(() => {
    if ((reload || (
      !dashbaordDataCalled &&
      !dashbaordData)) &&
      person.person &&
      project.project &&
      dateRange
    ) {
      
      setReload(false);
      let personId = person.person.id;
      let projectId = project.project.id;
      dashboardQuery({
        variables: {
          dashboardData: {
            projectId: projectId,
            personId: personId,
            startDate: dateRange[0],
            endDate: dateRange[1],
          },
        },
      });
    }
  }, [person, project, dateRange]);

  function getInterval(index){
    let length = inspectionsGraphData.length;
    if(length <= 10){
      return index % 1 === 0;
    }
    if(length <= 50 && length > 10){
      return index % 5 === 0;
    }
    if(length <= 100 && length > 50){
      return index % 10 === 0;
    }
    if(length <= 250 && length > 100){
      return index % 25 === 0;
    }
    if(length <= 400 && length > 250){
      return index % 40 === 0;
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <>
        {dateRange ? (
          <Grid
            container
            columns={12}
            sx={{ alignContent: "center", justifyContent: "center" }}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h4">Dashboard</Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ width: 550, justifyContent: 'space-evenly'}} flexDirection={'row'}>
                <DatePicker
                  label="Start Date"
                  value={dateRange[0]}
                  onChange={(newValue) => {
                    debugger;
                    let date = new Date(newValue.$d)
                    var newDateRange = [date, dateRange[1]];
                    setReload(true);
                    setDateRange(newDateRange);
                  }}
                  renderInput={(inputProps) => 
                    <TextField
                      {...inputProps}
                    />}
                />
                <DatePicker
                  label="End Date"
                  value={dateRange[1]}
                  onChange={(newValue) => {
                    let date = new Date(newValue.$d)
                    var newDateRange = [dateRange[0], date];
                    setReload(true);
                    setDateRange(newDateRange);
                  }}
                  renderInput={(inputProps) => 
                    <TextField
                      {...inputProps}
                    />}
                />
              </Stack>
            </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  alignContent: "center",
                  justifyContent: "center",
                  marginTop: 5,
                }}
              >
                <SummaryWidget
                  title="Total Damaged Addresses"
                  total={damagedAddresses ?? 0}
                  color="success"
                  icon={<House sx={{ color: "green", fontSize: 40 }}></House>}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  alignContent: "center",
                  justifyContent: "center",
                  marginTop: 5,
                }}
              >
                <SummaryWidget
                  title="Unscheduled"
                  total={unscheduled ?? 0}
                  color="success"
                  icon={
                    <CalendarMonth
                      sx={{ color: "red", fontSize: 40 }}
                    ></CalendarMonth>
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  alignContent: "center",
                  justifyContent: "center",
                  marginTop: 5,
                }}
              >
                <SummaryWidget
                  title="Reports Generated"
                  total={reportsGenerated ?? 0}
                  color="success"
                  icon={<Task sx={{ color: "blue", fontSize: 40 }}></Task>}
                />
              </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 8,
              }}
            >
              <Box>
                <ResponsiveChartContainer
                  series={inspectionsGraphSeries}
                  height={400}
                  margin={{ top: 10 }}
                  xAxis={[
                    {
                      id: "date",
                      data: inspectionsGraphData.map((date) => date.date),
                      scaleType: "band",
                      valueFormatter: (value) => value,
                    },
                  ]}
                  yAxis={[
                    {
                      id: "inspections",
                      scaleType: "linear",
                      valueFormatter: (value) => `${value}`,
                    },
                  ]}
                >
                  <ChartsAxisHighlight x="line" />
                  <BarPlot />
                  <LinePlot />
                  <LineHighlightPlot />
                  <ChartsXAxis
                    position="bottom"
                    axisId="date"
                    tickInterval={(value, index) => {
                      return getInterval(index);
                    }}
                    tickLabelStyle={{
                      fontSize: 10,
                    }}
                  />
                  <ChartsYAxis
                    label="Inspections"
                    position="left"
                    axisId="inspections"
                    tickInterval={1}
                    tickLabelStyle={{ fontSize: 12, fontWeight: "bold" }}
                    sx={{
                      [`& .${axisClasses.label}`]: {
                        transform: "translateX(-5px)",
                        fontSize: 20,
                        fontWeight: "bold",
                      },
                    }}
                  />
                  <ChartsTooltip />
                </ResponsiveChartContainer>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress></CircularProgress>
        )}
      </>
    </LocalizationProvider>
  );
}
