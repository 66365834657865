import { useEffect, useState } from "react";
import TextField from "../../../../utils/mui-rhf/TextField";
import Select from "../../../../utils/mui-rhf/Select";
import DateTimePicker from "../../../../utils/mui-rhf/DateTimePicker";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button } from "@mui/material";
import _ from "lodash";
import { GQLMutationAction } from "../../../../utils/gql-mui-rhf/SingleMutation";

function createInspectionAssignment(refetch, snackbar) {
  return GQLMutationAction({
    mutationString: "createInspectionAssignment",
    onCompleted: (response) => {
      snackbar("Assignment Created", "success");
      refetch();
    },
  });
}

function hasConflict(events, locationId, inspectionId, start) {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  for (const event of events) {
    if (
      event.locationId === locationId &&
      event.inspectionId === inspectionId &&
      new Date(event.start) >= sevenDaysAgo &&
      new Date(event.start) <= new Date(start)
    ) {
      return true; // Conflict found
    }
  }
  return false; // No conflict
}

function isPersonAvailable(events, personId, startTime, endTime) {
  for (const event of events) {
    if (
      event.resourceId === personId &&
      new Date(event.start) <= new Date(endTime) &&
      new Date(event.end) >= new Date(startTime)
    ) {
      // There is an overlapping event; the person is not available
      return false;
    }
  }
  // No overlapping events found; the person is available
  return true;
}

export default function Forms({
  snackbar,
  assignmentLocation,
  inspection,
  inspectors,
  refetch,
  events,
}) {
  const methods = useForm({ ...assignmentLocation });
  const { handleSubmit, setValue, setError, clearErrors, formState } = methods;
  const { errors } = formState;

  const [currentInspection, setInspection] = useState({});
  
  const [showWarning, setShowWarning] = useState(false);

  const [options, setOptions] = useState([]);

  const handleCreateInspectionAssignment = createInspectionAssignment(
    refetch,
    snackbar
  );
  const onSubmit = (values) => {
    if (!isPersonAvailable(events, values.personId, values.start, values.end)) {
      setError("personId", {
        type: "availability",
        message: "Inspector is not available during this time.",
      });
    } else if (
      !showWarning &&
      hasConflict(
        events,
        assignmentLocation.inspectionLocation,
        values.id,
        values.start
      )
    ) {
      // Display a warning to the user, e.g., using a modal or a message element
      // Example: set a state variable like showWarning to true
      setShowWarning(true);
    } else {
      // Clear the error if the person is available
      clearErrors("personId");
      // Continue with form submission logic
      
      handleCreateInspectionAssignment({
        firmPersonId: values.personId,
        personId: values.personId,
        inspectionId: values.id,
        start: values.start,
        end: values.end,
        status: "Scheduled",
        requiresDrawing: values.inspectionType.requiresDrawing,
        isWeb: values.inspectionType.isWeb,
        isMobile: values.inspectionType.isMobile
      });
      methods.reset({});
      setShowWarning(false);
      populateAssignmentLocationData();
    }
  };

  function populateAssignmentLocationData(aLocation, setVal) {
    _.forOwn(aLocation, (value, key) => {
      setVal(key, value);
    });
  }

  useEffect(() => {
    populateAssignmentLocationData(assignmentLocation, setValue);
  }, [assignmentLocation, setValue]);

  if (inspection !== currentInspection) {
    setValue("inspectionId", inspection.id);
    setInspection(inspection);
  }

  useEffect(() => {
    // This will recreate options each time inspectors are updated
    const options = inspectors.map(inspector => ({
      label: `${inspector.firstName} ${inspector.lastName}`,
      value: inspector.id,
      ...inspector,
    }));
    // Assuming you have a setter for options in your state
    setOptions(options);
  }, [inspectors]);
  return (
    <div style={{ width: "100%" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} padding={1}>
            <Grid item spacing={2} xs={12}>
              <TextField
                disabled={true}
                key={1}
                fullWidth
                label={"Street 1"}
                name={"street1"}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item spacing={2} xs={12}>
              <TextField
                disabled={true}
                key={1}
                fullWidth
                label={"Street 2"}
                name={"street2"}
              />
            </Grid>
            <Grid item spacing={2} xs={12}>
              <TextField
                disabled={true}
                key={1}
                fullWidth
                label={"Street 3"}
                name={"street3"}
              />
            </Grid>
            <Grid item spacing={2} xs={4}>
              <TextField
                disabled={true}
                key={2}
                fullWidth
                label={"City"}
                name={"city"}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item spacing={2} xs={4}>
              <TextField
                disabled={true}
                key={3}
                fullWidth
                label={"State"}
                name={"state"}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item spacing={2} xs={4}>
              <TextField
                disabled={true}
                key={3}
                fullWidth
                label={"Zip"}
                name={"zip"}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item spacing={2} xs={12}>
              <Select
                options={options}
                fullWidth
                label={"Inspector"}
                name={"personId"}
                rules={{ required: true }}
              />
              {errors.personId && (
                <span style={{ color: "red" }}>{errors.personId.message}</span>
              )}
            </Grid>
            {/* {assignmentLocation.inspectionType && (
              <Grid item spacing={2} xs={12}>
                <Switch
                  name="requiresDrawing"
                  label="Requires Drawing"
                  defaultValue={
                    assignmentLocation.inspectionType.requiresDrawing
                  }
                  disabled={false}
                />
              </Grid>
            )} 
            {assignmentLocation.inspectionType && (
              <Grid item spacing={2} xs={12}>
                <Switch
                  name="isWeb"
                  label="Web Based"
                  defaultValue={assignmentLocation.inspectionType.isWeb}
                  disabled={false}
                />
              </Grid>
            )}
            {assignmentLocation.inspectionType && (
              <Grid item spacing={2} xs={12}>
                <Switch
                  name="isMobile"
                  label="Mobile Based"
                  defaultValue={assignmentLocation.inspectionType.isMobile}
                  disabled={false}
                />
              </Grid>
            )} */}
            <Grid item spacing={2} xs={12}>
              <DateTimePicker
                fullWidth
                label={"Start"}
                name={"start"}
                rules={{
                  required: true,
                  validate: {
                    afterNow: (v) =>
                      v > new Date() || "Start date must be in the future",
                    beforeEnd: (v, fV) =>
                      (fV.end && v < fV.end) ||
                      "Start date must come before end date",
                  },
                }}
              />
            </Grid>
            <Grid item spacing={2} xs={12} style={{ marginBottom: 32 }}>
              <DateTimePicker
                fullWidth
                label={"End"}
                name={"end"}
                rules={{
                  required: true,
                  validate: {
                    afterStart: (v, fV) =>
                      v > fV.start || "End date must come after start date",
                    notOverThree: (v, fV) =>
                      (v - fV.start) / (1000 * 60 * 60) <= 3 ||
                      "Assignments cannot exceed 3 hours",
                  },
                }}
              />
            </Grid>
          </Grid>
          {showWarning && (
            <div
              style={{
                color: "orange",
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
              className="warning-message"
            >
              This inspection conflicts with an existing inspection within 7
              days. Are you sure you want to continue?
            </div>
          )}
          <Button variant="contained" type="submit">
            submit
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
