import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  Tabs,
  Tab
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Page from "src/components/Page";
import CreateInputButtons from "./components/CreateInputButtons";
import DnDContext from "./components/DnDContext";
import BuilderWrapper, { FormCtx } from "./FormWrapper/BuilderWrapper";
import { SyncHandler } from "./FormWrapper/SyncHandler";
import { gql, useMutation } from "@apollo/client";
import useDebounce from "../../../utils/useDebounce";
import DeleteDialog from "./components/DeleteDialog";
import Editor from "./components/Editor";
import FormDisplay from "./components/FormDisplay";
import SubForms from "./components/SubForms";
import MDEditor from "@uiw/react-md-editor";
import GeoFeatureConfigurationDisplay from "./components/GeoFeatureConfigurationDisplay";

export default function FormBuilder() {
  return (
    <BuilderWrapper>
      <UnwrappedFormBuilder />
    </BuilderWrapper>
  );
}

const UPDATE_INSPECTION_TYPE_TEMPLATE = gql`
  mutation Mutation(
    $inspectionTypeTemplate: InputInspectionTypeTemplateParams
  ) {
    updateInspectionTypeTemplate(
      inspectionTypeTemplate: $inspectionTypeTemplate
    ) {
      id
    }
  }
`;

const formTypes = [
  {
    label: "Room Inspection",
    value: "RoomInspection",
  },
  {
    label: "Component Inspection",
    value: "ComponentInspection",
  },
  {
    label: "Interior Inspection",
    value: "InteriorInspection",
  },
  {
    label: "Exterior Inspection",
    value: "ExteriorInspection",
  },
  {
    label: "Subform",
    value: "SubForm",
  },
  {
    label: "Questionnaire",
    value: "Questionnaire",
  },
  {
    label: "E-Signature",
    value: "ESignature",
  },
  {
    label: "Right Of Entry",
    value: "RightOfEntry",
  },
  {
    label: "Special Conditions",
    value: "SpecialConditions",
  },
  {
    label: "Additional Photos",
    value: "AdditionalPhotos",
  },
  {
    label: "Time Log",
    value: "TimeLog",
  },
];

function UnwrappedFormBuilder() {
  const [tab, setTab] = useState(0);
  const { state, dispatch } = useContext(FormCtx);
  const { formId } = useParams();

  useEffect(() => {
    if (state.formId !== formId) {
      dispatch({
        type: "x_Set_Form_Do_Not_Call",
        payload: {
          formId: formId,
        },
      });
    }
  }, [dispatch, formId, state.formId]);

  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === formId
  );

  if (!form) throw Error("Form not found");
  const [mutateFunction] = useMutation(UPDATE_INSPECTION_TYPE_TEMPLATE);
  const { inspectionTypeTemplateId } = useParams();

  const handleSync = (stateUpdate) => {
    if (!stateUpdate || !inspectionTypeTemplateId) return;
    let newUpdateState;
    if (
      !stateUpdate.data.inspectionTypeTemplate.configuration
        .inspectionTypeTemplateId
    ) {
      newUpdateState = JSON.parse(JSON.stringify(stateUpdate));
      newUpdateState.data.inspectionTypeTemplate.configuration.inspectionTypeTemplateId =
        inspectionTypeTemplateId;
    } else {
      newUpdateState = stateUpdate;
    }

    return mutateFunction({
      variables: {
        inspectionTypeTemplate: {
          id: stateUpdate.data.inspectionTypeTemplate.id,
          configuration: JSON.stringify(
            newUpdateState.data.inspectionTypeTemplate.configuration
          ),
        },
      },
    });
  };

  const syncing = useDebounce({
    stateToWatch: state,
    debounceTimeoutMS: 1000,
    callback: handleSync,
    disabled: state.formId === "",
  });

  const handleTabChange = (e, newTabValue) => setTab(newTabValue);

  return (
    <>
      {state.formId && (
        <SyncHandler syncing={syncing} errors={state.errors}>
          <Page
            title="Form Builder | FINBACK670"
            id="pagee"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Breadcrumbs>
              <Link to="../../.." relative="path">
                Inspection Types List
              </Link>
              <Link to="../.." relative="path" 
            onClick={() => {
              window.location.href = "..";
            }}>
                Inspection Type
              </Link>
              <Link to=".." relative="path">
                Inspection Type Template
              </Link>
              <Link to="." relative="path">
                Form Builder
              </Link>
            </Breadcrumbs>
            <Box height="1rem"></Box>
            <DnDContext>
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 100%",
                  flexDirection: "row",
                  maxHeight: "100%",
                }}
              >
                <Stack direction="column" height="100%" spacing={2} width="50%">
                  <Stack direction="row" justifyContent={"space-between"}>
                    <TextField
                      label="Form Name"
                      value={form.name}
                      error={form.name === ""}
                      sx={{ flex: 1 }}
                      size="small"
                      helperText={form.name === "" ? "Form name required." : ""}
                      onChange={(e) =>
                        dispatch({
                          type: "updateFormName",
                          payload: {
                            formName: e.target.value,
                          },
                        })
                      }
                    />
                    <Button>
                      Other Names [{form.groupParentTo?.length ?? 0}]
                    </Button>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                  >
                    {form.canAddAsSubForm && (
                      <FormGroup>
                        <FormControlLabel
                          checked={form.isEmbedded}
                          onChange={(e) =>
                            dispatch({
                              type: "updateSubformEmbedded",
                              payload: {
                                subformId: form.id,
                                isEmbeddedValue: e.target.checked,
                              },
                            })
                          }
                          control={<Checkbox />}
                          label="Embedded?"
                        />
                      </FormGroup>
                    )}
                    <TextField
                      select
                      label="Form Type"
                      fullWidth
                      size="small"
                      value={form.type}
                      onChange={(e) => {
                        dispatch({
                          type: "updateFormType",
                          payload: {
                            formType: e.target.value,
                          },
                        });
                      }}
                    >
                      {formTypes.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  {form.name && <h3>Prompt:</h3>}
                  {form.name && (
                    <MDEditor
                      data-color-mode="light"
                      value={form.prompt}
                      onChange={(e) => {
                        dispatch({
                          type: "updateFormPrompt",
                          payload: {
                            formPrompt: e,
                          },
                        });
                      }}
                    />
                  )}
                  {form.canAddFields !== false && form.name && (
                    <Paper
                      elevation={3}
                      sx={{
                        border: "3px solid #e6e6e6",
                        flex: 1,
                        width: "100%",
                        padding: 5
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          margin: 1,
                          marginTop: 2,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {form.name &&
                          (state.editingFieldId ? (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "100%",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                              >
                                <IconButton
                                  sx={{ position: "absolute", left: 0 }}
                                  onClick={() =>
                                    dispatch({ type: "closeEditor" })
                                  }
                                >
                                  <ArrowBack />
                                </IconButton>
                                <Typography>Edit Form Input</Typography>
                              </Box>
                              <Editor />
                            </>
                          ) : (
                            <Stack
                              direction="column"
                              spacing={3}
                              alignItems="center"
                            >
                            <Box sx={{ marginBottom: "1.5rem" }}>
                              <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                aria-label="toggle logic editor"
                                variant="fullWidth"
                              >
                                <Tab
                                  label="Edit Fields"
                                  value={0}
                                  iconPosition="end"
                                />
                                <Tab
                                  label="Edit Geo Feature Configurations"
                                  value={1}
                                  iconPosition="end"
                                />
                              </Tabs>
                            </Box>
                            {tab === 0 && 
                              <CreateInputButtons />}
                              {/* <AutocompleteChips /> */}
                              <SubForms />
                            </Stack>
                          ))}
                      </Box>
                    </Paper>
                  )}
                </Stack>
                {tab === 0 && 
                <FormDisplay />}
                {tab === 1 && <GeoFeatureConfigurationDisplay />}
                <DeleteDialog />
              </Box>
            </DnDContext>
          </Page>
        </SyncHandler>
      )}
    </>
  );
}
