import { useState, useEffect, useContext } from "react";
import GQLTable from "../../utils/gql-mui-rhf/Table";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { ProjectContext } from "../../utils/contexts/project";
import { PersonContext } from "../../utils/contexts/person";
import { FirmContext } from "../../utils/contexts/firm";
import { FirmUnitContext } from "../../utils/contexts/firmUnit";
import { AuthContext } from "../../utils/contexts/auth";
import starOutline from "@iconify/icons-eva/star-outline";
import { GQLMutationAction } from "../../utils/gql-mui-rhf/SingleMutation";
// material
import { Grid, Container } from "@mui/material";
// components
import Page from "../../components/Page";
import AddProject from "./createProject";
import { gql, useQuery } from '@apollo/client';

// ----------------------------------------------------------------------
const GET_FIRM_UNITS = gql`
query FirmUnits($firmId: ID, $firmUnitId: ID) {
  firmUnits(firmId: $firmId, firmUnitId: $firmUnitId) {
    id
    name
  }}
`;

function generateMutation(snackbar) {
  return GQLMutationAction({
      mutationString: "createProjectFavorite",
      onCompleted: (response) => {
          snackbar("Favorited Project", "success");
      },
  });
}

export default function Projects(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
};

  let projectContext = useContext(ProjectContext);
  const { project, setProject } = projectContext;
  let personContext = useContext(PersonContext);
  const { person, setPerson } = personContext;
  let firmContext = useContext(FirmContext);
  const { firm, setFirm } = firmContext;
  let firmUnitContext = useContext(FirmUnitContext);
  let firmUnitId = props.firmUnitId;
  const [firmUnits, setFirmUnits] = useState([])
  const [firmUnitOptions, setFirmUnitOptions] = useState([])

  const { loading, error, data } = useQuery(GET_FIRM_UNITS, {
    fetchPolicy: 'network-only',
    variables: {
      firmId: firm.id,
      firmUnitId: null
    }
  });

  useEffect(() => {
    if (data) {
      setFirmUnits(data.firmUnits)
      setFirmUnitOptions(data.firmUnits.map((firmUnit) => ({ value: firmUnit.id, label: firmUnit.name })))
    }
  }, [data, setFirmUnits]);

  const favoriteProject = generateMutation(snackbar)
  const [addProject, setAddProject] = useState(false);
  const closeAddProject = () => setAddProject(false);
  const openAddProject = () => setAddProject(true);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const refetch = () => setShouldRefetch(true);
  console.log("PROJECT PROPS: ", projectContext)

  if (loading || error)
    return <></>
  if (firmUnits)
    return (
      <Page title="Projects | FINBACK670">
        <AddProject
          open={addProject}
          handleClose={closeAddProject}
          firmUnits={firmUnitOptions}
          projectId={undefined}
          projectOwner={firmUnitId}
          refetch={refetch}
        />
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <GQLTable
                queryString={"projects"}
                shouldRefetch={shouldRefetch}
                setShouldRefetch={setShouldRefetch}
                deleteMutationString={"deleteProject"}
                customActions={[
                  {
                    text: "Add Project",
                    callback: openAddProject,
                    type: "general",
                  },
                  {
                    text: "Favorite",
                    icon: starOutline,
                    callback: (selectionModel) => {
                      favoriteProject({
                        projectId: selectionModel[0].id,
                        personId: person.id
                      })
                    }
                  }
                ]}
                queryVariables={{
                  projectOwner: firm.id,
                  projectId: null,
                  active: true,
                }}
                addMutationVariables={{
                  projectOwner: firmUnitId,
                  projectId: null,
                }}
                updateModal={true}
                columnOrder={[
                  "projectNumber",
                  "name",
                  "projectLocation",
                  "numberOfUnits",
                  "publicPrivatePartnership",
                ]}
                columnOptions={{
                  projectNumber: { headerName: "Project Number" },
                  numberOfUnits: { headerName: "Number of Units" },
                  projectLocation: {
                    headerName: "Location",
                    valueGetter: ({ value }) =>
                      value && `${value.city}, ${value.state}`,
                  },
                  name: { headerName: "Name" },
                  publicPrivatePartnership: {
                    headerName: "Public Private Partnership",
                  },
                }}
                restrictedColumns={{
                  childProjects: true,
                  parentProject: true,
                  id: true,
                  client: true,
                  offeror: true,
                  projectPersons: true,
                  projectFirmUnits: true,
                  description: true,
                  active: true,
                  projectOwner: true,
                  createdBy: true,
                  locationId: true,
                  invoiceTemplate: true,
                  popEnd: true,
                  popStart: true,
                  projectId: true,
                  projectLogo: true,
                  end: true,
                  start: true,
                }}
                onRowClick={(rowData) => {
                  setProject(rowData);
                  if (props.setValue) {
                    props.setValue(0);
                  } else {
                    navigate(`/app/details`);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
}
