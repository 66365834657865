import { useLayoutEffect, useRef, useState } from "react";
import { IFormJson, InspectionDisplayProps } from "..";
import { gql, useMutation } from "@apollo/client";
import { encode } from "./utilities/encode";
import { TreeView } from "@mui/lab";
import InspectionTreeRecurse from "./InspectionTreeRecurse";

const UPDATE_THO_FORM_INSTANCE = gql`
  mutation UploadThoFormInstance($thoFormInstance: InputThoFormInstanceParams) {
    uploadThoFormInstance(thoFormInstance: $thoFormInstance) {
      id
    }
  }
`;

export default function InspectionDisplay({
  config,
  formJson,
  expandedDisplay,
  setIsSyncing,
}: InspectionDisplayProps & {
  expandedDisplay?: boolean;
  setIsSyncing?: (instanceId: string, isSyncing: boolean) => void;
}) {
  const [formState, setFormState] = useState(formJson);

  const [updateFormInstance] = useMutation(UPDATE_THO_FORM_INSTANCE);

  function extractNodeIds(json: IFormJson) {
    let childNodeIds: string[] = [];

    if (json.subFormInstances)
      json.subFormInstances.forEach((subform) =>
        childNodeIds.push.apply(childNodeIds, extractNodeIds(subform))
      );
    return [json.id, ...childNodeIds];
  }

  const nodeIds = extractNodeIds(formJson);
  const [expanded, setExpanded] = useState<string[]>(
    expandedDisplay ? nodeIds : []
  );

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if (expandedDisplay) return;
    setExpanded(nodeIds);
  };

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setIsSyncing && setIsSyncing(formJson.id, true);

      function syncFormInstanceChanges() {
        
        const encodedFormState = encode(formState);
        const {
          type,
          name,
          isDeleted,
          inspectionId,
          inspectionTypeTemplateId,
          inspectionAssignmentId,
          formId,
          formConfigurationId,
          reference,
          referenceType,
          id,
        } = formJson;
        updateFormInstance({
          variables: {
            thoFormInstance: {
              type,
              requiresArchive: false,
              name,
              isDeleted,
              inspectionId,
              inspectionTypeTemplateId,
              inspectionAssignmentId,
              formJson: encodedFormState,
              formId,
              formConfigurationId,
              reference,
              referenceType,
              id,
            },
          },
        })
          .then(() => {
            setIsSyncing && setIsSyncing(formJson.id, false);
            
          })
          .catch((e) => {});
      }
      let timer = setTimeout(() => {
        syncFormInstanceChanges();
        setIsSyncing && setIsSyncing(formJson.id, false);
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formState, updateFormInstance, setIsSyncing, formJson]);

  function formJsonTransform(parentChain: string[], newForm: IFormJson) {
    function drillSubforms(
      formLevel: IFormJson,
      newFormData: IFormJson,
      chainLevel: number
    ): boolean {
      let newChainLevel = chainLevel + 1;
      let subformIndex = formLevel.subFormInstances.findIndex(
        (subform) => subform.id === parentChain[newChainLevel]
      );
      if (subformIndex === -1) {
        return false;
      }
      if (newChainLevel + 1 === parentChain.length) {
        formLevel.subFormInstances[subformIndex] = newFormData;
        return true;
      }
      return drillSubforms(
        formLevel.subFormInstances[subformIndex],
        newFormData,
        newChainLevel
      );
    }

    let topLevelFormCopy: IFormJson = JSON.parse(JSON.stringify(formJson));
    let success = drillSubforms(topLevelFormCopy, newForm, 0);

    // Drill form json recursion.
    // update form.
    if (success) {
      setFormState(topLevelFormCopy);
    }
  }

  return (
    <TreeView onNodeToggle={handleToggle} expanded={expanded}>
      <InspectionTreeRecurse
        config={config}
        formJson={formState}
        callDrilledUpdate={formJsonTransform}
      />
    </TreeView>
  );
}
