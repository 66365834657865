import {
  DictionaryContents,
  excelColumnToInteger,
  integerToExcelColumn,
} from "src/utils/excelParse";
import { DictionaryLevel } from "../pages/ExcelMappingTool";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";

export default function autofillNextField(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  currentColumnValue: string,
  nextFieldId: string,
  specifiedLocationIndex?: number
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          autofillNextField(
            dictionary.subforms[chain[1].formId],
            newChain,
            currentColumnValue,
            nextFieldId,
            specifiedLocationIndex
          );
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          autofillNextField(
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId],
            newChain,
            currentColumnValue,
            nextFieldId,
            specifiedLocationIndex
          );
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    const nextValue = integerToExcelColumn(
      excelColumnToInteger(currentColumnValue) + 1
    );
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        if (dictionary.fields) dictionary.fields[nextFieldId] = nextValue;
        break;
      case "specifiedLocation":
        if (specifiedLocationIndex === undefined) return;
        dictionary.enumerations[specifiedLocationIndex].fields![nextFieldId] =
          nextValue;
    }
  }
}
