import { useEffect, useState } from "react";
import TextField from "../../../../utils/mui-rhf/TextField";
import Switch from "../../../../utils/mui-rhf/Switch";
import Select from "../../../../utils/mui-rhf/Select";
import DateTimePicker from "../../../../utils/mui-rhf/DateTimePicker";
import { FormProvider, useForm } from "react-hook-form";
import {
    Grid, Button, Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import _ from "lodash";
import { GQLMutationAction } from "../../../../utils/gql-mui-rhf/SingleMutation";

function updateInspectionAssignment(refetch, snackbar, handleClose) {
    return GQLMutationAction({
        mutationString: "updateInspectionAssignment",
        onCompleted: (response) => {
            snackbar("Assignment Updated", "success");
            refetch();
            handleClose();
        },
    });
}

function hasConflict(events, locationId, inspectionId, start) {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    for (const event of events) {
        if (
            event.locationId === locationId &&
            event.inspectionId === inspectionId &&
            new Date(event.start) >= sevenDaysAgo &&
            new Date(event.start) <= new Date(start)
        ) {
            return true; // Conflict found
        }
    }
    return false; // No conflict
}

function isPersonAvailable(events, personId, startTime, endTime) {
    for (const event of events) {
        if (
            event.resourceId === personId &&
            new Date(event.start) <= new Date(endTime) &&
            new Date(event.end) >= new Date(startTime)
        ) {
            // There is an overlapping event; the person is not available
            return false;
        }
    }
    // No overlapping events found; the person is available
    return true;
}

const localDate = (date) => new Date(date.getTime() + date.getTimezoneOffset() * 60000);


export default function Forms({ hoverOpen, handleClose, hoverEvent, inspectors, inspectionLocations, events, refetch, snackbar }) {

    const personId = hoverEvent ? hoverEvent._def.resourceIds[0] : null;
    const inspection = hoverEvent ? hoverEvent._def.extendedProps.inspection : null;
    const inspectionAssignment = hoverEvent ? hoverEvent._def.extendedProps.inspectionAssignment : null;
    const assignmentLocation = hoverEvent ? inspectionLocations.find((location) => location.value === inspection.inspectionLocation) : null;
    const start = hoverEvent ? localDate(hoverEvent._instance.range.start) : null
    const end = hoverEvent ? localDate(hoverEvent._instance.range.end) : null

    const methods = useForm({ defaultValues: { ...assignmentLocation, personId, start, end } });
    const { handleSubmit, setValue, setError, clearErrors, formState } = methods;
    const { errors, isDirty } = formState;

    const [currentInspection, setInspection] = useState({});
    const [showWarning, setShowWarning] = useState(false);

    const handleUpdateInspectionAssignment = updateInspectionAssignment(
        refetch,
        snackbar,
        handleClose
    );
    const onSubmit = (values) => {

        if (!isPersonAvailable(events, values.personId, values.start, values.end)) {
            setError("personId", { type: "availability", message: "Inspector is not available during this time." });
        }

        else if (!showWarning && hasConflict(events, assignmentLocation.inspectionLocation, values.id, values.start)) {
            // Display a warning to the user, e.g., using a modal or a message element
            // Example: set a state variable like showWarning to true
            setShowWarning(true);
        }
        else {
            // Clear the error if the person is available
            clearErrors("personId");
            // Continue with form submission logic
            handleUpdateInspectionAssignment({
                id: inspectionAssignment.id,
                firmPersonId: values.personId,
                personId: values.personId,
                inspectionId: values.id,
                start: values.start,
                end: values.end,
                status: "Scheduled",
                requiresDrawing: values.requiresDrawing,
            });
            methods.reset({});
            setShowWarning(false);
            populateAssignmentLocationData();
        }
    };

    const onDelete = () => {
        handleUpdateInspectionAssignment({
            id: inspectionAssignment.id,
            status: 'cancelled',
            isDeleted: true
        });
        methods.reset({});
        setShowWarning(false);
        populateAssignmentLocationData();
    };

    function populateAssignmentLocationData(aLocation, setVal) {
        _.forOwn(aLocation, (value, key) => {
            setVal(key, value);
        });
    }

    useEffect(() => {
        if (!isDirty) {
            populateAssignmentLocationData(assignmentLocation, setValue);
            setValue("personId", personId)
            setValue("start", start)
            setValue("end", end)
        }
    }, [isDirty, assignmentLocation, personId, start, end, setValue]);

    if (hoverEvent && inspection !== currentInspection) {
        setValue("inspectionId", inspection.id);
        setInspection(inspection);
    }
    const options = inspectors.map((inspector) => {
        return {
            label: `${inspector.firstName} ${inspector.lastName}`,
            value: inspector.id,
            ...inspector,
        };
    });

    if (!hoverEvent) {
        return null
    }

    return (
        <Dialog open={hoverOpen} onClose={handleClose}>
            <DialogTitle>{inspection.inspectionType.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ width: "100%" }}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2} padding={1}>
                                    <Grid item spacing={2} xs={12}>
                                        <TextField
                                            disabled={true}
                                            key={1}
                                            fullWidth
                                            label={"Street 1"}
                                            name={"street1"}
                                        />
                                    </Grid>
                                    <Grid item spacing={2} xs={12}>
                                        <TextField
                                            disabled={true}
                                            key={1}
                                            fullWidth
                                            label={"Street 2"}
                                            name={"street2"}
                                        />
                                    </Grid>
                                    <Grid item spacing={2} xs={12}>
                                        <TextField
                                            disabled={true}
                                            key={1}
                                            fullWidth
                                            label={"Street 3"}
                                            name={"street3"}
                                        />
                                    </Grid>
                                    <Grid item spacing={2} xs={4}>
                                        <TextField
                                            disabled={true}
                                            key={2}
                                            fullWidth
                                            label={"City"}
                                            name={"city"}
                                        />
                                    </Grid>
                                    <Grid item spacing={2} xs={4}>
                                        <TextField
                                            disabled={true}
                                            key={3}
                                            fullWidth
                                            label={"State"}
                                            name={"state"}
                                        />
                                    </Grid>
                                    <Grid item spacing={2} xs={4}>
                                        <TextField
                                            disabled={true}
                                            key={3}
                                            fullWidth
                                            label={"Zip"}
                                            name={"zip"}
                                        />
                                    </Grid>
                                    <Grid item spacing={2} xs={12}>
                                        <Select
                                            options={options}
                                            fullWidth
                                            label={"Inspector"}
                                            name={"personId"}
                                            rules={{ required: true }}
                                        />
                                        {errors.personId && <span style={{ color: 'red' }}>{errors.personId.message}</span>}
                                    </Grid>
                                    {assignmentLocation.inspectionType && (
                                        <Grid item spacing={2} xs={12}>
                                            <Switch
                                                name="requiresDrawing"
                                                label="Requires Drawing"
                                                defaultValue={
                                                    assignmentLocation.inspectionType.requiresDrawing
                                                }
                                                disabled={false}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item spacing={2} xs={12}>
                                        <DateTimePicker
                                            fullWidth
                                            label={"Start"}
                                            name={"start"}
                                            rules={{
                                                required: true,
                                                validate: {
                                                    beforeEnd: (v, fV) =>
                                                        (fV.end && v < fV.end) ||
                                                        "Start date must come before end date",
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item spacing={2} xs={12} style={{ marginBottom: 32 }}>
                                        <DateTimePicker
                                            fullWidth
                                            label={"End"}
                                            name={"end"}
                                            rules={{
                                                required: true,
                                                validate: {
                                                    afterStart: (v, fV) =>
                                                        v > fV.start || "End date must come after start date",
                                                    notOverThree: (v, fV) =>
                                                        (v - fV.start) / (1000 * 60 * 60) <= 3 ||
                                                        "Assignments cannot exceed 3 hours",
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {showWarning && (
                                    <div style={{ color: "orange", paddingTop: '16px', paddingBottom: '16px' }} className="warning-message">
                                        This inspection conflicts with an existing inspection within 7 days.
                                        Are you sure you want to continue?
                                    </div>
                                )}
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Button onClick={onDelete} variant="contained" color="error">
                                            Cancel Inspection
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" type="submit">
                                            submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
