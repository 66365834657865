import { gql } from "@apollo/client";
import { encodeToBase64 } from "pdf-lib";
import { client } from "src";
import { genPDFContent } from "src/pages/Inspections/MyInspections/routes/InspectionWebView/downloadReport";
import {
  IFormInstance,
  IFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import { decode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/decode";

const REPORT_TEMPLATES_QUERY = gql`
  query ReportTypeTemplates(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplates(reportTypeTemplate: $reportTypeTemplate) {
      id
      reportType {
        type
      }
      templateUrl
      configuration
    }
  }
`;

const GET_MAP_CAPTURES = gql`
  query MapCaptures($mapCapture: InputMapCaptureParams!) {
    mapCaptures(mapCapture: $mapCapture) {
      id
      description
      title
      metadata
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

const THO_FORM_INSTANCES = gql`
  query ThoFormInstances($thoFormInstance: InputThoFormInstanceParams) {
    thoFormInstances(thoFormInstance: $thoFormInstance) {
      id
      inspectionId
      inspectionAssignmentId
      formId
      formConfigurationId
      inspectionTypeTemplateId
      inspectionTypeTemplate {
        configuration
        description
        end
        id
        isDeleted
        name
        start
      }
      reference
      referenceType
      formJson
      name
      type
      isDeleted
    }
  }
`;

const INSPECTION_ASSIGNMENTS = gql`
  query InspectionAssignments(
    $inspectionAssignment: InputInspectionAssignmentParams!
  ) {
    inspectionAssignments(inspectionAssignment: $inspectionAssignment) {
      id
      personId
      firmPersonId
      inspection {
        id
        number
        inspectionLocation
        location {
          id
          description
          city
          buildingType
          identifier
          lat
          lis
          lng
          mapboxId
          name
          neighborhood
          owner
          parentId
          projectId
          state
          street1
          street2
          street3
          tenant
          unit
          zip
        }
        inspectionType {
          id
          name
        }
      }
      thoFormInstances {
        id
        inspectionId
        inspectionAssignmentId
        formId
        formConfigurationId
        inspectionTypeTemplateId
        inspectionTypeTemplate {
          configuration
          description
          end
          id
          isDeleted
          name
          start
        }
        reference
        referenceType
        formJson
        name
        type
        isDeleted
      }
      inspectionId
      start
      end
      formChangesAcknowledged
      frontElevationPhotoCaptured
      isCheckedIn
      notesAcknowledged
      eSignatureAcknowledged
      rightOfEntryCaptured
      onsiteComplete
      inaccessible
      stopWorkHazardPresent
      status
      requiresDrawing
      isDeleted
      isMobile
      isWeb
    }
  }
`;

const UPLOAD_REPORT_RESULT = gql`
  mutation UploadReportResult($reportResult: InputReportResultParams!) {
    uploadReportResult(reportResult: $reportResult) {
      id
    }
  }
`;

const UPLOAD_REPORT_GENERATION_REQUEST = gql`
  mutation UploadReportGenerationRequest(
    $reportGenerationRequest: InputReportGenerationRequestParams!
  ) {
    uploadReportGenerationRequest(
      reportGenerationRequest: $reportGenerationRequest
    ) {
      id
    }
  }
`;


function uploadReportResult(variables:any){
    return client.mutate({
        mutation: UPLOAD_REPORT_RESULT,
        variables: variables,
        fetchPolicy: 'network-only'
    })
}

function uploadReportGenerationRequest(variables:any){
    return client.mutate({
        mutation: UPLOAD_REPORT_GENERATION_REQUEST,
        variables: variables,
        fetchPolicy: 'network-only'
    })
}

function inspectionAssignmentQuery(variables:any){
    return client.query({
        query: INSPECTION_ASSIGNMENTS,
        variables: variables,
        fetchPolicy: 'network-only'
    })
}

function getReportTypeTemplates(variables:any){
    return client.query({
        query: REPORT_TEMPLATES_QUERY,
        variables: variables,
        fetchPolicy: 'network-only'
    })
}

function getMapCaptures(variables:any){
    return client.query({
        query: GET_MAP_CAPTURES,
        variables: variables,
        fetchPolicy: 'network-only'
    })
}


export function generateReports(
    inspectionAssignmentId: any,
    person: any,
    requestId: any
  ) {
    genReports(inspectionAssignmentId, person, requestId);
  }

  async function genReports(
    inspectionAssignmentId: any,
    person: any,
    requestId: any
  ) {
    var ias: any = [];

      inspectionAssignmentQuery({
          inspectionAssignment: {
            id: inspectionAssignmentId,
          },
        }).then((result: any) => {
        var ia = result.data.inspectionAssignments[0];
        var myLoc = ia.inspection.location;
        var iaWithAddress = {
          ...ia,
          formattedAddress:
            myLoc.street1 +
            " " +
            myLoc.city +
            " " +
            myLoc.state +
            " " +
            myLoc.zip,
        };
        ias.push(iaWithAddress);
        step2(ias, person, requestId).then((result: any) => {});
      });
  }

  async function step2(
    loadedInspectionAssignments: any,
    person: any,
    requestId: any
  ) {
    let loadedInstancesData: any = [];
    let reportTemplates: any = [];
    let loadedCount = 0;
    let formInstancesLoadedCount = 0;
    let validChecks: any = [];
    let index = 0;
    loadedInspectionAssignments.forEach((ia: any) => {
      loadedCount++;
      let valid = true;
      let instances: IFormJson[] = ia.thoFormInstances.map(
        (instance: IFormInstance) => {
          var json = decode(instance.formJson);
          if (!json.isValid) {
            valid = false;
          }
          return json;
        }
      );
      validChecks.push({ isValid: valid, index: index });
      index++;
      instances.forEach((i) => {
        loadedInstancesData.push({
          inspectionId: ia.inspectionId,
          inspectionAssignmentId: ia.id,
          formInstance: i,
          inspectionTypeTemplateId: i.inspectionTypeTemplateId,
          address: ia.formattedAddress,
        });
      });
      let formInstancesLoadedCount = 0;
      ia.thoFormInstances.forEach((fi: any) => {
        formInstancesLoadedCount++;
        getReportTypeTemplates({
            reportTypeTemplate: {
              inspectionTypeTemplateId: fi.inspectionTypeTemplate.id,
            },
          }).then((r:any) => {
          r.data.reportTypeTemplates.forEach((rtt: any) => {
            let existing = reportTemplates.find((e: any) => e.id === rtt.id);
            if (!existing) {
              reportTemplates.push({
                ...rtt,
                inspectionTypeTemplateId: fi.inspectionTypeTemplate.id,
              });
            }
          });
          // console.log(formInstancesLoadedCount + ":" + ia.thoFormInstances.length);
          if (formInstancesLoadedCount === ia.thoFormInstances.length) {
            if (loadedCount === loadedInspectionAssignments.length) {
              let ias: any = [...loadedInspectionAssignments];
              validChecks.forEach((vc: any) => {
                let assignment: any = ias[vc.index];
                assignment.isValid = vc.isValid;
                ias[vc.index] = assignment;
              });
              step3(
                ias,
                loadedInstancesData,
                reportTemplates,
                person,
                requestId
              );
            }
          }
        });
      });
    });
  }

  async function step3(
    inspectionAssignments: any,
    loadedInstances: any,
    reportTemplates: any,
    person: any,
    requestId: any
  ) {
    let pdfs = reportTemplates;
    let formStates = loadedInstances;
    let mapCaptures: any = null;

    let formStateCount = 0;
    let formPdfs: any = [];
    formStates.forEach((fs: any) => {
      getMapCaptures({
          mapCapture: {
            inspectionId: fs.inspectionId,
            inspectionAssignmentId: fs.inspectionAssignmentId,
          },
        }).then((response: any) => {
        if (response.data.mapCaptures) {
          var responseMapCaptures: any = [...response.data.mapCaptures];
          for (var i = 0; i < responseMapCaptures.length; i++) {
            var newMapCapture = {
              ...responseMapCaptures[i],
            };
            newMapCapture.image =
              "data:image/png;base64, " + newMapCapture.image;
            responseMapCaptures[i] = newMapCapture;
          }
          mapCaptures = responseMapCaptures;
        }

        let myPDFs = pdfs.filter((pdf: any) => {
          var templateId = Object.keys(pdf.configuration)[0];
          let name = pdf.configuration[templateId].name;
          return (
            pdf.inspectionTypeTemplateId === fs.inspectionTypeTemplateId &&
            name === fs.formInstance.name
          );
        });

        let focusedPDF = null;
        if (myPDFs.length === 1) {
          focusedPDF = myPDFs[0];
        }
        genPDFContent(focusedPDF, fs, mapCaptures).then((result: any) => {
          formStateCount++;
          if (result) {
            formPdfs.push(result);
          }
          if (formStateCount === formStates.length) {
            let reports: any = [];
            formPdfs.forEach((fp: any) => {
              reports.push({
                  reportResult: {
                    reportTypeTemplateId: fp.reportTemplateId,
                    inspectionId: fp.formState.inspectionId,
                    inspectionAssignmentId: fp.formState.inspectionAssignmentId,
                    thoFormInstanceId: fp.formState.formInstance.id,
                    documentBytes: encodeToBase64(fp.pdfBytes),
                    requestedBy: person.id,
                    fileName: fp.filename,
                    fileExtension: ".pdf",
                  },
                });
            });

            uploadReportTemplates(reports, requestId);
          }
        });
      });
    });
  }

  async function uploadReportTemplates(reports: any, requestId: any) {
    if (reports) {
      if (reports.length > 0) {
        let uploadedCount = 0;
        let reportResultIds: any = [];
        reports.forEach((fp: any) => {
          uploadReportResult(fp).then((result: any) => {
            uploadedCount++;
            reportResultIds.push(result.data.uploadReportResult.id);
            if (uploadedCount === reports.length) {
              uploadReportGenerationRequest({
                  reportGenerationRequest: {
                    id: requestId,
                    jobStatus: "completed",
                    destinationReportResultIds: reportResultIds,
                  },
                });
            }
          });
        });
      }
    }
  }
