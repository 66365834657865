import { useState, useContext, useEffect } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from "react-hook-form";
import { gql } from '@apollo/client';
import Select from '../../../utils/mui-rhf/Select';
import TextField from '../../../utils/mui-rhf/TextField';
import DateTimePicker from '../../../utils/mui-rhf/DateTimePicker';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PersonContext } from "../../../utils/contexts/person";

const GET_ASSET_TYPES = gql`
    query assetTypes($firmUnitId: ID) {
    assetTypes(firmUnitId: $firmUnitId) {
        name
        id
    }
    }
`

const CREATE_ASSET = gql`
    mutation createAsset($asset: InputAssetParams){
      createAsset(asset: $asset) {
        id
        name
      }
    }
  `;
export default function CreateAsset({ open, setOpen, getAssets, firmId }) {
    const handleClose = () => {
        setOpen(false);
    };

    let personContext = useContext(PersonContext);
    const onCompleted = () => { 
        handleClose()
        getAssets({ variables: { firmUnitId: personContext.person.firmId } })
    }
    const [createAsset] = useMutation(CREATE_ASSET, { onCompleted });

  

    const methods = useForm({ defaultValues: { operationValues: [] } });
    const { handleSubmit } = methods;
    const onSubmit = (data) => {
        createAsset({ variables: { asset: { 
            name: data.name,
            firmUnitId: personContext.person.firmId,
            assetTypeId: data.assetTypeId,
            serialNumber: data.serialNumber,
            manufacturer: data.manufacturer,
            model: data.model,
            purchaseCost: data.purchaseCost,
            currentValue: data.currentValue,
            location: data.location,
            maintenanceInterval: parseInt(data.maintenanceInterval),
            calibrationInterval: parseInt(data.calibrationInterval),
            factoryCalibrationInterval: parseInt(data.factoryCalibrationInterval),
            purchaseDate: data.purchaseDate
         } } })
    }
    const [assetTypes, setAssetTypes] = useState(null);
    const [getAssetTypes] = useLazyQuery(GET_ASSET_TYPES, {
        onCompleted: (response) => {
            setAssetTypes(response.assetTypes.map((assetType) => {
                return { label: assetType.name, value: assetType.id }
            }))
        },
        onError: (response) => {
            debugger
        }
    });
    useEffect(
        () => {
            if (personContext.person.firmId && !assetTypes) {
                getAssetTypes({ variables: { firmUnitId: personContext.person.firmId } })
            }
        },
        [assetTypes, getAssetTypes, personContext]
    )
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add New Asset</DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField required label={"Name"} name={'name'} />
                            </Grid>
                            <Grid item xs={6}>
                                <Select minWidth={200} required label={"Asset Type"} name={'assetTypeId'} options={assetTypes} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required label={"Serial Number"} name={'serialNumber'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required label={"Manufacturer"} name={'manufacturer'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required label={"Model"} name={'model'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type={"number"} required label={"Purchase Cost"} name={'purchaseCost'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type={"number"} required label={"Current Value"} name={'currentValue'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField required label={"Location"} name={'location'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type={"number"} required label={"Maintenance Interval"} name={'maintenanceInterval'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type={"number"} required label={"Calibration Interval"} name={'calibrationInterval'} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type={"number"} required label={"Factory Calibration Interval"} name={'factoryCalibrationInterval'} />
                            </Grid>
                            <Grid item xs={6}>
                                <DateTimePicker type={"number"} required label={"Purchase Date"} name={'purchaseDate'} />
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type={'submit'}>Save</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
}