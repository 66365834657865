// material
import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// components
import Page from "../../../components/Page";
import { GQLForm } from "../../../utils/gql-mui-rhf/FormProvider";
import { createQuery } from "../../../utils/gql-mui-rhf/QueryAction";
import { Checkbox, Select, TextField } from "../../../utils/mui-rhf";
import { useContext } from "react";
import { ProjectContext } from "../../../utils/contexts/project";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";

const GET_FIRM_UNIT = gql`
  query firmUnit($id: ID!) {
    firmUnit(id: $id) {
      id
      firm {
        id
        name
      }
      dba
      domain
      duns
      fax
      fein
      legalAbbreviation
      legalName
      name
      owner
      phone1
      phone2
      website
    }
  }
`;
// ----------------------------------------------------------------------
export default function OnboardingFirm() {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  const snackbar = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const firms = createQuery("roleTypes", { active: true, entity: "FIRM" });
  const people = createQuery("roleTypes", {
    active: true,
    faRole: true,
    entity: "PERSON",
  });

  let [firmUnit, setFirmUnit] = useState(null);
  const [getFirmUnit] = useLazyQuery(GET_FIRM_UNIT, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setFirmUnit(response.firmUnit);
    },
    onError: (response) => {
      debugger;
    },
  });
  useEffect(() => {
    if (project) {
      getFirmUnit({ variables: { id: project.projectOwner } });
    }
  }, [project, getFirmUnit]);
  if (firms.loading || people.loading) return <CircularProgress />;
  if (firms.error || people.error) {
    return <div>{`${firms.error}`}</div>;
  }
  if (!project || !firmUnit) {
    return <div>Select a Project to Continue</div>;
  }
  if (firmUnit && firms.data && people.data) {
    const selectOptions = people.data.roleTypes.map((roleType) => ({
      value: roleType.id,
      label: roleType.name,
    }));

    const options = firms.data.roleTypes.map((roleType) => ({
      value: roleType.id,
      name: roleType.name,
      label: roleType.name,
    }));
    return (
      <Page title="Onboarding | FINBACK670">
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Onboard New Firm</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item container spacing={0} xs={12} sm={6} md={3}>
              <GQLForm
                mutationString={"createRolesRequest"}
                additionalVariables={{
                  entity: "FIRM",
                  projectId: project.id,
                  projectFirmUnitId: project.projectOwner,
                  projectFirmId: firmUnit.firm.id,
                }}
                skipFields={{
                  projectFirmId: true,
                  projectFirmUnitId: true,
                  projectId: true,
                  entity: true,
                  id: true,
                  faRole: true,
                  roleTypes: true,
                  name: true,
                }}
                defaultValues={{ roleTypes: [] }}
                inputOrder={{
                  "First Name": { order: 1 },
                  "Last Name": { order: 2 },
                }}
                onCompleted={(res) => {
                  snackbar("Onboarding Request Submitted", "success");
                  if (res.createRolesRequest)
                    navigate(
                      `/app/team/onboarding/request/${res.createRolesRequest.id}`
                    );
                }}
              >
                <Grid item xs={12} style={{ marginBottom: 32 }}>
                  <TextField
                    rules={{ required: "Required Field" }}
                    fullWidth
                    label={"Firm Name"}
                    name={"name"}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 32 }}>
                  <Select
                    rules={{ required: "select option" }}
                    fullWidth
                    label={"Firm Administrator Role"}
                    name={"faRole"}
                    options={selectOptions}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 32 }}>
                  <Checkbox
                    rules={{ required: "select one or many" }}
                    description={"Roles"}
                    fieldName={"roleTypes"}
                    options={options}
                  />
                </Grid>
              </GQLForm>
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
}
