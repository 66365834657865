import {
    PageState,
    Action,
    Validation,
    GeoFeatureConfiguration,
  } from "../../components/TypeDefinitions";
  import { v4 as uuidv4 } from "uuid";
  
  export function createGeoFeatureConfiguration<
    E extends Extract<
      Action,
      {
        type: "createGeoFeatureConfiguration";
      }
    >
  >(action: E, draft: PageState): void {
    
    const newGeoFeatureConfiguration: GeoFeatureConfiguration = generateNewGeoFeatureConfiguration();
  
    const formIndex =
      draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
        (form) => form.id === draft.formId
      );
  if(!draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].geoFeatureConfigurations){
    draft.data.inspectionTypeTemplate.configuration.forms[
      formIndex
    ].geoFeatureConfigurations = [];
  }
    draft.data.inspectionTypeTemplate.configuration.forms[
      formIndex
    ].geoFeatureConfigurations.push(newGeoFeatureConfiguration);
  }
  
  function generateNewGeoFeatureConfiguration(): GeoFeatureConfiguration {
    const newConfig: GeoFeatureConfiguration = {
        id: uuidv4(),
        geoRuleId: null,
        count: null,
        order: "asc"
    };
    return newConfig;
  }
  