import {
  DictionaryContents,
  excelColumnToInteger,
  getLowestLocation,
} from "src/utils/excelParse";
import { DictionaryLevel } from "../pages/ExcelMappingTool";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";

export default function offsetAdd(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  newColumnValue: string
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          offsetAdd(
            dictionary.subforms[chain[1].formId],
            newChain,
            newColumnValue
          );
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          offsetAdd(
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId],
            newChain,
            newColumnValue
          );
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    if (dictionary.enumerable !== "byOffset") return;
    const { value } = getLowestLocation(dictionary);
    const newColumnNumber = excelColumnToInteger(newColumnValue);
    
    if (!value) return;
    const offset = newColumnNumber - value;
    dictionary.offsetLocations.push(offset);
  }
}
