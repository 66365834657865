import { useEffect } from 'react';
import TextField from "../../../utils/mui-rhf/TextField";
import Switch from "../../../utils/mui-rhf/Switch";
import Address from "../../../utils/mui-rhf/Address";
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Button, FormLabel } from '@mui/material';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import FileUpload from './FileUpload';
import { options } from '@fullcalendar/core/preact';

const UPDATE_PROJECT = gql`
mutation updateProject($project: InputProjectParams) {
    updateProject(project: $project) {
        name
        projectLogo
        projectNumber
        projectOwner
        numberOfUnits
        publicPrivatePartnership
        allowDataReplication
        projectLocation {
            street1
            street2
            street3
            city
            state
            zip
        }
    }
  }
`
const projectLocationParams = (project) => {
    const allowedProps = ["city", "description", "mapboxId", "name", "owner", "state", "street1", "street2", "street3", "tenant", "zip", "lat", "lng", "neighborhood"]
    const result = {};

    for (const prop of allowedProps) {
        if (project.hasOwnProperty(prop) && !!project[prop]) {
            result[prop] = project[prop];
        }
    }
    return result;
}
const projectParams = (project) => {
    const allowedProps = ['id', 'name', 'description', 'end', 'invoiceTemplate', 'locationId', 'popEnd', 'popStart', 'projectId', 'projectLogo', 'projectNumber', 'projectOwner', 'start', 'projectLocation', 'numberOfUnits', 'publicPrivatePartnership', 'allowDataReplication'];
    const result = {};

    for (const prop of allowedProps) {
        if (project.hasOwnProperty(prop) && !!project[prop]) {
            if (prop === 'projectLocation') {
                result[prop] = projectLocationParams(project[prop])
            } else {
                result[prop] = project[prop];
            }
        }
    }
    return result;
}

export default function Forms({ project, refetchProject }) {
    
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const defaultValues = projectParams(project)

    const methods = useForm({ defaultValues });
    useEffect(() => {
        methods.reset(projectParams(project))
    }, [project, methods])


    const { handleSubmit } = methods;
    const [updateProject] = useMutation(UPDATE_PROJECT, {
        onCompleted: (response) => {
            methods.reset(projectParams(response.updateProject))
            snackbar('Project Updated', 'success');
            refetchProject();
        }
    })


    const onSubmit = (values) => {
        updateProject({
            variables: {
                project: {
                    id: project.id,
                    ...values,
                    numberOfUnits: parseInt(values.numberOfUnits)
                }
            }
        })
    }
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ width: "100%" }}>
                    <Grid container spacing={2} padding={1}>
                        <Grid item spacing={2} xs={12} >
                            <TextField key={1} fullWidth rules={{ required: "Required Field" }} label={"Project Name"} name={"name"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <TextField key={2} fullWidth rules={{ required: "Required Field" }} label={"Project Number"} name={"projectNumber"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                           
                                <Switch
                                name="allowDataReplication"
                                label="Allow Data Replication"
                                defaultValue={
                                    project.allowDataReplication ? project.allowDataReplication : false
                                }
                                disabled={false}
                                />
                        </Grid>
                        {!project.parentProject?.id && <Grid item spacing={2} xs={12}>
                            <FormLabel component="legend">Project Logo</FormLabel>
                            <FileUpload fieldName={'projectLogo'} setValue={methods.setValue} defaultValues={defaultValues} />
                        </Grid>}
                        <Grid item spacing={2} xs={12} >
                            <Address
                                methods={methods}
                                group={'projectLocation.'}
                                initialConfig={{ type: 'create' }}
                                rules={
                                    {
                                        city: { required: "Required Field" },
                                        state: { required: "Required Field" }
                                    }
                                }
                            />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <TextField key={4} fullWidth label={"Number of Units"} name={"numberOfUnits"} />
                        </Grid>
                        <Grid item spacing={2} xs={12} >
                            <TextField key={5} fullWidth label={"Public Private Partnership"} name={"publicPrivatePartnership"} />
                        </Grid>
                    </Grid>
                </div>
                <Button variant="contained" type="submit">
                    {"Update"}
                </Button>
            </form>
        </FormProvider>
    );
}