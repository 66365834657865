import { useState, useContext } from "react";
import GQLTable from "../../../utils/gql-mui-rhf/Table";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../../utils/contexts/project";
// material
import { Grid, Container } from "@mui/material";
// components
import Page from "../../../components/Page";
import AddProject from "../createProject";
// ----------------------------------------------------------------------

export default function Projects(props) {
  const navigate = useNavigate();
  let projectContext = useContext(ProjectContext);
  const { setProject } = projectContext;
  let projectId = props.projectId;
  let firmUnitId = props.firmUnitId;
  const [addProject, setAddProject] = useState(false);
  const closeAddProject = () => setAddProject(false);
  const openAddProject = () => setAddProject(true);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const refetch = () => setShouldRefetch(true);
  return (
    <Page title="Projects | FINBACK670">
      <AddProject
        open={addProject}
        handleClose={closeAddProject}
        projectId={projectId}
        projectOwner={firmUnitId}
        refetch={refetch}
      />
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GQLTable
              queryString={"projects"}
              shouldRefetch={shouldRefetch}
              setShouldRefetch={setShouldRefetch}
              deleteMutationString={"deleteProject"}
              customActions={[
                {
                  text: "Add Project",
                  callback: openAddProject,
                  type: "general",
                },
              ]}
              queryVariables={{
                projectOwner: firmUnitId,
                projectId: projectId,
                active: true,
              }}
              addMutationVariables={{
                projectOwner: firmUnitId,
                projectId: projectId,
              }}
              updateModal={true}
              columnOrder={[
                "projectNumber",
                "name",
                "projectLocation",
                "numberOfUnits",
                "publicPrivatePartnership",
              ]}
              columnOptions={{
                projectNumber: { headerName: "Project Number" },
                numberOfUnits: { headerName: "Number of Units" },
                projectLocation: {
                  headerName: "Location",
                  valueGetter: ({ value }) =>
                    value && `${value.city}, ${value.state}`,
                },
                name: { headerName: "Name" },
                publicPrivatePartnership: {
                  headerName: "Public Private Partnership",
                },
              }}
              restrictedColumns={{
                childProjects: true,
                parentProject: true,
                id: true,
                client: true,
                offeror: true,
                projectPersons: true,
                projectFirmUnits: true,
                description: true,
                active: true,
                projectOwner: true,
                createdBy: true,
                locationId: true,
                invoiceTemplate: true,
                popEnd: true,
                popStart: true,
                projectId: true,
                projectLogo: true,
                end: true,
                start: true,
              }}
              onRowClick={(rowData) => {
                setProject(rowData);
                if (props.setValue) {
                  props.setValue(0);
                } else {
                  navigate(`/app/details`);
                }
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
