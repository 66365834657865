import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "src/utils/contexts/person";

export type WashoutCommentDialogConfiguration =
  | { state: "closed" | "add" }
  | { state: "edit"; note: string };

const CREATE_NOTE = gql`
  mutation CreateWashoutNote($washoutNote: InputWashoutNoteParams) {
    createWashoutNote(washoutNote: $washoutNote) {
      id
    }
  }
`;

export default function WashoutCommentDialog({
  configuration,
  handleClose,
  washoutId,
  refetch,
}: {
  configuration: WashoutCommentDialogConfiguration;
  handleClose: () => void;
  refetch: () => void;
  washoutId: string;
}) {
  const { state } = configuration;
  const [note, setNote] = useState("");

  useEffect(() => {
    configuration.state === "edit" ? setNote(configuration.note) : setNote("");
  }, [configuration]);

  const { person }: any = useContext(PersonContext);

  const [createNote, { data }] = useMutation(CREATE_NOTE, {
    onCompleted: () => {
      refetch();
      handleClose();
    },
  });

  return (
    <Dialog open={state !== "closed"} onClose={handleClose}>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          //   console.log(person_context);
          //   debugger;
          // createNote();
          await createNote({
            variables: {
              washoutNote: {
                note: note,
                createdPersonId: person.id,
                washoutId: washoutId,
              },
            },
          });
          // handleClose();
        }}
      >
        <DialogContent>
          <TextField
            label="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
