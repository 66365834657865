import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Configuration } from "../../FormBuilder/components/TypeDefinitions";
import {
  Dispatch,
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Dictionary,
  DictionaryContents,
  getLowestLocation,
  integerToExcelColumn,
} from "src/utils/excelParse";
import { useImmerReducer } from "use-immer";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import offsetRemove from "../actions/offsetRemove";
import offsetUpdate from "../actions/offsetUpdate";
import offsetAdd from "../actions/offsetAdd";
import updateField from "../actions/updateField";
import autofillNextField from "../actions/autofillNextField";
import { read, utils } from "xlsx";
import changeEnumerationType, {
  ChangeEnumerationParams,
} from "../actions/changeEnumerateType";
import addSpecificLocation from "../actions/addSpecificLocation";
import { SyncHandler } from "../../FormBuilder/FormWrapper/SyncHandler";
import { ArrowRight, Clear } from "@mui/icons-material";
import createMapping from "../actions/createMapping";
import deleteMapping from "../actions/deleteMapping";
import useDebounce from "../../../../utils/useDebounce";

const getReport = gql`
  query ReportTypeTemplate(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplate(reportTypeTemplate: $reportTypeTemplate) {
      templateUrl
      inspectionTypeTemplate {
        id
        name
        description
        start
        end
        configuration
        isDeleted
      }
      id
      configuration
    }
  }
`;

const updateReport = gql`
  mutation UpdateReportTypeTemplate(
    $reportTypeTemplate: InputReportTypeTemplateParams
  ) {
    updateReportTypeTemplate(reportTypeTemplate: $reportTypeTemplate) {
      id
      configuration
    }
  }
`;

const ExcelMappingTool = () => {
  const { inspectionTypeTemplateId } = useParams();
  const { data, loading } = useQuery(getReport, {
    fetchPolicy: 'network-only',
    variables: {
      reportTypeTemplate: {
        inspectionTypeTemplateId: inspectionTypeTemplateId,
      },
    },
  });

  if (data) {
    console.log("initial dictionary");
    console.log(data.reportTypeTemplate.configuration);
  }

  return (
    <>
      {loading && <CircularProgress />}
      {data && (
        <ExcelMapDisplay
          config={data.reportTypeTemplate.inspectionTypeTemplate.configuration}
          initialDictionary={data.reportTypeTemplate.configuration}
        />
      )}
    </>
  );
};

export type Action =
  | {
      type: "updateField";
      payload: {
        chain: DictionaryLevel[];
        fieldId: string;
        newExcelValue: string;
      };
    }
  | {
      type: "addOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        enumerationStartLocation: string;
      };
    }
  | {
      type: "deleteOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        index: number;
      };
    }
  | {
      type: "updateOffsetLocation";
      payload: {
        chain: DictionaryLevel[];
        index: number;
        newValue: number;
      };
    }
  | {
      type: "openOffsetDialog";
      payload: {
        chain: DictionaryLevel[];
      };
    }
  | {
      type: "autofillNextField";
      payload: {
        chain: DictionaryLevel[];
        currentColumnValue: string;
        nextFieldId: string;
        specifiedLocationIndex?: number;
      };
    }
  | {
      type: "changeEnumerateType";
      payload: {
        chain: DictionaryLevel[];
        newType: ChangeEnumerationParams;
      };
    }
  | {
      type: "addSpecificLocation";
      payload: {
        chain: DictionaryLevel[];
      };
    }
  | {
      type: "createMapping";
      payload: {
        chain: DictionaryLevel[];
        newRemap: {
          mappedFrom: string | "bool-val-true" | "bool-val-false";
          mappedTo: string;
        };
      };
    }
  | {
      type: "deleteMapping";
      payload: {
        chain: DictionaryLevel[];
        mappedFromValue: string;
      };
    };

type PageState = {
  dictionary: Dictionary;
  addOffsetState: null | DictionaryLevel[];
  config: Configuration;
};

function reducer(draft: PageState | null, action: Action) {
  if (draft === null) return;
  switch (action.type) {
    case "updateField":
      for (const content in draft.dictionary) {
        updateField(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.fieldId,
          action.payload.newExcelValue
        );
        break;
      }
      break;
    case "addOffsetLocation":
      for (const content in draft.dictionary) {
        offsetAdd(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.enumerationStartLocation
        );
        break;
      }
      break;
    case "deleteOffsetLocation":
      for (const content in draft.dictionary) {
        offsetRemove(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.index
        );
      }
      break;
    case "updateOffsetLocation":
      for (const content in draft) {
        offsetUpdate(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.index,
          action.payload.newValue
        );
      }
      break;
    case "openOffsetDialog":
      draft.addOffsetState = action.payload.chain;
      break;
    case "autofillNextField":
      for (const content in draft.dictionary) {
        autofillNextField(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.currentColumnValue,
          action.payload.nextFieldId,
          action.payload.specifiedLocationIndex
        );
      }
      break;
    case "changeEnumerateType":
      for (const content in draft.dictionary) {
        changeEnumerationType(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.newType,
          draft.config
        );
      }
      break;
    case "addSpecificLocation":
      for (const content in draft.dictionary) {
        addSpecificLocation(
          draft.dictionary[content],
          action.payload.chain,
          draft.config
        );
      }
      break;
    case "createMapping":
      for (const content in draft.dictionary) {
        createMapping(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.newRemap
        );
      }
      break;
    case "deleteMapping":
      for (const content in draft.dictionary) {
        deleteMapping(
          draft.dictionary[content],
          action.payload.chain,
          action.payload.mappedFromValue
        );
      }
      break;
    default:
      exhaustiveGuard(action);
  }
}

const ExcelMapCtx = createContext<{
  draft: PageState;
  dispatch: Dispatch<Action>;
} | null>(null);

const ExcelMapDisplay: FC<{
  config: Configuration;
  initialDictionary: Dictionary;
}> = ({ config, initialDictionary }) => {
  const topLevelFormId =
    config.forms.find((form) => form.type === "Questionnaire")?.id || null;

  const [updateTemplate] = useMutation(updateReport);

  const { reportTypeTemplateId } = useParams();

  const [pageState, dispatch] = useImmerReducer(
    reducer,
    initialDictionary
      ? {
          dictionary: initialDictionary,
          addOffsetState: null,
          config: config,
        }
      : null
  );
  const [file, setFile] = useState<File | null>(null);

  const syncing = useDebounce({
    stateToWatch: pageState?.dictionary || null,
    debounceTimeoutMS: 1000,
    callback: (state) => {
      if (!state) return;
      return updateTemplate({
        variables: {
          reportTypeTemplate: {
            id: reportTypeTemplateId,
            configuration: JSON.stringify(state),
          },
        },
      });
    },
  });

  useEffect(() => {
    (async () => {
      if (!file) return;
      const buffer = await file.arrayBuffer();
      /* Download file */ const wb = read(buffer); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data: any = utils.sheet_to_json(ws, { header: "A" }); // generate objects
      console.log(data);
    })();
  }, [file]);

  pageState && console.log(pageState.dictionary);

  return (
    <>
      {pageState && topLevelFormId && (
        <>
          <SyncHandler syncing={syncing} errors={[]}>
            <ExcelMapCtx.Provider
              value={{ draft: pageState, dispatch: dispatch }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <input
                  style={{ display: "none" }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id="contained-button-file"
                  multiple
                  type="file"
                  value=""
                  onChange={(e) => {
                    let uploadFile =
                      (e.target as HTMLInputElement).files !== null
                        ? (e.target as HTMLInputElement).files![0]
                        : null;
                    if (!uploadFile) return;
                    setFile(uploadFile);
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                {file && <Typography fontWeight="bold">{file.name}</Typography>}
              </Stack>
              <DictionaryRecurse
                config={config}
                key={JSON.stringify(pageState.dictionary[topLevelFormId])}
                topLevelFormId={topLevelFormId}
                dictionary={pageState.dictionary[topLevelFormId]}
              />
            </ExcelMapCtx.Provider>
          </SyncHandler>
        </>
      )}
    </>
  );
};

export type DictionaryLevel = {
  formId: string;
  enumeration: number | false;
  name: string;
};

const DictionaryRecurse = ({
  dictionary,
  topLevelFormId,
  config,
  dictionaryLevel,
}: {
  dictionary: DictionaryContents;
  config: Configuration;
  topLevelFormId: string;
  dictionaryLevel?: DictionaryLevel[];
}) => {
  const form = config.forms.find((form) => topLevelFormId === form.id);
  const ctx = useContext(ExcelMapCtx);

  if (!form || !ctx) {
    return <></>;
  }

  const { dispatch } = ctx;

  const level =
    dictionaryLevel === undefined
      ? ([
          { formId: form.id, enumeration: false, name: form.name },
        ] as DictionaryLevel[])
      : dictionaryLevel;

  if (!dictionary || dictionary.enumerable === undefined) debugger;

  switch (dictionary.enumerable) {
    case "byOffset":
    case false:
      return (
        <div
          style={{
            border: "1px solid green",
            paddingLeft: "1rem",
            marginTop: "0.5rem",
            paddingBottom: "1rem",
          }}
        >
          <ChangeEnumerationType
            enumerationType={dictionary.enumerable}
            updateEnumeration={(params: ChangeEnumerationParams) => {
              dispatch({
                type: "changeEnumerateType",
                payload: {
                  chain: level,
                  newType:
                    params.type === "groupMember"
                      ? {
                          type: params.type,
                          groupParent: params.groupParent,
                        }
                      : {
                          type: params.type,
                        },
                },
              });
            }}
          />
          {dictionary.enumerable === "byOffset" ? (
            <h1 style={{ fontWeight: "bold" }}>
              ENUMERATED GROUP: {form.name} [
              {dictionary.offsetLocations.length + 1}]
            </h1>
          ) : (
            <h1 style={{ fontWeight: "bold" }}>{form.name}</h1>
          )}
          {dictionary.enumerable === "byOffset" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "0.25rem",
              }}
            >
              <h1 style={{ fontWeight: "bold" }}>
                Offset Count:{" "}
                {dictionary.offsetLocations.length ? (
                  `${dictionary.offsetLocations.length}`
                ) : (
                  <span style={{ fontWeight: "bold" }}>NONE</span>
                )}
              </h1>
              {dictionary.offsetLocations.map((location, i) => {
                const getLocationStart = integerToExcelColumn(
                  (getLowestLocation(dictionary).value as number) +
                    (location as number)
                );
                return (
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: "1" }}
                  >
                    <p>{getLocationStart}</p>
                    <button
                      onClick={(e) =>
                        dispatch({
                          type: "deleteOffsetLocation",
                          payload: {
                            chain: level,
                            index: i,
                          },
                        })
                      }
                      style={{ color: "red" }}
                    >
                      Delete
                    </button>
                  </div>
                );
              })}

              <AddEnumeration
                fn={(str: string) =>
                  dispatch({
                    type: "addOffsetLocation",
                    payload: {
                      chain: level,
                      enumerationStartLocation: str,
                    },
                  })
                }
              />
            </div>
          )}

          <RemapDisplay remapObject={dictionary.remaps} chain={level} />
          {form.fields.map((field, fieldIndex) => {
            const excelMapping = dictionary.fields![field.id] || "";
            return (
              <div style={{ paddingBottom: "0.5rem" }}>
                <p>{field.label}</p>
                <input
                  type="text"
                  placeholder="Excel Column"
                  value={excelMapping}
                  onKeyDown={(e) => {
                    if (e.key === "Tab") {
                      if (form.fields[fieldIndex + 1] === undefined) return;
                      const nextFieldId = form.fields[fieldIndex + 1].id;
                      dispatch({
                        type: "autofillNextField",
                        payload: {
                          chain: level,
                          currentColumnValue: excelMapping,
                          nextFieldId: nextFieldId,
                        },
                      });
                    }
                  }}
                  onChange={(e) => {
                    dispatch({
                      type: "updateField",
                      payload: {
                        fieldId: field.id,
                        newExcelValue: e.target.value,
                        chain: level,
                      },
                    });
                  }}
                  style={{
                    border: `1px solid ${
                      excelMapping === "" ? "orange" : "lightgrey"
                    }`,
                    borderRadius: "5px",
                    paddingLeft: "3px",
                  }}
                />
              </div>
            );
          })}
          {dictionary.subforms &&
            form.subformIds.length &&
            form.subformIds.map((subformId) => {
              const subDictionary = dictionary.subforms![subformId];
              if (!subDictionary) return null;
              const newLevel = [
                ...level,
                {
                  enumeration: false,
                  formId: subformId,
                  name: subDictionary.name,
                },
              ] as DictionaryLevel[];
              return (
                <DictionaryRecurse
                  key={JSON.stringify(newLevel)}
                  dictionary={subDictionary}
                  config={config}
                  topLevelFormId={subformId}
                  dictionaryLevel={newLevel}
                />
              );
            })}
        </div>
      );
    case "specifiedLocation":
      return (
        <div
          style={{
            border: "1px dashed red",
            paddingLeft: "1rem",
            paddingBottom: "1rem",
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
          }}
        >
          <ChangeEnumerationType
            enumerationType={dictionary.enumerable}
            updateEnumeration={(params: ChangeEnumerationParams) => {
              dispatch({
                type: "changeEnumerateType",
                payload: {
                  chain: level,
                  newType:
                    params.type === "groupMember"
                      ? {
                          type: params.type,
                          groupParent: params.groupParent,
                        }
                      : {
                          type: params.type,
                        },
                },
              });
            }}
          />
          <h1 style={{ fontWeight: "bold" }}>
            ENUMERATED GROUP: {form.name} [{dictionary.enumerations.length}]
          </h1>
          <button
            onClick={(e) =>
              dispatch({
                type: "addSpecificLocation",
                payload: {
                  chain: level,
                },
              })
            }
          >
            Add Enumeration
          </button>
          <RemapDisplay remapObject={dictionary.remaps} chain={level} />
          {dictionary.enumerations &&
            dictionary.enumerations.length &&
            dictionary.enumerations.map((enumeration, i) => {
              return (
                <div
                  style={{
                    border: "1px solid red",
                    paddingLeft: "1rem",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <h1>{form.name + " " + (i + 1)}</h1>
                  {form.fields.map((field, fieldIndex) => {
                    const excelMapping = enumeration.fields![field.id] || "";
                    return (
                      <div style={{ paddingBottom: "0.5rem" }}>
                        <p>{field.label}</p>
                        <input
                          type="text"
                          placeholder="Excel Column"
                          value={excelMapping}
                          onKeyDown={(e) => {
                            if (e.key === "Tab") {
                              if (form.fields[fieldIndex + 1] === undefined)
                                return;
                              const nextFieldId =
                                form.fields[fieldIndex + 1].id;
                              dispatch({
                                type: "autofillNextField",
                                payload: {
                                  chain: level,
                                  currentColumnValue: excelMapping,
                                  nextFieldId: nextFieldId,
                                  specifiedLocationIndex: i,
                                },
                              });
                            }
                          }}
                          onChange={(e) => {
                            let editChain = [...level];
                            editChain[editChain.length - 1].enumeration = i;
                            dispatch({
                              type: "updateField",
                              payload: {
                                fieldId: field.id,
                                chain: [...editChain],
                                newExcelValue: e.target.value,
                              },
                            });
                          }}
                          style={{
                            border: `1px solid ${
                              excelMapping === "" ? "orange" : "lightgrey"
                            }`,
                            borderRadius: "5px",
                            paddingLeft: "3px",
                          }}
                        />
                      </div>
                    );
                  })}
                  {enumeration.subforms &&
                    form.subformIds.map((subformId) => {
                      const dictionary = enumeration.subforms![subformId];
                      if (!dictionary) {
                        return null;
                      }
                      const newLevel = [
                        ...level,
                        {
                          formId: subformId,
                          enumeration: i,
                          name: dictionary.name,
                        },
                      ];
                      return (
                        <DictionaryRecurse
                          key={JSON.stringify(newLevel)}
                          topLevelFormId={subformId}
                          config={config}
                          dictionary={dictionary}
                          dictionaryLevel={newLevel}
                        />
                      );
                    })}
                </div>
              );
            })}
          <button
            onClick={(e) =>
              dispatch({
                type: "addSpecificLocation",
                payload: {
                  chain: level,
                },
              })
            }
          >
            Add Enumeration
          </button>
        </div>
      );
    case "groupMember":
      return <></>;
    default:
      exhaustiveGuard(dictionary);
  }
};

type PossibleSelection =
  | Exclude<DictionaryContents["enumerable"], false>
  | "false";

const ChangeEnumerationType: FC<{
  enumerationType: DictionaryContents["enumerable"];
  updateEnumeration: (params: ChangeEnumerationParams) => void;
}> = ({ enumerationType, updateEnumeration }) => {
  const value = enumerationType === false ? "false" : enumerationType;

  const handleChange = (newValue: PossibleSelection) => {
    if (newValue !== "groupMember") {
      updateEnumeration({ type: newValue === "false" ? false : newValue });
    }
  };
  return (
    <select
      value={value}
      style={{ marginTop: "0.5rem" }}
      onChange={(e) => handleChange(e.target.value as PossibleSelection)}
    >
      <option value={"false"}>No enumeration</option>
      <option value={"byOffset"}>Offset</option>
      <option value={"specifiedLocation"}>Specific Location</option>
      <option value={"groupMember"}>Group Member</option>
    </select>
  );
};

// const AddEnumerationDialog = (
//   chain: DictionaryLevel[],
//   config: Configuration
// ) => {
//   const form = config.forms.find(
//     (form) => form.id === chain[chain.length - 1].formId
//   );
//   return (
//     <Dialog open={!!pageState?.addOffsetState}>
//       <DialogTitle>Add Enumeration</DialogTitle>
//       <DialogContent>
//         {pageState.addOffsetState && <p>Field: </p>}
//       </DialogContent>
//     </Dialog>
//   );
// };
export default ExcelMappingTool;

const AddEnumeration = ({ fn }: { fn: (columnValue: string) => void }) => {
  const [text, setText] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!text) return;
        fn(text);
        setText("");
      }}
    >
      <input
        type="text"
        placeholder="Offset start location"
        name="offsetStart"
        style={{ padding: "0 10px", marginRight: "10px" }}
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <button type="submit">Add</button>
    </form>
  );
};

type RemapOption = "bool-val-true" | "bool-val-false" | string;
const RemapDisplay: FC<{
  remapObject?: { [key: string]: string };
  chain: DictionaryLevel[];
}> = ({ remapObject, chain }) => {
  const [remapState, setRemapState] = useState<{
    mapFrom: RemapOption;
    mapTo: string;
  }>({ mapFrom: "", mapTo: "" });
  const ctx = useContext(ExcelMapCtx);
  if (!ctx) return <></>;
  const { dispatch } = ctx;
  const isBoolean =
    remapState.mapFrom === "bool-val-true" ||
    remapState.mapFrom === "bool-val-false";
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
      {remapObject &&
        Object.keys(remapObject).map((key) => {
          let keyText;
          if (key === "bool-val-true") {
            keyText = "*boolean TRUE";
          } else if (key === "bool-val-false") {
            keyText = "*boolean FALSE";
          } else {
            keyText = key;
          }
          return (
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <input
                type="text"
                title={keyText}
                disabled={true}
                value={keyText}
              />
              <Icon fontSize="small">
                <ArrowRight />
              </Icon>
              <input
                type="text"
                disabled={true}
                title={remapObject[key]}
                value={remapObject[key]}
              />
              <button
                style={{
                  background: "none",
                  color: "inherit",
                  border: "none",
                  padding: 0,
                  font: "inherit",
                  cursor: "pointer",
                  outline: "inherit",
                }}
                onClick={(e) =>
                  dispatch({
                    type: "deleteMapping",
                    payload: {
                      chain: chain,
                      mappedFromValue: key,
                    },
                  })
                }
              >
                <Icon fontSize="small" color="error">
                  <Clear />
                </Icon>
              </button>
            </div>
          );
        })}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch({
            type: "createMapping",
            payload: {
              chain: chain,
              newRemap: {
                mappedFrom: remapState.mapFrom,
                mappedTo: remapState.mapTo,
              },
            },
          });
          setRemapState({
            mapFrom: "",
            mapTo: "",
          });
        }}
      >
        <div
          style={{
            marginTop: 5,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
        >
          <select
            value={isBoolean ? remapState.mapFrom : ""}
            onChange={(e) =>
              setRemapState({
                ...remapState,
                mapFrom: e.target.value as RemapOption,
              })
            }
          >
            <option value={"bool-val-true"}>True</option>
            <option value={"bool-val-false"}>False</option>
            <option value={""}>Text</option>
          </select>
          {!isBoolean && (
            <input
              type="text"
              disabled={false}
              value={remapState.mapFrom}
              onChange={(e) =>
                setRemapState({ ...remapState, mapFrom: e.target.value })
              }
              placeholder="Map from . . ."
            />
          )}
          <Icon fontSize="small">
            <ArrowRight />
          </Icon>
          <input
            type="text"
            disabled={false}
            value={remapState.mapTo}
            onChange={(e) =>
              setRemapState({ ...remapState, mapTo: e.target.value })
            }
            placeholder="Map to . . ."
          />
          <input type="submit" value={"Add"} />
        </div>
      </form>
    </div>
  );
};
