import { Action, PageState } from "../../components/TypeDefinitions";

export function updateField<
  E extends Extract<
    Action,
    {
      type: "updateField";
    }
  >
>(action: E, draft: PageState) {
  const { fieldId, newValues } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  if (formIndex === -1) return;
  const fieldIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.findIndex((field) => field.id === fieldId);
  if (fieldIndex === -1) return;

  Object.assign(
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex].fields[
      fieldIndex
    ],
    newValues
  );
}
