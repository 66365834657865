import { Action, PageState } from "../../components/TypeDefinitions";

export function updateValidationAction<
  E extends Extract<
    Action,
    {
      type: "updateValidationAction";
    }
  >
>(action: E, draft: PageState) {
  const { validationId, newValue } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  if (formIndex === -1) return;
  const validationIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.findIndex((validation) => validation.id === validationId);
  if (validationIndex === -1) return;

  switch (newValue) {
    case "Error": {
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].action = newValue;
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].message = "";
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].value = null;
      break;
    }
    case "Show": {
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].action = newValue;
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].message = null;
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].value = null;
      break;
    }
    case "Answer": {
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].action = newValue;
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].message = null;
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].value = null;
      break;
    }
    case null: {
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].action = newValue;
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].message = null;
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].validations[validationIndex].value = null;
      break;
    }
    default: {
      let __exhaustiveCheck: never = newValue;
      return __exhaustiveCheck;
    }
  }
  updateIsVisibleFields(draft);
}

function updateIsVisibleFields(draft: PageState) {
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );
  const form = draft.data.inspectionTypeTemplate.configuration.forms[formIndex];
  if (!form) return;
  const fieldsWithShowValidations = form.validations
    .filter((validation) => validation.action === "Show")
    .map((validation) => validation.field);
  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.forEach((field) => {
    field.isVisible = !fieldsWithShowValidations.includes(field.id);
  });
}
