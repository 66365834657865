import { useContext, useState, useEffect, useRef } from "react";
import { WebInspectionCtx, getTopLevelFormInstance } from "./controller";
import { IFormJson } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import {
  Collapse,
  ListItemButton,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Grid,
  alpha,
  Button,
  useTheme,
  styled,
  Divider,
  Icon,
  Badge,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { MemoizedInspectionItem } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/FieldDisplayForForm";
import { FormMapWithOrder } from "./wrapper";
import {
  ExpandLess,
  ExpandMore,
  Info,
  ChevronLeft,
  ChevronRight,
  Menu,
  Error,
  Check,
  Flag,
  Close,
} from "@mui/icons-material";
import EnvironmentMap from "src/pages/EnvironmentMapping/EnvironmentMap";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { ProjectContext } from "src/utils/contexts/project";
import { PersonContext } from "src/utils/contexts/person";
import { toPng } from "html-to-image";
import MuiMarkdown from "mui-markdown";
import palette from "src/theme/palette";
import DownloadReport from "./downloadReport";
import Washouts from "src/pages/Review/pages/InspectionAssignment/Washouts";
import { decode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/decode";
import { create } from "@mui/material/styles/createTransitions";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GET_REFERENCE_MATERIALS = gql`
  query ReferenceMaterials($referenceMaterial: InputReferenceMaterialParams!) {
    referenceMaterials(referenceMaterial: $referenceMaterial) {
      id
      documentId
      isDeleted
      stringContents
      title
    }
  }
`;

const GET_DOCUMENT = gql`
  query Document($document: InputDocumentParams!) {
    document(document: $document) {
      id
      description
      documentBytes
      fileExtension
      fileName
      title
      url
      isDeleted
    }
  }
`;

const GET_PROJECT_LOCATION = gql`
  query ProjectLocation($projectLocationId: ID!) {
    projectLocation(projectLocationId: $projectLocationId) {
      id
      description
      city
      buildingType
      identifier
      lat
      lis
      lng
      mapboxId
      name
      neighborhood
      owner
      parentId
      projectId
      state
      street1
      street2
      street3
      tenant
      unit
      zip
    }
  }
`;
const GET_MAP_CAPTURES = gql`
  query MapCaptures($mapCapture: InputMapCaptureParams!) {
    mapCaptures(mapCapture: $mapCapture) {
      id
      description
      title
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

const UPLOAD_MAP_CAPTURE = gql`
  mutation UploadMapCapture($mapCapture: InputMapCaptureParams) {
    uploadMapCapture(mapCapture: $mapCapture) {
      id
      description
      title
      metadata
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

const CREATE_MAP_CAPTURE_SNAPSHOT = gql`
  mutation CreateMapCaptureSnapshot(
    $mapCaptureSnapshot: InputMapCaptureSnapshotParams
  ) {
    createMapCaptureSnapshot(mapCaptureSnapshot: $mapCaptureSnapshot) {
      id
      description
      title
      metadata
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

const GET_GEO_RULE_RESULTS = gql`
  query GetGeoRuleResults($geoRuleResult: InputGeoRuleResultParams) {
    getGeoRuleResults(geoRuleResult: $geoRuleResult) {
      inspectionId
      geoRuleId
      expressionId
      fieldId
      formId
      validationId
      lat
      long
      value
    }
  }
`;

const WASHOUTS_QUERY = gql`
  query Washouts($washout: InputWashoutParams!) {
    washouts(washout: $washout) {
      id
      assignedPerson {
        id
        firstName
        lastName
      }
      currentStatus
      description
      isDeleted
      referenceId
      referenceType
      reportedDate
      reportingPersonId
      title
      inspectionId
      inspectionAssignmentId
    }
  }
`;

const GENERATE_MAP_CAPTURE_FROM_CONFIG = gql`
  mutation CreateMapFromConfig($mapCapture: InputMapCaptureParams) {
    createMapFromConfig(mapCapture: $mapCapture) {
      id
      description
      title
      metadata
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

const MAP_GENERATION_REQUESTS = gql`
  query MapGenerationRequests(
    $mapGenerationRequest: InputMapGenerationRequestParams!
  ) {
    mapGenerationRequests(mapGenerationRequest: $mapGenerationRequest) {
      id
      metadata
      personId
      jobId
      jobStatus
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldInstanceId
      thoFormFieldId
      dataReplicationRequestId
      topLevelFormInstanceId
      type
      mapCaptureId
    }
  }
`;

const INCOMPLETE_MAP_GENERATION_REQUESTS = gql`
  query MapGenerationRequestsIncomplete(
    $mapGenerationRequest: InputMapGenerationRequestParams!
  ) {
    mapGenerationRequestsIncomplete(
      mapGenerationRequest: $mapGenerationRequest
    ) {
      id
      metadata
      personId
      jobId
      jobStatus
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldInstanceId
      thoFormFieldId
      dataReplicationRequestId
      topLevelFormInstanceId
      type
      mapCaptureId
    }
  }
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


export const InspectionWebView = ({ editMode }: { editMode?: boolean }) => {
  const { height, width } = useWindowDimensions();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const person: any = useContext(PersonContext);
  const project: any = useContext(ProjectContext);
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = (message: any, variant: any) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const [showReplicateAddresses, setShowReplicateAddresses] = useState(false);

  const [initialValidationsRan, setInitialValidationsRan] = useState(false);
  const [showReferenceMaterialList, setShowReferenceMaterialList] =
    useState(false);
  const [currReferenceMaterialField, setCurrReferenceMaterialField] =
    useState(null);
  const [showMarkdownReferenceMaterial, setShowMarkdownReferenceMaterial] =
    useState(false);
  const [shouldAlterAnchorTags, setShouldAlterAnchorTags] = useState(false);
  const [currReferenceMaterialSelected, setCurrReferenceMaterialSelected] =
    useState<any>(null);

  const [washoutView, setWashoutView] = useState(false);

  const [washoutReferenceIdFilter, setWashoutReferenceIdFilter] = useState<
    string | null
  >(null);

  const componentRef = useRef<any>(null);

  const [referenceMaterials, setReferenceMaterials] = useState([]);
  const [value, setValue] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [downloadReport, setDownloadReport] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [projectLocation, setProjectLocation] = useState(null);
  const [mapCaptures, setMapCaptures] = useState([]);
  const [environmentMap, setEnvironmentMap] = useState(null);
  const [showEnvironmentMap, setShowEnvironmentMap] = useState(false);
  const [mapCaptureArgs, setMapCaptureArgs] = useState(null);
  const [mapArgs, setMapArgs] = useState(null);
  const [isCapture, setIsCapture] = useState(false);

  const [
    expressionsNeedingRequestResults,
    setExpressionsNeedingRequestResults,
  ] = useState<any[] | null>([]);
  const [createMapCaptureSnapshot] = useMutation(CREATE_MAP_CAPTURE_SNAPSHOT, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {},
  });

  const [
    getGeoRuleResults,
    { data: geoRuleResultsData, loading: geoRuleResultsLoading },
  ] = useLazyQuery(GET_GEO_RULE_RESULTS, {
    fetchPolicy: "network-only",
  });

  const ctx = useContext(WebInspectionCtx);
  const { state, dispatch } = ctx;
  const { inspection, inspectionAssignment } = state;

  const [washoutMap, setWashoutMap] = useState<{
    [fieldId: string]: any[];
  }>();

  const [washoutQuery, { data, loading, error }] = useLazyQuery(
    WASHOUTS_QUERY,
    {
      variables: {
        washout: {
          inspectionAssignmentId: inspectionAssignment.id,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (editMode === true) {
      washoutQuery();
    }
  }, [editMode, inspectionAssignment.id, washoutQuery]);

  useEffect(() => {
    if (data) {
      let washoutMapObj: { [fieldId: string]: any[] } = {};
      data.washouts.forEach((washout: any) => {
        if (washout.referenceType === "field") {
          const fieldId = washout.referenceId;
          if (washoutMapObj[fieldId] === undefined) {
            washoutMapObj[fieldId] = [];
          }
          washoutMapObj[fieldId].push(washout);
        }
      });
      setWashoutMap(washoutMapObj);
    }
  }, [data]);

  const currentFormInstance = state.formInstances[value];

  const [getProjectLocation] = useLazyQuery(GET_PROJECT_LOCATION, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setProjectLocation(response.projectLocation);
      setDataLoading(false);
    },
    onError: (response) => {},
  });
  const [getReferenceMaterials] = useLazyQuery(GET_REFERENCE_MATERIALS, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setReferenceMaterials(response.referenceMaterials);
    },
    onError: (response) => {},
  });
  const [getDocument] = useLazyQuery(GET_DOCUMENT, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      var url = "";

      switch (response.document.fileExtension) {
        case ".pdf":
          var file: any = base64ToFile(response.document.documentBytes);
          setSelectedFile(file);
          url = URL.createObjectURL(file);
          break;
        case ".png":
          const blobPNG: any = base64ToBlob(
            response.document.documentBytes,
            "image/png"
          );
          setSelectedFile(blobPNG);
          url = URL.createObjectURL(blobPNG);
          break;
      }

      let contentWindow: any = window.open("");
      contentWindow.document.write(
        "<iframe width='100%' height='100%' src='" + url + "'></iframe>"
      );
      contentWindow.document.close();
    },
    onError: (response) => {},
  });
  const [generateMapCaptureFromConfig] = useMutation(
    GENERATE_MAP_CAPTURE_FROM_CONFIG,
    {
      fetchPolicy: "network-only",
      onError: (response) => {
        debugger;
      },
    }
  );

  function base64ToBlob(base64: any, type = "application/octet-stream") {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
  }

  const base64ToFile = (base64: string) => {
    let dataStr = atob(base64);
    let n = dataStr.length;
    let dataArr = new Uint8Array(n);

    while (n--) {
      dataArr[n] = dataStr.charCodeAt(n);
    }

    let file = new File([dataArr], "File.pdf", { type: "application/pdf" });

    return file;
  };

  const [getMapCaptures] = useLazyQuery(GET_MAP_CAPTURES, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (response.mapCaptures) {
        var responseMapCaptures: any = [...response.mapCaptures];
        for (var i = 0; i < responseMapCaptures.length; i++) {
          var newMapCapture = { ...responseMapCaptures[i] };
          newMapCapture.image = "data:image/png;base64, " + newMapCapture.image;
          responseMapCaptures[i] = newMapCapture;
        }
        setMapCaptures(responseMapCaptures);
      }
    },
    onError: (response) => {},
  });

  const [mapGenerationRequests, setMapGenerationRequests] = useState([]);
  const [mapGenerationRequestsComplete, setMapGenerationRequestsComplete] =
    useState(false);
  const [mapGenerationRequestsLoading, setMapGenerationRequestsLoading] =
    useState(false);

  const funRef = useRef<any>(null);
  const [mapGenerationRequestsQuery] = useLazyQuery(MAP_GENERATION_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (results) => {
      if (results) {
        var requests: any = [...mapGenerationRequests];
        results.mapGenerationRequests.forEach((e: any) => {
          var index = requests.indexOf(
            requests.filter((d: any) => d.id === e.id)[0]
          );
          if (index >= 0) {
            requests[index] = e;
          } else {
            requests.push(e);
          }
        });
        setMapGenerationRequests(requests);
      }
    },
  });

  const [
    mapGenerationRequestsIncompleteQuery,
    {
      data: mapGenerationRequestsIncompleteData,
      loading: mapGenerationRequestsIncompleteLoading,
    },
  ] = useLazyQuery(INCOMPLETE_MAP_GENERATION_REQUESTS, {
    fetchPolicy: "network-only",
    onCompleted: (results) => {
      if (results) {
        var requests: any = [...mapGenerationRequests];
        results.mapGenerationRequestsIncomplete.forEach((e: any) => {
          var index = requests.indexOf(
            requests.filter((d: any) => d.id === e.id)[0]
          );
          if (index >= 0) {
            requests[index] = e;
          } else {
            requests.push(e);
          }
        });
        setMapGenerationRequests([]);
        setMapGenerationRequests(requests);
      }
    },
  });

  useEffect(() => {}, [mapGenerationRequestsLoading]);

  useEffect(() => {
    funRef.current = setInterval(() => {
      if (!mapGenerationRequestsComplete && mapGenerationRequestsLoading) {
        reloadMapGenerationRequestsToWatch();
      }
    }, 5000);
    return () => {
      clearInterval(funRef.current);
    };
  });

  async function reloadMapGenerationRequestsToWatch() {
    mapGenerationRequestsIncompleteQuery({
      variables: {
        mapGenerationRequest: {
          inspectionAssignmentId: state.inspectionAssignment.id,
        },
      },
    });
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (mapGenerationRequests) {
      if (mapGenerationRequests.length > 0) {
        var allComplete = true;
        console.log(mapGenerationRequests);
        mapGenerationRequests.forEach((mgr: any) => {
          if (mgr.jobStatus !== "completed") {
            allComplete = false;
          }
        });
        if (allComplete) {
          if (!mapGenerationRequestsComplete) {
            setMapGenerationRequestsComplete(true);
            setMapGenerationRequests([]);
            getMapCaptures({
              variables: {
                mapCapture: {
                  inspectionId: inspection.id,
                  inspectionAssignmentId: state.inspectionAssignment.id,
                },
              },
            });
          }
        }
      }
    }
  }, [mapGenerationRequests]);

  useEffect(() => {
    if (dataLoading) {
      async function getData() {
        if (inspection) {
          await getProjectLocation({
            variables: {
              projectLocationId: inspection.inspectionLocation,
            },
          });
          await getReferenceMaterials({
            variables: {
              referenceMaterial: {
                isDeleted: false,
              },
            },
          });
        }
      }
      let timer = setTimeout(() => {
        getData();
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [inspection]);

  // useEffect(() => {
  //   if (mapCaptures && mapCaptures.length > 0) {
  //     setMapImageTags();
  //   }
  // }, [mapCaptures]);

  useEffect(() => {
    if (mapCaptures && mapCaptures.length > 0) {
      setMapImageTags();
    }
  });

  useEffect(() => {}, [selectedFile]);

  useEffect(() => {
    if (projectLocation && componentRef) {
      let ev: any = (
        <EnvironmentMap
          addresses={[projectLocation]}
          componentRef={componentRef}
          showMeasurementLine={true}
          isCapture={isCapture}
          mapCaptureArgs={mapCaptureArgs}
          handleUploadMapCapture={handleUploadMapCapture}
          mapArgs={mapArgs}
          setMapArgs={setMapArgs}
          project={project}
        ></EnvironmentMap>
      );
      setEnvironmentMap(ev);
    }
  }, [projectLocation, showEnvironmentMap]);
  // function updateForms(formInstance: any) {
  //   var formInstanceId = formInstance[0];
  //   if (!formInstanceId) {
  //   }
  //   var formIndex = state.configuration.forms.findIndex(
  //     (form) =>
  //       form.id ===
  //       (formInstance[0] ? formInstance[0].formId : formInstance.formId)
  //   );
  //   var length = formInstance[1]
  //     ? formInstance[1].fields.length
  //     : formInstance.fields.length;
  //   for (var i = 0; i < length; i++) {
  //     var fieldIndex = state.configuration.forms[formIndex].fields.findIndex(
  //       (field) =>
  //         field.id ===
  //         (formInstance[1]
  //           ? formInstance[1].fields[i].formFieldId
  //           : formInstance.fields[i].formFieldId)
  //     );

  //     formInstance[1]
  //       ? (formInstance[1].fields[i].referenceMaterialIds =
  //           state.configuration.forms[formIndex].fields[
  //             fieldIndex
  //           ].referenceMaterialIds)
  //       : (formInstance.fields[i].referenceMaterialIds =
  //           state.configuration.forms[formIndex].fields[
  //             fieldIndex
  //           ].referenceMaterialIds);
  //   }
  //   let fs1 = formInstance[1] ? formInstance[1] : formInstance;
  //   if (fs1.subFormInstances) {
  //     if (fs1.subFormInstances.length > 0) {
  //       for (var s = 0; s < fs1.subFormInstances.length; s++) {
  //         updateForms(
  //           formInstance[1]
  //             ? formInstance[1].subFormInstances[s]
  //             : formInstance.subFormInstances[s]
  //         );
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (processingForms) {
  //     var formInstances = state.formInstances;

  //     for (var i = 0; i < formInstances.length; i++) {
  //       updateForms(formInstances[i]);
  //     }
  //     setProcessingForms(false);
  //   }
  // });

  function setMapImageTags() {
    var captures: any = mapCaptures;
    if (captures) {
      for (var i = 0; i < captures.length; i++) {
        var mapCapture: any = captures[i];
        if (mapCapture.image) {
          let imageTag = document.getElementById(
            "mapCapture" + mapCapture.thoFormFieldId
          );
          imageTag?.replaceChildren();
          let img = document.createElement("img");
          img.setAttribute("src", mapCapture.image);
          img.setAttribute(
            "style",
            "padding: 10px; height: 400px; width: 600px;"
          );
          imageTag?.appendChild(img);
        }
      }
    } else {
      for (var j = 0; j < mapCaptures.length; j++) {
        var mc: any = mapCaptures[j];
        let imageTag = document.getElementById(
          "mapCapture" + mc.thoFormFieldInstanceId
        );
        imageTag?.replaceChildren();
        let img = document.createElement("img");
        img.setAttribute("src", mc.image);
        img.setAttribute(
          "style",
          "padding: 10px; height: 400px; width: 400px;"
        );
        imageTag?.appendChild(img);
      }
    }
  }

  const imageUrlToBase64 = async (url: any) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

  const handleInitiateMapCapture = async (
    formInstance: any,
    field: any,
    fieldIndex: any,
    subformIndexPath: any,
    formInstanceIndex: any
  ) => {
    let args: any = {
      formInstance: formInstance,
      field: field,
      fieldIndex: fieldIndex,
      subformIndexPath: subformIndexPath,
      formInstanceIndex: formInstanceIndex,
    };
    setMapCaptureArgs(args);
    setIsCapture(true);
    setShowEnvironmentMap(true);
  };

  const handleUploadMapCapture = async (args: any) => {
    let formInstance = args.captureArgs.formInstance;
    let topLevelFormInstance = getTopLevelFormInstance(formInstance, state);
    let topLevelFormInstanceId = topLevelFormInstance.id;

    let inspectionId = formInstance.inspectionId;
    let inspectionAssignmentId = formInstance.inspectionAssignmentId;
    let thoFormInstanceId = formInstance.id;
    let thoFormId = formInstance.formId;
    
    let thoFormField = args.captureArgs.field;
    let thoFormFieldId = thoFormField.formFieldId;
    let thoFormFieldInstanceId = thoFormField.id;
    let personId = person.person.id;
    let projectId = project.project.id;

    var mapCaptureToUpload: any = {
      inspectionId,
      projectId,
      personId,
      thoFormFieldInstanceId,
      thoFormFieldId,
      thoFormInstanceId,
      inspectionAssignmentId,
      thoFormId,
      topLevelFormInstanceId,
    };
    await createMapCaptureSnapshot({
      variables: {
        mapCaptureSnapshot: {
          mapCapture: mapCaptureToUpload,
          features: JSON.stringify(args.mapArgs.features),
          bounds: {
            northEast: args.mapArgs.viewport.bounds._ne,
            southWest: args.mapArgs.viewport.bounds._sw,
            center: {
              lng: args.mapArgs.viewport.viewState.longitude,
              lat: args.mapArgs.viewport.viewState.longitude,
            },
            zoom: args.mapArgs.viewport.viewState.zoom,
          },
        },
      },
    })
      .then((result) => {
        var currMapCaptures: any = [...mapCaptures];
        if (result) {
          var found = false;
          for (var i = 0; i < currMapCaptures.length; i++) {
            var mapCapture: any = currMapCaptures[i];
            if (mapCapture.fieldInstanceId === thoFormFieldId) {
              found = true;

              mapCapture.image =
                "data:image/png;base64, " +
                JSON.parse(result.data.createMapCaptureSnapshot.image);
            }
          }
          if (!found) {
            var newMapCapture = result.data.createMapCaptureSnapshot;
            newMapCapture.image =
              "data:image/png;base64, " +
              JSON.parse(result.data.createMapCaptureSnapshot.image);
            currMapCaptures.push(newMapCapture);
          }
          setMapCaptures(currMapCaptures);
          snackbar("Captured Map", "success");
          mapCapture = newMapCapture.id;
        } else {
          snackbar("Capture Failed", "error");
        }
        var value = { mapCaptureId: result.data.createMapCaptureSnapshot.id };
        var newField = JSON.parse(JSON.stringify(args.captureArgs.field));
        newField.formInput.formValue.jsonValue = value;

        dispatch({
          type: "updateField",
          payload: {
            fieldIndex: args.captureArgs.fieldIndex,
            newField,
            subformIndexPath: args.captureArgs.subformIndexPath,
            thoFormIndex: args.captureArgs.formInstanceIndex,
          },
        });
        return result.data.createMapCaptureSnapshot;
      })
      .catch((err) => {
        console.log(err);
      });
    setShowEnvironmentMap(false);
  };

  useEffect(() => {
    if (!dataLoading) {
      if (state.expressionsNeedingRequest && projectLocation) {
        var myLoc: any = projectLocation;
        let promises = state.expressionsNeedingRequest.map((a) => {
          return getGeoRuleResults({
            variables: {
              geoRuleResult: {
                inspectionId: a.inspectionId,
                geoRuleId: a.geoRuleId,
                expressionId: a.expressionId,
                fieldId: a.fieldId,
                formId: a.formId,
                validationId: a.validationId,
                lat: myLoc.lat,
                long: myLoc.lng,
                testReturnValue: true,
              },
            },
          });
        });
        var ranCount = 0;
        for (var z = 0; z < promises.length; z++) {
          promises[z].then((result) => {
            console.log(result);
            ranCount++;
            let ruleResult = result.data.getGeoRuleResults;
            let currExpressionNeedingRequest = expressionsNeedingRequestResults;
            if (!currExpressionNeedingRequest) {
              currExpressionNeedingRequest = [];
            }
            let index = currExpressionNeedingRequest.findIndex(
              (e) => e.expressionId === ruleResult.expressionId
            );
            if (index >= 0) {
              currExpressionNeedingRequest[index] = ruleResult;
            } else {
              currExpressionNeedingRequest.push(ruleResult);
            }
            setExpressionsNeedingRequestResults(null);
            setExpressionsNeedingRequestResults(currExpressionNeedingRequest);
            if (ranCount === promises.length) {
              setInitialValidationsRan(true);
            }
          });
        }
        if (!promises.length) {
          setExpressionsNeedingRequestResults(null);
          setInitialValidationsRan(true);
        }
      }
    }
  }, [dataLoading]);
  useEffect(() => {
    if (initialValidationsRan) {
      if (state.expressionsNeedingRequest) {
        if (!expressionsNeedingRequestResults) {
          return;
        }
        if (
          expressionsNeedingRequestResults.length === 0 &&
          state.expressionsNeedingRequest.length === 0
        ) {
          return;
        }
        if (
          expressionsNeedingRequestResults.length ===
          state.expressionsNeedingRequest.length
        ) {
          dispatch({
            type: "updateValidationsNeedingRequest",
            payload: {
              expressionsNeedingRequest: expressionsNeedingRequestResults,
            },
          });
          setExpressionsNeedingRequestResults([]);
        }
      }
    }
  }, [initialValidationsRan]);

  useEffect(() => {
    if (state.mapCapturesNeedingGeneration && initialValidationsRan) {
      if (state.mapCapturesNeedingGeneration.length > 0) {
        setMapGenerationRequestsLoading(true);

        dispatch({
          type: "updateInitialMapCapturesGenerated",
        });
        let mapPromises: any = state.mapCapturesNeedingGeneration.map((m) => {
          let formInstance = m.formInstance;
          let field = null;
          for (var i = 0; i < formInstance.fields.length; i++) {
            if (formInstance.fields[i].inputType === "MapCapture") {
              field = formInstance.fields[i];
              break;
            }
          }
          if (field === null) {
            return;
          }
          let topLevelFormInstance = getTopLevelFormInstance(
            formInstance,
            state
          );
          let topLevelFormInstanceId = topLevelFormInstance.id;

          let inspectionId = formInstance.inspectionId;
          let inspectionAssignmentId = formInstance.inspectionAssignmentId;
          let thoFormInstanceId = formInstance.id;
          let thoFormId = formInstance.formId;
          let thoFormFieldId = field.formFieldId;
          let thoFormFieldInstanceId = field.id;
          let personId = person.person.id;
          let projectId = project.project.id;
          var mapCaptureToUpload: any = {
            inspectionId,
            projectId,
            personId,
            thoFormFieldInstanceId,
            thoFormFieldId,
            thoFormInstanceId,
            inspectionAssignmentId,
            thoFormId,
            topLevelFormInstanceId,
          };
          return generateMapCaptureFromConfig({
            variables: {
              mapCapture: mapCaptureToUpload,
            },
          });
        });
        let ranCount = 0;
        for (var p = 0; p < mapPromises.length; p++) {
          mapPromises[p].then((result: any) => {
            ranCount++;
          });
        }
      } else {
        getMapCaptures({
          variables: {
            mapCapture: {
              inspectionId: inspection.id,
              inspectionAssignmentId: state.inspectionAssignment.id,
            },
          },
        });
      }
    }
  }, [initialValidationsRan]);

  useEffect(() => {}, [expressionsNeedingRequestResults]);

  function getSecondaryText(referenceMaterial: any) {
    if (referenceMaterial.documentId) return "Document";
    return "Free Text";
  }

  function getReferenceMaterialList(field: any) {
    return field.referenceMaterialIds?.map((refid: any) => {
      var selectedRefm: any =
        referenceMaterials.find((r: any) => r.id === refid) ?? [];

      return (
        <MenuItem
          id={refid}
          onClick={async (e: any) => {
            if (selectedRefm.documentId) {
              await getDocument({
                variables: {
                  document: {
                    id: selectedRefm.documentId,
                    isDeleted: false,
                  },
                },
              });
            } else {
              setShowMarkdownReferenceMaterial(true);
              setShowReferenceMaterialList(false);
              setCurrReferenceMaterialField(null);
              setCurrReferenceMaterialSelected(selectedRefm);
            }
          }}
        >
          <ListItemText
            primary={selectedRefm?.title || ""}
            secondary={getSecondaryText(selectedRefm)}
          />
        </MenuItem>
      );
    });
  }
  const markedComplete =
    state.inspectionAssignment.status === "Onsite Complete";

  if (
    state.inspectionAssignment.status === "Onsite Complete" &&
    !markedComplete
  ) {
  }

  useEffect(() => {
    if (showMarkdownReferenceMaterial) {
      setShouldAlterAnchorTags(true);
    }
  }, [showMarkdownReferenceMaterial]);

  useEffect(() => {
    if (shouldAlterAnchorTags) {
      let markdown = document.getElementById("refMatMarkdown");
      if (markdown) {
        var change = markdown.getElementsByTagName("a");
        for (var i = 0; i < change.length; i++) {
          change[i].setAttribute("target", "_blank");
        }
      }
    }
  }, [shouldAlterAnchorTags]);

  useEffect(() => {}, [showReplicateAddresses]);

  const edit =
    editMode && washoutMap
      ? {
          washoutMap,
          setWashoutMenu: (id: string) => {
            setWashoutReferenceIdFilter(id);
            setWashoutView(true);
          },
          selectedWashoutFieldId: washoutReferenceIdFilter,
        }
      : undefined;

  return (
    <>
      {editMode && (
        <FormControlLabel
          control={
            <Switch
              checked={washoutView}
              onChange={() => {
                if (washoutView) {
                  setWashoutReferenceIdFilter(null);
                }
                setWashoutView(!washoutView);
              }}
            />
          }
          label="Washout View"
        />
      )}
      <Box
        sx={{
          display: "flex",
          // flex: "1 1 100%",
          flexDirection: "row",
          maxHeight: (height-125).toString() + "px",
          position: "absolute",
        }}
      >
        <Dialog
          open={downloadReport}
          maxWidth="md"
          fullWidth={true}
          onClose={(event, reason) => {
            setDownloadReport(false);
          }}
        >
          <DialogTitle>Report Download</DialogTitle>
          <DialogContent>
            <DownloadReport formState={state} mapCaptures={mapCaptures} />
          </DialogContent>
        </Dialog>

        {projectLocation && (
          <Stack direction="column" width={washoutView ? "500px" : "0px"}>
            {washoutView ? (
              <div style={{ width: "500px" }}>
                <Washouts
                  inspectionId={inspection.id}
                  controlWashout={
                    washoutReferenceIdFilter
                      ? {
                          filterByRefenceId: washoutReferenceIdFilter,
                          clearFilter: () => setWashoutReferenceIdFilter(null),
                          referenceType: "field",
                          updateWashouts: () => {
                            washoutQuery();
                          },
                        }
                      : undefined
                  }
                />
              </div>
            ) : (
              // <EnvironmentMap
              //   addresses={[projectLocation]}
              //   componentRef={componentRef}
              //   showMeasurementLine={true}
              // ></EnvironmentMap>
              <div></div>
            )}
          </Stack>
        )}
        {dataLoading && !initialValidationsRan && (
          <CircularProgress></CircularProgress>
        )}
        {!mapGenerationRequestsComplete && mapGenerationRequestsLoading && (
          <Box>
            <CircularProgress />
            <Typography>Generating and downloading maps.</Typography>
          </Box>
        )}
        {initialValidationsRan && (
          <Box
            sx={{
              ...(open && {
                display: "none",
                width: "30px",
                height: "30px",
                padding: "5px",
                position: "fixed",
                top: 0,
                right: 0,
                alignContent: "center",
              }),
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <Menu />
            </IconButton>
          </Box>
        )}
        {initialValidationsRan && (
          <Grid container spacing={2} columns={12} maxHeight={"100%"}>
            {/* <Grid item xs={4}> */}
            <Collapse
              orientation="horizontal"
              in={open}
              sx={{ overflowY: "scroll", height: "100%" }}
            >
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "ltr" ? (
                    <ChevronLeft />
                  ) : (
                    <ChevronRight />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <Box position="relative" flex="1" padding="15px">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="form selector"
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      flexWrap: "wrap",
                      display: "grid",
                    },
                    "& .MuiTabs-indicator": { display: "none" },
                    "& button": {
                      borderRadius: 2,
                      border: "1px solid black",
                      margin: "5px",
                    },
                    "& button:hover": {
                      backgroundColor: alpha(palette.secondary.main, 0.25),
                    },
                    "& button.Mui-selected": {
                      backgroundColor: "rgba(18, 175, 45, .5)",
                    },
                  }}
                >
                  {state.formInstances.map((fi, i) => (
                    <Tab
                      key={fi.id}
                      label={
                        <Stack>
                          <p>{fi.name}</p>
                          {!fi.isValid && (
                            <Icon>
                              <Error />
                            </Icon>
                          )}
                        </Stack>
                      }
                      {...a11yProps(i)}
                    />
                    //   <Typography variant="h6">{fi[1].name}</Typography>
                  ))}
                </Tabs>
              </Box>
            </Collapse>
            {/* </Grid> */}
            {/* <Grid item xs={8}> */}
            <Box
              position="relative"
              flex="1"
              padding="15px"
              height="100%"
              style={{ overflowY: "scroll" }}
            >
              {/* <Stack direction='column' > */}

              {showReferenceMaterialList && (
                <Dialog
                  open={showReferenceMaterialList}
                  maxWidth="md"
                  onClose={() => {
                    setCurrReferenceMaterialField(null);
                    setShowReferenceMaterialList(false);
                  }}
                >
                  <DialogTitle>Reference Materials</DialogTitle>
                  <DialogContent>
                    {getReferenceMaterialList(currReferenceMaterialField)}
                  </DialogContent>
                </Dialog>
              )}

              {/* {showReplicateAddresses && (
                <Dialog
                  open={showReplicateAddresses}
                  maxWidth="lg"
                  onClose={() => {
                    setCurrReferenceMaterialField(null);
                    setShowReferenceMaterialList(false);
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    columns={12}
                    sx={{ padding: "15px" }}
                  >
                    <Grid item xs={10}>
                      <DialogTitle>
                        Would you like to replicate data for this inspection to
                        additional addresses?
                      </DialogTitle>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "15px",
                      }}
                    >
                      <IconButton
                        autoFocus
                        onClick={() => {
                          setShowReplicateAddresses(false);
                        }}
                        color="primary"
                        sx={{
                          backgroundColor: "lightgray",
                          color: "gray",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <DialogContent>
                    <DataReplication
                      inspectionAssignment={state.inspectionAssignment}
                      initialStep={2}
                      onComplete={(result: any) => {
                        if (result) {
                          setShowReplicateAddresses(false);
                        }
                      }}
                    ></DataReplication>
                  </DialogContent>
                </Dialog>
              )} */}

              {showEnvironmentMap && (
                <Dialog
                  open={showEnvironmentMap}
                  maxWidth="lg"
                  onClose={() => {}}
                >
                  <Grid
                    container
                    spacing={2}
                    columns={12}
                    sx={{ padding: "15px" }}
                  >
                    <Grid item xs={10}>
                      <DialogTitle>Environment Map</DialogTitle>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "15px",
                      }}
                    >
                      <IconButton
                        autoFocus
                        onClick={() => {
                          setShowEnvironmentMap(false);
                        }}
                        color="primary"
                        sx={{
                          backgroundColor: "lightgray",
                          color: "gray",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <DialogContent>{environmentMap}</DialogContent>
                </Dialog>
              )}
              {/* <Dialog
            open={processingForms}
            maxWidth="md"
            fullWidth={true}
            onClose={(event, reason) => {
              if (reason && reason === "backdropClick") return;
            }}
          >
            <DialogTitle>Processing Forms</DialogTitle>
            <DialogContent>
              <CircularProgress></CircularProgress>
            </DialogContent>
          </Dialog> */}

              {showMarkdownReferenceMaterial && (
                <Dialog
                  open={showMarkdownReferenceMaterial}
                  maxWidth="md"
                  onClose={() => {
                    setCurrReferenceMaterialSelected(null);
                    setShowMarkdownReferenceMaterial(false);
                  }}
                >
                  <DialogTitle>
                    {currReferenceMaterialSelected?.title}
                  </DialogTitle>
                  <DialogContent id="refMatMarkdown">
                    <MuiMarkdown>
                      {currReferenceMaterialSelected?.stringContents}
                    </MuiMarkdown>
                  </DialogContent>
                </Dialog>
              )}
              <Box
                sx={{
                  ...{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingBottom: "15px",
                    justifyContent: "space-between",
                    width: "100%",
                  },
                }}
              >
                <p>{state.formInstances[value].name}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      dispatch({ type: "onsiteComplete" });
                    }}
                    sx={
                      markedComplete
                        ? {
                            backgroundColor: "green",
                          }
                        : undefined
                    }
                    endIcon={markedComplete ? <Check /> : undefined}
                  >
                    {markedComplete ? "Marked Complete" : "Mark Complete"}
                  </Button>
                  {/* {markedComplete && (
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        setShowReplicateAddresses(true);
                      }}
                    >
                      {"Replicate Inspection Data"}
                    </Button>
                  )} */}
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      setShowEnvironmentMap(true);
                    }}
                  >
                    {"Show Map"}
                  </Button>
                  {/* <Button
                    variant="contained"
                    onClick={(e) => {
                      setDownloadReport(true);
                    }}
                  >
                    {"Download Report"}
                  </Button> */}
                </div>
              </Box>
              <FormInstanceFields
                formInstance={state.formInstances[value]}
                formInstanceIndex={value}
                formMap={state.formMap}
                subformIndexPath={[]}
                handleMapImageUpload={handleInitiateMapCapture}
                referenceMaterials={referenceMaterials}
                setShowReferenceMaterialList={setShowReferenceMaterialList}
                setCurrReferenceMaterialField={setCurrReferenceMaterialField}
                editMode={edit}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  gap: "0.5rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() =>
                    dispatch({
                      type: "submit",
                      payload: {
                        thoFormIndex: value,
                        subformIndexPath: [],
                      },
                    })
                  }
                >
                  Save
                </Button>
                {value !== state.formInstances.length - 1 && (
                  <Button
                    variant="outlined"
                    onClick={(e) => setValue(value + 1)}
                  >
                    Next Form
                  </Button>
                )}
              </div>
              {currentFormInstance.subFormInstances.length > 0 &&
                currentFormInstance.subFormInstances.map((fi, i) => (
                  <RecurseSubforms
                    formInstance={fi}
                    editMode={edit}
                    topLevelFormInstanceIndex={value}
                    formMap={state.formMap}
                    subformIndexPath={[i]}
                    handleMapImageUpload={handleInitiateMapCapture}
                    referenceMaterials={referenceMaterials}
                    nextForm={
                      state.formInstances.length === value
                        ? null
                        : () => setValue(value + 1)
                    }
                    setShowReferenceMaterialList={setShowReferenceMaterialList}
                    setCurrReferenceMaterialField={
                      setCurrReferenceMaterialField
                    }
                  />
                ))}
            </Box>
            {/* </Grid> */}
          </Grid>
        )}
      </Box>
    </>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const FormInstanceFields = ({
  formInstance,
  formInstanceIndex,
  formMap,
  subformIndexPath,
  handleMapImageUpload,
  referenceMaterials,
  setShowReferenceMaterialList,
  setCurrReferenceMaterialField,
  editMode,
}: {
  formInstance: IFormJson;
  formInstanceIndex: number;
  formMap: FormMapWithOrder;
  subformIndexPath: number[];
  handleMapImageUpload: any;
  referenceMaterials: any;
  setShowReferenceMaterialList: any;
  setCurrReferenceMaterialField: any;
  editMode?:
    | false
    | {
        washoutMap: {
          [fieldId: string]: any[];
        };
        setWashoutMenu: (fieldId: string) => void;
        selectedWashoutFieldId: string | null;
      };
}) => {
  const ctx = useContext(WebInspectionCtx);
  const { dispatch } = ctx;
  function hasReferenceMaterials(field: any) {
    if (field.referenceMaterialIds) {
      if (field.referenceMaterialIds.length > 0) {
        return true;
      }
    }
    return false;
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      paddingLeft={"1rem"}
      paddingTop={"0.5rem"}
    >
      {formInstance.fields.map((field, fieldIndex) => {
        const shown = field.isVisible;
        return shown ? (
          <Stack
            direction="row"
            key={field.id}
            alignItems="start"
            border={
              editMode && editMode.selectedWashoutFieldId === field.id
                ? "dashed red"
                : ""
            }
          >
            {editMode && (
              <IconButton
                sx={{ marginRight: "0.5rem" }}
                onClick={() => editMode.setWashoutMenu(field.id)}
              >
                <Badge
                  badgeContent={
                    editMode.washoutMap[field.id]
                      ? editMode.washoutMap[field.id].length
                      : 0
                  }
                  color="error"
                >
                  <Flag />
                </Badge>
              </IconButton>
            )}
            <Stack direction="column" sx={{ flex: 1 }}>
              {formMap.formMap[formInstance.formId] && (
                <MemoizedInspectionItem
                  key={field.id}
                  field={field}
                  form={formMap.formMap[formInstance.formId].form}
                  formJson={formInstance}
                  callback={
                    field.inputType === "MapCapture"
                      ? async () => {
                          var result = await handleMapImageUpload(
                            formInstance,
                            field,
                            fieldIndex,
                            subformIndexPath,
                            formInstanceIndex
                          );
                        }
                      : undefined
                  }
                  fieldUpdater={(newField) => {
                    dispatch({
                      type: "updateField",
                      payload: {
                        fieldIndex,
                        newField,
                        subformIndexPath: subformIndexPath,
                        thoFormIndex: formInstanceIndex,
                      },
                    });
                  }}
                />
              )}
            </Stack>
            {hasReferenceMaterials(field) && (
              <IconButton
                onClick={(e) => {
                  setShowReferenceMaterialList(true);
                  setCurrReferenceMaterialField(field);
                }}
              >
                <Info></Info>
              </IconButton>
            )}
          </Stack>
        ) : (
          <></>
        );
      })}
    </Stack>
  );
};

const RecurseSubforms = ({
  formInstance,
  topLevelFormInstanceIndex,
  formMap,
  subformIndexPath,
  handleMapImageUpload,
  referenceMaterials,
  setShowReferenceMaterialList,
  setCurrReferenceMaterialField,
  editMode,
  nextForm,
}: {
  formInstance: IFormJson;
  /**  The index of the parent thoFormInstance as it presents in the list of thoFormInstances. */
  topLevelFormInstanceIndex: number;
  formMap: FormMapWithOrder;
  subformIndexPath: number[];
  handleMapImageUpload: any;
  referenceMaterials: any;
  setShowReferenceMaterialList: any;
  setCurrReferenceMaterialField: any;
  nextForm: (() => void) | null;
  editMode?:
    | false
    | {
        washoutMap: {
          [fieldId: string]: any[];
        };
        setWashoutMenu: (fieldId: string) => void;
        selectedWashoutFieldId: string | null;
      };
}) => {
  const { dispatch } = useContext(WebInspectionCtx);
  const [open, setOpen] = useState(false);
  return (
    <Stack direction="column" alignItems="stretch" width="100%" spacing={1}>
      <ListItemButton onClick={(e) => setOpen(!open)} sx={{ width: "100%" }}>
        <ListItemText primary={formInstance.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <FormInstanceFields
          formMap={formMap}
          formInstance={formInstance}
          formInstanceIndex={topLevelFormInstanceIndex}
          subformIndexPath={subformIndexPath}
          handleMapImageUpload={handleMapImageUpload}
          referenceMaterials={referenceMaterials}
          setShowReferenceMaterialList={setShowReferenceMaterialList}
          setCurrReferenceMaterialField={setCurrReferenceMaterialField}
          editMode={editMode}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            onClick={(e) =>
              dispatch({
                type: "submit",
                payload: {
                  thoFormIndex: topLevelFormInstanceIndex,
                  subformIndexPath: subformIndexPath,
                },
              })
            }
          >
            Save
          </Button>
          <Button variant="outlined" onClick={nextForm ? nextForm : undefined}>
            Next Form
          </Button>
        </div>
        {formInstance.subFormInstances.length > 0 && (
          <div style={{ borderLeft: "1px solid blue", marginLeft: "1rem" }}>
            {formInstance.subFormInstances.map((fi, i) => (
              <RecurseSubforms
                key={fi.id}
                formMap={formMap}
                subformIndexPath={[...subformIndexPath, i]}
                formInstance={fi}
                topLevelFormInstanceIndex={topLevelFormInstanceIndex}
                handleMapImageUpload={handleMapImageUpload}
                referenceMaterials={referenceMaterials}
                nextForm={nextForm}
                setShowReferenceMaterialList={setShowReferenceMaterialList}
                setCurrReferenceMaterialField={setCurrReferenceMaterialField}
              />
            ))}
          </div>
        )}
      </Collapse>
    </Stack>
  );
};
