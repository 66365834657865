import { DictionaryContents, generateDictionary } from "src/utils/excelParse";
import { DictionaryLevel } from "../pages/ExcelMappingTool";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import { Configuration } from "../../FormBuilder/components/TypeDefinitions";

export default function addSpecificLocation(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  config: Configuration
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          addSpecificLocation(
            dictionary.subforms[chain[1].formId],
            newChain,
            config
          );
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          addSpecificLocation(
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId],
            newChain,
            config
          );
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    
    if (dictionary.enumerable === "specifiedLocation") {
      const newDict = generateDictionary(
        config,
        chain[0].formId,
        "specifiedLocation"
      );

      if (!newDict) return;

      const dictContent = newDict[
        Object.keys(newDict)[0]
      ] as DictionaryContents & { enumerable: "specifiedLocation" };

      dictionary.enumerations.push(dictContent.enumerations[0]);
    }
  }
}
