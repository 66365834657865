import { PageState, Action } from "../../components/TypeDefinitions";
export function addReferenceMaterialToField<
  E extends Extract<
    Action,
    {
      type: "addReferenceMaterialToField";
    }
  >
>(action: E, draft: PageState): void {
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  if (formIndex === -1) return;
  const fieldIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.findIndex((field) => field.id === action.payload.fieldId);
  if (fieldIndex === -1) return;
  const currField =
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex].fields[
      fieldIndex
    ];

  if (!draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds) {
    draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds = [];

    return;
  }

  if (
    !draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds.includes(
      action.payload.referenceMaterialId
    )
  ) {
    draft.data.inspectionTypeTemplate.configuration.referenceMaterialIds.push(
      action.payload.referenceMaterialId
    );
  }
  var fieldReferenceMaterials = currField.referenceMaterialIds;
  if(!fieldReferenceMaterials){
    fieldReferenceMaterials = [];
  }
  if(!fieldReferenceMaterials?.includes(action.payload.referenceMaterialId)){
    fieldReferenceMaterials?.push(action.payload.referenceMaterialId);
  }
  Object.assign(
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex].fields[
      fieldIndex
    ],
    {referenceMaterialIds: fieldReferenceMaterials}
  );
}
