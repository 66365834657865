import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect } from "react";
import {
  Expression,
  Operator,
  Validation,
  ExpressionType,
} from "../TypeDefinitions";
import { FormCtx } from "../../FormWrapper/BuilderWrapper";
import getAvailableOperators from "./getAvailableOperators";
import { gql, useLazyQuery } from "@apollo/client";

const GET_GEO_RULES = gql`
  query GeoRules($geoRule: InputGeoRuleParams) {
    geoRules(geoRule: $geoRule) {
      id
      title
      description
      value
      unit
      featureType {
        id
        name
      }
      operand
    }
  }
`;

const Expressions: FC<{ validation: Validation }> = ({ validation }) => {
  return (
    <>
      {validation.expressions.length > 0 ? (
        <>
          <Typography
            color="GrayText"
            sx={{ marginBottom: "-0.5rem", marginTop: "0.5rem" }}
          >
            Conditions
          </Typography>
          {validation.expressions.map((expression, i) => {
            return (
              <ExpressionComponent
                expression={expression}
                key={expression.id}
              />
            );
          })}
        </>
      ) : (
        <Typography textAlign="center" color="red">
          Please add a condition.
        </Typography>
      )}
    </>
  );
};

export default Expressions;

const ExpressionComponent: FC<{ expression: Expression }> = ({
  expression,
}) => {
  // const { fields, getExpression, updateExpression, deleteExpression } =
  //   useContext(BuilderContext);

  const { state, dispatch } = useContext(FormCtx);

  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );

  const fields = form!.fields;

  const [getGeoRules, { data: geoRulesData, loading: geoRulesLoading }] =
    useLazyQuery(GET_GEO_RULES, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted: () => {},
    });

  let field = fields.find((field) => field.id === expression.fieldToCompare);
  let geoRule = geoRulesData
    ? geoRulesData.geoRules.find(
        (rule: any) => rule.id === expression.geoRuleToCompare
      )
    : null;

  const isFirst = expression.order === 0;

  const expressionTypes = [
    { key: "Field", label: "Field" },
    { key: "GeoRule", label: "Geo Rule" },
  ];

  useEffect(() => {
    if (!geoRulesData && !geoRulesLoading) {
      getGeoRules();
    }
  });

  useEffect(() => {}, [geoRulesData]);

  return (
    // <Card sx={{ marginBottom: "0.5rem", boxShadow: 3 }}>
    //   <CardContent>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //alignItems: "center",
        justifyContent: "space-between",
        borderTop: "dashed 1px #cccccc",
        paddingTop: "1rem",
        marginTop: isFirst ? "1rem" : 0,
      }}
    >
      {geoRulesLoading && <CircularProgress></CircularProgress>}

      {!isFirst && !geoRulesLoading && (
        <TextField
          select
          sx={{ marginBottom: "0.5rem", minWidth: "4rem" }}
          value={expression.linkedBy}
          onChange={(e) => {
            if (e.target.value !== "AND" && e.target.value !== "OR") return;
            dispatch({
              type: "updateExpression",
              payload: {
                expressionId: expression.id,
                validationId: expression.formValidationId,
                paramToUpdate: {
                  linkedBy: e.target.value,
                },
              },
            });
          }}
        >
          <MenuItem value="AND">AND</MenuItem>
          <MenuItem value="OR">OR</MenuItem>
        </TextField>
      )}
      {!geoRulesLoading && (
        <Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <TextField
              sx={{ margin: "2px" }}
              select
              label="Type"
              fullWidth
              size="small"
              value={expression.type ? expression.type : "Field"}
              onChange={(e) => {
                let params: any = {
                  type: e.target.value as ExpressionType,
                };
                if (expression.value) {
                  params["value"] = expression.value;
                } else {
                  params["value"] = true;
                }

                dispatch({
                  type: "updateExpression",
                  payload: {
                    expressionId: expression.id,
                    validationId: expression.formValidationId,
                    paramToUpdate: params,
                  },
                });
              }}
            >
              {expressionTypes &&
                expressionTypes.length > 0 &&
                expressionTypes.map((type, i) => (
                  <MenuItem key={type.key} value={type.key}>
                    {type.label}
                  </MenuItem>
                ))}
            </TextField>
            {expression.type === "GeoRule" && (
              <TextField
                select
                fullWidth
                label="Geo Rule"
                size="small"
                value={geoRule?.id}
                error={!geoRule?.id}
                onChange={
                  (event) =>
                    dispatch({
                      type: "updateGeoRuleToCompare",
                      payload: {
                        validationId: expression.formValidationId,
                        expressionId: expression.id,
                        geoRuleToCompareId: event.target.value,
                      },
                    })
                  // handleUpdate({
                  //   fieldToCompare: event.target.value,
                  //   linkedBy: expression.linkedBy,
                  //   order: index,
                  // })
                }
              >
                {geoRulesData &&
                  geoRulesData.geoRules.length > 0 &&
                  geoRulesData.geoRules.map((g: any, i: any) => (
                    <MenuItem key={g.id} value={g.id}>
                      {g.title}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            {expression.type === "Field" && (
              <TextField
                select
                fullWidth
                label="Field"
                size="small"
                value={field?.id}
                error={!field?.id}
                onChange={
                  (event) =>
                    dispatch({
                      type: "updateFieldToCompare",
                      payload: {
                        validationId: expression.formValidationId,
                        expressionId: expression.id,
                        fieldToCompareId: event.target.value,
                      },
                    })
                  // handleUpdate({
                  //   fieldToCompare: event.target.value,
                  //   linkedBy: expression.linkedBy,
                  //   order: index,
                  // })
                }
              >
                {fields &&
                  fields.length > 0 &&
                  fields.map((field, i) => (
                    <MenuItem key={field.id} value={field.id}>
                      {field.label}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "3rem",
              }}
            >
              <Typography>is</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              marginTop: "0.5rem",
            }}
          >
            <ExpressionEditingOptions expression={expression} />
          </Box>
          <Button
            sx={{ marginY: "0.5rem" }}
            onClick={(e) =>
              dispatch({
                type: "deleteExpression",
                payload: {
                  validationId: expression.formValidationId,
                  expressionId: expression.id,
                },
              })
            }
          >
            Delete Condition
          </Button>
        </Stack>
      )}
    </Box>
  );
};

const ExpressionEditingOptions: FC<{ expression: Expression }> = ({
  expression,
}) => {
  const { dispatch, state } = useContext(FormCtx);
  const formIndex =
    state.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === state.formId
    );
  const field = state.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].fields.find((field) => field.id === expression.fieldToCompare);
  //if (!field) return <></>;

  const availableOperators = field ? getAvailableOperators(field) : [];

  var controlToReturn = <></>;
  if (expression.type == "Field") {
    controlToReturn = (
      <Stack direction="column" width="100%">
        <Stack direction="row" width="100%" spacing={1}>
          <TextField
            select
            fullWidth
            label=""
            size="small"
            value={expression.operator === null ? "" : expression.operator}
            onChange={(e) => {
              const operator = e.target.value as Operator;
              dispatch({
                type: "updateExpression",
                payload: {
                  validationId: expression.formValidationId,
                  expressionId: expression.id,
                  paramToUpdate: {
                    operator: operator,
                    value: null,
                  },
                },
              });
            }}
          >
            {availableOperators.map((operator) => {
              function getOperatorLabel(type: Operator): String {
                switch (type) {
                  case "!==":
                    return "Not Equal To . . .";
                  case "<":
                    return "Less Than . . .";
                  case "==":
                    return "Equal To . . .";
                  case ">":
                    return "Greater Than . . .";
                  case "contains":
                    return "Containing Each Of . . .";
                  case "does not contain":
                    return "Not Containing Each Of . . .";
                  case "contains one of":
                    return "Containing One Of . . .";
                  case "does not contain one of":
                    return "Not Containing One Of . . .";
                  case "not null":
                    return "Not Empty";
                }
              }
              const label = getOperatorLabel(operator);
              return (
                <MenuItem key={operator} value={operator}>
                  {label}
                </MenuItem>
              );
            })}
          </TextField>
          {(field?.inputType === "OptionSelect" ||
            field?.inputType === "DropDown") &&
            expression.operator !== "not null" && (
              <TextField
                select
                fullWidth
                size="small"
                error={!expression.value}
                helperText={!expression.value && "Make a selection."}
                value={expression.value}
                onChange={(e) =>
                  dispatch({
                    type: "updateExpression",
                    payload: {
                      validationId: expression.formValidationId,
                      expressionId: expression.id,
                      paramToUpdate: {
                        value: e.target.value,
                      },
                    },
                  })
                }
              >
                {field.possibleValues.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          {field?.inputType === "Number" &&
            expression.operator !== "not null" && (
              <TextField
                size="small"
                error={expression.value === null}
                helperText={
                  expression.value === null
                    ? "Enter a number."
                    : "Only numbers."
                }
                value={
                  expression.value
                    ? expression.value
                    : expression.value === 0
                    ? 0
                    : ""
                }
                onChange={(e) => {
                  const onlyNums = Number(
                    e.target.value.replace(/[^0-9]/g, "")
                  );
                  const newVal =
                    e.target.value === "" ||
                    (e.target.value !== "0" && onlyNums === 0)
                      ? null
                      : onlyNums;
                  dispatch({
                    type: "updateExpression",
                    payload: {
                      validationId: expression.formValidationId,
                      expressionId: expression.id,
                      paramToUpdate: {
                        value: newVal,
                      },
                    },
                  });
                }}
              />
            )}
          {field?.inputType === "YesNo" &&
            expression.operator !== "not null" && (
              <TextField
                select
                size="small"
                fullWidth
                error={expression.value === null}
                helperText={expression.value === null && "Make a selection."}
                value={
                  expression.value === null
                    ? null
                    : expression.value === true
                    ? "yes"
                    : "no"
                }
                onChange={(e) => {
                  let newVal = e.target.value === "yes";
                  dispatch({
                    type: "updateExpression",
                    payload: {
                      validationId: expression.formValidationId,
                      expressionId: expression.id,
                      paramToUpdate: {
                        value: newVal,
                      },
                    },
                  });
                }}
              >
                <MenuItem value={"yes"}>Yes</MenuItem>
                <MenuItem value={"no"}>No</MenuItem>
              </TextField>
            )}
        </Stack>
        {field?.inputType === "Checklist" &&
          (expression.operator === "contains" ||
            expression.operator === "does not contain" ||
            expression.operator === "contains one of" ||
            expression.operator === "does not contain one of") && (
            <Stack direction={"column"} alignSelf={"center"}>
              {field.possibleValues.map((pv) => (
                <FormControlLabel
                  key={pv.id}
                  control={
                    <Checkbox
                      name={pv.id}
                      checked={
                        expression.value && expression.value.includes(pv.id)
                      }
                      onChange={(e) => {
                        let currentValue =
                          expression.value === null ? [] : expression.value;
                        let currentArray: any[] | null = [...currentValue];
                        if (!currentArray.includes(e.target.name)) {
                          currentArray.push(e.target.name);
                        } else {
                          currentArray = currentArray.filter(
                            (pvId) => pvId !== e.target.name
                          );
                        }
                        if (currentArray && currentArray.length === 0)
                          currentArray = null;
                        dispatch({
                          type: "updateExpression",
                          payload: {
                            expressionId: expression.id,
                            paramToUpdate: {
                              value: currentArray,
                            },
                            validationId: expression.formValidationId,
                          },
                        });
                      }}
                    />
                  }
                  label={pv.label}
                />
              ))}
            </Stack>
          )}
        {(!expression.value || expression.value.length === 0) &&
          expression.operator !== "not null" && (
            <p style={{ color: "red" }}>*Select at least one value.</p>
          )}
      </Stack>
    );
  }
  if (expression.type == "GeoRule") {
    if(expression.value === null){
      dispatch({
        type: "updateExpression",
        payload: {
          validationId: expression.formValidationId,
          expressionId: expression.id,
          paramToUpdate: {
            value: true,
          },
        },
      });
    }
    controlToReturn = (
      <TextField
        select
        fullWidth
        label=""
        size="small"
        value={expression.value === null ? "true" : expression.value.toString()}
        onChange={(e) => {
          var result = false;
          if (e.target.value === "true") {
            result = true;
          }

          dispatch({
            type: "updateExpression",
            payload: {
              validationId: expression.formValidationId,
              expressionId: expression.id,
              paramToUpdate: {
                value: result,
              },
            },
          });
        }}
      >
        <MenuItem key={0} value={"true"}>
          {"True"}
        </MenuItem>
        <MenuItem key={1} value={"false"}>
          {"False"}
        </MenuItem>
      </TextField>
    );
  }

  return controlToReturn;
};
