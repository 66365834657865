// material
import { Box, Grid, Container, Typography } from "@mui/material";
// import { useSnackbar } from "notistack";
// import { useNavigate } from "react-router-dom";
// components
import Page from "../../../components/Page";
import GQLTable from "../../../utils/gql-mui-rhf/Table";
// ----------------------------------------------------------------------

export default function Assets() {
    // let navigate = useNavigate();
    // const { enqueueSnackbar } = useSnackbar();

    // const snackbar = (message, variant) => {
    //     // variant could be success, error, warning, info, or default
    //     enqueueSnackbar(message, { variant });
    // };
    return (
        <Page title="Onboarding | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Maintenance Records</Typography>
                </Box>
                <Grid container spacing={3}>
                    <GQLTable
                        queryString={'maintenanceRecords'}
                        queryVariables={{ "active": true }}
                        addMutationString={'createMaintenanceRecord'}
                        updateMutationString={'updateMaintenanceRecord'}
                        deleteMutationString={'deleteMaintenanceRecord'}
                        columnOptions={{ name: { width: 250 }, module: { width: 200 } }}
                        restrictedColumns={{ id: true, active: true }}
                    />
                </Grid>
            </Container>
        </Page>
    );
}

