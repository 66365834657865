import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import arrowBackOutline from "@iconify/icons-eva/arrow-back-outline";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.primary.main,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = item ? active(item.path) : false;
  const [open, setOpen] = useState(isActiveRoot);
  const [openSecondary, setOpenSecondary] = useState(isActiveRoot);
  if(!item){return null}
  const { title, path, icon, info, children } = item;
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };


  const handleOpenSecondary = () => {
    setOpenSecondary((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.primary.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item, idx) => {
              if(!item){
                return null
              }
              const { title, path, icon } = item;
              const isActiveSub = active(path);
              if (item.children) {
                return (
                  <Fragment key={idx}>
                    <ListItemStyle
                      key={idx}
                      onClick={handleOpenSecondary}
                      style={{ left: "16px" }}
                      sx={{
                        ...(isActiveRoot && activeRootStyle),
                      }}
                    >
                      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                      <ListItemText disableTypography primary={title} />
                      {info && info}
                      <Box
                        component={Icon}
                        icon={
                          openSecondary
                            ? arrowIosDownwardFill
                            : arrowIosForwardFill
                        }
                        sx={{ width: 16, height: 16, ml: 1 }}
                      />
                    </ListItemStyle>

                    <Collapse in={openSecondary} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.children.map((item, index) => {
                          const { title, path } = item;
                          const isActiveSub = active(path);

                          return (
                            <ListItemStyle
                              key={index}
                              style={{ left: "32px" }}
                              component={RouterLink}
                              to={path}
                              sx={{
                                ...(isActiveSub && activeSubStyle),
                              }}
                            >
                              <ListItemIconStyle>
                                <Box
                                  component="span"
                                  sx={{
                                    width: 4,
                                    height: 4,
                                    display: "flex",
                                    borderRadius: "50%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "text.disabled",
                                    transition: (theme) =>
                                      theme.transitions.create("transform"),
                                    ...(isActiveSub && {
                                      transform: "scale(2)",
                                      bgcolor: "primary.main",
                                    }),
                                  }}
                                />
                              </ListItemIconStyle>
                              <ListItemText disableTypography primary={title} />
                            </ListItemStyle>
                          );
                        })}
                      </List>
                    </Collapse>
                  </Fragment>
                );
              }
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
  backButton: PropTypes.bool,
};

export default function NavSection({ navConfig, backButton, ...other }) {
  const { pathname } = useLocation();
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding>
        {backButton && (
          <NavItem
            key="return"
            item={{
              title: "Return to Project",
              path: "/app",
              icon: getIcon(arrowBackOutline),
            }}
            active={() => false}
          />
        )}
        {navConfig.map((item, idx) => (
          <NavItem key={idx} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
