import { exhaustiveGuard } from "src/utils/exhaustiveGuard";

export default function updateField(
  dictionary,
  chain,
  fieldId,
  newPdfValue,
  fieldAnswer
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "specifiedLocation":
        {
          const enumeration = chain[1].enumeration;
          if (enumeration === false) return;
          const newChain = chain.slice(1);
          if (chain.length === 2) {
            if (chain[1].formId === chain[0].formId) {
              dictionary.enumerations[enumeration].fields[fieldId][fieldAnswer] = newPdfValue;
            } else {
              let subform =
                dictionary.enumerations[enumeration].subforms[chain[1].formId];
              if (subform.enumerable === false) {
                subform.fields[fieldId][fieldAnswer] = newPdfValue;
              }
            }
          } else {
            const nextDictionaryContent =
              dictionary.enumerations[enumeration].subforms;
            if (!nextDictionaryContent) return;
            updateField(
              nextDictionaryContent[chain[2].formId],
              newChain,
              fieldId,
              newPdfValue,
              fieldAnswer
            );
          }
        }
        break;
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (!dictionary.subforms) return;
          const nextDictionaryContent = dictionary.subforms[chain[1].formId];
          updateField(nextDictionaryContent, newChain, fieldId, newPdfValue, fieldAnswer);
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    switch (dictionary.enumerable) {
      case "specifiedLocation":
        if (chain[0].enumeration !== false) {
          dictionary.enumerations[chain[0].enumeration].fields[fieldId][fieldAnswer] =
            newPdfValue;
        }
        break;
      case "byOffset":
        dictionary.fields[fieldId][fieldAnswer] = newPdfValue;
        break;
      case false:
        if (!dictionary.fields[fieldId] || typeof dictionary.fields[fieldId] !== 'object') {
          dictionary.fields[fieldId] = {};
        }
        dictionary.fields[fieldId][fieldAnswer] = newPdfValue;
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
    // if (dictionary.enumerable !== false) return;
    // if (!dictionary.fields) return;
    // dictionary.fields[fieldId] = newPdfValue;
  }
}
