import * as React from "react";

import { useParams } from "react-router-dom";
//gql-mui-rhf
import { createQuery } from "../../utils/gql-mui-rhf/QueryAction";
import Projects from "./list";
import { GQLForm } from "../../utils/gql-mui-rhf/FormProvider";

// material
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../../components/Page";
// import ProjectProfile from './profile';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import ProjectPeople from "./People";
// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FirmView() {
  const params = useParams();
  const projectId = params.id;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { loading, error, data } = createQuery("project", { id: projectId });
  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    let previousElement = data.project.parentProject?.id ? (
      <Link to={`/app/admin/project/${data.project.parentProject.id}`}>
        {" "}
        Parent Project
      </Link>
    ) : (
      <Link to={`/app/admin/firm-unit/${data.project.projectOwner}`}>
        {" "}
        Firm Unit
      </Link>
    );
    const breadcrumbs = [
      previousElement,
      <Typography key="3" color="text.primary">
        Project View
      </Typography>,
    ];
    return (
      <Page title="Dashboard | FINBACK670">
        <Container maxWidth="xl" style={{ paddingBottom: "64pt" }}>
          <Box sx={{ pb: 5 }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Project Attributes" {...a11yProps(0)} />
              <Tab label="Projects" {...a11yProps(1)} />
              <Tab label="Project People" {...a11yProps(1)} />
              <Tab label="Add Project Person" {...a11yProps(1)} />
              <Tab label="Add Project Firm Unit" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <ProjectProfile projectId={data.project.id} /> */}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Projects
              projectId={data.project.id}
              firmUnitId={data.project.projectOwner}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ProjectPeople project={data.project} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <GQLForm
              mutationString={"createProjectPerson"}
              defaultValues={{ projectId: data.project.id }}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <GQLForm
              mutationString={"createProjectFirmUnit"}
              defaultValues={{ projectId: data.project.id }}
            />
          </TabPanel>
        </Container>
      </Page>
    );
  }
}
