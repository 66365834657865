import { motion } from 'framer-motion';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../../../components/animate';

export default function MobileMapCaptureComplete() {
  return (
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Map Capture Complete!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              You will be redirected momentarily.
            </Typography>
          </Box>
        </MotionContainer>
      </Container>
  );
}