import { useEffect, useState } from 'react';
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../../utils/contexts/project";
// material
import { Box, Container, Typography } from '@mui/material';
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
// components
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { gql } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

const GET_PROJECT_PROJECTS = gql`
query ProjectProjects($project: InputProjectParams!) {
    projectProjects(project: $project) {id}
  }
`;

const GET_PROJECT_INSPECTIONS = gql`
query projectsInspections($projectIds: InputProjectIdsParams) {
    projectsInspections(projectIds: $projectIds) {
    id
    projectId
    inspectionLocation
    number
    contactName
    contactPhone
    isDeleted
    status
  }}
`;

export default function InspectionList() {
    const apiRef = useGridApiRef();
    let projectContext = useContext(ProjectContext);
    const { project } = projectContext;
    const [selectionModel, setSelectionModel] = useState([]);
    const [setSelectionRecord] = useState([]);
    const navigate = useNavigate()
    const [projectProjects, setProjectProjects] = useState(null);
    const [inspections, setProjectInspections] = useState(null);
    
    const [getProjectProjects] = useLazyQuery(GET_PROJECT_PROJECTS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setProjectProjects(response.projectProjects)
        },
        onError: (response) => {
        }
    })
    const [getProjectInspections] = useLazyQuery(GET_PROJECT_INSPECTIONS, {
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            setProjectInspections(response.projectInspections)
        },
        onError: (response) => {
        }
    });
    useEffect(
        () => {
            if(project){
                getProjectProjects({variables: {project: {id: project.id, name: project.name, projectNumber: project.number}}})
            }
            if (project && projectProjects) {
                getProjectInspections({ variables: {projectIds: {projectIds: projectProjects.map(e => e.id)} }})
            }
        },
        [project, projectProjects, inspections, getProjectProjects, getProjectInspections]
    )
    let rows = inspections ? inspections : []
    return (
        <Page title="Inspections | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Inspections</Typography>
                </Box>
                <div style={{ height: 600, width: "100%" }}>
                    <DataGridPremium
                        rows={rows}
                        apiRef={apiRef}
                        columns={[
                            { field: 'inspectionType.name', headerName: "Inspection Type" },
                            { field: 'number', headerName: "Inspection Number" },
                            { field: 'contactName', headerName: "Contact Name" },
                            { field: 'contactPhone', headerName: "Contact Phone" },
                            { field: 'inspectionLocation', headerName: "Project Location" },
                            { field: 'projectId', headerName: "Project" },
                            { field: 'id', headerName: "ID" },
                        ]}
                        checkboxSelection
                        pageSize={5}
                        onRowClick={(rowData) => {
                            navigate(`/app/inspections/${rowData.id}`)
                        }}
                        pageSizeOptions={[50]}
                        experimentalFeatures={{ newEditingApi: true }}
                        onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = rows.filter((row) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectionRecord(selectedRowData);
                            setSelectionModel(ids);
                        }}
                        rowSelectionModel={selectionModel}
                    />
                </div>
            </Container>
        </Page>
    );
}
