import { DictionaryContents } from "src/utils/excelParse";
import { DictionaryLevel } from "../pages/ExcelMappingTool";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";

export default function offsetRemove(
  dictionary: DictionaryContents,
  chain: DictionaryLevel[],
  index: number
) {
  if (chain.length > 1) {
    switch (dictionary.enumerable) {
      case "byOffset":
      case false:
        {
          const newChain = chain.slice(1);
          if (dictionary.subforms === null) return;
          offsetRemove(dictionary.subforms[chain[1].formId], newChain, index);
        }
        break;
      case "specifiedLocation":
        {
          const newChain = chain.slice(1);
          const nextEnumeration = chain[0].enumeration;
          if (nextEnumeration === false) return;
          offsetRemove(
            dictionary.enumerations[nextEnumeration].subforms![chain[1].formId],
            newChain,
            index
          );
        }
        break;
      case "groupMember":
        return;
      default:
        exhaustiveGuard(dictionary);
    }
  } else {
    if (dictionary.enumerable !== "byOffset") return;
    dictionary.offsetLocations.splice(index, 1);
  }
}
