import { gql, useLazyQuery } from "@apollo/client";
import { Edit } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Page from "src/components/Page";
import { ProjectContext } from "src/utils/contexts/project";

const GET_PROJECT_TREE = gql`
query ProjectTree($project: InputProjectParams!) {
    projectTree(project: $project) {id}
  }
`;

const GET_PROJECTS_INSPECTION_TYPES = gql`
query projectsInspectionType($projectIds: [InputInspectionTypeParams]) {
    projectsInspectionTypes(projectIds: $projectIds) {
      id
      name
      description
      projectId
      inspectionTypeTemplates {
        id
      }
  }}
`;

export default function InspectionTypesTable() {
  let projectContext = useContext(ProjectContext);
  const { project } = projectContext;
  const [dataLoading, setDataLoading] = useState(true);
  const [projectTree, setProjectTree] = useState(null);
  const [getProjectTree, {called: projectTreeCalled}] = useLazyQuery(GET_PROJECT_TREE, {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
          setProjectTree(response.projectTree)
      },
      onError: (response) => {
      }
  })
  const columns = [
    {
      field: "Edit",
      width: 50,
      renderCell: (params) => (
        <Link to={params.id}>
          <Edit color="primary" />
        </Link>
      ),
    },
    {
      field: "name",
      headerName: "Inspection Type",
      width: 250,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "projectId",
      headerName: "Project ID",
      flex: 1,
    },
    // {
    //   headerName: "Template Count",
    //   width: 70,
    //   renderCell: (params) => params.inspectionTypeTemplates.length,
    // },
  ];
  const [inspectionTypes, setProjectsInspectionTypes] = useState([]);
  const [getProjectsInspectionTypes, {called: getInspectionTypesCalled}] = useLazyQuery(GET_PROJECTS_INSPECTION_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      var projectsInspectionTypes = response.projectsInspectionTypes.filter(function(element){ return element.id!=null; })
        setProjectsInspectionTypes(projectsInspectionTypes);
        setDataLoading(false);
    },
    onError: (response) => {
    }
})


useEffect(
  () => {
    //if(!projectTreeCalled){
    async function getData() {
      if (project) {
        await getProjectTree({ variables: { project: { id: project.id, name: project.name, projectNumber: project.projectNumber, projectId: project.projectId } } });
      }
    }
      let timer = setTimeout(() => {
        getData();
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    //}
  }, [project]
)
useEffect(()=>{
  if (project && projectTree) {
    getProjectsInspectionTypes({ variables: { projectIds: projectTree.map(e => {return {projectId: e.id}})  } });
  }
}, [projectTree])
  return (
    <Page
      title="Inspection Types | FINBACK670"
      sx={{ height: "100%", maxHeight: "100%" }}
    >
      <Breadcrumbs>
        <Link to=".">Inspection Types List</Link>
      </Breadcrumbs>
      <Box height="0.5rem" />
      {
        <Button
          variant="outlined"
          component={Link}
          to={"create"}
          sx={{ marginBottom: "1rem" }}
        >
          Create New Inspection Type
        </Button>
      }
      {dataLoading && <CircularProgress />}
      {inspectionTypes && <DataGrid rows={inspectionTypes} columns={columns} />}
    </Page>
  );
}
