import React, { createContext, useContext, useState } from "react";
import { Form } from "../FormBuilder/components/TypeDefinitions";
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormCtx } from "../FormBuilder/FormWrapper/BuilderWrapper";
import { useNavigate } from "react-router-dom";

const rootTypes: Form["type"][] = [
  "Questionnaire",
  "ESignature",
  "AdditionalPhotos",
  "TimeLog",
  "RightOfEntry",
  "SpecialConditions",
];

const MenuContext = createContext<{
  contextMenu: {
    mouseX: number;
    mouseY: number;
  } | null;
  handleClose: () => void;
} | null>(null);

export default function TreeViewFormDisplay({ forms }: { forms: Form[] }) {
  let rootForms: string[] = [];
  let formMap: FormMap = {};
  forms.forEach((form) => {
    formMap[form.id] = form;
    if (rootTypes.includes(form.type)) rootForms.push(form.id);
  });

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const { dispatch } = useContext(FormCtx);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  function getUnaddressedForms() {
    let unaddressedForms: { [key: string]: boolean } = {};
    let indexedForms: { [key: string]: Form } = {};
    let beenRecursed: { [key: string]: boolean } = {};

    forms.forEach((form) => {
      indexedForms[form.id] = form;
      if (unaddressedForms[form.id] === undefined) {
        unaddressedForms[form.id] = true;
      }
      if (rootTypes.includes(form.type)) {
        if (!form.subformIds || form.subformIds.length === 0) {
          beenRecursed[form.id] = true;
          unaddressedForms[form.id] = false;
        } else {
          beenRecursed[form.id] = false;
        }
      }
    });

    function recurse(nodeId: string) {
      if (!beenRecursed[nodeId]) {
        indexedForms[nodeId].subformIds.forEach((id) => recurse(id));
        unaddressedForms[nodeId] = false;
        beenRecursed[nodeId] = true;
      }
    }

    Object.keys(beenRecursed).forEach((key) => recurse(key));

    let isRootUnaddressed: { [key: string]: boolean } = {};

    Object.keys(unaddressedForms).forEach((key) => {
      const isUnaddressed = unaddressedForms[key] === true;
      if (isUnaddressed && isRootUnaddressed[key] !== false) {
        isRootUnaddressed[key] = true;
        if (indexedForms[key].subformIds.length > 0) {
          setChildrenRootUnaddressedToFalse(key);
        }
      }
    });

    function setChildrenRootUnaddressedToFalse(formId: string) {
      const form = indexedForms[formId];
      form.subformIds.forEach((subformId) => {
        isRootUnaddressed[subformId] = false;
        setChildrenRootUnaddressedToFalse(subformId);
      });
    }

    const unaddressedRoots =
      Object.keys(isRootUnaddressed).length > 0
        ? Object.keys(isRootUnaddressed)
            .filter((key) => isRootUnaddressed[key] === true)
            .map((key) => indexedForms[key])
        : [];

    return unaddressedRoots;
  }

  const unaddressedForms = getUnaddressedForms();

  return (
    <MenuContext.Provider value={{ contextMenu, handleClose }}>
      <div onContextMenu={handleContextMenu}>
        <DragDropContext
          onDragEnd={(newOrder) => {
            switch (newOrder.type) {
              case "0":
                {
                  if (!newOrder.destination) return;
                  const destination = newOrder.destination;
                  const formId = JSON.parse(newOrder.draggableId)[0];
                  const initialFormIndex = forms.findIndex((f) => {
                    return f.id === formId;
                  });
                  const newFormIndex = forms.findIndex((f) => {
                    return f.id === rootForms[destination.index];
                  });
                  dispatch({
                    type: "moveForm",
                    payload: {
                      initialIndex: initialFormIndex,
                      newIndex: newFormIndex,
                    },
                  });
                }
                break;
              default: {
                const chain = JSON.parse(newOrder.draggableId);
                const parent = chain[chain.length - 2];
                if (!newOrder.destination) return;
                let newIndex = newOrder.destination.index;

                dispatch({
                  type: "moveSubform",
                  payload: {
                    initialIndex: newOrder.source.index,
                    newIndex: newIndex,
                    parentFormId: parent,
                  },
                });
              }
            }
          }}
        >
          <Droppable droppableId="0" type="0">
            {(provided, snapshot) => (
              <Stack
                direction="column"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {rootForms.map((rootForm, i) => (
                  <FormRow
                    chain={[rootForm]}
                    formMap={formMap}
                    index={i}
                    key={rootForm}
                  />
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
          {unaddressedForms.length > 0 && (
            <Droppable droppableId="1">
              {(provided, snapshot) => (
                <Stack
                  direction="column"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <Typography variant="h6">Inaccessible Forms</Typography>
                  {unaddressedForms.map((form, i) => (
                    <FormRow
                      chain={["!", form.id]}
                      formMap={formMap}
                      index={i}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          )}
        </DragDropContext>
      </div>
    </MenuContext.Provider>
  );
}

type FormMap = {
  [formId: string]: Form;
};

const FormRow = ({
  chain,
  formMap,
  index,
}: {
  chain: string[];
  formMap: FormMap;
  index: number;
}) => {
  const navigate = useNavigate();
  const menu = useContext(MenuContext);
  const form = formMap[chain[chain.length - 1]];
  const [expanded, setExpanded] = useState(false);
  const hasSubforms = form.subformIds.length > 0;

  if (menu === null) return <></>;
  const { handleClose, contextMenu } = menu;
  const dragId = JSON.stringify(chain);
  console.log({ dragId });
  return (
    <>
      <Draggable draggableId={dragId} index={index}>
        {(provided, snapshot) => (
          <Stack
            direction="column"
            alignItems="stretch"
            justifyContent="start"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Stack direction="row" alignItems="center" minWidth="100%">
              <div
                style={{
                  height: "100%",
                  width: "1.2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={(e) => setExpanded(!expanded)}
              >
                {hasSubforms &&
                  (expanded ? (
                    <ArrowDropDown fontSize="small" color="disabled" />
                  ) : (
                    <ArrowRight fontSize="small" color="disabled" />
                  ))}
              </div>
              <div onClick={(e) => navigate(form.id)}>
                <Typography>{form.name}</Typography>
              </div>
            </Stack>
            {expanded && hasSubforms && (
              <Droppable
                droppableId={JSON.stringify(chain)}
                type={JSON.stringify(chain)}
              >
                {(provided, snapshot) => (
                  <div
                    style={{
                      marginLeft: "1rem",
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {form.subformIds.map((subform, i) => {
                      const newRowChain = [...chain, formMap[subform].id];
                      const keychain = JSON.stringify(newRowChain);
                      return (
                        <FormRow
                          key={keychain}
                          chain={[...chain, formMap[subform].id]}
                          formMap={formMap}
                          index={i}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </Stack>
        )}
      </Draggable>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClose}>Delete</MenuItem>
        {form.canCopy && <MenuItem onClick={handleClose}>Duplicate</MenuItem>}
      </Menu>
    </>
  );
};
