import {
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Box, IconButton } from "@mui/material";
import PossibleValues from "./PossibleValues";
import LogicEditor from "./LogicEditor";
// import { FormCtx } from "./FormBuilder/FormWrapper/BuilderWrapper";
import { FormCtx } from "../FormWrapper/BuilderWrapper";

import { Error, Add, Cancel } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateField, DateTimeField } from "./TypeDefinitions";
import { exhaustiveGuard } from "src/utils/exhaustiveGuard";
import { useLazyQuery, gql } from "@apollo/client";
import MDEditor from "@uiw/react-md-editor";

const GET_REFERENCE_MATERIALS = gql`
  query ReferenceMaterials($referenceMaterial: InputReferenceMaterialParams!) {
    referenceMaterials(referenceMaterial: $referenceMaterial) {
      id
      documentId
      isDeleted
      stringContents
      title
    }
  }
`;

export default function Editor() {
  const [tab, setTab] = useState(0);
  const { state, dispatch } = useContext(FormCtx);
  const [referenceMaterials, setReferenceMaterials] = useState([]);
  const [selectedReferenceMaterial, setSelectedReferenceMaterial] =
    useState<any>(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [getReferenceMaterials, { refetch, called }] = useLazyQuery(
    GET_REFERENCE_MATERIALS,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        setDataLoading(false);
        setReferenceMaterials(response.referenceMaterials);
      },
      onError: (response) => {},
    }
  );
  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );

  useEffect(() => {
    if (dataLoading && !called) {
      let timer = setTimeout(async () => {
        await getReferenceMaterials({
          variables: {
            referenceMaterial: {
              isDeleted: false,
            },
          },
        });
      }, 500);
      return () => {
        clearTimeout(timer);
      };
    }
  });

  if (!form) return <h1>err1</h1>;

  const fieldParams = form.fields.find(
    (field) => field.id === state.editingFieldId
  );
  if (!fieldParams) return <h1>err2</h1>;
  const { label, isRequired, description, id } = fieldParams;

  const handleTabChange = (e: any, newTabValue: any) => setTab(newTabValue);

  const validationErrors = state.errors.some(
    (error) => error.fieldId === fieldParams.id && error.validationId
  );

  const fieldParameterErrors = state.errors.some(
    (err) => err.fieldId === fieldParams.id && !err.validationId
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        marginTop: "0.5rem",
      }}
    >
      <Box sx={{ marginBottom: "1.5rem" }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="toggle logic editor"
          variant="fullWidth"
        >
          <Tab
            label="Edit Fields"
            value={0}
            icon={fieldParameterErrors ? <Error htmlColor="red" /> : undefined}
            iconPosition="end"
          />
          <Tab
            label="Logic"
            value={1}
            icon={validationErrors ? <Error htmlColor="red" /> : undefined}
            iconPosition="end"
          />
          <Tab label="Reference Material" value={2} />
        </Tabs>
      </Box>
      {tab === 1 ? (
        <LogicEditor />
      ) : tab === 2 ? (
        <Stack padding={1} maxHeight="100%" borderRadius={1}>
          <Stack direction="column" alignItems="stretch">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1.5rem",
              }}
            >
              <TextField
                select
                sx={{ flex: 5 }}
                size="small"
                label="Reference Material"
                onChange={(e) => setSelectedReferenceMaterial(e.target.value)}
              >
                {referenceMaterials.map((rf: any, i) => (
                  <MenuItem key={rf.id} value={rf.id}>
                    {rf.title}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box
              sx={{
                flex: 3,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "0.5rem",
              }}
            >
              <Button
                sx={{ alignSelf: "center", marginY: "1rem" }}
                onClick={() => {
                  if (selectedReferenceMaterial) {
                    dispatch({
                      type: "addReferenceMaterialToField",
                      payload: {
                        referenceMaterialId: selectedReferenceMaterial,
                        fieldId: id,
                      },
                    });
                  }
                }}
                variant="outlined"
                startIcon={<Add />}
              >
                Attatch Reference Material
              </Button>
            </Box>
            <Stack
              sx={{ backgroundColor: "#F0F0F0" }}
              padding={1}
              maxHeight="100%"
              borderRadius={1}
            >
              <Stack
                sx={{ backgroundColor: "#FFFFFF" }}
                padding={1}
                maxHeight="100%"
                borderRadius={1}
              >
                <center>
                  <Typography>Attached Reference Materials</Typography>
                </center>
                {fieldParams.referenceMaterialIds?.map((e) => {
                  var refMat: any = referenceMaterials.find(
                    (f: any) => f.id === e
                  );
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "0.5rem",
                        backgroundColor: "white",
                      }}
                    >
                      <TextField
                        fullWidth
                        label=""
                        value={refMat.title}
                        size="small"
                        disabled={true}
                        onChange={(e) => {}}
                      />
                      <IconButton
                        onClick={() =>
                          dispatch({
                            type: "deleteReferenceMaterialFromField",
                            payload: {
                              fieldId: id,
                              referenceMaterialId: e,
                            },
                          })
                        }
                      >
                        <Cancel />
                      </IconButton>
                    </Box>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <>
          <MarginWrap>
            <TextField
              label="Field Label"
              fullWidth
              value={label ?? ""}
              placeholder="This field represents . . ."
              onChange={(e) =>
                dispatch({
                  type: "updateField",
                  payload: {
                    fieldId: fieldParams.id,
                    newValues: {
                      inputType: fieldParams.inputType,
                      label: e.target.value,
                    },
                  },
                })
              }
              error={!label}
              helperText={
                <>
                  <span style={{ color: "red" }}>*</span>A field label is
                  required.
                </>
              }
            />
          </MarginWrap>
          {fieldParams.inputType !== "Description" && (
            <MarginWrap>
              <FormControlLabel
                control={
                  <Checkbox
                    name="requiredFieldCheck"
                    checked={isRequired ?? false}
                    onChange={(e) =>
                      dispatch({
                        type: "updateField",
                        payload: {
                          fieldId: fieldParams.id,
                          newValues: {
                            inputType: fieldParams.inputType,
                            isRequired: !isRequired,
                          },
                        },
                      })
                    }
                  />
                }
                label="This is a required field."
              />
            </MarginWrap>
          )}
          <MarginWrap>
            {fieldParams.inputType !== "Description" ? (
              <TextField
                label="Description"
                fullWidth
                value={description ?? ""}
                multiline
                onChange={(e) =>
                  dispatch({
                    type: "updateField",
                    payload: {
                      fieldId: fieldParams.id,
                      newValues: {
                        inputType: fieldParams.inputType,
                        description: e.target.value,
                      },
                    },
                  })
                }
                helperText={
                  <>Provide additional information about this field.</>
                }
              />
            ) : (
              <MDEditor
                data-color-mode="light"
                value={fieldParams?.description ?? ""}
                onChange={(e) =>
                  dispatch({
                    type: "updateField",
                    payload: {
                      fieldId: fieldParams.id,
                      newValues: {
                        inputType: fieldParams.inputType,
                        description: e,
                      },
                    },
                  })
                }
              />
            )}
          </MarginWrap>
          {(fieldParams.inputType === "Date" ||
            fieldParams.inputType === "DateTime") && (
            <>
              <MinMaxDateSelect fieldParams={fieldParams} config="min" />
              <MinMaxDateSelect fieldParams={fieldParams} config="max" />
            </>
          )}
          {(fieldParams.inputType === "Text" ||
            fieldParams.inputType === "LongText") && (
            <MarginWrap>
              <TextField
                value={fieldParams.placeholder ?? ""}
                label="Placeholder"
                fullWidth
                onChange={(e) =>
                  dispatch({
                    type: "updateField",
                    payload: {
                      fieldId: fieldParams.id,
                      newValues: {
                        inputType: fieldParams.inputType,
                        placeholder: e.target.value,
                      },
                    },
                  })
                }
                helperText={<>Provide some example text for the user.</>}
              />
            </MarginWrap>
          )}
          <PossibleValues />
        </>
      )}
    </Box>
  );
}

const MarginWrap: FC<{ children: ReactNode }> = ({ children }) => {
  return <Box sx={{ marginBottom: "2rem" }}>{children}</Box>;
};

const MinMaxDateSelect: FC<{
  fieldParams: DateTimeField | DateField;
  config: "min" | "max";
}> = ({ fieldParams, config }) => {
  const { dispatch } = useContext(FormCtx);

  let fieldKey: "maxDate" | "minDate";
  let label: string;
  switch (config) {
    case "max": {
      label = "Maximum Date";
      fieldKey = "maxDate";
      break;
    }
    case "min": {
      label = "Minimum Date";
      fieldKey = "minDate";
      break;
    }
  }

  let dateType: ValueConstant;

  let value: string | null;
  // =
  //   fieldParams[fieldKey] === "today" ? null : fieldParams[fieldKey];

  switch (fieldParams[fieldKey]) {
    case "yesterday": {
      dateType = "yesterday";
      value = null;
      break;
    }
    case "today": {
      dateType = "today";
      value = null;
      break;
    }
    case "tomorrow": {
      dateType = "tomorrow";
      value = null;
      break;
    }
    case "": {
      dateType = "date";
      value = "";
      break;
    }
    case null: {
      dateType = "none";
      value = null;
      break;
    }
    case undefined: {
      dateType = "none";
      value = null;
      break;
    }
    default:
      dateType = "date";
      let x = fieldParams[fieldKey];
      if (x === null) {
        value = "";
      } else if (!isNaN(Date.parse(x))) {
        value = x;
      } else {
        value = "";
      }
      break;
  }

  type ValueConstant = "yesterday" | "today" | "tomorrow" | "date" | "none";

  interface Option {
    value: ValueConstant;
    label: string;
  }
  const options: Option[] = [
    { value: "yesterday", label: "Date Before Inspection" },
    { value: "today", label: "Date of Inspection" },
    { value: "tomorrow", label: "Date After Inspection" },
    {
      value: "date",
      label: "Specify Date",
    },
    {
      value: "none",
      label: "None",
    },
  ];

  function fieldUpdate(newValue: string | null) {
    dispatch({
      type: "updateField",
      payload: {
        fieldId: fieldParams.id,
        newValues: {
          inputType: fieldParams.inputType,
          [fieldKey]: newValue,
        },
      },
    });
  }

  function handleChange(newValue: ValueConstant) {
    switch (newValue) {
      case "date": {
        fieldUpdate("");
        break;
      }
      case "yesterday": {
        fieldUpdate("yesterday");
        break;
      }
      case "today": {
        fieldUpdate("today");
        break;
      }
      case "tomorrow": {
        fieldUpdate("tomorrow");
        break;
      }
      case "none": {
        fieldUpdate(null);
        break;
      }
      default:
        exhaustiveGuard(newValue);
    }
  }
  return (
    <>
      <MarginWrap>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
          spacing={2}
        >
          <TextField
            select
            label={label}
            sx={{ width: "50%" }}
            value={dateType}
            onChange={(e) => handleChange(e.target.value as ValueConstant)}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              value={value}
              disabled={dateType !== "date"}
              onChange={(e) => {
                const dateObj = e === null ? null : new Date(e);
                let dateStringOrNull = dateObj?.toISOString() || null;
                dispatch({
                  type: "updateField",
                  payload: {
                    fieldId: fieldParams.id,
                    newValues: {
                      inputType: fieldParams.inputType,
                      ...(config === "min" && { minDate: dateStringOrNull }),
                      ...(config === "max" && { maxDate: dateStringOrNull }),
                    },
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "50%" }}
                  error={value === ""}
                  helperText={
                    (params.error || value === "") &&
                    !params.disabled && (
                      <>
                        <>Please enter a valid date.</>
                      </>
                    )
                  }
                  placeholder="mm/dd/yyyy"
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </MarginWrap>
    </>
  );
};
