import {
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormHelperText
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";

const MUIRHFCheckbox = ({ description, options, fieldName, valueField, rules, ...rest }) => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field, fieldState: { invalid } }) => {
          return <FormControl component="fieldset" variant="standard" error={invalid}>
            <FormLabel component="legend">{description}</FormLabel>
            {invalid && <FormHelperText variant="filled" error={true}>Make at least one selection</FormHelperText>}
            <FormGroup required={rules && rules.required}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  label={option.label}
                  control={
                    <Checkbox
                      value={option.value}
                      disabled={option.disabled}
                      // For some reason codesandbox doesn't support `field.value.includes(...)`
                      checked={option.disabled || field.value.some(
                        (existingValue) => {
                          return existingValue[valueField] === option.value
                        }
                      )}
                      onChange={(event, checked) => {
                        if (checked) {
                          field.onChange([
                            ...field.value,
                            option.formValue ? option.formValue : option.value
                          ]);
                        } else {
                          field.onChange(
                            field.value.filter(
                              (value) => value[valueField] !== option.value
                            )
                          );
                        }
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        }}
      />
    </>
  );
};

export default MUIRHFCheckbox;
