import { gql, useMutation, useQuery } from "@apollo/client";
import { Edit } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FileField } from "src/components/upload/FileField";
import { generateDictionary } from "src/utils/excelParse";
import { FormCtx } from "../../FormBuilder/FormWrapper/BuilderWrapper";
import uploadFileToBlob from "src/pages/Projects/Details/azure-storage-blob";

const REPORT_TEMPLATES_QUERY = gql`
  query ReportTypeTemplates(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplates(reportTypeTemplate: $reportTypeTemplate) {
      id
      reportType {
        type
      }
      templateUrl
    }
  }
`;

const CREATE_REPORT_TYPE = gql`
  mutation CreateReportType($reportType: InputReportTypeParams) {
    createReportType(reportType: $reportType) {
      type
      id
      description
    }
  }
`;

const CREATE_REPORT_TEMPLATE = gql`
  mutation CreateReportTypeTemplate(
    $reportTypeTemplate: InputReportTypeTemplateParams
  ) {
    createReportTypeTemplate(reportTypeTemplate: $reportTypeTemplate) {
      id
      configuration
      templateUrl
    }
  }
`;

const initialForm: {
  name: string;
  description: string;
  file: File | null;
} = {
  name: "",
  description: "",
  file: null,
};

export default function ReportSelect() {
  const { state } = useContext(FormCtx);
  const { inspectionTypeTemplateId } = useParams();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(initialForm);
  const navigate = useNavigate();
  const { data, loading } = useQuery(REPORT_TEMPLATES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      reportTypeTemplate: {
        inspectionTypeTemplateId: inspectionTypeTemplateId,
      },
    },
  });

  const [createReportType] = useMutation(CREATE_REPORT_TYPE);
  const [createReportTemplate] = useMutation(CREATE_REPORT_TEMPLATE);

  const handleCreate = async () => {
    const createdType = await createReportType({
      variables: {
        reportType: {
          type: form.name,
          description: form.description,
        },
      },
    });
    const config = JSON.stringify(
      generateDictionary(state.data.inspectionTypeTemplate.configuration) || {}
    );
    debugger
    // const myNewFile = form.file
    //   ? new File([form.file], uuidv4() + ".xlsx", { type: form.file.type })
    //   : null;

    const fileUrlArray = await uploadFileToBlob(form.file);
    const fileUrl = fileUrlArray[0];
    
    const createdTemplate = await createReportTemplate({
      variables: {
        reportTypeTemplate: {
          templateUrl: fileUrl,
          reportTypeId: createdType.data.createReportType.id,
          configuration: config,
          inspectionTypeTemplateId: state.data.inspectionTypeTemplate.id,
        },
      },
    });
    navigate(createdTemplate.data.createReportTypeTemplate.id);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={async (e) => {
          setOpen(false);
          setTimeout(() => {
            setForm(initialForm);
          }, 500);
        }}
      >
        <DialogTitle>Create New Report</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2}>
            <TextField
              label="name"
              fullWidth
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
            <TextField
              label="description"
              fullWidth
              multiline
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
            />
            <FileField
              loading={false}
              disabled={false}
              file={form.file}
              setFile={(blob) => {
                setForm({ ...form, file: blob });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h1>Report Templates</h1>
        <Button onClick={(e) => setOpen(true)}>Create New Report</Button>
      </div>
      {loading && <CircularProgress />}
      {data && (
        <DataGridPremium
          autoHeight
          columns={[
            {
              headerName: "Edit",
              field: "id",
              renderCell: (params: any) => {
                return (
                  <IconButton onClick={(e) => navigate(params.value)}>
                    <Edit />
                  </IconButton>
                );
              },
            },
            {
              headerName: "Report Type",
              field: "reportType",
              valueGetter: (params: any) => {
                return params.value.type;
              },
            },
            { headerName: "Template Link", field: "templateUrl" },
          ]}
          rows={data.reportTypeTemplates}
        />
      )}
    </>
  );
}
