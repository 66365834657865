import { Add, Clear, Error, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  FormGroup,
  Checkbox,
} from "@mui/material";
import React, { useContext } from "react";
import { FormCtx } from "../FormWrapper/BuilderWrapper";
import Expressions from "./Expressions";
import MuiMarkdown from "mui-markdown";

const answerValidationFieldTypes = [
  "Checklist",
  "YesNo",
  "OptionSelect",
  "DropDown",
  "Text",
  "LongText",
  "Number",
];

const triggers = [
  {
    label: "Value Change",
    value: "ValueChange",
  },
  {
    label: "Form Save",
    value: "FormSave",
  },
  ,
  {
    label: "Form Create",
    value: "FormCreate",
  },
];

export default function LogicEditor() {
  const { state, dispatch } = useContext(FormCtx);
  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );
  const field = form.fields.find((field) => field.id === state.editingFieldId);
  const validations = form.validations.filter(
    (validation) => validation.field === field.id
  );

  const actions = answerValidationFieldTypes.includes(field.inputType)
    ? ["Show", "Error", "Answer"]
    : ["Show", "Error"];

  function getAnswerValueInputControl(validation) {
    switch (field.inputType) {
      case "Checklist": {
        return (
          <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
            <FormGroup>
              {field.possibleValues?.map(function (value) {
                return (
                  <FormControlLabel
                    key={value.id}
                    control={
                      <Checkbox
                        name={value.id}
                        checked={
                          validation.value
                            ? validation.value.includes(value.id)
                            : false
                        }
                        onChange={(e) => {
                          let currValues = [...validation.value];

                          if (e.target.checked) {
                            if (currValues) {
                              if (!currValues.includes(value.id)) {
                                currValues.push(value.id);
                              }
                            } else {
                              currValues = [value.id];
                            }
                          } else {
                            if (currValues) {
                              if (currValues.includes(value.id)) {
                                const index = currValues.indexOf(value.id);
                                if (index > -1) {
                                  // only splice array when item is found
                                  currValues.splice(index, 1); // 2nd parameter means remove one item only
                                }
                              }
                            }
                            dispatch({
                              type: "updateValidationValue",
                              payload: {
                                value: currValues,
                                validationId: validation.id,
                              },
                            });
                          }
                        }}
                      />
                    }
                    label={value.label}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        );
      }
      case "YesNo": {
        return (
          <FormControl sx={{ marginLeft: "0.6rem" }}>
            <RadioGroup
              value={validation.value}
              onChange={(e) => {
                dispatch({
                  type: "updateValidationValue",
                  payload: {
                    value: e.target.value,
                    validationId: validation.id,
                  },
                });
              }}
            >
              <FormControlLabel
                key={"a"}
                value={true}
                control={<Radio />}
                label={"Yes"}
              />
              <FormControlLabel
                key={"b"}
                value={false}
                control={<Radio />}
                label={"No"}
              />
            </RadioGroup>
          </FormControl>
        );
      }
      case "OptionSelect": {
        return (
          <FormControl sx={{ marginLeft: "0.6rem" }}>
            <RadioGroup
              name={`${field.label} group`}
              value={validation.value}
              onChange={(e) => {
                dispatch({
                  type: "updateValidationValue",
                  payload: {
                    value: e.target.value,
                    validationId: validation.id,
                  },
                });
              }}
            >
              {field.possibleValues?.map((value) => (
                <FormControlLabel
                  key={value.id}
                  value={value.id}
                  control={<Radio />}
                  label={value.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }
      case "DropDown": {
        return (
          <TextField
            label={field.label}
            fullWidth
            select
            value={validation.value}
            onChange={(e) => {
              dispatch({
                type: "updateValidationValue",
                payload: {
                  value: e.target.value,
                  validationId: validation.id,
                },
              });
            }}
          >
            {field.possibleValues.map((possibleValue) => (
              <MenuItem key={possibleValue.id} value={possibleValue.id}>
                {possibleValue.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }
      case "Text":
      case "LongText": {
        return (
          <TextField
            label="Answer Value"
            size="small"
            value={validation.value}
            onChange={(e) =>
              dispatch({
                type: "updateValidationValue",
                payload: {
                  value: e.target.value,
                  validationId: validation.id,
                },
              })
            }
          />
        );
      }
      case "Number": {
        return (
          <TextField
            type="number"
            fullWidth
            value={validation.value}
            onChange={(e) => {
              var newValue = validation.value;
              var shouldUpdate = false;
              if (e.target.value.length === 0) {
                newValue = null;
                shouldUpdate = true;
              }
              if (e.target.value.indexOf(".") > -1) {
                let newFloat = parseFloat(e.target.value);
                if (!isNaN(newFloat)) {
                  shouldUpdate = true;
                  newValue = newFloat;
                }
              } else {
                let newInt = parseInt(e.target.value);

                if (!isNaN(newInt)) {
                  shouldUpdate = true;
                  newValue = newInt;
                }
              }
              if (shouldUpdate) {
                dispatch({
                  type: "updateValidationValue",
                  payload: {
                    value: newValue,
                    validationId: validation.id,
                  },
                });
              }
            }}
            helperText={
              <span style={{ fontWeight: "bold" }}>
                Only numbers in this field.
              </span>
            }
          />
        );
      }
    }
  }

  return (
    <>
      {validations.length > 0 ? (
        <Stack
          sx={{ backgroundColor: "#F0F0F0" }}
          padding={1}
          maxHeight="100%"
          borderRadius={1}
        >
          {validations.map((validation, i) => {
            const validationHasError = state.errors.some(
              (error) => error.validationId === validation.id
            );
            return (
              <Accordion key={validation.id}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <Typography>{"Validation " + (i + 1)}</Typography>
                    {validationHasError && <Error htmlColor="red" />}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" alignItems="stretch">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="start"
                    >
                      <FormControl>
                        <FormLabel>Run validation on:</FormLabel>
                        <RadioGroup
                          value={validation.onTrigger}
                          onChange={(e) => {
                            let newValue = e.target.value;
                            if (
                              newValue !== "FormSave" &&
                              newValue !== "ValueChange" &&
                              newValue !== "FormCreate"
                            ) {
                              return;
                            }
                            dispatch({
                              type: "updateValidationTrigger",
                              payload: {
                                validationId: validation.id,
                                newValue: newValue,
                              },
                            });
                          }}
                        >
                          {triggers.map((trigger) => (
                            <FormControlLabel
                              label={trigger.label}
                              control={<Radio />}
                              value={trigger.value}
                              key={trigger.value}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <Button
                        color="error"
                        endIcon={<Clear />}
                        onClick={() =>
                          dispatch({
                            type: "deleteValidation",
                            payload: {
                              validationId: validation.id,
                            },
                          })
                        }
                      >
                        Delete
                      </Button>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1.5rem",
                      }}
                    >
                      <TextField
                        select
                        sx={{ flex: 5 }}
                        size="small"
                        label="Action"
                        value={validation?.action ?? null}
                        error={validation?.action === null}
                        onChange={(e) =>
                          dispatch({
                            type: "updateValidationAction",
                            payload: {
                              validationId: validation.id,
                              newValue: e.target.value,
                            },
                          })
                        }
                      >
                        {actions.map((option, i) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Box
                        sx={{
                          flex: 3,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography textAlign="center" fontSize="1rem">
                          this field{" "}
                          {validation.action == "Error" ||
                          validation.action == "Answer"
                            ? "with"
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        flex: 3,
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: "0.5rem",
                      }}
                    >
                      {validation.action === "Error" && (
                        <Stack width="100%" marginTop="1rem">
                          <TextField
                            label="Error Message"
                            size="small"
                            value={validation.message}
                            onChange={(e) =>
                              dispatch({
                                type: "updateValidationMessage",
                                payload: {
                                  messageValue: e.target.value,
                                  validationId: validation.id,
                                },
                              })
                            }
                          />
                        </Stack>
                      )}
                      {validation.action === "Answer" && (
                        <Stack width="100%" marginTop="1rem">
                          {getAnswerValueInputControl(validation)}
                        </Stack>
                      )}
                      <Typography textAlign="center" fontSize="1rem">
                        when all of the following conditions are met.
                      </Typography>
                      <Button
                        sx={{ alignSelf: "center", marginY: "1rem" }}
                        onClick={() =>
                          dispatch({
                            type: "createExpression",
                            payload: { validationId: validation.id },
                          })
                        }
                        variant="outlined"
                        startIcon={<Add />}
                      >
                        Add Condition
                      </Button>
                    </Box>
                    <Expressions validation={validation} />
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
      ) : (
        <>
          <Typography
            sx={{ display: "flex", alignSelf: "center" }}
            color="GrayText"
          >
            No validations on this field yet.
          </Typography>
        </>
      )}
      <Button
        sx={{ display: "flex", alignSelf: "center", marginTop: "0.5rem" }}
        variant="contained"
        size="small"
        endIcon={<Add />}
        onClick={() => dispatch({ type: "createValidation" })}
      >
        Add Validation
      </Button>
    </>
  );
}
