import { Action, PageState } from "../../components/TypeDefinitions";
import updateIsVisibleFields from "./utilities/updateIsVisibleFields";

export function deleteValidation<
  E extends Extract<
    Action,
    {
      type: "deleteValidation";
    }
  >
>(action: E, draft: PageState): void {
  const { validationId } = action.payload;
  const formId = draft.formId;
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => formId === form.id
    );

  if (formIndex === -1) return;

  const validationIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.findIndex((validation) => validation.id === validationId);

  draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.splice(validationIndex, 1);

  updateIsVisibleFields(draft);
}
