import React from "react";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const MultiSelect = (props) => {
  var ac = (
    <Autocomplete
      sx={{ m: 1 }}
      multiple
      options={props.options}
      getOptionLabel={(option) => option.value}
      disableCloseOnSelect
      defaultValue={props.defaultValue}
      isOptionEqualToValue={(option, value) => {
        var result = option.value == value.value;
        return result;
      }}
      onChange={(e, newValue) => {
        props.onChange(newValue);
      }}
      renderInput={(params) => {
        var input = (
          <TextField
            {...params}
            variant="outlined"
            label={props.label}
            placeholder={props.placeholder}
          />
        );

        return input;
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <MenuItem
            {...props}
            key={option.value}
            value={option.value}
            sx={{ justifyContent: "space-between" }}
          >
            {option.value}
            {selected ? <CheckIcon color="info" /> : null}
          </MenuItem>
        );
      }}
    />
  );

  return ac;
};

export default MultiSelect;
