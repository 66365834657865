import { React, useContext, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  Paper,
  IconButton,
  Box,
  Stack,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  DragIndicator,
  Edit,
  VisibilityOff,
  MoreVert,
  ContentCopy,
  DeleteOutline,
  ContentCut,
} from "@mui/icons-material";
import ChildField from "./ChildField";
import { FormCtx } from "../FormWrapper/BuilderWrapper";

export default function FormElement({ fieldData, index, thereIsMotion }) {
  const { state, dispatch } = useContext(FormCtx);
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setEditor = (fieldId) =>
    dispatch({
      type: "setEditor",
      payload: {
        fieldId: fieldId,
      },
    });

  const editModeDisplaySettings = {
    border: "2px solid gold",
    borderLeft: "10px solid gold",
  };
  const parentSettings = {
    border: "1px solid blue",
  };
  const label = (
    <>
      {fieldData.label}{" "}
      {fieldData.isRequired === true && <span style={{ color: "red" }}>*</span>}
    </>
  );

  const form = state.data.inspectionTypeTemplate.configuration.forms.find(
    (form) => form.id === state.formId
  );

  const validationsOnEditingField = form.validations.filter(
    (validation) => validation.field === state.editingFieldId
  );

  const isParent = validationsOnEditingField.some((validation) =>
    validation.expressions.some(
      (expression) => expression.fieldToCompare === fieldData.id
    )
  );

  const disabled = form.name === "";

  const isEditMode = fieldData.id === state.editingFieldId;

  return (
    <Draggable draggableId={fieldData.id} index={index}>
      {(provided, snapshot) => (
        <Paper
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{
            "&:hover .revealOnHover": {
              visibility: thereIsMotion ? "hidden" : "visible",
            },
            ".revealOnHover": {
              visibility: snapshot.isDragging ? "visible" : "hidden",
            },
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "end",
            width: "100%",
            padding: "10px",
            borderRadius: "10px",
            marginBottom: "1rem",
            border: "1px solid rgba(0,0,0,0.0)",
            ...(isEditMode && editModeDisplaySettings),
            ...(isParent && parentSettings),
            boxShadow: snapshot.isDragging ? 10 : 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                width: "100%",
                justifyContent: "space-between",
              }}
              onClick={(e) =>
                dispatch({
                  type: "setEditor",
                  payload: {
                    fieldId: fieldData.id,
                  },
                })
              }
            >
              {!disabled && (
                <IconButton {...provided.dragHandleProps}>
                  <DragIndicator sx={{ fontSize: 40 }} color="disabled" />
                </IconButton>
              )}
              <ChildField fieldData={{ ...fieldData, label: label }} />
              <Box sx={{ display: "flex", flexGrow: 1 }} />
            </div>
            <Stack
              direction="row"
              marginTop="8px"
              justifyContent="end"
              spacing={1}

              // sx={{
              //   display: "flex",
              //   flexDirection: "row",
              //   width: "7rem",
              //   marginTop: "8px",
              //   justifyContent: "space-evenly",
              // }}
            >
              {!disabled && (
                <>
                  <div
                    style={{ minHeight: "100%" }}
                    onClick={(e) =>
                      dispatch({
                        type: "setEditor",
                        payload: { fieldId: fieldData.id },
                      })
                    }
                  >
                    <IconButton
                      disabled={true}
                      sx={{
                        visibility: fieldData.isVisible ? "hidden" : "visible",
                      }}
                    >
                      <VisibilityOff color="disabled" />
                    </IconButton>
                  </div>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {state.editingFieldId !== fieldData.id && (
                      <MenuItem
                        onClick={() => {
                          setEditor(fieldData.id);
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        dispatch({
                          type: "copyField",
                          payload: {
                            fieldId: fieldData.id,
                          },
                        });
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <ContentCopy />
                      </ListItemIcon>
                      <ListItemText>Copy</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch({
                          type: "copyField",
                          payload: {
                            fieldId: fieldData.id,
                          },
                        });
                        dispatch({
                          type: "deleteField",
                          payload: {
                            fieldId: fieldData.id,
                          },
                        });
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <ContentCut />
                      </ListItemIcon>
                      <ListItemText>Cut</ListItemText>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch({
                          type: "toggleDelete",
                          payload: {
                            deleteMessage: "Delete this field?",
                            fieldId: fieldData.id,
                          },
                        });
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <DeleteOutline />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Stack>
          </Box>
        </Paper>
      )}
    </Draggable>
  );
}
