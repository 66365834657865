import { PageState, Action, Form } from "../../components/TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function createForm<
  E extends Extract<
    Action,
    {
      type: "createForm";
    }
  >
>(action: E, draft: PageState): void {
  const { type } = action.payload;

  const newFormFn = (): Form => {
    const params = {
      fields: [],
      formConfigurationId: draft.data.inspectionTypeTemplate.configuration.id,
      subformIds: [],
      id: uuidv4(),
      validations: [],
      isEmbedded: false,
      prompt: "",
      geoFeatureConfigurations: []
    };

    switch (type) {
      case "SubForm":
      case "InteriorInspection":
      case "ExteriorInspection":
      case "Questionnaire":
        return { ...params, type: type, name: "", canAddFields: true, canAddAsSubForm: true, canCopy: true, canAddSubForms: true};
      case "RightOfEntry": 
        return {...params, type: type, name: "Right Of Entry", canAddFields: false, canAddAsSubForm: false, canCopy: false, canAddSubForms: false};
      case "ESignature": 
        return { ...params, type: type, name: "E-Signature", canAddFields: false, canAddAsSubForm: false, canCopy: false, canAddSubForms: false};
      case "SpecialConditions": 
        return { ...params, type: type, name: "Special Conditions", canAddFields: false, canAddAsSubForm: false, canCopy: false, canAddSubForms: false};
      case "AdditionalPhotos": 
        return { ...params, type: type, name: "Additional Photos", canAddFields: false, canAddAsSubForm: false, canCopy: false, canAddSubForms: false};
        case "TimeLog": 
          return { ...params, type: type, name: "Time Log", canAddFields: false, canAddAsSubForm: false, canCopy: false, canAddSubForms: false};
      case "RoomInspection":
      case "ComponentInspection":
        return { ...params, type: type, references: [], name:"", canAddFields: true, canAddAsSubForm: true, canCopy: true, canAddSubForms: true};
      default:
        const _exhaustiveCheck: never = type;
        return _exhaustiveCheck;
    }
  };

  const newForm = newFormFn();

  draft.data.inspectionTypeTemplate.configuration.forms.push(newForm);
  draft.navigate(`${newForm.id}`);
}
