import { Add, AddLink, DragHandle, LinkOff } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormCtx } from "../FormWrapper/BuilderWrapper";
export default function SubForms() {
  const { state, dispatch } = useContext(FormCtx);
  const [open, setOpen] = useState(false);
  const [subform, setSubform] = useState();
  const [createSubform, setCreateSubform] = useState({ open: false, type: "" });

  const currentForm =
    state.data.inspectionTypeTemplate.configuration.forms.find(
      (form) => form.id === state.formId
    );

  const subformIds =
    currentForm?.subformIds ?? false ? currentForm.subformIds : [];

  const subforms = subformIds.map((subformId) =>
    state.data.inspectionTypeTemplate.configuration.forms.find(
      (form) => form.id === subformId
    )
  );

  const availableSubforms =
    state.data.inspectionTypeTemplate.configuration.forms.filter((form) => {
      if (form.id === state.formId) return false;
      if(!form.canAddAsSubForm) return false;
      if (currentForm.subformIds.includes(form.id)) return false;
      return true;
    });

  const formTypes = [
    { type: "ComponentInspection", name: "Component Inspection" },
    { type: "RoomInspection", name: "Room Inspection" },
    { type: "SubForm", name: "SubForm" },
    { type: "ExteriorInspection", name: "Exterior Inspection" },
    { type: "InteriorInspection", name: "Interior Inspection" },
  ];

  return (
    <>
      <Stack marginTop={"2rem"} width="100%" alignItems="center">
        {subforms.length > 0 && (
          <SubformDnD>
            {subforms.map((subform, i) => (
              <SubformDraggable
                key={subform.id}
                label={subform.name}
                index={i}
                id={subform.id}
              />
            ))}
          </SubformDnD>
        )}
        <ButtonGroup>
          <Button
            endIcon={<Add />}
            onClick={(e) => setCreateSubform({ ...createSubform, open: true })}
          >
            Create Subform
          </Button>
          {availableSubforms.length > 0 && (
            <Button endIcon={<AddLink />} onClick={(e) => setOpen(true)}>
              Link Subform
            </Button>
          )}
        </ButtonGroup>
      </Stack>
      <Dialog open={open} handleClose={(e) => setOpen(false)}>
        <DialogTitle>Link which subform?</DialogTitle>
        <DialogContent>
          <TextField
            select
            value={subform}
            fullWidth
            onChange={(e) => setSubform(e.target.value)}
          >
            {availableSubforms.map((subform) => (
              <MenuItem value={subform.id} key={subform.id}>
                {subform.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpen(false)}>Cancel</Button>
          <Button
            onClick={(e) => {
              dispatch({
                type: "linkSubForm",
                payload: {
                  parentFormId: state.formId,
                  subFormId: subform,
                },
              });
              setOpen(false);
            }}
          >
            Link
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={createSubform.open}
        handleClose={(e) => setCreateSubform({ ...createSubform, open: false })}
      >
        <DialogTitle>Create subform of which type?</DialogTitle>
        <DialogContent>
          <TextField
            select
            value={subform}
            fullWidth
            onChange={(e) =>
              setCreateSubform({ ...createSubform, type: e.target.value })
            }
          >
            {formTypes.map((type) => (
              <MenuItem value={type.type} key={type.type}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => setCreateSubform({ ...createSubform, open: false })}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              dispatch({
                type: "createSubform",
                payload: {
                  parentFormId: state.formId,
                  newFormType: createSubform.type,
                },
              });
              setOpen(false);
            }}
          >
            Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const SubformDnD = ({ children }) => {
  const { dispatch } = useContext(FormCtx);
  return (
    <DragDropContext
      onDragEnd={(result) => {
        const { source, destination } = result;
        if (source == null || destination == null) return;
        let oldIndex = source.index;
        let newIndex = destination.index;
        dispatch({
          type: "moveSubform",
          payload: {
            initialIndex: oldIndex,
            newIndex: newIndex,
          },
        });
      }}
    >
      <Droppable droppableId="subformDroppable">
        {(provided, snapshot) => (
          <List
            sx={{ width: "100%" }}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const SubformDraggable = ({ label, id, index }) => {
  const { dispatch, state } = useContext(FormCtx);
  const navigate = useNavigate();
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          {/* {index !== 0 && <Divider />} */}
          <Stack direction="row">
            <IconButton {...provided.dragHandleProps}>
              <DragHandle color="disabled" />
            </IconButton>
            <ListItem
              disablePadding
              secondaryAction={
                <IconButton
                  onClick={(e) =>
                    dispatch({
                      type: "unlinkSubForm",
                      payload: {
                        parentFormId: state.formId,
                        subFormId: id,
                      },
                    })
                  }
                >
                  <LinkOff />
                </IconButton>
              }
            >
              <ListItemButton onClick={(e) => navigate(`../${id}`)}>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          </Stack>
        </div>
      )}
    </Draggable>
  );
};
