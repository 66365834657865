import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  ExitToApp,
  ArrowBack,
  ArrowForward,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { PersonContext } from "src/utils/contexts/person";
import { ProjectContext } from "src/utils/contexts/project";
import checkboxColumn from "src/pages/Inspections/Create/CheckboxColumn";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getIsoNow } from "src/utils/dates";
import {
  IFormInstance,
  IFormJson,
} from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay";
import { decode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/decode";
import {
  State,
  WebInspectionCtx,
  initialize,
  validateForm,
} from "../MyInspections/routes/InspectionWebView/controller";
import { getFormInstancesIndex, getFormsIndex } from "../MyInspections/routes/InspectionWebView/wrapper";
import { FirmContext } from "src/utils/contexts/firm";
import { encode } from "src/pages/Review/pages/InspectionAssignment/components/InspectionDisplay/components/utilities/encode";
import { downloadAllAsZip } from "../MyInspections/routes/InspectionWebView/downloadReport";
import { genPDFs, downloadZip } from "../MyInspections/routes/InspectionWebView/downloadReport";
import JSZip from "jszip";

const MY_INSPECTIONS = gql`
  query InspectionAssignments(
    $inspectionAssignment: InputInspectionAssignmentParams!
  ) {
    inspectionAssignments(inspectionAssignment: $inspectionAssignment) {
      id
      personId
      firmPersonId
      inspection {
        id
        number
        inspectionLocation
        location  {
          id
          description
          city
          buildingType
          identifier
          lat
          lis
          lng
          mapboxId
          name
          neighborhood
          owner
          parentId
          projectId
          state
          street1
          street2
          street3
          tenant
          unit
          zip
        }
        inspectionType {
          id
          name
        }
      }
      inspectionId
      start
      end
      formChangesAcknowledged
      frontElevationPhotoCaptured
      isCheckedIn
      notesAcknowledged
      eSignatureAcknowledged
      rightOfEntryCaptured
      onsiteComplete
      inaccessible
      stopWorkHazardPresent
      status
      requiresDrawing
      isDeleted
      isMobile
      isWeb
    }
  }
`;

const GET_PROJECT_LOCATIONS = gql`
  query ProjectLocations($projectId: ID!) {
    projectLocations(projectId: $projectId) {
      id
      description
      city
      buildingType
      identifier
      lat
      lis
      lng
      mapboxId
      name
      neighborhood
      owner
      parentId
      projectId
      state
      street1
      street2
      street3
      tenant
      unit
      zip
    }
  }
`;

const CREATE_MAP = gql`
  mutation CreateMap($map: InputMapGenerationParams) {
    createMap(map: $map) {
      id
    }
  }
`;

const REPLICATE_INSPECTION = gql`
  mutation ReplicateInspection($dataReplication: InputDataReplicationParams) {
    replicateInspection(dataReplication: $dataReplication) {
      id
    }
  }
`;

const CREATE_INSPECTION = gql`
  mutation CreateInspection($inspection: InputInspectionParams) {
    createInspection(inspection: $inspection) {
      id
    }
  }
`;

const CREATE_INSPECTION_ASSIGNMENT = gql`
  mutation Mutation($inspectionAssignment: InputInspectionAssignmentParams) {
    createInspectionAssignment(inspectionAssignment: $inspectionAssignment) {
      id
    }
  }
`;

const COPY_FORMS = gql`
  mutation Mutation($copyFormsRequest: InputCopyFormInstancesParams) {
    copyFormInstances(copyFormsRequest: $copyFormsRequest) {
      result
    }
  }
`;

const THO_FORM_INSTANCES = gql`
  query ThoFormInstances($thoFormInstance: InputThoFormInstanceParams) {
    thoFormInstances(thoFormInstance: $thoFormInstance) {
      id
      inspectionId
      inspection {
        id
        number
        inspectionLocation
        inspectionType {
          id
          name
        }
      }
      inspectionAssignmentId
      inspectionAssignment {
        id
        personId
        firmPersonId
        inspection {
          id
          number
          inspectionLocation
          inspectionType {
            id
            name
          }
        }
        inspectionId
        start
        end
        formChangesAcknowledged
        frontElevationPhotoCaptured
        isCheckedIn
        notesAcknowledged
        eSignatureAcknowledged
        rightOfEntryCaptured
        onsiteComplete
        inaccessible
        stopWorkHazardPresent
        status
        requiresDrawing
        isDeleted
        isMobile
        isWeb
      }
      formId
      formConfigurationId
      inspectionTypeTemplateId
      inspectionTypeTemplate {
        configuration
        description
        end
        id
        isDeleted
        name
        start
      }
      reference
      referenceType
      formJson
      name
      type
      isDeleted
    }
  }
`;

const GET_GEO_RULE_RESULTS = gql`
  query GetGeoRuleResults($geoRuleResult: InputGeoRuleResultParams) {
    getGeoRuleResults(geoRuleResult: $geoRuleResult) {
      inspectionId
      geoRuleId
      expressionId
      fieldId
      formId
      validationId
      lat
      long
      value
    }
  }
`;

const GET_PROJECT_LOCATION = gql`
  query ProjectLocation($projectLocationId: ID!) {
    projectLocation(projectLocationId: $projectLocationId) {
      id
      description
      city
      buildingType
      identifier
      lat
      lis
      lng
      mapboxId
      name
      neighborhood
      owner
      parentId
      projectId
      state
      street1
      street2
      street3
      tenant
      unit
      zip
    }
  }
`;

const UPLOAD_FORM_INSTANCE = gql`
  mutation UploadThoFormInstance($thoFormInstance: InputThoFormInstanceParams) {
    uploadThoFormInstance(thoFormInstance: $thoFormInstance) {
      id
      name
    }
  }
`;

const REPORT_TEMPLATES_QUERY = gql`
  query ReportTypeTemplates(
    $reportTypeTemplate: InputReportTypeTemplateParams!
  ) {
    reportTypeTemplates(reportTypeTemplate: $reportTypeTemplate) {
      id
      reportType {
        type
      }
      templateUrl
      configuration
    }
  }
`;
const GET_MAP_CAPTURES = gql`
  query MapCaptures($mapCapture: InputMapCaptureParams!) {
    mapCaptures(mapCapture: $mapCapture) {
      id
      description
      title
      metadata
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

const GENERATE_MAP_CAPTURE_FROM_CONFIG = gql`
  mutation CreateMapFromConfig($mapCapture: InputMapCaptureParams) {
    createMapFromConfig(mapCapture: $mapCapture) {
      id
      description
      title
      metadata
      image
      inspectionId
      inspectionAssignmentId
      thoFormInstanceId
      thoFormId
      thoFormFieldId
      thoFormFieldInstanceId
      personId
      projectId
      isDeleted
    }
  }
`;

export const DataReplication = ({
  inspectionAssignment,
  initialStep,
  onComplete,
}: {
  inspectionAssignment?: any;
  initialStep?: any;
  onComplete?: any;
}) => {
  const person: any = useContext(PersonContext);
  const project: any = useContext(ProjectContext);
  const firmCtx: any = useContext(FirmContext);
  const [
    inspectionAssignmentsQuery,
    { data: assignmentsData, loading: assignmentsLoading },
  ] = useLazyQuery(MY_INSPECTIONS, {
    fetchPolicy: "network-only",
  });
  const [
    projectLoactionsQuery,
    { data: projectLocationsData, loading: projectLocationsLoading },
  ] = useLazyQuery(GET_PROJECT_LOCATIONS, {
    fetchPolicy: "network-only",
  });
  const [reportTypeTemplates, setReportTypeTemplates] = useState([]);
  const [getReportTypeTemplates, { called, data }] = useLazyQuery(
    REPORT_TEMPLATES_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );

  const [createInspection] = useMutation(CREATE_INSPECTION, {
    fetchPolicy: "network-only",
  });

  const [replicateInspection] = useMutation(REPLICATE_INSPECTION, {
    fetchPolicy: "network-only",
    onCompleted: (result)=>{
      
      onComplete();
      
      ///TODO: Go to requests list
    }
  });

  const [createMap] = useMutation(CREATE_MAP, {
    fetchPolicy: "network-only",
  });

  const [createInspectionAssignment] = useMutation(
    CREATE_INSPECTION_ASSIGNMENT,
    {
      fetchPolicy: "network-only",
    }
  );

  const [copyForms] = useMutation(COPY_FORMS, {
    fetchPolicy: "network-only",
  });

  const [
    formInstancesQuery,
    { data: formInstancesData, loading: loadingInstances },
  ] = useLazyQuery(THO_FORM_INSTANCES, {
    fetchPolicy: "network-only",
  });

  const [uploadFormInstance] = useMutation(UPLOAD_FORM_INSTANCE, {
    fetchPolicy: "network-only",
  });

  const [
    getGeoRuleResults,
    { data: geoRuleResultsData, loading: geoRuleResultsLoading },
  ] = useLazyQuery(GET_GEO_RULE_RESULTS, {
    fetchPolicy: "network-only",
  });

  const [getProjectLocation] = useLazyQuery(GET_PROJECT_LOCATION, {
    fetchPolicy: "network-only",
    onError: (response) => {},
  });
  const [generateMapCaptureFromConfig] = useMutation(
    GENERATE_MAP_CAPTURE_FROM_CONFIG,
    {
      fetchPolicy: "network-only",
      onCompleted: (response) => {},
    }
  );

  const [getMapCaptures] = useLazyQuery(GET_MAP_CAPTURES, {
    fetchPolicy: "network-only",
  });

  const [selectionModel, setSelectionModel] = useState([]);
  const [currentStep, setCurrentStep] = useState(initialStep ? initialStep : 1);
  const [selectionRecord, setSelectionRecord] = useState(
    inspectionAssignment ? [inspectionAssignment] : []
  );
  const [selectionAddressModel, setAddressSelectionModel] = useState([]);
  const [selectionAddressRecord, setAddressSelectionRecord] = useState([]);
  const [selectionValidatedModel, setValidatedSelectionModel] = useState([]);
  const [selectionValidatedRecord, setValidatedSelectionRecord] = useState([]);
  const [processedAssignments, setProcessedAssignments] = useState([]);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [generatingInspections, setGeneratingInspections] = useState(false);
  const [validatingFormData, setValidatingFormData] = useState(false);
  const [dataReplicationComplete, setDataReplicationComplete] = useState(false);
  const [createdInspectionIds, setCreatedInspectionIds] = useState([]);
  const [createdInspectionAssignmentIds, setCreatedInspectionAssignmentIds] =
    useState([]);
  const [
    processedLocationsWithoutAssignment,
    setProcessedLocationsWithoutAssignment,
  ] = useState([]);
  const [replicatedInspectionAssignments, setReplicatedInspectionAssignments] =
    useState([]);
  const [validatedFormStates, setValidatedFormStates] = useState([]);

  const handleSelection = (ids: any) => {
    let selectedIDs = new Set(ids);
    let selectedRowData = processedAssignments.filter((row: any) =>
      selectedIDs.has(row.id)
    );
    setSelectionRecord(selectedRowData);
    let idsArray: any = Array.from(selectedIDs);
    setSelectionModel(idsArray);
    setCurrentStep(2);
  };

  const handleAddressSelection = (ids: any) => {
    let selectedIDs = new Set(ids);
    let selectedRowData = processedLocationsWithoutAssignment.filter(
      (row: any) => selectedIDs.has(row.id)
    );
    setAddressSelectionRecord(selectedRowData);
    let idsArray: any = Array.from(selectedIDs);
    setAddressSelectionModel(idsArray);
  };

  const handleValidatedSelection = (ids: any) => {
    let selectedIDs = new Set(ids);
    let selectedRowData = replicatedInspectionAssignments.filter((row: any) =>
      selectedIDs.has(row.id)
    );

    setValidatedSelectionRecord(selectedRowData);
    let idsArray: any = Array.from(selectedIDs);
    setValidatedSelectionModel(idsArray);
  };

  useEffect(() => {
    if (person.person && project.project && !assignmentsLoading) {
      inspectionAssignmentsQuery({
        variables: {
          inspectionAssignment: {
            projectId: project.project.id,
            statusValues: ["Onsite Complete", "Approved"],
          },
        },
      });
    }
  }, [person, project]);

  useEffect(() => {
    if (assignmentsData && !projectLocationsLoading) {
      projectLoactionsQuery({
        variables: {
          projectId: project.project.id,
        },
      });
    }
  }, [assignmentsData]);

  useEffect(() => {
    if (assignmentsData && projectLocationsData && !dataProcessing) {
      setDataProcessing(true);
      let assignmentResults: any = [];
      for (let i = 0; i < assignmentsData.inspectionAssignments.length; i++) {
        let assignment: any = assignmentsData.inspectionAssignments[i];
        let address = assignment.inspection.location;

        assignmentResults.push({
          ...assignment,
          formattedAddress: address
            ? address.street1 +
              " " +
              address.city +
              " " +
              address.state +
              " " +
              address.zip
            : "Unknown",
        });
      }
      setProcessedAssignments(assignmentResults);
    }
  }, [assignmentsData, projectLocationsData]);

  useEffect(() => {
    if (processedAssignments && dataProcessing) {
      let locationIds = processedAssignments.map(
        (e: any) => e.inspection.inspectionLocation
      );
      debugger;
      let locationResults: any = projectLocationsData.projectLocations.filter(
        (x: any) => !locationIds.includes(x.id)
      );
      debugger;
      setProcessedLocationsWithoutAssignment(locationResults);
      setDataProcessing(false);
    }
  }, [processedAssignments]);

  useEffect(() => {}, [dataProcessing, currentStep]);

  useEffect(() => {
    if (selectionRecord) {
    }
  }, [selectionRecord, selectionAddressRecord]);

  useEffect(() => {
    if (validatingFormData) {
      let assignmentsValidated = 0;
      let validated: any = replicatedInspectionAssignments;
      let formStates: any = [];
      // loadedFormData.forEach((e: any) => {
      //   assignmentsValidated++;
      //   getProjectLocation({
      //     variables: {
      //       projectLocationId: e.inspection.inspectionLocation,
      //     },
      //   }).then((pLoc) => {
      //     var myLoc: any = pLoc.data.projectLocation;
      //     let formConfig = e.inspectionTypeTemplate.configuration;
      //     debugger;
      //     let formState = {
      //       formMap: getFormsIndex(formConfig),
      //       formInstanceMap:getFormInstancesIndex(e.formInstances),
      //       configuration: formConfig,
      //       inspection: e.inspection,
      //       inspectionAssignment: e.inspectionAssignment,
      //       formInstances: e.formInstances,
      //       thoFormIndexesRequiringSync: [],
      //       onsiteCompleteSync: false,
      //       onsiteCompleteTriggered: false,
      //       message: null,
      //       firmId: firmCtx.firm.id,
      //       personId: person.id,
      //       callback: null,
      //       expressionsNeedingRequest: [],
      //       mapCapturesNeedingGeneration: [],
      //       inspectionTypeTemplateId: e.inspectionTypeTemplate.id,
      //       formattedAddress: myLoc
      //         ? myLoc.street1 +
      //           " " +
      //           myLoc.city +
      //           " " +
      //           myLoc.state +
      //           " " +
      //           myLoc.zip
      //         : "Unknown",
      //     } as State;

      //     formState.formInstances.forEach((f: any) => {
      //       initialize(f, formState, person, project);
      //     });
      //     let formattedAddress = myLoc
      //       ? myLoc.street1 +
      //         " " +
      //         myLoc.city +
      //         " " +
      //         myLoc.state +
      //         " " +
      //         myLoc.zip
      //       : "Unknown";

      //     validated.push({
      //       id: e.inspectionAssignment.id,
      //       formattedAddress: formattedAddress,
      //       validationStatus: true,
      //     });

      //     if (formState.expressionsNeedingRequest) {
      //       let promises = formState.expressionsNeedingRequest.map((a) => {
      //         return getGeoRuleResults({
      //           variables: {
      //             geoRuleResult: {
      //               inspectionId: a.inspectionId,
      //               geoRuleId: a.geoRuleId,
      //               expressionId: a.expressionId,
      //               fieldId: a.fieldId,
      //               formId: a.formId,
      //               validationId: a.validationId,
      //               lat: myLoc.lat,
      //               long: myLoc.lng
      //             },
      //           },
      //         });
      //       });
      //       var ranCount = 0;
      //       let expressionsNeedingRequestResults: any = [];
      //       let uploadPromises: any = [];
            
      //       if (promises.length === 0) {
      //         validateFormState(
      //           formState,
      //           expressionsNeedingRequestResults,
      //           formStates,
      //           validated,
      //           uploadPromises,
      //           assignmentsValidated
      //         );
      //       } else {
      //         for (var z = 0; z < promises.length; z++) {
      //           promises[z].then((result) => {
                  
      //             ranCount++;
      //             let ruleResult = result.data.getGeoRuleResults;
      //             // GeoFeatureConfigurations
      //             expressionsNeedingRequestResults.push(ruleResult);
      //             if (ranCount === promises.length) {
      //               validateFormState(
      //                 formState,
      //                 expressionsNeedingRequestResults,
      //                 formStates,
      //                 validated,
      //                 uploadPromises,
      //                 assignmentsValidated
      //               );
      //             }
      //           });
      //         }
      //       }
      //     }
      //   });
      // });
    }
  }, [validatingFormData]);

  useEffect(() => {}, [dataReplicationComplete]);

  const apiRef = useGridApiRef();

  // function validateFormState(
  //   formState: any,
  //   expressionsNeedingRequestResults: any,
  //   formStates: any,
  //   validated: any,
  //   uploadPromises: any,
  //   assignmentsValidated: any
  // ) {
  //   formState.expressionsNeedingRequest = expressionsNeedingRequestResults;

  //   formState.formInstances.forEach((f: any) => {
  //     validateForm(f, formState, "FormCreate");
  //     validateForm(f, formState, "FormSave");

  //     if (!f.isValid) {
  //       for (let v = 0; v < validated.length; v++) {
  //         if (validated[v].id === formState.inspectionAssignment.id) {
  //           validated[v].validationStatus = false;
  //         }
  //       }
  //     }
  //     uploadPromises.push(
  //       uploadFormInstance({
  //         variables: {
  //           thoFormInstance: {
  //             type: f.type,
  //             requiresArchive: false,
  //             referenceType: f.referenceType,
  //             reference: f.reference,
  //             id: f.id,
  //             name: f.name,
  //             isDeleted: f.isDeleted,
  //             inspectionTypeTemplateId: f.inspectionTypeTemplateId,
  //             inspectionId: f.inspectionId,
  //             inspectionAssignmentId: f.inspectionAssignmentId,
  //             formId: f.formId,
  //             formConfigurationId: f.formConfigurationId,
  //             formJson: encode(f),
  //           },
  //         },
  //       })
  //     );
  //   });
  //   formStates.push(formState);
  //   Promise.all(uploadPromises).then(() => {
  //     if (assignmentsValidated === loadedFormData.length) {
  //       setReplicatedInspectionAssignments(validated);
  //       setValidatingFormData(false);
  //       setDataReplicationComplete(true);
  //       setValidatedFormStates(formStates);
  //     }
  //   });
  // }

  function getAvailableAddressRows() {
    var selected = selectionRecord[0];
    let finalResults: any = processedLocationsWithoutAssignment.filter(
      (z: any) => z.neighborhood === selected.inspection.location.neighborhood
    );
    return finalResults;
  }

  function handleReplicateInspection(){

    var record: any = selectionRecord[0];
    var locationsToReplicate = selectionAddressRecord.map((e: any) => {return e.id});
      replicateInspection({
        variables: {
          dataReplication: {
            inspectionLocations: locationsToReplicate,
            inspectionId: record.inspection.id,
            inspectionAssignmentId: record.id,
            personId: person.person.id,
            firmId: firmCtx.firm
          },
        },
      });
  }

  return (
    <>
      <Typography variant="h3">Data Replication</Typography>
      {(projectLocationsLoading || assignmentsLoading || dataProcessing) && (
        <CircularProgress />
      )}
      <Stack>
        {assignmentsData &&
          processedAssignments &&
          !dataProcessing &&
          currentStep === 1 && (
            <Paper elevation={6}>
              <Box sx={{ padding: 2 }}>
                <Typography variant="h4">
                  Step 1.{")"} Select a completed inspection:
                </Typography>
                <DataGridPremium
                  sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                      {
                        display: "none",
                      },
                  }}
                  rows={processedAssignments}
                  apiRef={apiRef}
                  columnVisibilityModel={{
                    id: false,
                  }}
                  disableRowSelectionOnClick
                  columns={[
                    { ...checkboxColumn(apiRef) },
                    { field: "id", headerName: "Id" },
                    {
                      field: "formattedAddress",
                      headerName: "Address",
                      width: 450,
                    },
                    {
                      field: "start",
                      headerName: "Inspection Date",
                      width: 200,
                      valueFormatter: (params) =>
                        moment(params?.value).format("MM/DD/YYYY hh:mm A"),
                    },
                    { field: "status", headerName: "Status", width: 200 },

                    {
                      field: "view",
                      headerName: "",
                      width: 10,

                      renderCell: (params: any) => {
                        return (
                          // <Link to={`${params.number}?inspection=${params.id}`}>
                          //   <Edit />
                          // </Link>
                          <Link
                            to={`/app/myInspections/${params.row.id}`}
                            target="_blank"
                          >
                            <ExitToApp />
                          </Link>
                        );
                      },
                    },
                  ]}
                  checkboxSelection
                  onRowSelectionModelChange={handleSelection}
                />
              </Box>
            </Paper>
          )}
        {projectLocationsData &&
          processedLocationsWithoutAssignment &&
          !dataProcessing &&
          currentStep === 2 && (
            <Paper elevation={6}>
              <Button
                sx={{ margin: 2 }}
                onClick={() => {
                  setSelectionModel([]);
                  setSelectionRecord([]);
                  setCurrentStep(1);
                }}
                variant="contained"
                startIcon={<ArrowBack></ArrowBack>}
              >
                Previous Step
              </Button>
              <Button
                sx={{ margin: 2 }}
                onClick={() => {
                  setCurrentStep(3);
                }}
                variant="contained"
                startIcon={<ArrowForward></ArrowForward>}
              >
                Next Step
              </Button>
              <Box sx={{ padding: 2 }}>
                <Typography variant="h4">
                  Step 2.{")"} Select address{"(es)"} in the same neighborhood to replicate the
                  inspection data to:
                </Typography>
                <DataGridPremium
                  rows={getAvailableAddressRows()}
                  apiRef={apiRef}
                  columnVisibilityModel={{
                    id: false,
                  }}
                  autoPageSize
                  disableRowSelectionOnClick
                  columns={[
                    { ...checkboxColumn(apiRef) },
                    { field: "id", headerName: "Id" },
                    {
                      field: "street1",
                      headerName: "Street",
                      width: 300,
                    },
                    {
                      field: "city",
                      headerName: "City",
                      width: 200,
                    },
                    {
                      field: "state",
                      headerName: "State",
                    },
                    {
                      field: "zip",
                      headerName: "Zip",
                    },
                    {
                      field: "unit",
                      headerName: "Unit",
                    },
                    {
                      field: "neighborhood",
                      headerName: "Neighborhood",
                    },
                  ]}
                  checkboxSelection
                  onRowSelectionModelChange={handleAddressSelection}
                />
              </Box>
            </Paper>
          )}
        <Dialog open={currentStep === 3} onClose={() => {}}>
          <DialogTitle>
            Are you sure you want to continue with replicating the selected data
            to these {selectionAddressRecord.length} address{"(es)?"}
          </DialogTitle>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setCurrentStep(1);
                setSelectionModel([]);
                setSelectionRecord([]);
                setAddressSelectionModel([]);
                setAddressSelectionRecord([]);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setGeneratingInspections(true);
                handleReplicateInspection();
                setCurrentStep(4);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* {dataReplicationComplete && (
          <Paper elevation={6}>
            <Box sx={{ padding: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4">
                  Step 3.{")"} Review and export:
                </Typography>
                {selectionValidatedRecord.length > 0 && (
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      let pdfs = reportTypeTemplates;
                      let formStates = validatedFormStates;
                      let mapCaptures: any = null;
                      let currentZip = new JSZip();
                      let formStateCount = 0;
                      formStates.forEach((fs: any) => {
                        getMapCaptures({
                          variables: {
                            mapCapture: {
                              inspectionId: fs.inspection.id,
                              inspectionAssignmentId:
                                fs.inspectionAssignment.id,
                            },
                          },
                        }).then((response: any) => {
                          if (response.data.mapCaptures) {
                            var responseMapCaptures: any = [
                              ...response.data.mapCaptures,
                            ];
                            for (
                              var i = 0;
                              i < responseMapCaptures.length;
                              i++
                            ) {
                              var newMapCapture = { ...responseMapCaptures[i] };
                              newMapCapture.image =
                                "data:image/png;base64, " + newMapCapture.image;
                              responseMapCaptures[i] = newMapCapture;
                            }
                            mapCaptures = responseMapCaptures;
                          }

                          let myPDFs = pdfs.filter(
                            (pdf: any) =>
                              pdf.inspectionTypeTemplateId ===
                              fs.inspectionTypeTemplateId
                          );

                          genPDFs(
                            currentZip,
                            myPDFs,
                            fs,
                            mapCaptures,
                            fs.formattedAddress
                          ).then((result: any) => {
                            formStateCount++;
                            currentZip = result;
                            if(formStateCount === formStates.length){
                              downloadZip(currentZip);
                            }
                          });
                        });
                      });
                    }}
                  >
                    Download Selected Reports
                  </Button>
                )}
              </Box>
              <DataGridPremium
                rows={replicatedInspectionAssignments}
                apiRef={apiRef}
                columnVisibilityModel={{
                  id: false,
                }}
                disableRowSelectionOnClick
                columns={[
                  { ...checkboxColumn(apiRef) },
                  { field: "id", headerName: "Id" },
                  {
                    field: "formattedAddress",
                    headerName: "Address",
                    width: 450,
                  },
                  {
                    field: "validationStatus",
                    headerName: "Validation Status",
                    width: 200,

                    renderCell: (params: any) => {
                      return (
                        // <Link to={`${params.number}?inspection=${params.id}`}>
                        //   <Edit />
                        // </Link>
                        params.row.validationStatus ? (
                          <CheckCircle color="success"></CheckCircle>
                        ) : (
                          <Cancel color="error"></Cancel>
                        )
                      );
                    },
                  },

                  {
                    field: "view",
                    headerName: "",
                    width: 10,

                    renderCell: (params: any) => {
                      return (
                        // <Link to={`${params.number}?inspection=${params.id}`}>
                        //   <Edit />
                        // </Link>
                        <Link
                          to={`/app/myInspections/${params.row.id}`}
                          target="_blank"
                        >
                          <ExitToApp />
                        </Link>
                      );
                    },
                  },
                ]}
                checkboxSelection
                onRowSelectionModelChange={handleValidatedSelection}
              />
            </Box>
          </Paper>
        )} */}
      </Stack>
    </>
  );
};
