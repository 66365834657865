import { useState, useEffect, useContext, useCallback } from "react";
// material
import { Container, Box, Typography } from '@mui/material';
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

// components
import Confirmation from "../../../components/modals/confirmation";
import Page from '../../../components/Page';
// ----------------------------------------------------------------------
import { gql } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/client';
import CreateAsset from './create';
import EditAsset from './edit';
import TableActions from '../tableActions';
import { PersonContext } from "../../../utils/contexts/person";

const DELETE_ASSETS = gql`
    mutation deleteAssets($ids: [ID]!){
      deleteAssets(ids: $ids) {
        id
        name
      }
    }
  `;

const GET_ASSETS = gql`
    query assets($firmUnitId: ID) {
    assets(firmUnitId: $firmUnitId) {
        id
        name 
        firmUnitId
        assetType {
            id
            name
        } 
        serialNumber 
        manufacturer 
        model 
        purchaseCost
        currentValue
        location 
        maintenanceInterval
        calibrationInterval
        factoryCalibrationInterval
        purchaseDate 
    }
    }
`

const UPDATE_ASSET = gql`
    mutation updateAsset($asset: InputAssetParams){
      updateAsset(asset: $asset) {
        id
        name
      }
    }
  `;

export default function KpiList() {
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectionRecord, setSelectionRecord] = useState([]);
    let personContext = useContext(PersonContext);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenEdit = () => {
        setOpenEdit(true);
    };
    let columns = [
        { field: "name", headerName: "Name" },
        { field: "serialNumber", headerName: "Serial Number" },
        { field: "manufacturer", headerName: "Manufacturer" },
        { field: "model", headerName: "Model" },
        { field: "purchaseDate", headerName: "Purchase Date" },
        { field: "purchaseCost", headerName: "Purchase Cost" },
        { field: "currentValue", headerName: "Current Value" },
        { field: "location", headerName: "Location" },
        { field: "maintenanceInterval", headerName: "Maintenance Interval" },
        { field: "calibrationInterval", headerName: "Calibration Interval" },
        { field: "factoryCalibrationInterval", headerName: "Factory Calibration Interval" },
    ];
    const [assets, setAssets] = useState(null);
    const [getAssets, {refetch}] = useLazyQuery(GET_ASSETS, {
        fetchPolicy: "network-only",
        onCompleted: (response) => {
            setAssets(response.assets)
        },
        onError: (response) => {
            setAssets([])
            debugger
        }
    });
    const [updateAsset] = useMutation(UPDATE_ASSET)
    const [deleteAssets] = useMutation(DELETE_ASSETS, {
        onCompleted: () => {
            refetch()
        }
    })
    useEffect(
        () => {
            if (personContext.person && personContext.person.firmId && !assets) {
                getAssets({ variables: { firmUnitId: personContext.person.firmId } })
            }
        },
        [assets, getAssets, personContext]
    )
    const onDeleteAssets = (selectionModel) => {
        let ids = selectionModel.map((model) => model.id)
        deleteAssets({ variables: { ids } })
    }

    const processRowUpdate = useCallback(
        async (newRow) => {
            const asset = { id: newRow.id, ...newRow }
            updateAsset({ variables: { asset } });
            refetch();
        },
        [updateAsset, refetch]
    );
    const handleProcessRowUpdateError = useCallback((error) => { }, []);
    const [confirmation, setConfirmation] = useState({ open: false });
    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };
    if (!assets)
        return null
    return (
        <Page title="Assets | FINBACK670">
            <Confirmation
                confirmation={confirmation}
                handleClose={closeConfirmation}
            />
            <Container maxWidth="xl">
                <CreateAsset open={open} setOpen={setOpen} getAssets={refetch} />
                <EditAsset open={openEdit} setOpen={setOpenEdit} selection={selectionRecord} getAssets={refetch} />
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Assets</Typography>
                </Box>
                <div style={{ height: 600, width: "100%" }}>
                    <TableActions
                        addRecord={handleClickOpen}
                        editRecord={handleClickOpenEdit}
                        deleteRecords={onDeleteAssets}
                        selectionModel={selectionRecord}
                        setConfirmation={setConfirmation}
                    />
                    <DataGridPremium
                        rows={assets}
                        columns={columns}
                        disableRowSelectionOnClick
                        pageSize={5}
                        pageSizeOptions={[10, 25, 50]}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        slots={{ toolbar: GridToolbar }}
                        checkboxSelection
                        onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData = assets.filter((row) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectionRecord(selectedRowData);
                            setSelectionModel(ids);
                        }}
                        rowSelectionModel={selectionModel}
                    />
                </div>
            </Container>
        </Page>
    );
}
