import {
  PageState,
  Action,
  Expression,
} from "../../components/TypeDefinitions";
import { v4 as uuidv4 } from "uuid";

export function createExpression<
  E extends Extract<
    Action,
    {
      type: "createExpression";
    }
  >
>(action: E, draft: PageState): void {
  if (draft.editingFieldId === null) return;
  const { validationId } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );
  const validationIndex = draft.data.inspectionTypeTemplate.configuration.forms[
    formIndex
  ].validations.findIndex((validation) => validation.id === validationId);

  const validation =
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex]
      .validations[validationIndex];

  const newExpression = generateNewExpression(
    validationId,
    validation.expressions.length
  );

  draft.data.inspectionTypeTemplate.configuration.forms[formIndex].validations[
    validationIndex
  ].expressions.push(newExpression);
}

function generateNewExpression(
  validationId: string,
  index: number
): Expression {
  const newValidation: Expression = {
    id: uuidv4(),
    fieldToCompare: null,
    geoRuleToCompare: null,
    type: "Field",
    formValidationId: validationId,
    linkedBy: index === 0 ? null : "AND",
    operator: null,
    order: index,
    value: null,
  };
  return newValidation;
}
