import * as React from "react";

import { useParams } from "react-router-dom";
//gql-mui-rhf
import { createQuery } from "../../../utils/gql-mui-rhf/QueryAction";
import FirmUnitProfile from "./profile";
import FirmUnits from "../FirmUnits/list";
import Projects from "../FirmProjects/list";
import { GQLForm } from "../../../utils/gql-mui-rhf/FormProvider";

// material
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function FirmView() {
  const params = useParams();
  const firmUnitId = params.id;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { loading, error, data } = createQuery("firmUnit", { id: firmUnitId });
  if (loading) return <CircularProgress />;
  if (error) {
    return <div>{`${error}`}</div>;
  }
  if (data) {
    let previousElement = data.firmUnit.firmUnitId ? (
      <Link to={`/app/admin/firm-unit/${data.firmUnit.firmUnitId}`}>
        {" "}
        Parent Firm Unit
      </Link>
    ) : (
      <Link to={`/app/admin/firm/${data.firmUnit.firmId}`}>Firm</Link>
    );
    const breadcrumbs = [
      previousElement,
      <Typography key="3" color="text.primary">
        Firm Unit View
      </Typography>,
    ];
    return (
      <Page title="Dashboard | FINBACK670">
        <Container maxWidth="xl" style={{ paddingBottom: "64pt" }}>
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">{`Firm Unit Management for ${data.firmUnit.name}`}</Typography>
          </Box>
          <Box sx={{ pb: 5 }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Firm Unit Attributes" {...a11yProps(0)} />
              <Tab label="Firm Units" {...a11yProps(1)} />
              <Tab label="Firm Unit People" {...a11yProps(2)} />
              <Tab label="Projects" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ pb: 2 }}>
              <Typography variant="h5">{`Firm Unit Attributes`}</Typography>
            </Box>
            <FirmUnitProfile firmUnitId={firmUnitId} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FirmUnits
              firmId={data.firmUnit.firmId}
              firmUnitId={data.firmUnit.id}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box sx={{ pb: 2 }}>
              <Typography variant="h5">{`Firm Unit People`}</Typography>
            </Box>
            <GQLForm mutationString="createFirmUnitPerson" />
            {/* <FirmUnits firmId={firmId} /> */}
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Projects firmUnitId={data.firmUnit.id} />
          </TabPanel>
        </Container>
      </Page>
    );
  }
}
