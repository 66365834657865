import { Action, PageState } from "../../components/TypeDefinitions";

export function updateGeoFeatureConfiguration<
  E extends Extract<
    Action,
    {
      type: "updateGeoFeatureConfiguration";
    }
  >
>(action: E, draft: PageState): void {
  const { id, geoRuleId, count, order } = action.payload;

  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => form.id === draft.formId
    );

  const geoFeatureConfigurationIndex =
    draft.data.inspectionTypeTemplate.configuration.forms[
      formIndex
    ].geoFeatureConfigurations?.findIndex(
      (config) => config.id === id
    );
  if (
    draft.data.inspectionTypeTemplate.configuration.forms[formIndex]
      .geoFeatureConfigurations
  ) {
    if (geoRuleId) {
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].geoFeatureConfigurations[geoFeatureConfigurationIndex].geoRuleId =
        geoRuleId;
    }
    if (count) {
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].geoFeatureConfigurations[geoFeatureConfigurationIndex].count = count;
    }
    if (order) {
      draft.data.inspectionTypeTemplate.configuration.forms[
        formIndex
      ].geoFeatureConfigurations[geoFeatureConfigurationIndex].order = order;
    }
  }
}
