// material
import { Box, Grid, Button, Container, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from "react-hook-form";
import { useContext } from "react";

// components
import { GQLMutationAction } from '../../utils/gql-mui-rhf/SingleMutation';
import { TextField } from '../../utils/mui-rhf';
import { AuthContext } from "../../utils/contexts/auth";
import Page from '../../components/Page';

// ----------------------------------------------------------------------
function changePassword(snackbar) {
    let result = GQLMutationAction({
        mutationString: "changePassword",
        onCompleted: (response) => {
            snackbar('Changed Password', 'success');
        },
        onError: (error) =>{
            snackbar('Error Changing Password', 'error');
        }
    });
    return result;
}

export default function UserSettings() {
    let auth = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const snackbar = (message, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
    };
    const handleChangePassword = changePassword(snackbar);
    const methods = useForm();
    const { handleSubmit } = methods;
    const onSubmit = (data) => {
        
       handleChangePassword({ token: auth.token, oldPassword: data.oldPassword, password: data.newPassword })
    };
    return (
        <Page title="User Settings | FINBACK670">
            <Container maxWidth="xl">
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">Update Password</Typography>
                </Box>
                <Grid container spacing={3}>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid spacing={0} container justifyContent={'flex-start'} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <TextField isPassword={true} variant={"outlined"} id="outlined-basic" label={"Old Password"} name={"oldPassword"}  />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField isPassword={true} variant={"outlined"} id="outlined-basic" label={"New Password"} name={"newPassword"}  />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type={"submit"} variant="outlined" startIcon={<SaveIcon />}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </Grid>
            </Container>
        </Page>
    );
}
