import { Action, PageState } from "../../components/TypeDefinitions";

export function deleteForm<
  E extends Extract<
    Action,
    {
      type: "deleteForm";
    }
  >
>(action: E, draft: PageState): void {
  const { formId } = action.payload;
  const formIndex =
    draft.data.inspectionTypeTemplate.configuration.forms.findIndex(
      (form) => formId === form.id
    );

  if (formIndex === -1) return;

  const removedForm =
    draft.data.inspectionTypeTemplate.configuration.forms.splice(
      formIndex,
      1
    )[0];

  draft.data.inspectionTypeTemplate.configuration.forms.forEach((form) => {
    let subformIndex = form.subformIds.findIndex(
      (subformId) => subformId === removedForm.id
    );
    if (subformIndex !== -1) form.subformIds.splice(subformIndex, 1);
  });
  draft.editingFieldId = null;
  draft.deleteConfig = null;
}
